import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Absolute,
  Box,
  Button,
  Flex,
  Header,
  HStack,
  InputGroup,
  MoreBar,
  Popup,
  Subheader,
  TableWidget,
  Token,
} from '@revolut/ui-kit'
import { connect } from 'lape'
import { Statuses } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import {
  timeOffPolicyBalanceColumn,
  timeOffPolicyCategoryColumn,
  timeOffPolicyCreatedOnColumn,
  timeOffPolicyDetailsUrlColumn,
  timeOffPolicyGroupColumn,
  timeOffPolicyHeadcountColumn,
  timeOffPolicyNameColumn,
  timeOffPolicyPaidColumn,
  timeOffPolicyStatusColumn,
  timeOffPolicyUnitColumn,
} from '@src/constants/columns/timeOff'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import {
  bulkAssignPolicyRequests,
  timeOffAllPoliciesTableRequests,
} from '@src/api/timeOff'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { BulkAssignInterface, TimeOffPolicyTableInterface } from '@src/interfaces/timeOff'
import { IdAndName } from '@src/interfaces'
import SideBar from '@src/components/SideBar/SideBar'
import { selectorKeys } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import Form from '@src/features/Form/Form'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { TableNames } from '@src/constants/table'
import LapeSingleCheckbox from '@src/components/Inputs/LapeFields/LapeSingleCheckbox'
import { useLapeContext } from '@src/features/Form/LapeForm'
import RadioSwitch from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { COPY_FROM_POLICY_ID } from '@src/pages/Forms/TimeOffPolicies/EditTabs/common'
import Stat from '@src/components/Stat/Stat'

type PolicySource = 'new' | 'template' | null

const ROW: RowInterface<TimeOffPolicyTableInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.PREVIEW, { id })),
  cells: [
    {
      ...timeOffPolicyNameColumn,
      width: 250,
    },
    {
      ...timeOffPolicyHeadcountColumn,
      width: 100,
    },
    {
      ...timeOffPolicyGroupColumn,
      width: 240,
    },
    {
      ...timeOffPolicyCategoryColumn,
      width: 200,
    },
    {
      ...timeOffPolicyPaidColumn,
      width: 120,
    },
    {
      ...timeOffPolicyUnitColumn,
      width: 100,
    },
    {
      ...timeOffPolicyBalanceColumn,
      width: 130,
    },
    {
      ...timeOffPolicyDetailsUrlColumn,
      width: 100,
    },
    {
      ...timeOffPolicyCreatedOnColumn,
      width: 200,
    },
    {
      ...timeOffPolicyStatusColumn,
      width: 100,
    },
  ],
}

const Policies = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddTimeOffPolicies)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [newPopupOpen, setNewPopupOpen] = useState(false)
  const [copyFromPolicy, setCopyFromPolicy] = useState<IdAndName>()
  const [newPolicyType, setNewPolicyType] = useState<PolicySource>('new')

  const initialFilterBy = [
    {
      filters: [
        { id: Statuses.active, name: Statuses.active },
        { id: Statuses.draft, name: Statuses.draft },
      ],
      columnName: 'status',
      nonResettable: true,
    },
  ]

  const table = useTable(timeOffAllPoliciesTableRequests, initialFilterBy)

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <HStack space="s-32">
            <Stat label="Total" val={table.stats?.total} />
            <Stat label="Active" val={table.stats?.active} color={Token.color.green} />
            <Stat label="Draft" val={table.stats?.draft} color={Token.color.orange} />
            <Stat
              label="Archived"
              val={table.stats?.archived}
              color={Token.color.greyTone50}
            />
          </HStack>
        </TableWidget.Info>
        <TableWidget.Actions>
          {canAdd && (
            <MoreBar>
              <MoreBar.Action onClick={() => setNewPopupOpen(true)} useIcon="Plus">
                Add policy
              </MoreBar.Action>
              <MoreBar.Action onClick={() => setSidebarOpen(true)} useIcon="Lightning">
                Bulk assign
              </MoreBar.Action>
            </MoreBar>
          )}
        </TableWidget.Actions>
        <TableWidget.Table>
          <AdjustableTable
            name={TableNames.TimeOffPolicies}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="Policies will appear here"
          />
        </TableWidget.Table>
      </TableWidget>

      <Popup open={newPopupOpen} onClose={() => setNewPopupOpen(false)}>
        <Header>
          <Header.CloseButton aria-label="Close" />
          <Header.Title>New time off policy</Header.Title>
          <Header.Description>How do you want to start your policy?</Header.Description>
        </Header>
        <RadioSwitch<PolicySource>
          value={newPolicyType}
          options={[
            {
              id: 'new',
              label: 'Start from scratch',
              description:
                'You can configure all the policy settings in the following steps.',
              value: 'new',
            },
            {
              id: 'template',
              label: 'Copy from an existing policy',
              description:
                'Choose a policy that you want to duplicate and modify in the following steps.',
              value: 'template',
            },
          ]}
          onChange={val => {
            if (val === 'new') {
              setCopyFromPolicy(undefined)
            }
            setNewPolicyType(val)
          }}
        />
        {newPolicyType === 'template' && (
          <>
            <Subheader variant="nested">From which policy do you want to copy?</Subheader>
            <RadioSelectInput
              selector={selectorKeys.time_off_policies}
              label="Choose a policy"
              value={copyFromPolicy}
              onChange={val => setCopyFromPolicy(val || undefined)}
            />
          </>
        )}
        <Popup.Actions horizontal>
          <Button variant="secondary" onClick={() => setNewPopupOpen(false)}>
            Cancel
          </Button>
          <Button
            elevated
            onClick={() => {
              navigateTo(
                pathToUrl(
                  ROUTES.FORMS.TIME_OFF_POLICY.EDIT.BASICS,
                  undefined,
                  copyFromPolicy
                    ? { [COPY_FROM_POLICY_ID]: String(copyFromPolicy.id) }
                    : undefined,
                ),
              )
            }}
            disabled={newPolicyType === 'template' && !copyFromPolicy}
          >
            Start
          </Button>
        </Popup.Actions>
      </Popup>
      <SideBar
        title="Bulk assign"
        subtitle="Use this to assign a policy to multiple groups at once"
        isOpen={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      >
        <Form
          api={bulkAssignPolicyRequests}
          forceParams={{ new: 'new' }}
          disableLocalStorageCaching
        >
          <BulkAssignForm
            onSuccess={() => {
              table.refresh()
              setSidebarOpen(false)
            }}
          />
        </Form>
      </SideBar>
    </>
  )
}

interface SidebarFormProps {
  onSuccess: () => void
}

const BulkAssignForm: React.FC<SidebarFormProps> = connect(({ onSuccess }) => {
  const { values } = useLapeContext<BulkAssignInterface>()

  const CALENDAR_HEIGHT_ADJUSTMENT = '550px'

  return (
    <Flex flexDirection="column" height={0}>
      <Box pb={CALENDAR_HEIGHT_ADJUSTMENT}>
        <InputGroup>
          <LapeRadioSelectInput
            label="Group"
            selector={selectorKeys.dynamic_groups}
            name="group"
          />
          <LapeRadioSelectInput
            label="Policy"
            selector={selectorKeys.active_time_off_policies}
            name="policy"
          />
          <LapeSingleCheckbox
            name="force_use_employee_join_date"
            label="Use employee join date as start date"
            description="If employee has no join date, today's date will be used"
            checked={values.force_use_employee_join_date}
            onChange={e => {
              if (e.currentTarget.checked) {
                values.start_date = new Date().toISOString()
                values.force_use_employee_join_date = true
              } else {
                values.start_date = ''
                values.force_use_employee_join_date = false
              }
            }}
          />
          {!values.force_use_employee_join_date && (
            <LapeDatePickerInput name="start_date" label="Start date" required />
          )}
          <LapeDatePickerInput name="end_date" label="End date" />
        </InputGroup>
      </Box>

      <Absolute bottom="s-24" left="s-24" right="s-24">
        <Flex justifyContent="center">
          <NewSaveButtonWithPopup
            successText="Policies assigned"
            onAfterSubmit={onSuccess}
            useValidator
            maxWidth={375}
          />
        </Flex>
      </Absolute>
    </Flex>
  )
})

export default Policies
