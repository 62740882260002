import React from 'react'
import {
  Fixed,
  Header,
  PositionProps,
  HeaderSkeleton,
  Side,
  Avatar,
  AvatarProps,
  Token,
  Layout,
} from '@revolut/ui-kit'
import { useTheme } from '@src/styles/theme'
import { SideProps, SideVariant } from '@revolut/ui-kit/types/dist/components/Side/Side'
import { css } from 'styled-components'
import { SideBarHeaderTitle } from './SideBarHeaderTitle'
import { useCurrentTenantInfo } from '@src/api/tenants'

const fixedCSS = css`
  justify-content: center;
`

export interface SideBarProps extends Omit<PositionProps, 'title' | 'open'> {
  children: React.ReactNode
  isOpen?: boolean
  title?: React.ReactNode
  subtitle?: React.ReactNode
  onClose?: () => void
  // Is added to header right after title if you specify one
  headerContent?: React.ReactNode
  customHeader?: React.ReactNode
  zIndex?: number
  sideProps?: SideProps
  variant?: SideVariant
  loading?: boolean
  avatar?: string | null
  useIcon?: AvatarProps['useIcon']
  avatarProps?: AvatarProps
  useLayout?: boolean
}

const SideBar = ({
  children,
  isOpen = true,
  title,
  sideProps,
  onClose = () => {},
  headerContent,
  customHeader,
  subtitle,
  loading,
  variant,
  avatar,
  useIcon,
  avatarProps,
  useLayout,
  ...rest
}: SideBarProps) => {
  const theme = useTheme()
  const { isDemoMode } = useCurrentTenantInfo()

  const renderHeader = () => {
    if (loading) {
      return <HeaderSkeleton variant="item" labelBackButton="Back" />
    }

    if (customHeader) {
      return customHeader
    }

    return (
      <Header variant="item" pb={0}>
        <Header.CloseButton
          data-testid="sidebar-close"
          aria-label="Close sidebar"
          color={Token.color.foreground}
        />
        {(avatar || useIcon) && (
          <Header.Avatar>
            <Avatar
              variant="brand"
              {...(avatar ? { image: avatar } : { useIcon })}
              {...avatarProps}
            />
          </Header.Avatar>
        )}
        {title && (
          <SideBarHeaderTitle data-testid="sidebar-title">{title}</SideBarHeaderTitle>
        )}
        {subtitle && (
          <Header.Description data-testid="sidebar-description">
            {subtitle}
          </Header.Description>
        )}
        {headerContent}
      </Header>
    )
  }

  if (!isOpen) {
    return null
  }

  const renderContent = () => (
    <Side open onClose={onClose} variant={variant} height="100%" {...sideProps}>
      {renderHeader()}
      {children}
    </Side>
  )

  if (useLayout) {
    return <Layout.SideFill>{renderContent()}</Layout.SideFill>
  }

  return (
    <Fixed
      right={0}
      top={0}
      left={{ all: 0, lg: 'auto' }}
      display={{ all: 'flex', lg: 'block' }}
      bg={{ all: Token.color.layoutBackground, lg: 'transparent' }}
      zIndex={theme.zIndex.sideBar}
      pt={{ all: isDemoMode ? 's-40' : 0, lg: isDemoMode ? 's-56' : 's-16' }}
      pr={{ all: 0, lg: 's-16' }}
      pb={{ all: 0, lg: 's-16' }}
      pl={{ all: 0, lg: 's-16' }}
      height="100vh"
      css={fixedCSS}
      data-testid={`sidebar-${title}`}
      {...rest}
    >
      {renderContent()}
    </Fixed>
  )
}

export default SideBar
