import { env, Environments } from '../constants/api'
import { RadioOption } from '@components/Inputs/RadioSelect/RadioSelect'
import { FilterOption } from '@src/interfaces/data'
import * as Sentry from '@sentry/react'
import { useIsCareers } from '@src/pages/Careers/hooks/useIsCareers'
import { LocalStorageKeys } from '@src/store/auth/types'

export const saveFileByBlob = (data: string, fileName: string, type?: string) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.setAttribute('style', 'display: none')

  const blob = new Blob([data], { type })
  const url = (window as any)?.URL?.createObjectURL(blob)
  a.href = url
  a.download = fileName
  a.click()
  ;(window as any)?.URL?.revokeObjectURL(url)
  a.remove()
}

export const saveFile = (data: string, fileName: string) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.setAttribute('style', 'display: none')

  a.href = data
  a.download = fileName
  a.click()
  ;(window as any)?.URL?.revokeObjectURL(data)
  a.remove()
}

export const isDev = () => {
  const devEnvironments = [
    Environments.development,
    Environments.local,
    Environments.pipeline,
    Environments.developmentCommercial,
    Environments.developmentCommercialRoot,
    Environments.developmentSubdomains,
  ]
  return !!env && devEnvironments.includes(env)
}

export const isProd = () =>
  [
    Environments.production,
    Environments.productionCommercial,
    Environments.productionCommercialRoot,
  ].includes(env as Environments)

export const isInternalEnv = () => {
  return !!env && [Environments.production].includes(env)
}
export const isStaging = () => env === Environments.staging
export const isSubdomainDevelopment = () => env === Environments.developmentSubdomains
export const isCommercialRoot = () => env === Environments.productionCommercialRoot

export const useNeedAnalytics = () => {
  const isCareers = useIsCareers()
  return isCommercialRoot() || isCareers
}

export enum ListType {
  numbers = 'numbers',
  letters = 'letters',
  dots = 'dots',
}

export const getListIndicator = (i: number, type?: ListType) => {
  if (type === 'numbers') {
    return `${i + 1}`
  }
  if (type === 'letters') {
    return (i + 10 + 10 * Math.floor(i / 26)).toString(36)
  }
  if (type === 'dots') {
    return '•'
  }

  return ''
}

export const focusElemByKeyboard = (
  e: React.KeyboardEvent,
  activeIndex: number,
  setActiveIndex: (idx: number) => void,
  optionsRef: { current: (HTMLButtonElement | HTMLDivElement)[] },
  list?: (FilterOption | RadioOption)[],
) => {
  if (!list) {
    return
  }

  if (e.key === 'ArrowDown') {
    let newActiveIndex = activeIndex

    if (activeIndex + 1 >= list.length) {
      if (optionsRef.current[0]) {
        newActiveIndex = 0
      }
    } else {
      newActiveIndex += 1
    }

    optionsRef.current[newActiveIndex]?.focus()
    setActiveIndex(newActiveIndex)
    e.preventDefault()
  }

  if (e.key === 'ArrowUp') {
    let newActiveIndex = activeIndex

    if (activeIndex - 1 < 0) {
      if (optionsRef.current[list.length - 1]) {
        newActiveIndex = list.length - 1
      }
    } else {
      newActiveIndex -= 1
    }

    optionsRef.current[newActiveIndex]?.focus()
    setActiveIndex(newActiveIndex)
    e.preventDefault()
  }
}

export const isWorkspacesEnabled = () => {
  return (
    env === Environments.developmentCommercialRoot ||
    (env === Environments.local &&
      localStorage.getItem(LocalStorageKeys.ENABLE_DEV_WORKSPACES) === 'True')
  )
}

export const getTenantSubdomain = (namespace: string) => {
  if (
    env === Environments.local ||
    env === Environments.developmentCommercial ||
    env === Environments.developmentCommercialRoot ||
    env === Environments.productionCommercial
  ) {
    if (isWorkspacesEnabled()) {
      return localStorage.getItem(LocalStorageKeys.ACTIVE_WORKSPACE) || undefined
    }

    const elems = window.location.host.split('.')

    if (elems.length === 3) {
      return elems[0]
    }

    Sentry.captureMessage(`[${namespace}] Could not detect tenants subdomain`)
  }

  return undefined
}
