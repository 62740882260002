import React from 'react'
import HelpSections from '@src/pages/Forms/EmployeePerformance/components/HelpSections/HelpSections'
import { Text, List, Widget } from '@revolut/ui-kit'
import { Dot, LinkExternal } from '@revolut/icons'
import { REVIEWING_CULTURE_FIT } from '@src/constants/externalLinks'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'

interface Props {
  isCultureValues?: boolean
}
const CultureFitHelp = ({ isCultureValues }: Props) => {
  return isCultureValues ? (
    <>
      <MoreInfoButton
        href={REVIEWING_CULTURE_FIT}
        useIcon={LinkExternal}
        useMoreBar={false}
      >
        View playbook
      </MoreInfoButton>
      <Widget mt="s-24" p="s-16">
        <Text variant="primary" use="p">
          Value based behaviours
        </Text>
        <Text mt="s-8" variant="caption" use="p">
          At Revolut, we are committed to fostering a culture of continuous improvement.
          One key area for development derives from our Value Based Behaviours. We expect
          each and every single Revoluter to grow and excel on the feedback they receive
          and become true culture champions.
        </Text>
        <Text mt="s-24" variant="primary" use="p">
          Assessment levels
        </Text>
        <Text mt="s-8" variant="caption" use="p">
          When it comes to cultural performance, there are 3 levels of alignment with our
          cultural values:
        </Text>
        <List variant="compact">
          <List.Item>
            1. <Text fontWeight="bold">Improvement focus:</Text> The foremost opportunity
            for development in Revolut Value Based Behaviours for the employee.
          </List.Item>
          <List.Item>
            2. <Text fontWeight="bold">Performing:</Text> The employee exhibits
            continuously good alignment with our Value Based Behaviours in all situations.
          </List.Item>
          <List.Item>
            3. <Text fontWeight="bold">Superpower:</Text> The employee consistently sets
            an exemplary standard for our Value Based Behaviours within the organisation.
          </List.Item>
        </List>
        <Text mt="s-24" variant="primary" use="p">
          Requirements
        </Text>
        <Text mt="s-8" variant="caption" use="p">
          To promote a culture of continuous improvement, we encourage all reviewers to
          choose:
        </Text>
        <List variant="compact">
          <List.Item useIcon={<Dot size={16} />}>
            <Text fontWeight="bold">at least two</Text> behaviours under the “Improvement
            focus” category. In the true spirit of our "Never Settle" mentality, we want
            our employees to constantly strive for growth and development.
          </List.Item>
          <List.Item useIcon={<Dot size={16} />}>
            <Text fontWeight="bold">up to three behaviours</Text> as “Superpowers”. We ask
            you to recognize and celebrate employees who exhibit exceptional alignment
            with our cultural values and sets standards for others to follow.
          </List.Item>
        </List>
      </Widget>
    </>
  ) : (
    <HelpSections
      sections={[
        `The review scorecard contains the company cultural values and the definitions of their behaviours.`,
        `Each behaviour within a value can be assessed as either Developing, Performing, or Exceeding.`,
        `When reviewing the culture fit of an employee, please consider the definitions of each rating and whether these value-based-behaviours were demonstrated during the cycle.`,
        `Comments and justification can be left individually for each behaviour to support your assessment.`,
      ]}
    />
  )
}

export default CultureFitHelp
