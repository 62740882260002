import { useTheme } from '@src/styles/theme'
import { Flex, Text } from '@revolut/ui-kit'
import GradeLockIcon from '@src/features/Calibration/GradeLockIcon'
import { getInverseColorsAndGrade } from '@components/PerformanceGrade/PerformanceGrade'
import React from 'react'
import { FinalGrade } from '@src/interfaces/performance'

type Props = {
  name: string
  locked?: boolean
  id: number | string
  grade?: FinalGrade | null
}

export const TalentPerformanceObsoleteGradeColumn = ({
  name,
  locked,
  id,
  grade,
}: Props) => {
  const theme = useTheme()

  return (
    <Flex alignItems="center" data-testid={`${name}__${id}`}>
      {locked ? (
        <GradeLockIcon />
      ) : (
        <Text color={getInverseColorsAndGrade(theme, grade)?.color}>
          {getInverseColorsAndGrade(theme, grade)?.grade || '-'}
        </Text>
      )}
    </Flex>
  )
}
