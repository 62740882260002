import React, { useMemo } from 'react'
import { Box, Cell, Color, Fixed, Header, Side } from '@revolut/ui-kit'
import { useTheme } from '@src/styles/theme'
import SummaryCard from '@src/pages/Forms/EmployeePerformance/components/SummaryCard'
import MatrixTable, { MatrixCellInsertParams } from '@components/Table/MatrixTable'
import {
  CardTypes,
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewDataInterface,
  ReviewScorecardInterface,
  SkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import {
  performanceCriteriaColumn,
  performanceRatingColumn,
  performanceSkillsColumn,
} from '@src/constants/columns/performance'
import CardRating from '@components/ColumnInserts/CardRating/CardRating'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Grid } from '@components/CommonSC/Grid'
import KpiCard from '@src/pages/EmployeeProfile/Preview/Performance/Summary/KPICard'
import SummaryCommentsPopup from '@src/pages/Forms/EmployeePerformance/SummaryCommentsPopup'
import styled from 'styled-components'
import WarningMessage from '@components/WarningMessage/WarningMessage'
import { colorGetter } from '@src/styles/colors'
import { SideBarHeaderTitle } from '@src/components/SideBar/SideBarHeaderTitle'

const Warning = styled(WarningMessage)`
  margin: 8px 0;
  background: ${colorGetter(Color.GREY_TONE_2)};
  border-radius: 12px;
`

const rows = (type: PerformanceReviewTypes, category: ReviewCategory) => {
  const nameColumn =
    type === PerformanceReviewTypes.deliverables
      ? performanceCriteriaColumn
      : performanceSkillsColumn

  return {
    cells: [
      {
        ...nameColumn,
        width: 330,
      },
      {
        ...performanceRatingColumn,
        insert: ({
          data,
        }: MatrixCellInsertParams<SkillCardInterface | ValueBasedCardInterface>) => {
          return <CardRating data={data} type={type} category={category} />
        },
        width: 170,
      },
    ],
  }
}

type Props = {
  data: ReviewDataInterface
  isOpen: boolean
  onClose: () => void
}

const ProbationEditSummarySidebar = ({ data, isOpen, onClose }: Props) => {
  const theme = useTheme()
  const { values } = useLapeContext<ReviewScorecardInterface>()

  const calculatedDeliverables = useMemo(() => {
    const list: SkillCardInterface[] = []

    const deliverables = data?.calculated_deliverables_ratings

    if (!deliverables) {
      return list
    }

    const feedback = data?.extra_deliverables_overall_feedback

    return [
      {
        type: CardTypes.deliverableCriteriaCard,
        name: 'Complexity',
        rating: deliverables.complexity,
        sections: [],
        justifications: feedback?.complexity?.map((val: string) => ({
          comment: val,
        })),
        rating_expectation: null,
      },
      {
        type: CardTypes.deliverableCriteriaCard,
        name: 'Speed',
        rating: deliverables.speed,
        sections: [],
        justifications: feedback?.speed?.map((val: string) => ({
          comment: val,
        })),
        rating_expectation: null,
      },
      {
        type: CardTypes.deliverableCriteriaCard,
        name: 'Quality',
        rating: deliverables.quality,
        sections: [],
        justifications: feedback?.quality?.map((val: string) => ({
          comment: val,
        })),
        rating_expectation: null,
      },
    ] as SkillCardInterface[]
  }, [data?.calculated_deliverables_ratings])

  return (
    <Fixed
      right={0}
      top={0}
      zIndex={theme.zIndex.sideBar}
      p="s-16"
      height="100vh"
      overflow="auto"
    >
      <Side appear={false} open={isOpen} onClose={onClose} variant="wide" height="100%">
        <Header variant="item">
          <Header.CloseButton aria-label="Close" />
          <SideBarHeaderTitle>Summary</SideBarHeaderTitle>
        </Header>
        <Box width="100%">
          <Cell display="block">
            <Grid gap={32}>
              {data.calculated_deliverables_ratings && (
                <SummaryCard
                  title="Deliverables"
                  rating={data.calculated_deliverables_ratings.rating}
                >
                  <MatrixTable
                    rows={rows(PerformanceReviewTypes.deliverables, values.category)}
                    data={calculatedDeliverables}
                  />
                </SummaryCard>
              )}

              {data.deliverables && (
                <SummaryCard title="Deliverables" rating={data.deliverables.rating}>
                  <MatrixTable
                    rows={rows(PerformanceReviewTypes.deliverables, values.category)}
                    data={data.deliverables.cards || []}
                  />
                </SummaryCard>
              )}

              {data.kpis_section?.kpi_items && (
                <SummaryCard title="KPI" rating={data.kpis_section.rating}>
                  <KpiCard
                    data={data.kpis_section.kpi_items}
                    employeeId={values.reviewed_employee.id}
                    id={values.id}
                    category={values.category}
                  />
                  {data.kpis_section.comment && (
                    <Warning multiline>
                      You left a comment
                      <SummaryCommentsPopup
                        title="KPI"
                        justification={data.kpis_section.comment}
                        label="View comment"
                        subtitle="Comment"
                      />
                    </Warning>
                  )}
                </SummaryCard>
              )}

              {data.functional_skills && (
                <SummaryCard title="Skills" rating={data.functional_skills.rating}>
                  <MatrixTable
                    rows={rows(PerformanceReviewTypes.skills, values.category)}
                    data={data.functional_skills.cards || []}
                  />
                </SummaryCard>
              )}
              {data?.culture_skills && (
                <SummaryCard title="Culture Fit" rating={data.culture_skills?.rating}>
                  <MatrixTable
                    rows={rows(PerformanceReviewTypes.cultureFit, values.category)}
                    data={data.culture_skills?.cards || []}
                  />
                </SummaryCard>
              )}
              {data?.culture_values && (
                <SummaryCard title="Culture Fit" rating={data.culture_values?.rating}>
                  <MatrixTable
                    data={data.culture_values?.cards || []}
                    rows={rows(PerformanceReviewTypes.cultureValuesFit, values.category)}
                  />
                </SummaryCard>
              )}
            </Grid>
          </Cell>
        </Box>
      </Side>
    </Fixed>
  )
}

export default ProbationEditSummarySidebar
