import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import { useGlobalSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { Button, HStack } from '@revolut/ui-kit'
import { pathToFullUrlWithBaseUrl } from '@src/utils/router'
import kebabCase from 'lodash/kebabCase'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PUBLIC_ROUTES } from '@src/constants/routes'

type JobPreviewActionsButtonProps = {
  onCopyJobPosting: () => void
}

const JobPreviewActionsButton = ({ onCopyJobPosting }: JobPreviewActionsButtonProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const {
    settings: { career_page_enabled },
  } = useGlobalSettings()
  const isCommercial = useIsCommercial()

  const published =
    values.status !== PublishingStatuses.closed &&
    values.status !== PublishingStatuses.unpublished
  const showUrl = published && (!isCommercial || career_page_enabled)
  return (
    <HStack>
      <Button variant="text" size="sm" useIcon="Copy" onClick={onCopyJobPosting}>
        Copy content
      </Button>
      {showUrl && (
        <Button
          size="sm"
          target="_blank"
          to={
            !values.careers_position_url
              ? pathToFullUrlWithBaseUrl(PUBLIC_ROUTES.CAREERS.POSITION, {
                  id: `${kebabCase(values.name)}-${values.public_id}`,
                })
              : values.careers_position_url
          }
          use={InternalLink}
          useIcon="16/LinkExternal"
          variant="text"
        >
          Careers website
        </Button>
      )}
    </HStack>
  )
}

export default JobPreviewActionsButton
