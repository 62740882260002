import React, { useState, useEffect } from 'react'
import { Box, Flex, Text, Color, chain } from '@revolut/ui-kit'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { connect } from 'lape'
import cronstrue from 'cronstrue'

import { ROUTES } from '@src/constants/routes'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { documentRulesFormRequest } from '@src/api/documentsRules'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentsRuleInterface } from '@src/interfaces/documentsRule'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { OptionInterface } from '@src/interfaces/selectors'
import { documentTemplatesRequests } from '@src/api/documentsTemplates'
import { getStatusColor } from '@src/components/CommonSC/General'

import General from './General'
import RulesTable from './RulesTable'
import Preview from './Preview'

export type TemplateType = DocumentsTemplatesInterface['template_type']

export const Parameter: React.FC = ({ children }) => {
  return (
    <Box bg={Color.GREY_20_OPAQUE_50} px="s-6" py="s-4" mb="s-8" mr="s-8" radius="toast">
      <Text fontSize="small">{children}</Text>
    </Box>
  )
}

export const getScheduleMessage = (values: DocumentsRuleInterface) => {
  if (!values.schedule) {
    return null
  }

  let message = ''

  try {
    message = cronstrue.toString(values.schedule)
  } catch {
    message = 'Invalid cron value'
  }

  return message
}

const DocumentsRule = () => {
  const { values } = useLapeContext<DocumentsRuleInterface>()
  const params = useParams<{ id?: string }>()

  const isGeneralRoute = !!useRouteMatch(ROUTES.FORMS.DOCUMENT_RULE.GENERAL)

  const [templateType, setTemplateType] = useState<TemplateType>()
  const [requiredParameters, setRequiredParameters] = useState<string[]>([])

  useEffect(() => {
    if (values.document_template) {
      handleTemplateChange(values.document_template as OptionInterface)
    }
  }, [values])

  const handleTemplateChange = async (option?: OptionInterface) => {
    setTemplateType(undefined)
    setRequiredParameters([])

    if (option?.id) {
      const result = await documentTemplatesRequests.getItem(option.id)

      setTemplateType(result?.data.template_type)

      if (result?.data?.required_sql_fields) {
        setRequiredParameters(result.data.required_sql_fields)
      }
    }
  }

  const backUrl = ROUTES.APPS.DOCUMENTS.RULES

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.DOCUMENT_RULE.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.DOCUMENT_RULE.PREVIEW, params),
      component: Preview,
    },
    {
      title: 'Logs',
      path: ROUTES.FORMS.DOCUMENT_RULE.DOCUMENTS,
      to: pathToUrl(ROUTES.FORMS.DOCUMENT_RULE.DOCUMENTS, params),
      component: RulesTable,
    },
  ]

  const title = (() => {
    if (isGeneralRoute) {
      return params.id ? 'Edit document rule' : `New document rule`
    }
    return chain(
      values.name,
      <Text color={getStatusColor(values.status.id)}>{values.status.name}</Text>,
    )
  })()

  return (
    <>
      <PageWrapper>
        <PageHeader title={title} backUrl={backUrl}>
          {isGeneralRoute ? null : (
            <Box my="s-16" maxWidth="100vw" overflow="auto">
              <TabBarNavigation tabs={tabs} />
            </Box>
          )}
        </PageHeader>

        <Flex flexDirection="column" width="100%" flex={1} minHeight={0}>
          <Switch>
            <Route exact path={ROUTES.FORMS.DOCUMENT_RULE.GENERAL}>
              <General
                templateType={templateType}
                handleTemplateChange={handleTemplateChange}
                requiredParameters={requiredParameters}
              />
            </Route>
            {tabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                <tab.component
                  data={values}
                  templateType={templateType}
                  requiredParameters={requiredParameters}
                />
              </Route>
            ))}
          </Switch>
        </Flex>
      </PageWrapper>
    </>
  )
}

export default connect(() => (
  <Form api={documentRulesFormRequest}>
    <DocumentsRule />
  </Form>
))
