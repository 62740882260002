import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { FileInterface } from '@src/interfaces/files'

export const uploadFile = (
  file: File,
  category?: string,
  isPrivate?: boolean,
  apiHandler = apiWithoutHandling,
  fileNameOverride?: string,
  apiEndpoint = API.FILES,
) => {
  const fileFormData = new FormData()
  fileFormData.append('file', file, fileNameOverride || file.name)
  if (category) {
    fileFormData.append('category', category)
  }
  if (isPrivate) {
    fileFormData.append('access_level', 'private')
  }

  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  if (apiHandler) {
    return apiHandler.post<FileInterface>(apiEndpoint, fileFormData, {
      headers,
    })
  }

  return apiWithoutHandling.post<FileInterface>(apiEndpoint, fileFormData, {
    headers: {
      ...headers,
    },
  })
}

export const downloadFile = async (id: number) => {
  const res = await api.get<Blob>(`${API.FILES}/${id}/download`, {
    responseType: 'blob',
  })
  return URL.createObjectURL(res.data)
}

export const getFileMetadata = (id: number) => {
  return api.get<FileInterface>(`${API.FILES}/${id}`)
}

export const deleteFile = (id: number) => api.delete(`${API.FILES}/${id}`)
