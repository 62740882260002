import React from 'react'
import { Route, Switch } from 'react-router-dom'
import HubApp from '@src/features/HubApp/HubApp'
import { APPLICATIONS, globalSettingsAppTabsFilter } from '@src/constants/hub'
import { useGetEmployeeSettings } from '@src/api/settings'
import { BulkEmployeeUploadFlow } from '@src/features/EditableEmployeesTable/BulkEmployeesUploadFlow'
import { ROUTES } from '@src/constants/routes'
import { API } from '@src/constants/api'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'

const Employees = () => {
  const { data: employeeSettings } = useGetEmployeeSettings()

  const tabs = APPLICATIONS.employees.tabs.filter(tab =>
    globalSettingsAppTabsFilter(tab.homeSectionId, { employeeSettings }),
  )

  return (
    <Switch>
      <Route path={ROUTES.APPS.EMPLOYEES.BULK_UPDATE.ANY}>
        <BulkEmployeeUploadFlow
          isDetailed
          importRoute={ROUTES.APPS.EMPLOYEES.BULK_UPDATE.IMPORT}
          sessionRoute={ROUTES.APPS.EMPLOYEES.BULK_UPDATE.SESSION}
          anyRoute={ROUTES.APPS.EMPLOYEES.BULK_UPDATE.ANY}
          apiEndpoint={API.EMPLOYEE_UPLOADS}
          getHeader={title => (
            <PageHeader title={title} backUrl={pathToUrl(ROUTES.PEOPLE.EMPLOYEES)} />
          )}
        />
      </Route>
      <Route>
        <HubApp app={{ ...APPLICATIONS.employees, tabs }} />
      </Route>
    </Switch>
  )
}

export default Employees
