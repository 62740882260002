import React from 'react'
import {
  BoxProps,
  Button,
  Flex,
  StatusPopup,
  Subheader,
  Text,
  TextButton,
  Token,
  useStatusPopup,
} from '@revolut/ui-kit'

import { OnboardingCheckpointCategory } from '@src/interfaces/onboardingChecklistV2'
import {
  hideOnboardingCheckpoint,
  useGetOnboardingCheckpoints,
} from '@src/api/onboardingChecklistV2'

type SectionTitleProps = {
  title: React.ReactNode
  subtitle?: React.ReactNode
  categoriesToPostpone?: OnboardingCheckpointCategory[]
} & Pick<BoxProps, 'mt'>

export const SectionTitle = ({
  title,
  subtitle,
  categoriesToPostpone = [],
  mt,
}: SectionTitleProps) => {
  const { refetchCheckpoints } = useGetOnboardingCheckpoints()

  const statusPopup = useStatusPopup()

  const onClickLater = () =>
    Promise.all(categoriesToPostpone.map(category => hideOnboardingCheckpoint(category)))
      .then(refetchCheckpoints)
      .catch(() => {
        statusPopup.show(
          <StatusPopup variant="error" onClose={statusPopup.hide}>
            <StatusPopup.Title>Action failed</StatusPopup.Title>
            <StatusPopup.Description>
              There was a problem when postponing onboarding steps. Please, try again
              later.
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} variant="secondary">
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })

  return (
    <Flex mt={mt} justifyContent="space-between">
      <Subheader>
        <Subheader.Title>{title}</Subheader.Title>
      </Subheader>
      {subtitle && (
        <Text color={Token.color.greyTone50} pb="s-16" use="div" variant="caption">
          {subtitle}
        </Text>
      )}
      {!!categoriesToPostpone.length && (
        <TextButton onClick={onClickLater}>Later</TextButton>
      )}
    </Flex>
  )
}
