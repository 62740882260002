import React from 'react'
import { EntityTypes } from '@src/constants/api'
import {
  analyticsDashboardNameColumn,
  analyticsDashboardRelatedKpisColumn,
  analyticsDashboardOrganisationUnitsColumn,
  analyticsDashboardOwnerColumn,
  analyticsDashboardRatingColumn,
  analyticsDashboardSourceColumn,
} from '@src/constants/columns/analyticsDashboard'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { CompanyInterface } from '@src/interfaces/company'
import { RowInterface } from '@src/interfaces/data'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import { PermissionTypes } from '@src/store/auth/types'

interface Props {
  data: CompanyInterface
}

const initialFilterBy = [
  {
    filters: [{ id: 'true', name: 'true' }],
    columnName: 'company_related',
    nonResettable: true,
  },
]

const row: RowInterface<GenericAnalyticsDashboardInterface> = {
  cells: [
    {
      ...analyticsDashboardOrganisationUnitsColumn,
      width: 100,
    },
    {
      ...analyticsDashboardNameColumn,
      width: 200,
    },
    {
      ...analyticsDashboardRelatedKpisColumn,
      width: 300,
    },
    {
      ...analyticsDashboardRatingColumn,
      width: 40,
    },
    {
      ...analyticsDashboardSourceColumn,
      width: 40,
    },
    {
      ...analyticsDashboardOwnerColumn,
      width: 80,
    },
  ],
}

export const AnalyticsDashboards = ({ data }: Props) => {
  return (
    <AnalyticsDashboardsTab
      canAdd={data.field_options.permissions?.includes(PermissionTypes.ManageDashboards)}
      entity={EntityTypes.company}
      initialFilter={initialFilterBy}
      row={row}
    />
  )
}
