import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { timeManagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { TimeManagementIntro } from './TimeManagementIntro'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.INTRO,
    canView: [PermissionTypes.ViewTimeOffPolicies],
    component: TimeManagementIntro,
  },
]

export const TimeManagement = () => {
  return <OnboardingChecklistContent config={timeManagementConfig} routes={routes} />
}
