import React from 'react'
import { MoreBar, MoreButton } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useGetCandidateSettings } from '@src/api/settings'

type ReferCandidateButtonProps = {
  useMoreButton?: boolean
}

const ReferCandidateButton = ({ useMoreButton = false }: ReferCandidateButtonProps) => {
  const { data: settings } = useGetCandidateSettings()

  if (!settings?.enable_referral) {
    return null
  }

  const Component = useMoreButton ? MoreButton.Action : MoreBar.Action

  return (
    <Component
      useIcon="AddContact"
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.REFER_CANDIDATE.NEW)}
    >
      Refer a candidate
    </Component>
  )
}

export default ReferCandidateButton
