import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, VStack, StatusWidget } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeContractsInterface } from '@src/interfaces/employees'
import { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import { PageBody } from '@src/components/Page/PageBody'
import { getEmployeeContractCommentsAPI } from '@src/api/employees'
import ChatSidebar from '@src/components/Chat/ChatSidebar'
import { ChatMessageType } from '@src/components/Chat/common'
import { ContractActionWidgets } from '@src/pages/Forms/EmployeeContracts/Preview/ContractActionWidgets'
import { ContractActionButtons } from '@src/pages/Forms/EmployeeContracts/components/ContractActionButtons/ContractActionButtons'
import { goBack } from '@src/actions/RouterActions'
import { getPermissions } from './getPermissions'
import { ContractPreviewFields } from './common'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

type Props = {
  isBulkReview: boolean
  setForm: (form: LapeFormInterface<EmployeeContractsInterface>) => void
  onStatusUpdate: (data: EmployeeContractsInterface) => void
}
export const ContractPreview = ({ isBulkReview, setForm, onStatusUpdate }: Props) => {
  const form = useLapeContext<EmployeeContractsInterface>()
  const { values } = form

  const { employeeId, id } = useParams<{ employeeId: any; id: any }>()

  const commentsApi = getEmployeeContractCommentsAPI(employeeId, id)
  const getComments = commentsApi.useGetComments()

  const [isCommentsShown, setIsCommentsShown] = useState<boolean>(false)

  useEffect(() => {
    setForm(form)
  }, [])

  const onCopyContract = () => {
    const { id: omitId, contract_status, pay_group, ...newValues } = values
    const url = pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.GENERAL, { employeeId })

    workspaceLocalStorage.setItem(url, JSON.stringify(newValues))
    window.open(url, '_blank')
  }

  const permissions = getPermissions(values.field_options?.permissions)

  if (!permissions.canView) {
    return (
      <PageBody>
        <StatusWidget>
          <StatusWidget.Image
            src="https://assets.revolut.com/assets/3d-images/3D020.png"
            srcSet="https://assets.revolut.com/assets/3d-images/3D020@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D020@3x.png 3x"
          />
          <StatusWidget.Title>
            You are not allowed to view details of this contract
          </StatusWidget.Title>
          <StatusWidget.Description>
            Contact support for additional information
          </StatusWidget.Description>
          <StatusWidget.Action onClick={() => goBack(ROUTES.APPS.CONTRACTS.ALL)}>
            Go Back
          </StatusWidget.Action>
        </StatusWidget>
      </PageBody>
    )
  }
  return (
    <PageBody>
      <ContractActionButtons
        form={form}
        isBulkReview={isBulkReview}
        onCopyContract={onCopyContract}
        showComments={() => setIsCommentsShown(true)}
        onStatusUpdate={onStatusUpdate}
      />
      <VStack space="s-16">
        <ContractActionWidgets onCopyContract={onCopyContract} />
      </VStack>
      <Box mt="s-16">
        <ContractPreviewFields employeeId={employeeId} contract={values} />
        <ChatSidebar
          type={ChatMessageType.Comment}
          isOpen={isCommentsShown}
          onClose={() => setIsCommentsShown(false)}
          data={getComments.data?.results || []}
          isLoading={getComments.isLoading}
          refetch={getComments.refetch}
          onAddMessage={commentsApi.addComment}
          onEdit={commentsApi.editComment}
          onArchive={commentsApi.archiveComment}
          onResolve={commentsApi.resolveComment}
          canAddComments={permissions.canAddComments}
          canEdit={permissions.canEditComments}
          disableTodolistFeature
        />
      </Box>
    </PageBody>
  )
}
