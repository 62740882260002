import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { useCallback, useMemo } from 'react'
import { HUB_APPLICATIONS } from '@src/constants/hub'
import produce from 'immer'
import { HubAppType, HubInterface } from '@src/interfaces/hub'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { matchSorter } from 'match-sorter'
import { useGlobalSettings, useGetTimeOffSettings } from '@src/api/settings'

export const useHubApps = (showWithAccess = true) => {
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const { data: timeOffSettings } = useGetTimeOffSettings()
  const timeOffEnabled = !!timeOffSettings?.enabled

  const globalSettings = useGlobalSettings()

  const checkAccess = (canView?: PermissionTypes[], appFeatureFlags?: FeatureFlags[]) => {
    const checkPermissions = canView
      ? canView.some(permission => permissions.includes(permission))
      : true
    const checkFeatureFlags = appFeatureFlags
      ? appFeatureFlags.some(flag => featureFlags.includes(flag))
      : true

    return checkPermissions && checkFeatureFlags
  }

  const checkTabsAccess = (app: HubAppType) => {
    return 'tabs' in app
      ? Object.values(app.tabs).some(tab => checkAccess(tab.canView, tab.featureFlags))
      : checkAccess(app.canView)
  }

  const checkFeatureFlags = (app: HubAppType) => {
    return 'featureFlags' in app
      ? app.featureFlags?.some(featureFlag => featureFlags.includes(featureFlag))
      : true
  }

  return useMemo(() => {
    const hubApps: HubInterface = produce(HUB_APPLICATIONS, groups => {
      groups.forEach(group => {
        group.items = group.items.filter(item => {
          const show = showWithAccess === checkTabsAccess(item) && checkFeatureFlags(item)
          /** If feature disabled or no permission - show in "Discover", otherwise show in "Apps" */
          if ('globalSetting' in item && item.globalSetting) {
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            const settingEnabled = !!globalSettings.settings[item.globalSetting]
            const showWhenDisabled = !settingEnabled && !showWithAccess
            const showWhenEnabled = settingEnabled && show
            return showWhenDisabled || showWhenEnabled
          }
          // TODO: when BE returns time off settings in global settings response, move to hub config object, `globalSetting` property
          if (item.id === 'timeOff') {
            const showWhenDisabled = !timeOffEnabled && !showWithAccess
            const showWhenEnabled = timeOffEnabled && show
            return showWhenDisabled || showWhenEnabled
          }

          return show
        })
      })
    })

    return hubApps.filter(appGroup => appGroup.items.length > 0)
  }, [globalSettings, timeOffEnabled])
}

export const useHubAppSearch = () => {
  const hubApps = useHubApps()

  const apps = useMemo(() => {
    const initialValue: HubAppType[] = []
    return hubApps.reduce(
      (previousValue, currentValue) => previousValue.concat(currentValue.items),
      initialValue,
    )
  }, [hubApps])

  const findApps = useCallback(
    (query: string) => {
      return apps.length
        ? matchSorter(apps, query, {
            keys: [
              item => item.title,
              item => ('tabs' in item ? Object.values(item.tabs).map(i => i.title) : []),
            ],
          })
        : []
    },
    [apps],
  )

  return { findApps, apps }
}
