import React from 'react'
import { Box, Token, useTooltip, Tooltip, Icon } from '@revolut/ui-kit'
import { GradeOption } from '@src/pages/Forms/EmployeePerformanceLayout/utils'

export const ExceedingExpectationTooltip = ({ grade }: { grade: GradeOption }) => {
  const tooltip = useTooltip()
  const { aboveExp } = grade

  return (
    <Box>
      <Icon
        name={aboveExp ? 'ArrowUpgrade' : 'ArrowDowngrade'}
        size={20}
        {...tooltip.getAnchorProps()}
        color={aboveExp ? Token.color.green : Token.color.warning}
      />
      <Tooltip {...tooltip.getTargetProps()} placement="left" width={270}>
        This rating is {aboveExp ? 'above' : 'below'} the expectation. Please add your
        justification below.
      </Tooltip>
    </Box>
  )
}
