import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { useParams } from 'react-router-dom'
import { useGetRequisitionSettings } from '@src/api/settings'
import {
  requisitionsRequestsNew,
  useGetRequisitionApprovals,
} from '@src/api/requisitions'
import { useGetRequisitionCompensationBands } from '@src/api/benchmarks'
import { allCompensationBandsAvailable } from '@src/components/LocationCompensationBand/utils'
import { Statuses } from '@src/interfaces'
import { EntityPermissions } from '@src/store/auth/types'
import { MoreBar } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  ApproveButton,
  ArchiveButton,
  CopyButton,
  DeleteButton,
  EditButton,
  RejectButton,
} from '@src/features/SettingsButtons'
import { handleError } from '@src/api'
import { getApproveButtonTooltipLabel } from '@src/pages/Forms/RequisitionForm/utils'
import ReopenRequisitionButton from '@src/pages/Forms/RequisitionForm/Buttons/ReopenButton'
import PermissionTransferButton from '@src/pages/PermissionTransfer/Button/PermissionTransferButton'
import { INTERNAL_MOVE_PROCESS } from '@src/constants/externalLinks'
import PublishButton from '@src/pages/Forms/RequisitionForm/Buttons/PublishButton'
import ReferCandidateButton from '@src/components/ReferCandidateButton/ReferCandidateButton'
import RegulatedButton from '@src/pages/Forms/RequisitionForm/Buttons/RegulatedButton'
import CloseRequisitionButton from '@src/pages/Forms/RequisitionForm/Buttons/CloseButton'

type RequisitionActionBarProps = {
  onApproving: (isApproving: boolean) => void
}

const RequisitionActionBar = ({ onApproving }: RequisitionActionBarProps) => {
  const { values, initialValues } = useLapeContext<RequisitionInterface>()
  const params = useParams()
  const { data: requisitionSettings } = useGetRequisitionSettings()
  const { refetch: refetchApproval } = useGetRequisitionApprovals(
    requisitionSettings?.enable_approvals ? values.id : undefined,
  )
  const { data: compensationBands, isLoading: isCompensationBandsLoading } =
    useGetRequisitionCompensationBands(
      requisitionSettings?.enable_budget_impact ? values.id : null,
      values.locations
        ?.filter(item => item.posting_compensation_enabled)
        ?.map(item => item.id),
    )
  const isCostControlStep = values.approval_step === 'cost_control'
  const compensationBandUnavailable =
    isCompensationBandsLoading || !allCompensationBandsAvailable(compensationBands)
  const mrtQuestionnaireMissing =
    !!values.is_mrt_jira_ticket_url_required &&
    !values.is_mrt_jira_ticket_url_confirmed &&
    !!requisitionSettings?.enable_is_mrt_jira_ticket_url_confirmed_editing
  const isApproveBtnVisible =
    initialValues.status === Statuses.pending && requisitionSettings?.enable_approvals
  const canReopen = values.field_options?.actions?.includes(EntityPermissions.Reopen)
  const canChange = values.field_options?.actions?.includes(EntityPermissions.Change)
  const canClose = values.field_options?.actions?.includes(EntityPermissions.Close)
  const onBeforeApproval = () => onApproving(true)
  const onAfterApproval = () => {
    onApproving(false)
    refetchApproval()
  }
  return (
    <MoreBar maxCount={4} labelMoreButton="More requisition actions">
      <ApproveButton
        isVisible={isApproveBtnVisible}
        entityPermissions={EntityPermissions.Approve}
        notification={{
          updateMsg: 'Requisition successfully approved.',
        }}
        onSubmitFailed={handleError}
        onBeforeSubmit={onBeforeApproval}
        onAfterSubmit={onAfterApproval}
        disabled={
          (isCostControlStep && compensationBandUnavailable) || mrtQuestionnaireMissing
        }
        tooltip={getApproveButtonTooltipLabel(
          mrtQuestionnaireMissing,
          isCostControlStep && compensationBandUnavailable,
        )}
      />
      <RejectButton
        isVisible={isApproveBtnVisible}
        entityPermissions={EntityPermissions.Approve}
        notification={{
          updateMsg: 'Requisition successfully rejected.',
        }}
        dialog={{
          title: 'Reasons for rejection',
          placeholder: 'Please provide reasons for rejection',
          fieldName: 'rejection_comment',
        }}
        onSubmitFailed={handleError}
        onBeforeSubmit={onBeforeApproval}
        onAfterSubmit={onAfterApproval}
      />
      <ApproveButton
        buttonActionText="Approve all"
        statusToSet={Statuses.opened}
        isVisible={isApproveBtnVisible}
        entityPermissions={EntityPermissions.ApproveAll}
        notification={{
          updateMsg: 'Requisition successfully approved.',
        }}
        onSubmitFailed={handleError}
        onBeforeSubmit={onBeforeApproval}
        onAfterSubmit={onAfterApproval}
        disabled={compensationBandUnavailable || mrtQuestionnaireMissing}
        tooltip={getApproveButtonTooltipLabel(
          mrtQuestionnaireMissing,
          compensationBandUnavailable,
        )}
      />
      <EditButton
        route={pathToUrl(ROUTES.FORMS.REQUISITION.SETTINGS, params)}
        isVisible={canChange}
      />
      <CopyButton afterSubmitUrl={pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {})} />
      <ReopenRequisitionButton isVisible={canReopen} />
      {values.is_confidential && (
        <PermissionTransferButton
          path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.REQUISITION}
          values={values}
        />
      )}
      {values.status === Statuses.opened && requisitionSettings?.enable_apply_button && (
        <MoreBar.Action
          useIcon="Form"
          target="_blank"
          rel="noreferrer noopener"
          href={INTERNAL_MOVE_PROCESS}
          use="a"
        >
          Apply
        </MoreBar.Action>
      )}
      <PublishButton />
      <ReferCandidateButton />
      {requisitionSettings?.enable_is_regulated_editing && <RegulatedButton />}
      <DeleteButton
        backUrl={ROUTES.RECRUITMENT.REQUISITIONS}
        deleteApi={requisitionsRequestsNew.delete!}
        title="Requisition"
      />
      <ArchiveButton
        notification={{
          updateMsg: 'Requisition successfully archived.',
        }}
      />
      <CloseRequisitionButton isVisible={canClose} />
    </MoreBar>
  )
}

export default RequisitionActionBar
