import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { APPLICATIONS } from '@src/constants/hub'
import { ROUTES } from '@src/constants/routes'
import HubApp from '@src/features/HubApp/HubApp'
import { TimeOffPage } from '@src/pages/People/PeopleSubTabs/TimeOffPage/TimeOff'

const TimeOff = () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.ANY}>
        <TimeOffPage />
      </Route>

      <Route path={ROUTES.APPS.TIME_MANAGEMENT.ANY}>
        <HubApp app={APPLICATIONS.timeOff} />
      </Route>
    </Switch>
  )
}

export default TimeOff
