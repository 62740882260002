import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Flex, Grid, Input, Radio, RadioGroup, Text, Token } from '@revolut/ui-kit'
import {
  ReportInterface,
  ReportDownloadOutputFormat,
} from '@src/interfaces/dataAnalytics'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import {
  SelectionControls,
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ColumnsTable, TableColumnInterface } from './ColumnsTable'

export const DownloadOutputFormat = () => {
  const { values } = useLapeContext<ReportInterface>()
  const [selectControls, setSelectControls] =
    useState<SelectionControls<TableColumnInterface>>()
  const [selectedRows, setSelectedRows] = useState<TableColumnInterface[] | undefined>()

  const tableData = useMemo(() => {
    return values.query?.columns.map((key, index) => ({
      id: index,
      ...key,
    }))
  }, [values.query])

  useEffect(() => {
    if (values.output_format?.columns) {
      const rowsToBeSelected = tableData.filter(row =>
        values.output_format.columns.find(el => el.name === row.name),
      )
      selectControls && selectControls.setSelection(rowsToBeSelected)
    }
  }, [selectControls, tableData])

  useEffect(() => {
    const prevMappings = values.output_format?.columns || []

    if (selectedRows && values.output_format?.columns) {
      values.output_format.columns = selectedRows.map(row => ({
        name: row.name,
        type: row.type,
        alias: prevMappings.find(el => el.name === row.name)?.alias || null,
      }))
    }
  }, [selectedRows])

  const handleSelect = useCallback(
    (tableWrapperState: SelectTableWrapperOnChangeData<TableColumnInterface>) => {
      if (tableWrapperState && tableWrapperState.selectedRowsData.length) {
        setSelectedRows(tableWrapperState.selectedRowsData)
      } else if (tableWrapperState.isAllSelected) {
        const selected = tableData.filter(
          row => !tableWrapperState.excludeListIds.has(String(row.id)),
        )
        setSelectedRows(selected)
      } else {
        setSelectedRows([])
      }
    },
    [tableData],
  )

  return (
    <>
      <NewStepperTitle
        subtitle="Select the output type for the report"
        title="Select the output format for the report"
      />
      <Flex
        backgroundColor={Token.color.background}
        borderRadius={12}
        flexDirection="column"
        gap="s-16"
        p="s-16"
      >
        <RadioGroup<ReportDownloadOutputFormat>
          onChange={value => {
            if (value) {
              values.output_format.format = value
            }
          }}
          value={values.output_format?.format}
        >
          {group => (
            <>
              <Radio {...group.getInputProps({ value: 'csv' })}>
                <Radio.Label>CSV Format</Radio.Label>
                <Radio.Description>
                  A CSV file that can be downloaded or uploaded to a specific location
                </Radio.Description>
              </Radio>
              <Radio {...group.getInputProps({ value: 'xlsx' })}>
                <Radio.Label>Excel Format</Radio.Label>
                <Radio.Description>
                  An Excel file that can be downloaded or uploaded to a specific location
                </Radio.Description>
              </Radio>
            </>
          )}
        </RadioGroup>
      </Flex>
      <NewStepperTitle title="Select the columns to use from the query" />
      <ColumnsTable
        handleChange={handleSelect}
        handleControlsLoaded={setSelectControls}
        tableData={tableData}
      />
      {values.output_format && selectedRows && selectedRows.length > 0 && (
        <>
          <NewStepperTitle title="Rename the columns from the data for your report" />
          <Grid
            backgroundColor={Token.color.background}
            borderRadius={12}
            columns={{ all: 1, md: 2 }}
            gap="s-16"
            mt="s-8"
            p="s-16"
          >
            <Text color={Token.color.greyTone50}>Column Name</Text>
            <Text color={Token.color.greyTone50}>Report Column Name</Text>
          </Grid>
          {selectedRows.map(item => {
            return (
              <Grid
                backgroundColor={Token.color.background}
                borderRadius={12}
                columns={{ all: 1, md: 2 }}
                gap="s-16"
                key={item.name}
                mt="s-8"
                p="s-16"
              >
                <Text alignSelf="center">{item.name}</Text>
                <Input
                  label="Report Column Name"
                  onChange={e => {
                    const columnMapping = values.output_format.columns.find(
                      el => el.name === item.name,
                    )

                    if (columnMapping) {
                      columnMapping.alias = e.currentTarget.value || null
                    }
                  }}
                  name={item.name}
                  value={
                    values.output_format.columns.find(el => el.name === item.name)
                      ?.alias || ''
                  }
                />
              </Grid>
            )
          })}
        </>
      )}
    </>
  )
}
