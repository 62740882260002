import { goalsRequests, useGetGoalParentSelector } from '@src/api/goals'
import React, { useRef, useState } from 'react'
import RadioSelectInput, {
  RadioSelectInputProps,
} from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { GoalsInterface, ParentGoalSelector } from '@src/interfaces/goals'
import { captureException } from '@sentry/react'
import { Box, Token, VStack } from '@revolut/ui-kit'

export const ParentGoalField = ({
  contentType,
  onChange,
  value,
  required,
  ...props
}: {
  value: GoalsInterface | null
  onChange: (selected: GoalsInterface | null) => void
  contentType?: 'employees' | 'teams' | 'department'
} & Omit<RadioSelectInputProps<ParentGoalSelector>, 'onChange'>) => {
  const [pendingUpdate, setPendingUpdate] = useState(false)
  const [error, setError] = useState<string>()
  const parentsRef = useRef<Record<number, GoalsInterface>>({})
  const mapSelectorType = {
    employees: 'employee_goal' as const,
    teams: 'team_goal' as const,
    department: 'department_goal' as const,
  }
  const { data: options, isLoading } = useGetGoalParentSelector(
    contentType ? mapSelectorType[contentType] : undefined,
  )

  const onSelected = async (selected: ParentGoalSelector | null) => {
    setError(undefined)
    if (!selected) {
      onChange(selected)
    } else if (parentsRef.current[selected.id]) {
      onChange(parentsRef.current[selected.id])
    } else {
      setPendingUpdate(true)
      try {
        const { data: goal } = await goalsRequests.get({ id: String(selected.id) })
        parentsRef.current[goal.id] = goal
        onChange(goal)
      } catch (err) {
        captureException(err)
        setError('Something went wrong. Please try again.')
      } finally {
        setPendingUpdate(false)
      }
    }
  }

  const getOptionDescription = (item: ParentGoalSelector): string => {
    const contentTypeDict = {
      employees: 'Employee',
      teams: 'Team',
      department: 'Department',
    }
    if (item.content_type) {
      return `[${contentTypeDict[item.content_type.model]}] ${item.content_object.name}`
    }
    if (item.is_company) {
      return '[Company]'
    }

    return ''
  }

  return (
    <RadioSelectInput<ParentGoalSelector>
      options={
        options?.map(option => ({ value: option, label: option.name, key: option.id })) ||
        []
      }
      loading={isLoading || pendingUpdate}
      value={value}
      label={`Parent goal${required ? '' : ' (optional)'}`}
      hasError={!!error}
      message={error}
      clearable={!required}
      onChange={onSelected}
      {...props}
    >
      {option => (
        <VStack space="s-12">
          <Box>{option.label}</Box>
          <Box color={Token.color.greyTone50}>{getOptionDescription(option.value)}</Box>
        </VStack>
      )}
    </RadioSelectInput>
  )
}
