import { Avatar, HStack, Item, Text, Token, abbreviate } from '@revolut/ui-kit'
import { getStatusColor } from '@src/components/CommonSC/General'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import React from 'react'
import { useGetGoal } from '@src/api/goals'
import { getAvatarUrl } from '@src/utils/employees'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ColoredPercent } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { useGetEmployee } from '@src/api/employees'

export const ParentBadge = ({
  id,
  name,
  cycle,
  showAvatar = false,
}: {
  id: number
  name: string
  cycle?: ReviewCyclesInterface
  showAvatar?: boolean
}) => {
  const { data: parent } = useGetGoal(id)
  const { data: owner } = useGetEmployee(parent?.owner.id)

  const getCurrentProgress = () => {
    const goalPregressDetails = parent?.goal_cycles?.find(
      cycleDetails => cycleDetails.review_cycle.id === cycle?.id,
    )

    return goalPregressDetails?.calibrated_progress ?? goalPregressDetails?.progress
  }

  const currentProgress = getCurrentProgress()

  return (
    <Item
      onClick={() => navigateTo(pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: parent?.id }))}
      style={{ cursor: 'pointer', border: `1px solid ${Token.color.grey5}` }}
    >
      {showAvatar && (
        <Item.Avatar>
          <Avatar useIcon="Target" color={Token.color.accent} />
        </Item.Avatar>
      )}
      <Item.Content>
        <Item.Title>{name}</Item.Title>
        {cycle && <Item.Description>{cycle?.name}</Item.Description>}
      </Item.Content>
      <Item.Side>
        <HStack space="s-16" align="center">
          <Text color={getStatusColor(parent?.status?.id)}>{parent?.status?.name}</Text>
          {typeof currentProgress === 'number' ? (
            <ColoredPercent percent={currentProgress * 100} />
          ) : null}
          {!!parent && (
            <Avatar
              src={owner?.avatar ? getAvatarUrl(owner.avatar) : undefined}
              label={abbreviate(parent.owner.name || '')}
            />
          )}
        </HStack>
      </Item.Side>
    </Item>
  )
}
