import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import React, { useEffect, useState } from 'react'
import { GoalForm } from './Form/GoalForm'
import { PageActions } from '@src/components/Page/PageActions'
import { ActionButton, BREAKPOINTS, Flex, Spinner } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLocation } from 'react-router-dom'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { EntityTypes } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { Statuses } from '@src/interfaces'
import { PageBody } from '@src/components/Page/PageBody'
import { captureException } from '@sentry/core'
import { useSubmitFlowHelpers } from './common/utils'
import { deleteGoal } from '@src/api/goals'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import {
  FormObserverProvider,
  useFormObserver,
} from './Form/Widgets/FormObserverProvider'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'

const getCache = (key: string): Partial<GoalsInterface> | null => {
  try {
    const rawCache = localStorage.getItem(key)
    return rawCache ? JSON.parse(rawCache) : null
  } catch (err) {
    captureException(err)
    return null
  }
}

const setCache = (key: string, value: Partial<GoalsInterface>): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    captureException(err)
  }
}

const useGoalFormCache = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const [cacheUpdated, setCacheUpdated] = useState(false)

  const lsKey = `goal-${values.id}-cache`
  const cache = getCache(lsKey)

  useEffect(() => {
    if (cache) {
      Object.keys(cache).forEach(key => {
        // key is keyof GoalsInterface but typescript marks it as string
        // @ts-expect-error
        values[key] = cache[key]
      })
    }
    setCacheUpdated(true)
  }, [])

  useEffect(() => {
    setCache(lsKey, {
      name: values.name,
      description: values.description,
      owner: values.owner ? { id: values.owner.id } : undefined,
      parent: values.parent ? ({ id: values.parent.id } as GoalsInterface) : null,
      update_type: values.update_type
        ? { id: values.update_type.id, name: values.update_type.name }
        : undefined,
      content_object: values.content_object,
    })
  }, [
    values.name,
    values.description,
    values.owner,
    values.parent,
    values.update_type?.id,
    values.content_object,
    // todo: add cache for new target fields
  ])

  return {
    cleanCache: () => localStorage.removeItem(lsKey),
    cacheUpdated,
  }
}

const GoalFormPageBody = ({
  performanceSettings,
  defaultReviewCycle,
  backUrl,
}: {
  performanceSettings: PerformanceSettingsInterface
  defaultReviewCycle?: ReviewCyclesInterface
  backUrl: string
}) => {
  const goalForm = useLapeContext<GoalsInterface & { targets: unknown }>()
  const { values, submit } = goalForm
  const { getFormsGetters } = useFormObserver()
  const { cleanCache, cacheUpdated } = useGoalFormCache()
  const { showLoading } = useSubmitFlowHelpers()

  const targetFormsGetters = getFormsGetters()

  const isDraft = values.status.id === Statuses.draft

  const disableSubmit =
    values.update_type?.id === 'target_based'
      ? !values.kpis.length && !targetFormsGetters.length
      : values.update_type?.id === 'cascaded'
      ? !values.parent
      : false

  // eslint-disable-next-line consistent-return
  const onSubmit = async (updateStatus: boolean) => {
    let targetsError: unknown = false

    const hideLoading = showLoading('Saving...')

    if (values.update_type?.id === 'target_based' && targetFormsGetters.length) {
      /*
      we need sequence of requests because BE blocks DB per request 
      error example: 
          deadlock detected
          DETAIL:  Process 2843751 waits for ShareLock on transaction 1046283; blocked by process 2843674.
          Process 2843674 waits for ShareLock on transaction 1046282; blocked by process 2843751.
          HINT:  See server log for query details.
          CONTEXT:  while updating tuple (115,42) in relation "kpi_target"
      */
      for (const getter of targetFormsGetters) {
        const { form, validator } = getter()
        form.apiErrors = {}
        const cb = validator?.validate
          ? // eslint-disable-next-line no-loop-func
            validator.validate(async () => {
              try {
                const result = await form.submit()
                return result
              } catch (err) {
                targetsError = err
                throw err
              }
            })
          : form.submit

        // eslint-disable-next-line no-await-in-loop
        await cb()
      }
    }

    if (!targetsError) {
      let statusUpdated = false
      goalForm.apiErrors = {}

      if (values.status.id === Statuses.draft && updateStatus) {
        // business requirement to transit from draft status on a first submit
        values.status = { id: Statuses.on_track, name: 'On track ' }
        statusUpdated = true
      }

      if (!values.name) {
        // only empty string will trigger BE validation error in case of empty
        values.name = ''
      }

      try {
        const result = await submit()
        cleanCache()
        return result
      } catch (err) {
        if (statusUpdated) {
          values.status = { id: Statuses.draft, name: 'Draft' }
        }
        throw err
      } finally {
        hideLoading()
      }
    } else {
      hideLoading()
      // eslint-disable-next-line no-throw-literal
      throw targetsError
    }
  }

  const hasDirtyTargets = () => {
    return !!targetFormsGetters.find(getter => getter().form.dirty)
  }

  return (
    <PageBody maxWidth={{ all: BREAKPOINTS.lg, xxl: BREAKPOINTS.xl }}>
      {cacheUpdated && (
        <GoalForm
          enforceParentKpi={performanceSettings.enable_mandatory_parent_kpi}
          defaultReviewCycle={defaultReviewCycle}
        />
      )}

      <PageActions alignSelf="center" aria-label="page actions" mt="s-24">
        <Flex justifyContent="center" maxWidth="340px" alignSelf="center" gap="s-8">
          {isDraft ? (
            <NewSaveButtonWithPopup<GoalsInterface>
              variant="secondary"
              successText="Goal saved as draft"
              useValidator
              onClick={() => onSubmit(false)}
              noPopup
              previewUrl={backUrl}
              hideWhenNoChanges={!hasDirtyTargets()}
            >
              Save as draft
            </NewSaveButtonWithPopup>
          ) : null}
          <NewSaveButtonWithPopup<GoalsInterface>
            successText={isDraft ? 'Goal submitted for approval' : undefined}
            useValidator
            onClick={() => onSubmit(true)}
            elevated
            previewUrl={goal => pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: goal.id })}
            tooltipText={
              !disableSubmit
                ? ''
                : values.update_type?.id === 'target_based'
                ? 'Please, add at least one target'
                : 'Please, select a parent'
            }
            disabled={disableSubmit}
            hideWhenNoChanges={!hasDirtyTargets()}
          >
            {isDraft ? 'Submit' : 'Save changes'}
          </NewSaveButtonWithPopup>
        </Flex>
      </PageActions>
    </PageBody>
  )
}

export const GoalFormPage = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: reviewCycles } = useGetReviewCycles()
  const [autoDeletePending, setAutoDeletePending] = useState(false)
  const location = useLocation<{
    reviewCycleId?: string
    history: string[]
    confirmBack?: boolean
  }>()
  const { confirm, showError, showLoading, confirmationDialog } = useSubmitFlowHelpers()

  const shouldConfirmBack =
    values.status.id === Statuses.draft && location.state?.confirmBack

  const backRoutes: Record<'employees' | 'teams' | 'department', string> = {
    employees: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.GENERAL,
    teams: ROUTES.FORMS.TEAM.GOALS.GENERAL,
    department: ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL,
  }

  const backUrl = values.content_type?.model
    ? pathToUrl(backRoutes[values.content_type?.model], {
        id: values.object_id,
      })
    : ROUTES.FORMS.COMPANY.GOALS.GENERAL

  const contentLabelByType: Record<EntityTypes, string> = {
    [EntityTypes.department]: 'department',
    [EntityTypes.team]: 'team',
    [EntityTypes.teams]: 'team',
    [EntityTypes.employees]: 'employee',
    [EntityTypes.employee]: 'employee',
    [EntityTypes.company]: 'company',
    [EntityTypes.function]: 'function',
    [EntityTypes.role]: 'role',
    [EntityTypes.specialisation]: 'specialisation',
  }

  const isDefaultCycle = (cycle: ReviewCyclesInterface) => {
    return location.state?.reviewCycleId
      ? cycle.id === Number(location.state.reviewCycleId)
      : cycle.offset === 0
  }

  const entityTitle = values.content_type?.model
    ? contentLabelByType[values.content_type.model]
    : 'company'

  const title =
    values.status.id !== Statuses.draft
      ? `Edit ${entityTitle} goal`
      : `Add new ${entityTitle} goal`

  const confirmBack = async () => {
    setAutoDeletePending(true)
    await deleteGoal(values.id).catch(captureException)
    setAutoDeletePending(false)
    goBack(backUrl)
  }

  const onDeleteGoal = async () => {
    const confirmed = await confirm({
      yesMessage: 'Delete',
      yesBtnVariant: 'negative',
      noMessage: 'Cancel',
      variant: 'compact',
      body: 'Do you want to delete this goal?',
    })

    const navigateBack = () => {
      const history: string[] | undefined = location.state?.history
      const isLastWasPreview = history?.at(-1)?.match(RegExp(`/goal/.*/${values.id}.*`))

      if (isLastWasPreview) {
        navigateReplace(backUrl)
      } else {
        goBack(backUrl, undefined, true)
      }
    }

    if (confirmed.status === 'confirmed') {
      try {
        const hidePopup = showLoading('Deleting...')
        await deleteGoal(values.id)
        hidePopup()
        navigateBack()
      } catch (err) {
        captureException(err)
        showError('Failed to delete goal', 'Please try again')
      }
    }
  }

  return performanceSettings ? (
    <PageWrapper>
      <PageHeader
        variant="narrow"
        noWrap={false}
        title={title}
        backUrl={backUrl}
        backButton={autoDeletePending ? <Spinner /> : undefined}
        onClickBack={shouldConfirmBack ? confirmBack : undefined}
        subtitle={
          <ActionButton useIcon="Delete" variant="negative" onClick={onDeleteGoal}>
            Delete
          </ActionButton>
        }
      />
      <FormObserverProvider>
        <GoalFormPageBody
          defaultReviewCycle={reviewCycles?.results.find(isDefaultCycle)}
          backUrl={backUrl}
          performanceSettings={performanceSettings}
        />
      </FormObserverProvider>
      {confirmationDialog}
    </PageWrapper>
  ) : null
}
