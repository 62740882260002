import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useRef,
} from 'react'
import { TargetFormInterface } from '../SidebarForms/AddGoalTargetForm'
import { FormValidatorContextType } from '@src/features/Form/FormValidator'

type Getter = () => {
  form: TargetFormInterface
  validator: FormValidatorContextType
}

interface FormObserverContextInterface {
  registerForm: (getter: Getter, id: number) => void
  unregisterForm: (id: number) => void
  getFormsGetters: () => Getter[]
  getFormById: (id?: number) => Getter | null
}

const FormObserverContext = createContext<FormObserverContextInterface>({
  registerForm: () => {},
  unregisterForm: () => {},
  getFormsGetters: () => [],
  getFormById: () => null,
})

export const FormObserverProvider = ({ children }: PropsWithChildren<{}>) => {
  const forms = useRef<Record<number, Getter>>({})

  const context = useMemo<FormObserverContextInterface>(() => {
    return {
      registerForm: (getter, id) => {
        forms.current[id] = getter
      },
      unregisterForm: id => {
        delete forms.current[id]
      },
      getFormsGetters: () => {
        return Object.values(forms.current)
      },
      getFormById: id => {
        return id ? (forms.current[id] ? forms.current[id] : null) : null
      },
    }
  }, [])

  return (
    <FormObserverContext.Provider value={context}>
      {children}
    </FormObserverContext.Provider>
  )
}

export const useFormObserver = () => {
  return useContext(FormObserverContext)
}
