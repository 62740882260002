import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import DOMPurify from 'dompurify'
import styled from 'styled-components'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { FormattedMessage } from 'react-intl'

const StyledText = styled(Text)`
  p {
    margin: 0;
  }

  ol,
  ul {
    padding: 0 0 0 40px;
    margin: 0;
  }
`

type Props = {
  data?: string
}

const HeadCard = ({ data }: Props) => {
  if (!data || data === '<p><br></p>') {
    return null
  }

  return (
    <ActionWidget
      title={
        <FormattedMessage
          id="recruitment.interviewTemplate.purpose"
          defaultMessage="Purpose"
        />
      }
      mb="s-24"
      avatarColor={Token.color.blue}
    >
      <StyledText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />
    </ActionWidget>
  )
}

export default HeadCard
