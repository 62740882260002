import React from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { LeadershipPositionInterface, LeadershipStatus } from '@src/interfaces/leadership'
import { getColor } from '@src/styles/colors'
import { formatMoney } from '@src/utils/format'
import isNumber from 'lodash/isNumber'
import startCase from 'lodash/startCase'
import { TextButton } from '@revolut/ui-kit'

export const leadershipPositionNameColumn: ColumnInterface<LeadershipPositionInterface> =
  {
    type: CellTypes.text,
    idPoint: 'leadership_position',
    dataPoint: 'leadership_position',
    sortKey: 'leadership_position',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Position',
  }

export const leadershipEmployeeNameColumn: ColumnInterface<LeadershipPositionInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.id',
    dataPoint: 'employee.full_name',
    sortKey: 'employee__full_name',
    filterKey: 'employee__id',
    selectorsKey: selectorKeys.all_employees,
    title: 'Employee',
    insert: ({ data }) => {
      if (data.employee) {
        return <UserWithAvatar {...data.employee} />
      }
      return '-'
    },
  }

export const leadershipSalaryColumn: ColumnInterface<LeadershipPositionInterface> = {
  type: CellTypes.insert,
  idPoint: 'annual_expense',
  dataPoint: 'annual_expense',
  sortKey: 'annual_expense',
  filterKey: 'annual_expense',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (!isNumber(data.annual_expense)) {
      return '-'
    }

    return formatMoney(data.annual_expense, data.expense_currency)
  },
  masked: false,
  title: 'Salary/year',
}

export const leadershipSpecialisationColumn: ColumnInterface<LeadershipPositionInterface> =
  {
    type: CellTypes.text,
    idPoint: 'specialisation__id',
    dataPoint: 'specialisation__name',
    sortKey: 'specialisation__name',
    filterKey: 'specialisation__id',
    selectorsKey: selectorKeys.approved_specialisations,
    title: 'Role (Specialisation)',
  }

export const leadershipSeniorityColumn: ColumnInterface<LeadershipPositionInterface> = {
  type: CellTypes.text,
  idPoint: 'seniority__id',
  dataPoint: 'seniority__name',
  sortKey: 'seniority__name',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const leadershipLMColumn: ColumnInterface<LeadershipPositionInterface> = {
  type: CellTypes.insert,
  idPoint: 'line_manager__id',
  dataPoint: 'line_manager__name',
  sortKey: 'line_manager__full_name',
  filterKey: 'line_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'Line manager',
  insert: ({ data }) => {
    if (!data.line_manager) {
      return '-'
    }
    return <UserWithAvatar {...data.line_manager} />
  },
}

export const leadershipFMColumn: ColumnInterface<LeadershipPositionInterface> = {
  type: CellTypes.insert,
  idPoint: 'quality_control__id',
  dataPoint: 'quality_control__name',
  sortKey: 'quality_control__full_name',
  filterKey: 'quality_control__id',
  selectorsKey: selectorKeys.employee,
  title: 'Functional manager',
  insert: ({ data }) => {
    if (!data.quality_control) {
      return '-'
    }
    return <UserWithAvatar {...data.quality_control} />
  },
}

export const leadershipLocationColumn: ColumnInterface<LeadershipPositionInterface> = {
  type: CellTypes.text,
  idPoint: 'location__id',
  dataPoint: 'location__name',
  sortKey: 'location__location_name',
  filterKey: 'location__id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
}

export const leadershipPositionStatusColumn: ColumnInterface<LeadershipPositionInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status',
    dataPoint: 'status',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.leadership_statuses,
    title: 'Status',
    colors: (data, theme) => {
      switch (data.status) {
        case LeadershipStatus.Hired:
          return getColor(theme, 'warning')
        case LeadershipStatus.Terminated:
          return getColor(theme, 'error')
        case LeadershipStatus.Unassigned:
          return getColor(theme, 'grey-tone-50')
        default:
          return 'inherit'
      }
    },
    insert: ({ data }) => {
      return startCase(data.status)
    },
  }

export const getLeadershipPositionActionColumn = (
  onEdit: (rowData: LeadershipPositionInterface) => void,
): ColumnInterface<LeadershipPositionInterface> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    return (
      <TextButton
        onClick={e => {
          onEdit(data)
          e.stopPropagation()
        }}
      >
        Edit
      </TextButton>
    )
  },
})
