import { HStack, TextButton, Text, Icon } from '@revolut/ui-kit'
import { captureException } from '@sentry/react'
import { goalsRequests } from '@src/api/goals'
import { getStatusColor } from '@src/components/CommonSC/General'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { IdAndName, Statuses } from '@src/interfaces'
import { GoalsInterface } from '@src/interfaces/goals'
import { useSubmitFlowHelpers } from '@src/pages/Forms/GoalForm/common/utils'
import React from 'react'

export const GoalStatusDropdown = ({
  goal,
  onStatusChanged,
}: {
  goal: GoalsInterface
  onStatusChanged: (newStatus: IdAndName<Statuses>) => void
}) => {
  const { confirmationDialog, showError, prompt, showLoading, showSuccess } =
    useSubmitFlowHelpers()

  const statusLabel = (
    <Text variant="h6" color={getStatusColor(goal.status.id)}>
      {goal.status.name}
    </Text>
  )

  const isFilteredOutStatus = (status: Statuses) =>
    [Statuses.draft, Statuses.archived].includes(status)

  return isFilteredOutStatus(goal.status.id) ? (
    statusLabel
  ) : (
    <>
      <RadioSelectInput<IdAndName<Statuses>>
        filter={item => !isFilteredOutStatus(item.id)}
        onChange={async item => {
          if (!item) {
            return
          }

          const { status, comment } = await prompt({
            variant: 'compact',
            label: `You can leave a comment to justify ${item?.name} status`,
            yesMessage: 'Save',
            noMessage: 'Cancel',
          })

          if (status === 'confirmed') {
            try {
              const hidePopup = showLoading('Updating...')
              const response = await goalsRequests.update(
                { status: { id: item?.id, name: item?.name }, comment },
                { id: String(goal.id) },
              )

              onStatusChanged(response.data.status)
              hidePopup()
              showSuccess('Status updated successfully')
            } catch (err) {
              captureException(err)
              showError('Failed to update status.', 'Please, try again')
            }
          }
        }}
        useQuery
        selector={selectorKeys.goal_statuses}
        fitInAnchor
        searchable={false}
        renderInput={(open, setOpen, ref) => (
          <TextButton onClick={() => setOpen(!open)} ref={ref}>
            <HStack gap="s-8" align="center">
              <Text variant="h6" color={getStatusColor(goal.status.id)}>
                {goal.status.name}
              </Text>
              <Icon name={open ? 'ChevronUp' : 'ChevronDown'} size={16} />
            </HStack>
          </TextButton>
        )}
      >
        {item => <Text color={getStatusColor(item.value.id)}>{item.label}</Text>}
      </RadioSelectInput>
      {confirmationDialog}
    </>
  )
}
