import React, { useEffect } from 'react'
import { selectorKeys } from '@src/constants/api'
import {
  KpiTargetStrategy,
  KpiTargetStrategyEnum,
  KPITemplateInterface,
} from '@src/interfaces/KpiTemplates'
import KPITemplateParameters from '@src/features/FormTabs/Kpi/KPITemplateParameters'
import KPIDataBaseSelection from '@src/features/FormTabs/Kpi/KPIDataBaseSelection'
import TemplateTargetsTable from './TemplateTargets/TemplateTargetsTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { connect, useLape } from 'lape'
import { Color, HStack, InputGroup, Text, VStack } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewCodeEditor from '@components/Inputs/LapeFields/LapeNewCodeEditor'
import { Grid } from '@components/CommonSC/Grid'
import NewSelectionCard from '@components/NewSelectionCard/NewSelectionCard'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { KPITypes } from '@src/constants/table'
import { kpiTemplateRequestsNew } from '@src/api/kpiTemplate'
import { KPIPerformanceTypes } from '@src/interfaces/kpis'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { DeleteButtonLape } from '@src/pages/Forms/FormButtons'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { InfoIconWithTooltip } from '@src/components/Icon/InfoIconWithTooltip'

const backUrl = ROUTES.FORMS.KPI_TEMPLATES.TEMPLATES

export const General = connect(() => {
  const { values } = useLapeContext<KPITemplateInterface>()
  const state = useLape({
    hasSelectedType: false,
  })
  const isMandatory = values.kpi_performance_type?.id === KPIPerformanceTypes.mandatory

  useEffect(() => {
    if (!values.kpi_type) {
      values.kpi_type = { id: KPITypes.employee_kpi, name: 'Individual' }
    }
    if (!values.targets_strategy) {
      values.targets_strategy = KpiTargetStrategy[0]
    }
    if (!values.targets) {
      values.targets = []
    }
  }, [])

  useEffect(() => {
    if (isMandatory) {
      values.targets_strategy = KpiTargetStrategy.find(
        strategy => strategy.id === KpiTargetStrategyEnum.enforce,
      )!
    }
  }, [isMandatory])

  useEffect(() => {
    if (values.parent_template) {
      values.parent = undefined
    }
  }, [values.parent_template])

  if (!values.id && !state.hasSelectedType) {
    return (
      <PageWrapper>
        <PageHeader
          title={values.id ? values.name : 'Select KPI type'}
          backUrl={ROUTES.FORMS.KPI_TEMPLATES.TEMPLATES}
        />
        <PageBody>
          <Grid flow="row" gap={16}>
            <NewSelectionCard
              onClick={() => {
                values.kpi_type = {
                  id: KPITypes.department_kpi,
                  name: 'Department',
                }
                state.hasSelectedType = true
              }}
              icon="Bank"
              title="Department KPI"
              subtitle="Create a KPI template for departments"
            />
            <NewSelectionCard
              onClick={() => {
                values.kpi_type = { id: KPITypes.team_kpi, name: 'Team' }
                state.hasSelectedType = true
              }}
              icon="People"
              title="Team KPI"
              subtitle="Create a KPI template for teams"
            />
            <NewSelectionCard
              onClick={() => {
                values.kpi_type = { id: KPITypes.employee_kpi, name: 'Individual' }
                state.hasSelectedType = true
              }}
              icon="Profile"
              title="Employee KPI"
              subtitle="Create a KPI template for employees"
            />
          </Grid>
        </PageBody>
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <PageHeader
        title={values.id ? values.name : 'New KPI Template'}
        subtitle={values.id && values.owner?.full_name}
        backUrl={backUrl}
      >
        <DeleteButtonLape<KPITemplateInterface>
          data={values}
          backUrl={backUrl}
          deleteApi={kpiTemplateRequestsNew.delete!}
          title="KPI Template"
        />
      </PageHeader>
      <PageBody mt="s-24">
        <AutoStepper>
          <NewStepperTitle title="Template info" mt={0} />
          <InputGroup>
            <LapeNewInput label="Template Name" name="name" required />
            <LapeRadioSelectInput
              name="owner"
              label="Template Owner"
              selector={selectorKeys.employee}
            />
          </InputGroup>
          <NewStepperTitle title="KPI info" />
          <InputGroup>
            <LapeNewInput label="KPI Name" name="kpi_name" required />
            <LapeNewTextArea
              label="KPI Description"
              name="description"
              required
              rows={1}
            />
            <LapeRadioSelectInput
              name="unit"
              label="Unit"
              selector={selectorKeys.kpi_units}
              value={
                values.unit
                  ? {
                      name: values.unit,
                    }
                  : null
              }
              onChange={option => {
                if (option) {
                  values.unit = option.name
                }
              }}
            />
            <VStack pt="s-24" space="s-16">
              <Text variant="primary">Targets</Text>
              <TemplateTargetsTable dynamicTargets={values.dynamic_targets} />
              {values.dynamic_targets && (
                <VStack space="s-16" mt="s-8">
                  <HStack space="s-8" align="center">
                    <Text variant="h6" color={Color.GREY_TONE_50}>
                      Define Dynamic Targets
                    </Text>
                    <InfoIconWithTooltip
                      color={Color.GREY_TONE_50}
                      tooltipProps={{ width: '360px' }}
                      size={16}
                      content={`The query should return a table with two columns as integers, one labelled as "initial_value" and another as "target_value"`}
                    />
                  </HStack>
                  <LapeNewCodeEditor
                    placeholder="SQL query"
                    name="dynamic_targets_sql_query"
                  />
                </VStack>
              )}
            </VStack>
          </InputGroup>
          <VStack pt="s-32" space="s-16">
            <Text variant="primary">SQL</Text>
            <KPITemplateParameters type={values.kpi_type} />
            <VStack space="s-8">
              <KPIDataBaseSelection
                onChange={option => {
                  if (option) {
                    values.db_client_type = option
                  }
                }}
                value={values.db_client_type}
                label={values.db_client_type?.name}
              />
              <LapeNewCodeEditor placeholder="SQL query" name="sql_query_template" />
            </VStack>
          </VStack>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          afterSubmitUrl={ROUTES.FORMS.KPI_TEMPLATES.TEMPLATES}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
})

export const KpiTemplateForm = () => (
  <Form api={kpiTemplateRequestsNew}>
    <General />
  </Form>
)
