import { Button } from '@revolut/ui-kit'
import React from 'react'
import { PageActions } from '@src/components/Page/PageActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type Props = {
  closeRoute: string
}

const TrackProgressPageActions = ({ closeRoute }: Props) => {
  return (
    <PageActions>
      <Button elevated use={InternalLink} to={closeRoute} size="md" variant="primary">
        Close bulk edit task
      </Button>
    </PageActions>
  )
}

export default TrackProgressPageActions
