import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dropdown, MoreBar, Token, VStack } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { DocumentRequestType } from '@src/pages/Forms/DocumentRequest/common'
import { hasDocumentManagerPermissions } from '@src/pages/EmployeeProfile/Layout/Documents/common'
import { FeatureFlags } from '@src/store/auth/types'

type Props = {
  data: EmployeeInterface
}
export const RequestDocumentButton = ({ data }: Props) => {
  const user = useSelector(selectUser)
  const featureFlags = useSelector(selectFeatureFlags)

  const isCurrentUser = user.id === data.id
  const allowSelfServe = featureFlags.includes(FeatureFlags.DocumentsTemplateRevamp)

  const [isGenerateDropdownOpen, setIsGenerateDropdownOpen] = useState(false)
  const dropdownAnchorRef = useRef<HTMLButtonElement>(null)

  const onClickRequestTypeOption = (requestType: DocumentRequestType) =>
    navigateTo(
      pathToUrl(
        ROUTES.FORMS.DOCUMENT_REQUEST.GENERAL,
        { employeeId: data.id },
        requestType ? { requestType } : undefined,
      ),
      {
        initialValues: {
          employee: { id: data.id, name: data.full_name },
          issuer: { id: user.id, name: user.full_name },
        },
      },
    )

  if (!isCurrentUser && !hasDocumentManagerPermissions(data)) {
    return null
  }
  return hasDocumentManagerPermissions(data) ? (
    <>
      <MoreBar.Action
        useIcon="SendMessage"
        ref={dropdownAnchorRef}
        onClick={() => setIsGenerateDropdownOpen(!isGenerateDropdownOpen)}
      >
        Request
      </MoreBar.Action>
      <Dropdown
        open={isGenerateDropdownOpen}
        anchorRef={dropdownAnchorRef}
        onClose={() => setIsGenerateDropdownOpen(false)}
      >
        <VStack backgroundColor={Token.color.widgetBackground}>
          {[
            {
              icon: 'Document' as const,
              title: 'Manually',
              type: 'manual' as const,
            },
            {
              icon: 'Lightning' as const,
              title: 'By template rule',
              type: 'byTemplate' as const,
            },
            {
              icon: 'DocumentsPair' as const,
              title: 'Collection',
              type: 'byCollection' as const,
            },
            isCurrentUser && allowSelfServe
              ? {
                  icon: 'Envelope' as const,
                  title: 'Self serve',
                  type: 'selfServe' as const,
                }
              : null,
          ]
            .filter(Boolean)
            .map(({ icon, title, type }) => (
              <Dropdown.Item
                use="button"
                useIcon={icon}
                key={title}
                onClick={() => onClickRequestTypeOption(type)}
              >
                {title}
              </Dropdown.Item>
            ))}
        </VStack>
      </Dropdown>
    </>
  ) : allowSelfServe ? (
    <MoreBar.Action
      useIcon="SendMessage"
      onClick={() => onClickRequestTypeOption('selfServe')}
    >
      Request
    </MoreBar.Action>
  ) : null
}
