// TODO: REVC-5947 - move grade calculation logic (at least this config) to BE
export const perfGradesCalculationConfig = {
  perf_rating_scores: {
    improvement_needed: -1,
    dont_know: 0,
    poor: 1,
    poor_plus: 1.33,
    basic_minus: 1.67,
    basic: 2,
    basic_plus: 2.33,
    intermediate_minus: 2.67,
    intermediate: 3,
    intermediate_plus: 3.33,
    advanced_minus: 3.67,
    advanced: 4,
    advanced_plus: 4.33,
    expert_minus: 4.67,
    expert: 5,
  },
  values_average_limits: {
    exceptional: 0.8,
    strong: 0.4,
    average_plus: -0.4,
    average_minus: -0.8,
  },
  skills_average_limits: {
    exceptional: 1,
    strong: 0.5,
    average_plus: -0.5,
    average_minus: -1,
  },
  deliverables_coefficient: 0.5,
  skills_coefficient: 0.25,
  final_grade_scores: {
    empty: 0,
    poor: 1,
    average_minus: 2,
    average_plus: 3,
    strong: 4,
    exceptional: 5,
  },
  scores_to_grades_limits: {
    exceptional: 4.5,
    strong: 3.5,
    average_plus: 2.5,
    average_minus: 1.5,
  },
}
