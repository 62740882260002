import React, { useRef, useState } from 'react'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import {
  ActionMenu,
  MoreBar,
  StatusPopup,
  Token,
  Tooltip,
  useStatusPopup,
  useTooltip,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { EntityPermissions } from '@src/store/auth/types'
import {
  useGetOpenRequisitionsByJobPosting,
  useGetPendingRequisitionsByJobPosting,
} from '@src/api/requisitions'
import { useGetJobPostingSettings } from '@src/api/settings'
import { isPublished } from '../utils'
import { useCareersSettings } from '@src/api/recruitment/careers'
import useIsCommercial from '@src/hooks/useIsCommercial'

type PublishActionProps = {
  loading: boolean
  onPublish: (publishStatus: PublishingStatuses) => void
}

const PublishActionMenu = ({ loading, onPublish }: PublishActionProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  return (
    <>
      <MoreBar.Action
        useIcon="EyeShow"
        ref={anchorRef}
        onClick={() => {
          setOpen(!open)
        }}
        pending={loading}
        disabled={loading}
      >
        Publish
      </MoreBar.Action>
      <ActionMenu open={open} anchorRef={anchorRef} onClose={() => setOpen(false)}>
        <ActionMenu.Group>
          <ActionMenu.Item
            useIcon="Globe"
            disabled={values.status === PublishingStatuses.fully_published}
            onClick={() => {
              onPublish(PublishingStatuses.fully_published)
            }}
          >
            Publish Internally & Externally
          </ActionMenu.Item>
          <ActionMenu.Item
            useIcon="Services"
            disabled={values.status === PublishingStatuses.published_internally}
            onClick={() => {
              onPublish(PublishingStatuses.published_internally)
            }}
          >
            Publish Internally
          </ActionMenu.Item>
          <ActionMenu.Item
            useIcon="16/LinkExternal"
            disabled={values.status === PublishingStatuses.published_externally}
            onClick={() => {
              onPublish(PublishingStatuses.published_externally)
            }}
          >
            Publish Externally
          </ActionMenu.Item>
          {!(
            values.status === PublishingStatuses.closed ||
            values.status === PublishingStatuses.unpublished
          ) && (
            <ActionMenu.Item
              useIcon="EyeHide"
              color={Token.color.red}
              onClick={() => {
                onPublish(PublishingStatuses.closed)
              }}
            >
              Unpublish
            </ActionMenu.Item>
          )}
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}

const PublishAction = ({ loading, onPublish }: PublishActionProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  return (
    <MoreBar.Action
      useIcon={isPublished(values) ? 'EyeHide' : 'EyeShow'}
      onClick={() => {
        onPublish(
          values.status === PublishingStatuses.unpublished
            ? PublishingStatuses.fully_published
            : PublishingStatuses.unpublished,
        )
      }}
      pending={loading}
      disabled={loading}
    >
      {isPublished(values) ? 'Unpublish' : 'Publish'}
    </MoreBar.Action>
  )
}

type PublishActionWithTooltipProps = {
  tooltip: React.ReactNode
}

const PublishActionWithTooltip = ({ tooltip }: PublishActionWithTooltipProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { getAnchorProps, getTargetProps } = useTooltip()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const enableAllPublishingStatuses = jobPostingSettings?.enable_all_publishing_statuses
  const publishIcon = enableAllPublishingStatuses
    ? 'EyeShow'
    : isPublished(values)
    ? 'EyeHide'
    : 'EyeShow'
  const publishLabel = enableAllPublishingStatuses
    ? 'Publish'
    : isPublished(values)
    ? 'Unpublish'
    : 'Publish'
  return (
    <>
      <MoreBar.Action useIcon={publishIcon} aria-disabled="true" {...getAnchorProps()}>
        {publishLabel}
      </MoreBar.Action>
      <Tooltip {...getTargetProps()}>{tooltip}</Tooltip>
    </>
  )
}

const PublishButton = () => {
  const { values, loading, submit } = useLapeContext<JobPostingInterface>()
  const statusPopup = useStatusPopup()
  const canChange = !!values.field_options?.actions?.includes?.(EntityPermissions.Change)
  const isCommercial = useIsCommercial()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const { data: careerPageSettings } = useCareersSettings()
  const { data: openRequisitions, isLoading: loadingOpenRequisitions } =
    useGetOpenRequisitionsByJobPosting(values.id)
  const { data: pendingRequisitions, isLoading: loadingPendingRequisitions } =
    useGetPendingRequisitionsByJobPosting(values.id)
  if (!canChange) {
    return null
  }
  if (loadingOpenRequisitions || loadingPendingRequisitions) {
    return <MoreBar.Action aria-disabled="true" pending />
  }
  if (values.approval_status !== 'approved') {
    return (
      <PublishActionWithTooltip tooltip="Posting must be approved in order to be published" />
    )
  }
  if (isCommercial && !careerPageSettings?.publish_career_website) {
    return (
      <PublishActionWithTooltip
        tooltip={
          <>
            Careers website is not published
            <br />
            The application form is currently unavailable to candidates.
            <br />
            Please publish Careers website in the settings to start receiving
            applications.
          </>
        }
      />
    )
  }
  if (jobPostingSettings?.enable_requisition_publishing_checks) {
    const openRequisitionCount = openRequisitions?.count ?? 0
    const pendingRequisitionCount = pendingRequisitions?.count ?? 0
    if (!openRequisitionCount) {
      let tooltip = 'No requisitions are connected'
      if (pendingRequisitionCount === 1) {
        tooltip = 'Connected requisition is not opened'
      }
      if (pendingRequisitionCount > 1) {
        tooltip = 'None of the connected requisitions are opened'
      }
      return <PublishActionWithTooltip tooltip={tooltip} />
    }
  }
  const onClickPublish = async (publishStatus: PublishingStatuses) => {
    const fallbackStatus = values.status

    values.status = publishStatus

    try {
      await submit()

      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>
            Job posting{' '}
            {values.status === PublishingStatuses.unpublished
              ? 'unpublished'
              : 'published'}
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } catch (error) {
      values.status = fallbackStatus
      const errorsData = error?.response?.data
      const statusError = errorsData?.status?.[0]
      const compensationBandsError = errorsData?.salary_bands_by_location
        ? `Missing compensation data for: ${Object.keys(
            errorsData.salary_bands_by_location,
          ).join(', ')}`
        : undefined
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>
            {statusError || compensationBandsError || 'Error updating job posting status'}
          </StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }
  const Component = jobPostingSettings?.enable_all_publishing_statuses
    ? PublishActionMenu
    : PublishAction
  return <Component loading={!!loading} onPublish={onClickPublish} />
}

export default connect(PublishButton)
