import { api, apiWithoutHandling, normalizeCaptchaError } from '@src/api'
import {
  CAREERS_TEAM_OTHER,
  CareersApplicationInterface,
  CareersApplicationSettingsInterface,
  CareersFunctionGroupInterface,
  CareersPosition,
  CareersPositionLocation,
  CareersPositionResponse,
  CareersSections,
  CareersSettingsInterface,
} from '@src/interfaces/careers'
import findKey from 'lodash/findKey'
import { useFetch } from '@src/utils/reactQuery'
import { IdAndName, RequestInterfaceNew } from '@src/interfaces'
import { Country } from '@src/interfaces/selectors'
import { getIdFromPositionSlug } from '@src/pages/Careers/utils'
import { useMemo } from 'react'
import { API } from '@src/constants/api'
import { getFormData } from '@src/utils/getApplicationFormData'

export const normalizeCareersGroups = (
  groups?: CareersFunctionGroupInterface[],
): Record<string, string[]> =>
  groups?.reduce((acc, item) => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    acc[item.name] = item.functions.map(func => func.name)
    return acc
  }, {}) || {}

const responseTransformer = (
  response: CareersPositionResponse,
  groups?: CareersFunctionGroupInterface[],
): CareersPosition => {
  const team =
    findKey(
      normalizeCareersGroups(groups),
      functions => response.function?.name && functions.includes(response.function.name),
    ) || CAREERS_TEAM_OTHER

  const locations: CareersPositionLocation[] = response.locations.map(location => ({
    name: location.name,
    type: location.type || 'office',
    country: location.country?.name || null,
  }))

  return {
    id: response.id,
    text: response.title,
    locations,
    description: response.description || '',
    creation_date_time: response.creation_date_time,
    team,
  }
}

export const useFetchCareersList = () => {
  const { data: settings } = useCareersSettings()
  const context = useFetch<CareersPositionResponse[]>('/postings', 'v2')
  const data = useMemo(
    () =>
      context.data?.map((p: CareersPositionResponse) =>
        responseTransformer(p, settings?.function_groups),
      ),
    [context.data, settings?.function_groups],
  )

  return {
    ...context,
    data,
  }
}

export const useFetchPosition = (id?: string) => {
  const context = useFetch<CareersPositionResponse>(id ? `/postings/${id}` : null, 'v2')
  const data = useMemo(
    () => (context.data ? responseTransformer(context.data) : undefined),
    [context.data],
  )

  return {
    ...context,
    data,
  }
}

export const useFetchCountries = () => {
  const context = useFetch<{ options: Country[] }>(
    '/interviews/applications/applicantCountries',
  )

  return { ...context, data: context.data?.options }
}
export const useFetchAdditionalQuestions = (id?: string) =>
  useFetch<CareersSections | null>(
    id ? '/interviews/applications/generateAdditionalQuestions' : null,
    undefined,
    { params: { posting_id: id } },
    undefined,
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  )
export const useFetchGenderChoices = () => {
  const context = useFetch<{ options: IdAndName<string, string>[] }>(
    API.APPLICANT_GENDERS,
  )

  return { ...context, data: context.data?.options }
}
export const useFetchDiversityEthnicities = () => {
  const context = useFetch<{ options: IdAndName<string, string>[] }>(
    API.APPLICANT_ETHNICITIES,
  )

  return { ...context, data: context.data?.options }
}
export const useFetchUniversities = () => {
  const context = useFetch<{ options: IdAndName[] }>(API.APPLICANT_UNIVERSITIES)

  return { ...context, data: context.data?.options }
}
export const useFetchEducationMajors = () => {
  const context = useFetch<{ options: IdAndName[] }>(API.APPLICANT_EDUCATION_MAJORS)

  return { ...context, data: context.data?.options }
}
export const useFetchDegrees = () => {
  const context = useFetch<{ options: IdAndName<string>[] }>(API.APPLICANT_DEGREES)

  return { ...context, data: context.data?.options }
}
export const useFetchWorkExperienceCompanies = () => {
  const context = useFetch<{ options: IdAndName[] }>(
    API.APPLICANT_WORK_EXPERIENCE_COMPANIES,
  )

  return { ...context, data: context.data?.options }
}
export const useFetchWorkExperiencePositions = () => {
  const context = useFetch<{ options: IdAndName[] }>(
    API.APPLICANT_WORK_EXPERIENCE_POSITIONS,
  )

  return { ...context, data: context.data?.options }
}

export const careersApplicationRequest: RequestInterfaceNew<CareersApplicationInterface> =
  {
    get: async ({ id }) => api.get(`/interviews/applications/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`/interviews/applications/${id}`, data),
    submit: async (data, { id }) =>
      apiWithoutHandling
        .post(`/interviews/applications`, getFormData(data, getIdFromPositionSlug(id!)), {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch(normalizeCaptchaError),
    delete: async ({ id }) => api.delete(`/interviews/applications/${id}`),
  }

export const useCareersSettings = () =>
  useFetch<CareersSettingsInterface>(
    API.POSTING_CAREER_PAGE_SETTINGS,
    'v2',
    undefined,
    undefined,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const useCareersApplicationSettings = () =>
  useFetch<CareersApplicationSettingsInterface>('/postings/applicationFormSettings', 'v2')

export const useIsDiversityCollected = (countryId?: number) =>
  useFetch<{ is_diversity_collected: boolean }>(
    countryId ? '/interviews/applications/isDiversityCollected' : null,
    undefined,
    { params: { country_id: countryId } },
    undefined,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )
