import React, { useState } from 'react'
import { Avatar, Group, Item, TabBar, VStack } from '@revolut/ui-kit'
import {
  ReviewCategory,
  ReviewerRelation,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { BarChart } from '@revolut/icons'
import { CurrentReview } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/CurrentReview'
import { CompletedReviews } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/CompletedReviews'
import { useEmployeeProfileData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { EmployeeInterface } from '@src/interfaces/employees'

enum ReviewTabs {
  Current = 'Current',
  Completed = 'Completed',
}

const renderTab = (
  review: ReviewScorecardInterface,
  tab?: ReviewTabs,
  employee?: EmployeeInterface,
) => {
  switch (tab) {
    case ReviewTabs.Current:
      return <CurrentReview review={review} employee={employee} />
    case ReviewTabs.Completed:
      return <CompletedReviews review={review} employee={employee} />
    default:
      return null
  }
}

export const PerformanceEditSummary = ({
  values,
}: {
  values: ReviewScorecardInterface
}) => {
  const [tab, setTab] = useState<ReviewTabs>(ReviewTabs.Current)
  const { data: employee } = useEmployeeProfileData({
    employeeId: values.reviewed_employee.id,
  })

  const shouldShowCompletedReviews =
    values.category === ReviewCategory.Performance &&
    values.reviewer_relation &&
    [
      ReviewerRelation.FunctionalManager,
      ReviewerRelation.LineManager,
      ReviewerRelation.HeadOfFunction,
    ].includes(values.reviewer_relation)

  const getSubtitle = () => {
    switch (values.category) {
      case ReviewCategory.Upwards:
        return 'Upwards Review'
      case ReviewCategory.Probation:
        return `Probation Review · CP${values.probation_checkpoint?.number}`
      case ReviewCategory.PIP_V2:
      case ReviewCategory.PIP:
        return 'PIP Checkpoint'
      default:
        return values.cycle?.name
    }
  }

  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon={BarChart} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Performance summary</Item.Title>
          <Item.Description>{getSubtitle()}</Item.Description>
        </Item.Content>
      </Item>
      <VStack space="s-16" p="s-16">
        {shouldShowCompletedReviews && (
          <TabBar
            variant="segmented"
            value={tab}
            onChange={val => {
              if (val) {
                setTab(val as ReviewTabs)
              }
            }}
          >
            <TabBar.Item to={ReviewTabs.Current}>My review</TabBar.Item>
            <TabBar.Item to={ReviewTabs.Completed}>Completed reviews</TabBar.Item>
          </TabBar>
        )}
        {renderTab(values, tab, employee)}
      </VStack>
    </Group>
  )
}
