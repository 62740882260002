import React, { useEffect, useState } from 'react'
import { Group, VStack, Link as UIKitLink, HStack, Tag, Token } from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AccountsSettingsInterface } from '@src/interfaces/settings'
import {
  TenantOnboardingProgress,
  TenantOnboardingSidebar,
  formatAdminName,
} from '../common'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { getAccountSettings } from '@src/api/settings'
import { pushNotification } from '@src/store/notifications/actions'
import {
  ERROR_DEFAULT_DURATION,
  SUCCESS_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { useTenantOnboardingProgress, useTenantSubscriptionInfo } from '@src/api/tenants'
import { SubscriptionInfoInterface } from '@src/interfaces/plans'
import { formatMoney } from '@src/utils/format'
import {
  SUBSCRIPTION_CURRENCY,
  formatSubscriptionFreePeriod,
} from '@src/pages/Settings/PlanManagement/common'
import { useGetSelectors } from '@src/api/selectors'
import { OnboardingCheckpointSelectorInterface } from '@src/interfaces/onboardingChecklist'
import { selectorKeys } from '@src/constants/api'

const REFRESH_STATUS_INTERVAL = 5000

export const Information = () => {
  const { initialValues, values, reset } = useLapeContext<AccountsSettingsInterface>()

  const { data: subscriptionInfo } = useTenantSubscriptionInfo(values.id)

  const [progressSidebarOpen, setProgressSidebarOpen] = useState(false)

  const onboardingCheckpoints = useGetSelectors<OnboardingCheckpointSelectorInterface>(
    selectorKeys.tenant_onboarding_checkpoints,
  )
  const onboardingProgress = useTenantOnboardingProgress(values.id)

  useEffect(() => {
    if (
      values.state === 'setup_failed' ||
      values.state === 'active' ||
      values.state === 'demo' ||
      values.state === 'closed'
    ) {
      return undefined
    }
    const refreshInterval = setInterval(async () => {
      const response = await getAccountSettings(values.id)

      if (response.data.state !== values.state) {
        if (response.data.state === 'active') {
          pushNotification({
            value: 'Account set to active',
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        }
        if (response.data.state === 'setup_failed') {
          pushNotification({
            value: 'Setup failed',
            duration: ERROR_DEFAULT_DURATION,
            type: NotificationTypes.error,
          })
        }
        reset(response.data)
      }
    }, REFRESH_STATUS_INTERVAL)

    return () => clearInterval(refreshInterval)
  }, [values.state])

  return (
    <>
      <PageBody>
        <VStack space="s-24">
          <FormPreview title="Plan &amp; Billing" data={subscriptionInfo}>
            <Group>
              <FormPreview.Item
                title="Subscription date"
                field="subscribed_at"
                type="date"
              />
              <FormPreview.Item title="Plan" field="subscription_plan.name" />
              <FormPreview.Item
                title="Value for the plan"
                insert={() =>
                  subscriptionInfo?.subscription_plan?.monthly_fee != null
                    ? `${formatMoney(
                        Number(subscriptionInfo.subscription_plan.monthly_fee),
                        SUBSCRIPTION_CURRENCY,
                      )}/active employee`
                    : '-'
                }
              />
              <FormPreview.Item
                title="Trial period"
                insert={() =>
                  subscriptionInfo?.subscription_plan?.free_days
                    ? formatSubscriptionFreePeriod(
                        subscriptionInfo.subscription_plan.free_days,
                      )
                    : '-'
                }
              />
              <FormPreview.Item<SubscriptionInfoInterface>
                title="Payment status for last billing cycle"
                field="last_payment_status.name"
                color={data => {
                  if (data.last_payment_status?.id === 'success') {
                    return Token.color.green
                  }
                  if (data.last_payment_status?.id === 'failure') {
                    return Token.color.red
                  }
                  return Token.color.foreground
                }}
              />
              <FormPreview.Item
                title="Payment details valid"
                insert={() =>
                  values.is_payment_method_set == null
                    ? '-'
                    : values.is_payment_method_set
                    ? 'Yes'
                    : 'No'
                }
              />
            </Group>
          </FormPreview>

          <FormPreview data={initialValues} title="Company info">
            <Group>
              <FormPreview.Item title="Company name" field="company_name" />
              <FormPreview.Item
                title="Company subdomain"
                insert={() => {
                  const value = `${values.subdomain}.${window.location.hostname}`
                  return (
                    <UIKitLink
                      href={`${window.location.protocol}//${value}`}
                      target="_blank"
                    >
                      {value}
                    </UIKitLink>
                  )
                }}
              />
              <FormPreview.Item title="Country" field="country.name" />
              <FormPreview.Item title="Headcount" field="active_headcount" />
              {values.labels.length > 0 ? (
                <FormPreview.Item
                  title="Labels"
                  insert={() => (
                    <HStack space="s-4">
                      {values.labels.map(label => (
                        <Tag
                          key={label}
                          variant="faded"
                          color="foreground"
                          bg={Token.color.greyTone8}
                        >
                          {label}
                        </Tag>
                      ))}
                    </HStack>
                  )}
                />
              ) : null}
              <FormPreview.Item title="Company ID" field="id" />
              <FormPreview.Item
                title="Onboarding progress"
                insert={() => (
                  <TenantOnboardingProgress
                    onSelectProgress={() => setProgressSidebarOpen(true)}
                    checkpointsSelector={onboardingCheckpoints.data}
                    progress={onboardingProgress}
                  />
                )}
              />
            </Group>
          </FormPreview>

          <FormPreview data={initialValues} title="Admin info">
            <Group>
              <FormPreview.Item title="Name" insert={formatAdminName} />
              <FormPreview.Item title="Email" field="admin.email" />
              <FormPreview.Item
                title="Phone number"
                insert={() => {
                  if (
                    !initialValues.admin ||
                    !initialValues.admin.phone_country_code ||
                    !initialValues.admin.phone_number_short
                  ) {
                    return '-'
                  }
                  return `${initialValues.admin.phone_country_code.code}${initialValues.admin.phone_number_short}`
                }}
              />
            </Group>
          </FormPreview>
        </VStack>
      </PageBody>

      <TenantOnboardingSidebar
        progress={onboardingProgress}
        open={progressSidebarOpen}
        onClose={() => setProgressSidebarOpen(false)}
      />
    </>
  )
}
