import React from 'react'
import { Flex, MoreBar, Widget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { AutomationListInterface } from '@src/interfaces/jobPostings/automation'
import {
  automationCandidatesColumn,
  automationCreatedColumn,
  automationNameColumn,
  automationStatusColumn,
  automationUpdatedColumn,
} from '@src/constants/columns/jobPostings/automation'
import { automationRequests } from '@src/api/jobPostings/automation'
import { useTable } from '@components/Table/hooks'
import { useParams } from 'react-router-dom'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'

const row = (jobPostingId: number): RowInterface<AutomationListInterface> => ({
  linkToForm: ({ id }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.JOB_POSTING_AUTOMATION.DETAILS, { jobPostingId, id }),
    ),
  cells: [
    {
      ...automationNameColumn,
      width: 300,
    },
    {
      ...automationCandidatesColumn,
      width: 300,
    },
    {
      ...automationCreatedColumn,
      width: 300,
    },
    {
      ...automationUpdatedColumn,
      width: 300,
    },
    {
      ...automationStatusColumn,
      width: 300,
    },
  ],
})

const Automation = () => {
  const { id } = useParams<{ id: string }>()
  const initialFilter = [
    {
      filters: [{ name: `${id}`, id }],
      columnName: 'job_posting',
      nonResettable: true,
    },
  ]
  const table = useTable<AutomationListInterface>(automationRequests(), initialFilter)

  return (
    <Widget p="s-16">
      <Flex mb="s-16">
        <MoreBar>
          <MoreBar.Action
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.JOB_POSTING_AUTOMATION.DETAILS, {
              jobPostingId: id,
            })}
            useIcon="Plus"
          >
            Add new automation
          </MoreBar.Action>
        </MoreBar>
      </Flex>
      <Flex style={{ position: 'relative' }} flex="1 0">
        <AdjustableTable<AutomationListInterface>
          name={TableNames.PostingAutomationRules}
          useWindowScroll
          dataType="Automation rule"
          row={row(+id)}
          {...table}
          noDataMessage="Automation rules will appear here."
        />
      </Flex>
    </Widget>
  )
}

export default Automation
