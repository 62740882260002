import React, { useState } from 'react'
import { Header, Input, InputGroup, Layout, Side, TabBar, Text } from '@revolut/ui-kit'
import {
  ReportOutputFormat,
  QueryColumnInterface,
  ReportHighlightInterface,
} from '@src/interfaces/dataAnalytics'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { ReorderColumnsSettings } from './UiFormatTable/ReorderColumns'
import { SortColumnsSettings } from './UiFormatTable/SortColumns'
import { SummarizeSettings } from './UiFormatTable/Summarize'
import {
  ReorderSettingsInterface,
  SortSettingsInterface,
  FilterSettingsInterface,
} from '../hooks/useTableSettings'
import { IconName } from '@revolut/ui-kit/types/dist/components/Icon/types'
import { FilterColumnsSettings } from '@src/pages/Forms/DataAnalyticsReportForm/components/UiFormatTable/FilterColumns'
import { Highlight } from '@src/pages/Forms/DataAnalyticsReportForm/components/UiFormatTable/Highlight'

interface Props {
  isOpen: boolean
  handleClose: () => void
  outputFormat: ReportOutputFormat
  reorderSettings: ReorderSettingsInterface
  setReorderSettings: React.Dispatch<React.SetStateAction<ReorderSettingsInterface>>
  sortSettings: SortSettingsInterface
  setSortSettings: React.Dispatch<React.SetStateAction<SortSettingsInterface>>
  filterSettings: FilterSettingsInterface
  setFilterSettings: React.Dispatch<React.SetStateAction<FilterSettingsInterface>>
  highlightSettings: ReportHighlightInterface[] | null
  setHighlightSettings: React.Dispatch<
    React.SetStateAction<ReportHighlightInterface[] | null>
  >
  summarizeOptions: RadioSelectOption<QueryColumnInterface>[]
  tableSummary: { value: string | null; count: number }[]
}

enum tabsCategory {
  Filter = 'filter',
  Reorder = 'reorder',
  Sort = 'sort',
  Summarize = 'summarize',
  Colors = 'highlight',
}

const tabs: { title: string; id: tabsCategory; icon: IconName }[] = [
  {
    title: 'Edit Columns',
    id: tabsCategory.Reorder,
    icon: 'Drag',
  },
  {
    title: 'Sort',
    id: tabsCategory.Sort,
    icon: 'ArrowsSort',
  },
  {
    title: 'Filter',
    id: tabsCategory.Filter,
    icon: 'Filter',
  },
  {
    title: 'Summarize',
    id: tabsCategory.Summarize,
    icon: 'Lightning',
  },
  {
    title: 'Highlight',
    id: tabsCategory.Colors,
    icon: 'Palette',
  },
]

export const FormatTableSidebar = ({
  isOpen,
  handleClose,
  outputFormat,
  reorderSettings,
  setReorderSettings,
  sortSettings,
  setSortSettings,
  filterSettings,
  setFilterSettings,
  highlightSettings,
  setHighlightSettings,
  summarizeOptions,
  tableSummary,
}: Props) => {
  const [currentTabId, setCurrentTabId] = useState<tabsCategory>(tabsCategory.Reorder)
  const [renameItem, setRenameItem] = useState<string | null>(null)

  return (
    <Layout.SideFill>
      <Side
        onClose={() => {
          handleClose()
          setRenameItem(null)
        }}
        open={isOpen}
      >
        {renameItem ? (
          <>
            <Header variant="item">
              <Header.BackButton aria-label="Back" onClick={() => setRenameItem(null)} />
              <Header.Title>Edit columns</Header.Title>
            </Header>
            <InputGroup>
              <Text variant="secondary">Set Column Header Name</Text>
              <Input
                label={renameItem}
                onChange={e => {
                  setReorderSettings(current => ({
                    ...current,
                    visible: current.visible.map(t => {
                      return t.name === renameItem
                        ? { ...t, name: renameItem, alias: e.currentTarget.value }
                        : t
                    }),
                  }))
                }}
                value={
                  reorderSettings.visible.find(t => {
                    return t.name === renameItem
                  })?.alias || ''
                }
              />
            </InputGroup>
          </>
        ) : (
          <>
            <Header variant="item">
              <Header.CloseButton aria-label="Close sidebar" onClick={handleClose} />
            </Header>
            <TabBar mb="s-16" value={currentTabId} variant="segmented">
              {tabs.map(tab => (
                <TabBar.Item
                  aria-selected={currentTabId === tab.id}
                  key={tab.id}
                  onClick={() => setCurrentTabId(tab.id)}
                  useIcon={tab.icon}
                >
                  {tab.title}
                </TabBar.Item>
              ))}
            </TabBar>
            {currentTabId === tabsCategory.Reorder && (
              <ReorderColumnsSettings
                tableSettings={reorderSettings}
                setTableSettings={setReorderSettings}
                setRenameItem={setRenameItem}
              />
            )}
            {currentTabId === tabsCategory.Sort && (
              <SortColumnsSettings
                tableSettings={sortSettings}
                setTableSettings={setSortSettings}
              />
            )}
            {currentTabId === tabsCategory.Filter && (
              <FilterColumnsSettings
                tableSettings={filterSettings}
                setTableSettings={setFilterSettings}
                visibleColumns={reorderSettings.visible}
              />
            )}
            {currentTabId === tabsCategory.Summarize && (
              <SummarizeSettings
                options={summarizeOptions}
                outputFormat={outputFormat}
                tableSummary={tableSummary}
              />
            )}
            {currentTabId === tabsCategory.Colors && (
              <Highlight
                options={summarizeOptions}
                highlightSettings={highlightSettings}
                setHighlightSettings={setHighlightSettings}
              />
            )}
          </>
        )}
      </Side>
    </Layout.SideFill>
  )
}
