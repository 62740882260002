import React from 'react'
import HelpSections from '@src/pages/Forms/EmployeePerformance/components/HelpSections/HelpSections'
import { ManagementBehavioursHelp } from './ManagementBehavioursHelp'

interface Props {
  isManagerBehaviour?: boolean
}

const SkillsHelp = ({ isManagerBehaviour }: Props) => {
  return isManagerBehaviour ? (
    <ManagementBehavioursHelp />
  ) : (
    <HelpSections
      sections={[
        `The review scorecard has been generated based on the competency matrix of the employee’s specialisation. You can see the expected ratings in the summary details.`,
        `Skills are assessed by reviewing the criteria for each rating level, and confirming which ones the employee has demonstrated competency in.`,
        `Criteria for higher rating levels only appear once all of the lower levels are confirmed. Start from Basic and work up.`,
        `If a selected rating is different from the expectations or recommendation for an employee, you will need to add a comment to justify your review.`,
      ]}
    />
  )
}

export default SkillsHelp
