import React from 'react'
import { Toggle, Box, IconButton, Flex, Switch, Text } from '@revolut/ui-kit'
import * as Icons from '@revolut/icons'

import styled from 'styled-components'
import { Button } from '@src/pages/Landing/components/Button'

export interface CookieConsentPreferencesProps {
  onClickBack?: () => void
  onClickSave: (isAnalyticsEnabled: boolean) => void
  isAnalyticsEnabled?: boolean
}

const ButtonBack = styled(IconButton)({ flex: 1 })

export const CookieConsentPreferences = ({
  onClickBack,
  onClickSave,
  isAnalyticsEnabled = true,
}: CookieConsentPreferencesProps) => {
  return (
    <Toggle defaultState={isAnalyticsEnabled}>
      {({ state, toggle }) => (
        <>
          <Flex mb="s-24">
            {onClickBack && (
              <ButtonBack
                aria-label="Back"
                onClick={onClickBack}
                useIcon={Icons.BackButtonArrow}
              />
            )}
            <Text use="h5" variant="h6" mx="auto" textAlign="center" color="foreground">
              Cookie preferences
            </Text>
            {onClickBack && <Box flex={1} />}
          </Flex>
          <Text use="p" variant="secondary" color="foreground" mb="s-40">
            We’re not talking about the crunchy, tasty kind. These cookies help us keep
            our safe, give you a better experience and show more relevant ads. We won’t
            turn on unless you accept. Want to know more or adjust your preferences?
          </Text>
          <Box mb="s-32">
            <Text
              use="h6"
              // @ts-ignore
              variant="subtitle2"
              mb="s-12"
              color="foreground"
            >
              Essential cookies
            </Text>
            <Text variant="secondary" color="foreground">
              Essential cookies help you to access the website and each of its web pages
              and sub domains, by enabling basic functions like cookie consent. They
              cannot be disabled.
            </Text>
          </Box>
          <Box mb="s-32">
            <Flex
              use="label"
              mb="s-12"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                use="h6"
                // @ts-ignore
                variant="subtitle2"
                mr="s-12"
                color="foreground"
              >
                Analytics and advertising cookies
              </Text>
              <Switch checked={state} onClick={() => toggle()} />
            </Flex>
            <Text variant="secondary" color="foreground">
              These allow us to recognise and count the number of visitors to our website,
              see how visitors browse our website, and track the user feedback. This
              information is used for us to be able to provide the service with good
              quality and improve the product functionalities.
            </Text>
          </Box>
          <Button variant="primary" size="sm" onClick={() => onClickSave(state)}>
            Save cookie settings
          </Button>
        </>
      )}
    </Toggle>
  )
}
