import React from 'react'
import { Box, Sticky, BREAKPOINTS, BoxProps, PositionProps } from '@revolut/ui-kit'
import { css } from 'styled-components'
import { useTheme } from '@src/styles/theme'

const containerStyle = css`
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.aboveMain};
`

const contentsStyle = css`
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
  grid-auto-columns: minmax(max-content, 375px);
`

type Props = (BoxProps | PositionProps) & { maxWidthMd?: string }
export const PageActions: React.FC<Props> = ({
  maxWidthMd = BREAKPOINTS.md,
  children,
  ...props
}) => {
  const theme = useTheme()
  return (
    <Sticky
      display="flex"
      bottom={24}
      mt="auto"
      maxWidth={{ all: '100%', md: maxWidthMd }}
      css={containerStyle}
      style={{ zIndex: theme.zIndex.pageActions }}
      {...props}
    >
      <Box css={contentsStyle}>{children}</Box>
    </Sticky>
  )
}
