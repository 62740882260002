import {
  OnboardingCheckpointInterface,
  OnboardingCheckpointStateId,
} from '@src/interfaces/onboardingChecklistV2'

import { firstStepsCategories } from './constants'

export const getFirstStepsCheckpoints = (
  checkpoints: OnboardingCheckpointInterface[] = [],
) => checkpoints.filter(checkpoint => firstStepsCategories.includes(checkpoint.category))

export const getNextStepsCheckpoints = (
  checkpoints: OnboardingCheckpointInterface[] = [],
) => checkpoints.filter(checkpoint => !firstStepsCategories.includes(checkpoint.category))

export const checkIfFirstStepsCompleted = (
  checkpoints: OnboardingCheckpointInterface[] = [],
) => {
  return getFirstStepsCheckpoints(checkpoints).every(
    checkpoint => checkpoint.state.id === 'completed',
  )
}

const countCheckpointsByState = (
  checkpoints: OnboardingCheckpointInterface[] = [],
  stateId: OnboardingCheckpointStateId,
) => checkpoints.filter(checkpoint => checkpoint.state.id === stateId).length

export const getCompletedCheckpointsNum = (
  checkpoints: OnboardingCheckpointInterface[] = [],
) => countCheckpointsByState(checkpoints, 'completed')
