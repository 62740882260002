import {
  InterviewRecruiterInterface,
  InterviewRoundInterface,
  InterviewSpecialisationInterface,
  OriginType,
} from '@src/interfaces/interviewTool'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLape } from 'lape'
import {
  createInterviewRound,
  getCandidateEligibleRequisitions,
  updateInterviewRound,
  useGetInterviewRounds,
  useGetSources,
} from '@src/api/recruitment/interviews'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import SideBar from '@components/SideBar/SideBar'
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Input,
  InputGroup,
  Side,
  Subheader,
  Text,
  VStack,
} from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { RequisitionOptionInterface } from '@src/interfaces/requisitions'
import { getRequisitionsSelector } from '@src/api/requisitions'
import SenioritySelector from '@src/features/SenioritySelector/SenioritySelector'
import { selectorKeys } from '@src/constants/api'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { IdAndName } from '@src/interfaces'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'

type Props = {
  round?: InterviewRoundInterface
  refresh: () => void
  canAddRound?: boolean
  canEditRound?: boolean
}
const EditableSources: OriginType[] = ['application', 'sourcing']

const CandidateInterviewDetailsSidebar = ({
  round,
  refresh,
  canEditRound,
  canAddRound,
}: Props) => {
  const { setActiveAction } = useCandidateProfileContext()

  const onExit = () => {
    setActiveAction(undefined)
  }

  const [selectedRound, setSelectedRound] = useState<InterviewRoundInterface | undefined>(
    round,
  )

  const state = useLape({
    specialisation: selectedRound?.specialisation,
    seniority: selectedRound?.seniority,
    recruiter: selectedRound?.recruiter,
    origin: selectedRound?.origin,
    created_by: selectedRound?.created_by,
    hiringManager: selectedRound?.hiring_manager,
    requisition: selectedRound?.requisition,
    preferred_location: selectedRound?.preferred_location,
    local_currency: selectedRound?.local_currency,
    local_expected_salary: selectedRound?.local_expected_salary,
    additional_information_about_expected_salary:
      selectedRound?.additional_information_about_expected_salary,
    candidate_declined_to_disclose_expected_salary:
      selectedRound?.candidate_declined_to_disclose_expected_salary,
    isLoading: false,
  })

  const { data: originSelector } = useGetSources(round?.candidate?.id)

  const { data: rounds, refetch } = useGetInterviewRounds(round?.candidate.id)

  const canEditSource = selectedRound?.origin
    ? EditableSources.includes(selectedRound?.origin)
    : true

  const originOptions = useMemo(
    () =>
      originSelector?.options?.map(item => ({
        value: item,
        label: item.name,
      })) || [],
    [originSelector],
  )

  const origin = useMemo(
    () => originSelector?.options?.find(item => item.id === state.origin),
    [originSelector, state.origin],
  )

  useEffect(() => {
    if (selectedRound) {
      state.specialisation = selectedRound.specialisation
    }
    state.seniority = selectedRound?.seniority
    state.recruiter = selectedRound?.recruiter
    state.origin = selectedRound?.origin
    state.created_by = selectedRound?.created_by
    state.hiringManager = selectedRound?.hiring_manager
    state.requisition = selectedRound?.requisition
    state.preferred_location = selectedRound?.preferred_location
    state.local_currency = selectedRound?.local_currency
    state.local_expected_salary = selectedRound?.local_expected_salary
  }, [selectedRound])

  const eligibleRequisitions = useCallback(async () => {
    if (!round?.candidate.id) {
      return []
    }

    const result = await getCandidateEligibleRequisitions(round.candidate.id)
    return result.data.results
  }, [round])

  const onSubmit = async () => {
    if (!state.specialisation?.id || !round?.candidate.id) {
      return
    }

    try {
      state.isLoading = true
      if (!selectedRound) {
        await createInterviewRound(
          round.candidate.id,
          state.specialisation.id,
          state?.seniority?.id,
          state?.recruiter?.id,
          state.origin,
          ['sourcing', 'referral', 'booking_link'].includes(state.origin ?? '')
            ? state.created_by
            : null,
          state.hiringManager || null,
          state.requisition || null,
          state.preferred_location,
          state.local_currency,
          state.local_expected_salary,
          state?.additional_information_about_expected_salary,
          state?.candidate_declined_to_disclose_expected_salary,
        )
      } else {
        await updateInterviewRound({
          id: selectedRound.id,
          seniorityId: state.seniority?.id,
          recruiterId: state.recruiter?.id,
          origin: state.origin,
          created_by: ['sourcing', 'referral', 'booking_link'].includes(
            state.origin ?? '',
          )
            ? state.created_by
            : null,
          hiringManager: state.hiringManager || null,
          requisition: state.requisition || null,
          preferred_location: state.preferred_location,
          local_currency: state.local_currency,
          local_expected_salary: state.local_expected_salary,
          additional_information_about_expected_salary:
            state?.additional_information_about_expected_salary,
          candidate_declined_to_disclose_expected_salary:
            state?.candidate_declined_to_disclose_expected_salary,
        })
      }

      await refetch()
      await refresh()
      pushNotification({
        type: NotificationTypes.success,
        value: 'The round has been updated',
        duration: SUCCESS_DEFAULT_DURATION,
      })
      onExit()
    } finally {
      state.isLoading = false
    }
  }

  const canViewSalary = (round?.field_options?.actions ?? []).includes('view_salary')
  const canEditSalary = (round?.field_options?.actions ?? []).includes('edit_salary')

  return (
    <SideBar isOpen onClose={onExit} title="Interview details">
      <VStack gap="s-16">
        {round && (
          <Box>
            <Subheader variant="nested">
              <Subheader.Title>Assigned specialisation</Subheader.Title>
            </Subheader>

            <InputGroup>
              <RadioSelectInput<InterviewSpecialisationInterface>
                label="Main assigned specialisation"
                selector={eligibleRequisitions}
                disabled={!canAddRound}
                onChange={option => {
                  if (option) {
                    setSelectedRound(rounds?.find(r => r.specialisation.id === option.id))
                    state.specialisation = option
                  }
                }}
                value={state.specialisation}
              />

              <RadioSelectInput<RequisitionOptionInterface>
                label="Requisition"
                selector={getRequisitionsSelector}
                onChange={option => {
                  if (option) {
                    state.requisition = {
                      id: option.value?.id,
                      requisition_title: option.value?.requisition_title,
                    }
                  }
                }}
                value={
                  state.requisition
                    ? {
                        id: state.requisition.id,
                        name: `${state.requisition.requisition_title} #${state.requisition.id}`,
                      }
                    : null
                }
              />

              <SenioritySelector
                specialisationId={state.specialisation?.id || null}
                label="Seniority"
                onChange={option => {
                  state.seniority = option
                }}
                disabled={!canEditRound}
                value={state.seniority}
                clearable={false}
              />

              <RadioSelectInput<InterviewRecruiterInterface>
                label="Recruiter"
                selector={selectorKeys.employee}
                disabled={!canAddRound}
                onChange={option => {
                  if (option) {
                    state.recruiter = option
                  }
                }}
                value={state.recruiter}
              />

              <RadioSelectInput<EmployeeOptionInterface>
                label="Hiring manager"
                selector={selectorKeys.employee}
                disabled={!canEditRound}
                onChange={option => {
                  if (option) {
                    state.hiringManager = option
                  }
                }}
                value={state.hiringManager}
              />

              <RadioSelectInput<IdAndName<OriginType>>
                label="Source"
                options={originOptions}
                disabled={!canEditRound || !canEditSource}
                searchable={false}
                onChange={option => {
                  if (option) {
                    state.origin = option.id
                  }
                }}
                value={origin}
              />

              {(state.origin === 'sourcing' || state.origin === 'referral') && (
                <RadioSelectInput<EmployeeOptionInterface>
                  label={state.origin === 'sourcing' ? 'Sourced by' : 'Referred by'}
                  selector={selectorKeys.employee}
                  disabled={!canEditRound || !canEditSource}
                  onChange={option => {
                    if (option) {
                      state.created_by = option
                    }
                  }}
                  value={state.created_by}
                />
              )}
              <RadioSelectInput
                disabled={!canEditRound}
                label="Preferred location"
                selector={selectorKeys.location}
                value={state.preferred_location}
                onChange={option => {
                  state.preferred_location = option!
                }}
              />
              {canViewSalary && (
                <>
                  <Grid columns="repeat(2, 50%)" columnGap="s-8">
                    <Box>
                      <RadioSelectInput
                        disabled={
                          !canEditSalary ||
                          state.candidate_declined_to_disclose_expected_salary
                        }
                        label="Desired salary currency"
                        selector={selectorKeys.currencies}
                        value={state.local_currency}
                        onChange={option => {
                          state.local_currency = option!
                        }}
                      />
                    </Box>
                    <Box>
                      <Input
                        label="Desired salary (annual)"
                        type="number"
                        disabled={
                          !canEditSalary ||
                          state.candidate_declined_to_disclose_expected_salary
                        }
                        value={state.local_expected_salary}
                        onChange={event => {
                          state.local_expected_salary = +event.currentTarget.value
                        }}
                      />
                    </Box>
                  </Grid>
                  <Input
                    label="Additional information about desired compensation (optional)"
                    type="text"
                    disabled={!canEditSalary}
                    value={state.additional_information_about_expected_salary}
                    onChange={event => {
                      state.additional_information_about_expected_salary =
                        event.currentTarget.value
                    }}
                  />
                  <Checkbox
                    checked={state.candidate_declined_to_disclose_expected_salary}
                    disabled={!canEditSalary}
                    onChange={event => {
                      state.candidate_declined_to_disclose_expected_salary =
                        event.currentTarget.checked
                    }}
                  >
                    <Text>Candidate declined to provide desired salary information</Text>
                  </Checkbox>
                </>
              )}
            </InputGroup>
          </Box>
        )}
      </VStack>
      <Side.Actions>
        {(canAddRound || canEditRound) && (
          <Button onClick={onSubmit} disabled={state.isLoading} pending={state.isLoading}>
            Save changes
          </Button>
        )}
      </Side.Actions>
    </SideBar>
  )
}

export default (props: Props) => {
  const { activeAction } = useCandidateProfileContext()

  if (activeAction?.type !== 'candidate') {
    return null
  }

  return <CandidateInterviewDetailsSidebar {...props} />
}
