import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { candidatesConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { CandidatesIntro } from './CandidatesIntro'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.INTRO,
    canView: [PermissionTypes.ViewCandidatePreferences],
    component: CandidatesIntro,
  },
]

export const Candidates = () => {
  return <OnboardingChecklistContent config={candidatesConfig} routes={routes} />
}
