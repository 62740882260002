import React, { MouseEvent, useEffect, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ActionButton, Avatar, Item, Subheader, VStack } from '@revolut/ui-kit'
import { connect } from 'lape'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import Outcome from '@src/pages/Forms/InterviewScorecardTemplate/Sections/Outcome/Outcome'
import ApplicationFormPreviewSection from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormPreviewSection'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import ApplicationFormEditSection from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormEditSection'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import isEmpty from 'lodash/isEmpty'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import CareersApplicationPredefinedSections from '@src/pages/Careers/CareersApplication/CareersApplicationPredefinedSections'
import { useGetJobPostingsApplicationFormSettings } from '@src/api/settings'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import pluralize from 'pluralize'
import { useGetCareersPredefinedSections } from '@src/pages/Careers/hooks/useCareersPredefinedSections'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useParams } from 'react-router-dom'

type ApplicationFormProps = {
  disableEditing?: boolean
  useSubheader?: boolean
}
export const ApplicationForm = ({
  disableEditing = false,
  useSubheader = false,
}: ApplicationFormProps) => {
  const context = useLapeContext<JobPostingInterface>()
  const { values, errors } = context
  const [openSectionIdx, setOpenSectionIdx] = useState<number | null>(null)
  const { data: applicationSettings } = useGetJobPostingsApplicationFormSettings()
  const predefinedSections = useGetCareersPredefinedSections({ locked: true })
  const permissions = useSelector(selectPermissions)
  const canEdit = permissions.includes(PermissionTypes.ChangeJobDescriptions)

  useEffect(() => {
    if (errors.application_form_sections) {
      setOpenSectionIdx(
        errors.application_form_sections.findIndex(item => !isEmpty(item)),
      )
    }
  }, [errors])

  const handleAddSection = (
    e: MouseEvent<HTMLDivElement> | MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    if (!values.application_form_sections) {
      values.application_form_sections = [
        {
          title: 'New section',
          description: '',
          questions: [],
        },
      ]
    } else {
      values.application_form_sections.push({
        title: 'New section',
        description: '',
        questions: [],
      })
    }

    setOpenSectionIdx(values.application_form_sections.length - 1)
  }

  const predefinedSectionsCount =
    (applicationSettings?.global_sections?.length || 0) + predefinedSections.length

  const renderAddAction = () => {
    if (disableEditing) {
      return null
    }
    if (useSubheader) {
      return (
        <Subheader>
          <Subheader.Title>Application questions</Subheader.Title>
          <Subheader.Side>
            <ActionButton useIcon="Plus" onClick={handleAddSection}>
              Add new section
            </ActionButton>
          </Subheader.Side>
        </Subheader>
      )
    }
    return (
      <Item use="button" type="button" onClick={handleAddSection}>
        <Item.Avatar>
          <Avatar useIcon="Plus" />
        </Item.Avatar>
        <Item.Content color="primary">Add new section</Item.Content>
      </Item>
    )
  }

  return (
    <>
      <VStack gap="s-16">
        {canEdit && renderAddAction()}
        <ActionWidget
          title={
            <>
              This application form contains {predefinedSectionsCount} predefined{' '}
              {pluralize('section', predefinedSectionsCount)} that can’t be edited. <br />{' '}
              Click on add new section to add new questions for the candidate
            </>
          }
        />
        <CareersApplicationPredefinedSections locked />
        {applicationSettings?.global_sections?.map((_, sectionIdx) => (
          <ApplicationFormPreviewSection
            key={sectionIdx}
            idx={sectionIdx}
            titleIndexNumber={sectionIdx + 1}
            onClickEdit={setOpenSectionIdx}
            sections={applicationSettings.global_sections}
            locked
          />
        ))}
        {values.application_form_sections?.map((_, sectionIdx) => {
          return (
            <ApplicationFormPreviewSection
              key={sectionIdx}
              idx={sectionIdx}
              titleIndexNumber={
                (applicationSettings?.global_sections?.length || 0) + sectionIdx + 1
              }
              onClickEdit={setOpenSectionIdx}
              sections={values.application_form_sections}
              onChange={sections => {
                values.application_form_sections = sections || []
              }}
              disableEditing={disableEditing}
            />
          )
        })}
      </VStack>
      <Outcome />
      <ApplicationFormEditSection
        idx={openSectionIdx}
        onClose={() => setOpenSectionIdx(null)}
      />
    </>
  )
}

export default connect(() => {
  const params = useParams()
  return (
    <PageWrapper>
      <PageHeader
        title="Application form"
        backUrl={pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, params)}
      />
      <PageBody>
        <ApplicationForm />
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          onAfterSubmit={resp => {
            if (resp.id && resp.specialisation?.id) {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                  id: resp.id,
                  specId: resp.specialisation.id,
                }),
              )
              return
            }

            navigateReplace(pathToUrl(ROUTES.RECRUITMENT.JOB_POSTINGS))
          }}
          successText="Job posting saved successfully"
        />
      </PageActions>
    </PageWrapper>
  )
})
