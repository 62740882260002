import { ROUTES } from '@src/constants/routes'
import PendingInterviews from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/PendingInterviews'
import CompletedInterviews from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/CompletedInterviews'
import { Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Route, Switch } from 'react-router-dom'
import React from 'react'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import RejectedInterviews from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/RejectedInterviews'

const Interviews = () => {
  const tabs = [
    {
      path: ROUTES.APPS.CANDIDATES.INTERVIEWS.PENDING,
      to: ROUTES.APPS.CANDIDATES.INTERVIEWS.PENDING,
      title: 'Pending',
      component: PendingInterviews,
    },
    {
      path: ROUTES.APPS.CANDIDATES.INTERVIEWS.COMPLETED,
      to: ROUTES.APPS.CANDIDATES.INTERVIEWS.COMPLETED,
      title: 'Completed',
      component: CompletedInterviews,
    },
    {
      path: ROUTES.APPS.CANDIDATES.INTERVIEWS.REJECTED,
      to: ROUTES.APPS.CANDIDATES.INTERVIEWS.REJECTED,
      title: 'Rejected',
      component: RejectedInterviews,
    },
  ]

  return (
    <>
      <Flex alignItems="flex-start" mb="s-24">
        <TabBarNavigation isSubtab tabs={tabs} />
      </Flex>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
        <InternalRedirect to={tabs[0].path} />
      </Switch>
    </>
  )
}

export default Interviews
