import { useSelector } from 'react-redux'

import { selectPermissions } from '@src/store/auth/selectors'
import { useGlobalSettings } from '@src/api/settings'
import { useGetOnboardingCheckpoints } from '@src/api/onboardingChecklistV2'

import { allSortedConfigs, OnboardingCheckpointConfig } from './checkpointsConfig'

export const useGetAllowedCheckpoints = () => {
  const permissions = useSelector(selectPermissions)
  const { settings } = useGlobalSettings()
  const { checkpoints = [] } = useGetOnboardingCheckpoints()

  return checkpoints.filter(checkpoint => {
    const checkpointConfig = allSortedConfigs.find(
      config => config.category === checkpoint.category,
    )
    const hasPermissions = checkpointConfig?.canView.every(perm =>
      permissions.includes(perm),
    )
    const isVisible = checkpoint.state.id !== 'hidden'
    const enabledInSettings = checkpointConfig?.globalSetting
      ? settings[checkpointConfig.globalSetting]
      : true

    return Boolean(isVisible && enabledInSettings && hasPermissions)
  })
}

export const useGetAllowedCheckpointsWithConfigs = () => {
  const allowedCheckpoints = useGetAllowedCheckpoints()

  return allSortedConfigs
    .map(config => {
      const checkpointData = allowedCheckpoints.find(c => c.category === config.category)
      return checkpointData ? { data: checkpointData, config } : null
    })
    .filter(Boolean)
}

export const useNextConfig = (
  currentConfig: OnboardingCheckpointConfig,
): OnboardingCheckpointConfig | undefined => {
  const checkpoints = useGetAllowedCheckpointsWithConfigs()

  const currentConfigIndex = checkpoints.findIndex(
    checkpoint => checkpoint.data.category === currentConfig.category,
  )
  return checkpoints[currentConfigIndex + 1]?.config
}
