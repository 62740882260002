import React from 'react'
import JobPostingsComponentTable from '@src/features/JobPostingsTable/JobPostingsTable'
import { MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { useNavigateToJobPostingPath } from '@src/features/JobPostingFlow/utils'

const JobPostingsTable = () => {
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddJobDescriptions)

  const navigateToJobPosting = useNavigateToJobPostingPath()
  return (
    <JobPostingsComponentTable>
      {canAdd && (
        <MoreBar.Action
          onClick={() => {
            navigateToJobPosting({})
          }}
          useIcon={Plus}
        >
          Add Job posting
        </MoreBar.Action>
      )}
    </JobPostingsComponentTable>
  )
}

export default JobPostingsTable
