import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, MoreBar, Placeholder, TableWidget } from '@revolut/ui-kit'

import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/Table/AdjustableTable'
import { AllDocumentsStats, EmployeeDocumentInterface } from '@src/interfaces/documents'
import {
  documentAssignee,
  documentCategory,
  documentName,
} from '@src/constants/columns/documents'
import { getAllDocuments, getAllDocumentsStats } from '@src/api/documents'
import { selectPermissions } from '@src/store/auth/selectors'
import { TableNames } from '@src/constants/table'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { AddDocumentSidebar } from './AddDocumentSidebar'

const ROW: RowInterface<EmployeeDocumentInterface> = {
  cells: [
    {
      ...documentName,
      filterKey: null,
      width: 250,
    },
    {
      ...documentCategory,
      width: 200,
    },
    {
      ...documentAssignee,
      width: 200,
    },
  ],
}

export const DocumentsList = () => {
  const permissions = useSelector(selectPermissions)
  const showDocumentButtons = permissions.includes(PermissionTypes.UploadDocuments)

  const [isAddNewSidebarOpen, setIsAddNewSidebarOpen] = useState(false)

  const table = useTable<EmployeeDocumentInterface, AllDocumentsStats>({
    getItems: getAllDocuments,
    getStats: getAllDocumentsStats,
  })

  return (
    <>
      <TableWidget>
        <TableWidget.Actions>
          {showDocumentButtons && (
            <MoreBar>
              <MoreBar.Action
                useIcon="Plus"
                onClick={() => setIsAddNewSidebarOpen(!isAddNewSidebarOpen)}
              >
                Add document
              </MoreBar.Action>
              <MoreBar.Action
                to={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.FILES)}
                use={InternalLink}
                useIcon="ShareIOs"
              >
                Import documents
              </MoreBar.Action>
            </MoreBar>
          )}
        </TableWidget.Actions>
        <TableWidget.Table>
          <AdjustableTable
            name={TableNames.AllDocuments}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="All documents will appear here"
            emptyState={
              <Box mt="s-24">
                <Placeholder>
                  <Placeholder.Image src="https://assets.revolut.com/assets/3d-images-v2/3D299.png" />
                  <Placeholder.Title>Your documents will appear here</Placeholder.Title>
                </Placeholder>
              </Box>
            }
          />
        </TableWidget.Table>
      </TableWidget>
      <AddDocumentSidebar
        isOpen={isAddNewSidebarOpen}
        onClose={() => setIsAddNewSidebarOpen(false)}
        onSubmit={() => {
          table.refresh()
          setIsAddNewSidebarOpen(false)
        }}
      />
    </>
  )
}
