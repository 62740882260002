import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'

export const useOpenActionUrl = () => {
  const workspaceContext = useWorkspaceContext()

  return (actionUrl: string) => {
    const isExternalLink = !!actionUrl.match(/^https?:\/\//i)

    if (isExternalLink) {
      window.open(actionUrl, '_blank')
    } else {
      window.open(
        workspaceContext?.workspace
          ? `/${workspaceContext.workspace}${
              actionUrl.startsWith('/') ? actionUrl : `/${actionUrl}`
            }`
          : actionUrl,
        '_blank',
      )
    }
  }
}
