import React, { useState } from 'react'
import {
  Box,
  Flex,
  Text,
  Token,
  TransitionCollapse,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import ShevronToggle from '@src/components/Atoms/ShevronToggle'
import PredefinedLabel from '../PredefinedSectionLabel'

type LayoutProps = {
  body: React.ReactNode
  header: React.ReactNode
}

const Layout = ({ body, header }: LayoutProps) => {
  return (
    <Widget p="s-16">
      <VStack gap="s-16">
        {header}
        {body}
      </VStack>
    </Widget>
  )
}

type SectionHeadProps = {
  title: string
  subtitle?: string
  text?: string
  locked?: boolean
  isOpen?: boolean
}

const SectionHead = ({ title, subtitle, text, locked, isOpen }: SectionHeadProps) => {
  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" gap="s-8">
        <Flex justifyContent="space-between" alignItems="center" gap="s-8">
          <Text variant="h4" display="block">
            {title}
          </Text>
          {locked && <PredefinedLabel />}
        </Flex>
        {locked && <ShevronToggle isOpen={!!isOpen} />}
      </Flex>
      {subtitle && (
        <Text variant="h6" display="block" pt="s-4">
          {subtitle}
        </Text>
      )}
      {text && (
        <Text variant="secondary" color={Token.color.greyTone50} use="div" pt="s-4">
          {text}
        </Text>
      )}
    </Box>
  )
}

type SectionLayoutProps = Omit<SectionHeadProps, 'isOpen'> & {
  body: React.ReactNode
  locked?: boolean
}

const SectionLayout = ({ body, title, subtitle, text, locked }: SectionLayoutProps) => {
  const [expand, setExpand] = useState(locked)
  if (locked) {
    return (
      <Layout
        header={
          <Box
            onClick={() => {
              setExpand(!expand)
            }}
          >
            <SectionHead
              title={title}
              subtitle={subtitle}
              text={text}
              locked={locked}
              isOpen={expand}
            />
          </Box>
        }
        body={<TransitionCollapse in={expand}>{body}</TransitionCollapse>}
      />
    )
  }
  return (
    <Layout
      header={<SectionHead title={title} subtitle={subtitle} text={text} />}
      body={body}
    />
  )
}

export default SectionLayout
