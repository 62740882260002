import { Icon, TableWidget, TextButton, Token } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import {
  roadmapDueDate,
  roadmapGenericNameColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
} from '@src/constants/columns/roadmap'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import React, { useState } from 'react'
import { useSubmitFlowHelpers } from '../../../common/utils'
import { captureException } from '@sentry/react'
import { roadmapsRequests } from '@src/api/roadmaps'

export const RoadmapsList = ({ viewMode }: { viewMode: boolean }) => {
  const { values } = useLapeContext<GoalsInterface>()
  const [pendingDeleteId, setPendingdeleteId] = useState<number>()
  const { confirmationDialog, confirm, showError } = useSubmitFlowHelpers({
    confirmProps: { variant: 'compact' },
  })

  const cells: ColumnCellInterface<RoadmapInterface>[] = viewMode
    ? [
        {
          ...roadmapGenericNameColumn,
          sortKey: null,
          filterKey: null,
          width: 200,
        },
        {
          ...roadmapPriorityColumn,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          ...roadmapProgressColumn,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          ...roadmapDueDate,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
      ]
    : [
        {
          type: CellTypes.text,
          idPoint: 'review_cycle.id',
          dataPoint: 'review_cycle.name',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: 'Cycle',
          width: 100,
        },
        {
          ...roadmapGenericNameColumn,
          sortKey: null,
          filterKey: null,
          width: 200,
        },
        {
          ...roadmapPriorityColumn,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          ...roadmapDueDate,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          type: CellTypes.insert,
          idPoint: 'actions_column',
          dataPoint: 'actions_column',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          textAlign: 'right',
          insert: ({ data }) => {
            return (
              <TextButton
                disabled={!!pendingDeleteId}
                onClick={async () => {
                  try {
                    const confirmed = await confirm({
                      body: `Are you sure you want to delete ${data.name} roadmap`,
                      yesBtnVariant: 'negative',
                      yesMessage: 'Delete',
                    })

                    if (confirmed.status === 'confirmed') {
                      setPendingdeleteId(data.id)
                      await roadmapsRequests.deleteItem(data.id)
                      values.roadmaps = values.roadmaps.filter(r => r.id !== data.id)
                    }
                  } catch (err) {
                    captureException(err)
                    showError('Failed to delete roadmap')
                  } finally {
                    setPendingdeleteId(undefined)
                  }
                }}
              >
                <Icon
                  name={pendingDeleteId === data.id ? 'Loading' : 'Delete'}
                  size={16}
                  color={Token.color.greyTone50}
                />
              </TextButton>
            )
          },
          title: '',
          width: 50,
        },
      ]

  return values.roadmaps?.length ? (
    <TableWidget style={{ padding: 0 }}>
      <TableWidget.Table>
        <AdjustableTable<RoadmapInterface & { actions_column?: never }>
          hideCountAndButtonSection
          name={TableNames.RoadmapsMain}
          data={values.roadmaps}
          count={values.roadmaps.length}
          dataType="Roadmap"
          row={{
            cells,
          }}
        />
      </TableWidget.Table>
      {confirmationDialog}
    </TableWidget>
  ) : null
}
