import { HStack, Token, VStack, Text, Caption } from '@revolut/ui-kit'
import React, { PropsWithChildren } from 'react'
import { PersistentExpandableCell } from './PersistentExpandableCell'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { useFormObserver } from '../FormObserverProvider'
import { useSubmitFlowHelpers } from '../../../common/utils'
import { kpisRequests } from '@src/api/kpis'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { captureException } from '@sentry/react'
import { checkFormErrors } from './helpers'

export const TargetCell = ({
  target,
  children,
  allowDelete = true,
  ...cellProps
}: PropsWithChildren<{
  allowDelete?: boolean
  expanded: boolean
  onToggle: () => void
  target: KpiInterface & { tempId?: number }
  index: number
}>) => {
  const { values } = useLapeContext<GoalsInterface>()
  const { getFormById } = useFormObserver()

  const { confirmationDialog, confirm, showSuccess, showError } = useSubmitFlowHelpers()
  const { unregisterForm } = useFormObserver()

  const prettifyUnit = (unit?: string) => {
    if (unit?.includes('score')) {
      return 'score'
    }
    if (unit?.includes('tickets')) {
      return 'tickets'
    }

    return unit
  }

  const getReviewCycleName = (
    formValues: KpiInterface | undefined,
    fallback: KpiInterface,
  ) => {
    const data = formValues || fallback
    return data.targets.length
      ? data.targets[0].review_cycle?.name
      : data.target_epics?.[0]?.review_cycle.name
  }

  const updateTypeDescriptionByType = {
    [UpdateTypes.manual]: 'Manual progress',
    [UpdateTypes.looker]: 'Looker',
    [UpdateTypes.roadmap]: 'Roadmap',
    [UpdateTypes.sql]: 'SQL',
    [UpdateTypes.cascaded]: undefined,
    [UpdateTypes.aggregated]: undefined,
  }

  const form = getFormById(target.id || target.tempId)?.().form

  const formValues = form?.values
  const formTarget = formValues?.targets?.[0]
  const hasFormErrors = checkFormErrors(form)
  const initialValue = formTarget?.initial_value ?? target.initial_value
  const targetValue = formTarget?.target ?? target.target
  const unit = formValues?.unit ?? target.unit
  const title = formValues?.name || target.name
  const updateType = formValues?.update_type || target.update_type
  const description =
    updateType && Object.keys(updateTypeDescriptionByType).includes(updateType)
      ? updateTypeDescriptionByType[updateType]
      : undefined

  const isDefined = (value: unknown) => typeof value !== 'undefined'
  const reviewCycle = getReviewCycleName(formValues, target)

  const hasSavedTargets = !!values.kpis.find(kpi => !!kpi.id)
  const isExpandedDraft = !!formValues?.tempId && cellProps.expanded
  const showPlaceholderTitle = isExpandedDraft || !title
  const placeholderTitle = (
    <Text variant="caption" color={Token.color.greyTone50}>
      New target
    </Text>
  )

  const side =
    isDefined(initialValue) || isDefined(targetValue) || isDefined(reviewCycle) ? (
      <HStack gap="s-16" align="center">
        {isDefined(initialValue) && (
          <VStack>
            <Text variant="secondary">
              {initialValue} {prettifyUnit(unit)}
            </Text>
            <Caption color={Token.color.greyTone50}>Initial</Caption>
          </VStack>
        )}
        {isDefined(targetValue) && (
          <VStack>
            <Text variant="secondary">
              {targetValue} {prettifyUnit(unit)}
            </Text>
            <Caption color={Token.color.greyTone50}>Target</Caption>
          </VStack>
        )}
        {isDefined(reviewCycle) && (
          <VStack>
            <Text variant="secondary">{reviewCycle}</Text>
            <Caption color={Token.color.greyTone50}>Cycle</Caption>
          </VStack>
        )}
      </HStack>
    ) : undefined

  const handleDeleteClicked = async () => {
    if (!target.id) {
      values.kpis = values.kpis.filter(kpi => kpi.tempId !== formValues?.tempId)
      return
    }

    const confirmed = await confirm({
      body: `Please, confirm you want to delete ${target.name} target`,
      yesMessage: 'Delete',
      yesBtnVariant: 'negative',
      noMessage: 'Cancel',
      variant: 'compact',
    })

    if (confirmed.status === 'confirmed') {
      try {
        await kpisRequests.deleteItem(target.id)
        showSuccess('Target successfully deleted')
        values.kpis = values.kpis.filter(kpi => kpi.id !== target.id)
        unregisterForm(target.id)
      } catch (err) {
        captureException(err)
        showError('Something went wrong', 'Please try again')
      }
    }
  }

  return (
    <>
      <PersistentExpandableCell
        {...cellProps}
        side={isExpandedDraft ? undefined : side}
        title={showPlaceholderTitle ? placeholderTitle : title}
        description={showPlaceholderTitle ? undefined : description}
        hasFormErrors={hasFormErrors}
        onDelete={
          allowDelete && (hasSavedTargets || values.kpis.length > 1)
            ? handleDeleteClicked
            : undefined
        }
      >
        {children}
      </PersistentExpandableCell>
      {confirmationDialog}
    </>
  )
}
