import React, { useMemo, useState } from 'react'
import {
  BottomSheet,
  Box,
  Flex,
  Header,
  MoreBar,
  StatusPopup,
  useStatusPopup,
  Text,
  Color,
} from '@revolut/ui-kit'
import { Materials, Plus } from '@revolut/icons'
import pluralize from 'pluralize'
import { useParams } from 'react-router-dom'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { TableWrapper } from '@src/components/Table/TableWrapper'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import Stat from '@src/components/Stat/Stat'
import SearchTable from '@src/components/Table/SearchTable/SearchTable'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import {
  typeToMergeTitle,
  typeToBackUrl,
  typeToName,
  typeToSearchLabel,
  typeToTableRequests,
  typeToTitle,
  typeToTableName,
  getRow,
  typeToRoute,
  typeToAddButtonLabel,
  typeToMergeButtonLabel,
  typeToDefaultFilter,
} from './common'
import {
  UpdateOrganisationUnit,
  UpdateOrganisationUnitType,
} from '@src/interfaces/updateOrganisationStructure'
import { MergeDepartments } from './MergeDepartments'
import { MergeEntities } from './MergeEntities'
import { MergeTeams } from './MergeTeams'
import { MergeRoles } from './MergeRoles'

interface UpdateOrganisationStructureTableProps {
  type: UpdateOrganisationUnitType
}

export const UpdateOrganisationStructureTable = ({
  type,
}: UpdateOrganisationStructureTableProps) => {
  const table = useTable<UpdateOrganisationUnit>(
    typeToTableRequests[type],
    typeToDefaultFilter(type),
    [
      {
        sortBy: 'name',
        direction: SORT_DIRECTION.DESC,
        nonResettable: true,
      },
    ],
  )
  const statusPopup = useStatusPopup()

  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<UpdateOrganisationUnit>>()
  const [mergeUnitsPopupOpen, setMergeUnitsPopupOpen] = useState(false)

  const unitsToMerge = useMemo(() => {
    if (selectedData?.selectedRowsData.length) {
      return selectedData.selectedRowsData.map(unit => ({ id: unit.id }))
    }
    if (selectedData?.isAllSelected) {
      return table.data
        .filter(unit => !selectedData.excludeListIds.has(`${unit.id}`))
        .map(unit => ({ id: unit.id }))
    }
    return []
  }, [selectedData])

  const onMergeClick = () => {
    if (unitsToMerge.length > 1) {
      setMergeUnitsPopupOpen(true)
    }
  }

  const onSuccess = () => {
    setMergeUnitsPopupOpen(false)
    table.resetFiltersAndSorting()
  }

  const getStatusPopup = (count: number, name: string) => {
    return (
      <StatusPopup
        variant="success"
        onClose={() => {
          statusPopup.hide()
          onSuccess()
        }}
      >
        <StatusPopup.Title>
          {pluralize(type, count, true)} successfully merged to{' '}
          <Text color={Color.BLUE}>{name}</Text>
        </StatusPopup.Title>
      </StatusPopup>
    )
  }

  return (
    <>
      <TableWrapper>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex mb="s-24">
            <Stat
              label="Total"
              val={table?.loading ? undefined : table?.count}
              mr="s-32"
            />
          </Flex>
          <SearchTable
            placeholder={typeToSearchLabel[type]}
            mb="s-24"
            onFilter={table.onFilterChange}
          />
        </Flex>

        <Box mb="s-16">
          <MoreBar>
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(typeToRoute[type])}
              useIcon={Plus}
            >
              {typeToAddButtonLabel[type]}
            </MoreBar.Action>
            <MoreBar.Action
              onClick={onMergeClick}
              useIcon={Materials}
              disabled={unitsToMerge.length < 2}
              variant="accent"
            >
              {typeToMergeButtonLabel[type]}
            </MoreBar.Action>
          </MoreBar>
        </Box>

        <SelectTableWrapper
          enabled
          onChange={setSelectedData}
          filters={table.filterBy}
          tableDataLength={table.data.length}
        >
          <AdjustableTable
            name={typeToTableName[type]}
            useWindowScroll
            dataType={typeToName[type]}
            row={getRow(type, table.refresh) as RowInterface<UpdateOrganisationUnit>}
            {...table}
          />
        </SelectTableWrapper>
      </TableWrapper>

      <BottomSheet
        open={mergeUnitsPopupOpen}
        onClose={() => setMergeUnitsPopupOpen(false)}
      >
        <Header>
          <Header.Title>{typeToMergeTitle[type]}</Header.Title>
        </Header>

        {type === 'department' && (
          <MergeDepartments
            unitsToMerge={unitsToMerge}
            onSuccess={response => {
              statusPopup.show(getStatusPopup(response.count, response.department.name))
            }}
          />
        )}
        {type === 'team' && (
          <MergeTeams
            unitsToMerge={unitsToMerge}
            onSuccess={response => {
              onSuccess()
              statusPopup.show(getStatusPopup(response.count, response.team.name))
            }}
            showMissionField
            setCurrentUserAsOwner={false}
          />
        )}
        {type === 'role' && (
          <MergeRoles
            unitsToMerge={unitsToMerge}
            onSuccess={response => {
              statusPopup.show(getStatusPopup(response.count, response.role.name))
            }}
          />
        )}
        {type === 'entity' && (
          <MergeEntities
            unitsToMerge={unitsToMerge}
            onSuccess={response => {
              statusPopup.show(getStatusPopup(response.count, response.entity.name))
            }}
          />
        )}
      </BottomSheet>
    </>
  )
}

export const UpdateOrganisationStructure = () => {
  const { type } = useParams<{ type: UpdateOrganisationUnitType }>()

  return (
    <PageWrapper>
      <PageHeader title={typeToTitle[type]} backUrl={typeToBackUrl[type]} />
      <UpdateOrganisationStructureTable type={type} />
    </PageWrapper>
  )
}
