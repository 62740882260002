import React, { useEffect, useState } from 'react'
import isNil from 'lodash/isNil'
import { ChatMessageInterface, ChatMessageStatus } from '@src/interfaces/chat'
import {
  Avatar,
  Bar,
  Box,
  Button,
  chain,
  Flex,
  MoreButton,
  Text,
  Token,
} from '@revolut/ui-kit'
import { IconButton } from '@revolut/ui-kit'
import { getInitials, getAvatarUrl } from '@src/utils/employees'
import { Check, Delete, InfoOutline, Pencil } from '@revolut/icons'
import { formatRelative } from 'date-fns'
import { css } from 'styled-components'
import { colorGetter } from '@src/styles/colors'
import Tooltip from '@components/Tooltip/Tooltip'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { ChatMessageType } from '@components/Chat/common'
import ChatTextEditor, {
  ChatTextEditorVariant,
} from '@components/Chat/ChatTextEditor/ChatTextEditor'
import { successNotification } from '@src/store/notifications/actions'
import { BoxProps } from '@revolut/ui-kit'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'

export interface ChatMessageProps extends BoxProps {
  message: ChatMessageInterface
  onEdit: (id: number, editedText: string) => Promise<any>
  onArchive: (id: number) => Promise<any>
  onResolve: (id: number) => Promise<any>
  type: ChatMessageType
  refetch: () => Promise<any>
  // TODO: remove when it's supported by api in all entities
  disableTodolistFeature?: boolean
  /**
   * If set to true, message will be highlighted for one second
   */
  highlight?: boolean
  canEdit?: boolean
  messageBorder?: boolean
}

const MoreButtonCss = css`
  background: transparent;
  color: ${colorGetter('grey-20')};
  height: max-content;

  &:hover,
  &:active {
    background: transparent;
    color: ${colorGetter('blue')};
  }
`

const resolveButtonCss = css`
  height: max-content;
  transition: all 0.3s;

  &:hover {
    background: ${colorGetter('action-background')};
    color: ${colorGetter('primary')};
  }
`

const transitionCss = css`
  transition: background-color 1s;
`

const EditorContainerCss = css`
  .ql-editor {
    font-size: ${Token.fontSize.caption};
    line-break: normal;
    word-break: break-word;
  }
`

enum State {
  Default = 'Default',
  Editing = 'Editing',
}

const ChatMessage = ({
  message,
  onEdit,
  onArchive,
  type,
  refetch,
  canEdit = true,
  highlight,
  onResolve,
  disableTodolistFeature = false,
  messageBorder = false,
  ...boxProps
}: ChatMessageProps) => {
  const user = useSelector(selectUser)

  const { theme } = useAppTheme()

  const [state, setState] = useState<State>(State.Default)
  const [editingPending, setEditingPending] = useState<boolean>()
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false)
  const [isCompleted, setIsCompleted] = useState<boolean>(
    message.status?.id === ChatMessageStatus.completed,
  )

  useEffect(() => {
    if (highlight) {
      setIsHighlighted(true)
      // Return to default color after a second
      setTimeout(() => {
        setIsHighlighted(false)
      }, 1500)
    }
  }, [highlight])

  const author = message.created_by
  const editable = author && canEdit && user.id === author.id

  const editedAt = !isNil(message.edit_date_time)
    ? formatRelative(new Date(message.edit_date_time), new Date())
    : null

  const saveChanges = (value: string) => {
    setEditingPending(true)

    return onEdit(message.id, value)
      .then(() => {
        return refetch()
      })
      .finally(() => {
        setEditingPending(false)
        setState(State.Default)
      })
  }

  const cancelChanges = (setEditorValue: (value: string) => void) => {
    setState(State.Default)
    setEditorValue(message.body)
  }

  return (
    <Box
      aria-label="comment"
      data-commentid={message.id}
      p="s-12"
      radius="widget"
      bg={
        isHighlighted
          ? 'action-background'
          : theme === 'light'
          ? 'background'
          : 'widget-background'
      }
      css={transitionCss}
      border={messageBorder ? `1px solid ${Token.color.greyTone10}` : undefined}
      {...boxProps}
    >
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Flex pr="1em" alignItems="center">
            <Flex height="100%" alignItems="center" justifyContent="center" pr="1em">
              <Avatar variant="brand" image={getAvatarUrl(author?.avatar)} size={32}>
                {!author?.avatar &&
                  getInitials(author?.full_name || message.employee_full_name)}
              </Avatar>
            </Flex>
            <Flex flexDirection="column" justifyContent="space-between">
              <Text fontSize="13px">
                {author
                  ? chain(author.full_name, author.job_title)
                  : message.employee_full_name}
              </Text>
              <Text color="grey-tone-50" fontSize="13px">
                <Flex flexWrap="wrap">
                  {formatRelative(new Date(message.creation_date_time), new Date())}
                  {editedAt && (
                    <Flex>
                      <Text px="s-4" fontSize="13px">
                        · Edited
                      </Text>
                      <Tooltip placement="top" text={editedAt}>
                        <InfoOutline size={12} />
                      </Tooltip>
                    </Flex>
                  )}
                </Flex>
              </Text>
            </Flex>
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            {editable && (
              <MoreButton css={MoreButtonCss}>
                <MoreButton.Action
                  useIcon={Pencil}
                  onClick={() => setState(State.Editing)}
                >
                  Edit
                </MoreButton.Action>
                <MoreButton.Action
                  useIcon={Delete}
                  onClick={() =>
                    onArchive(message.id).then(() => {
                      successNotification('Comment was successfully archived')
                      return refetch()
                    })
                  }
                >
                  Archive
                </MoreButton.Action>
              </MoreButton>
            )}
            {!disableTodolistFeature && message.create_task && (
              <Tooltip
                placement="top"
                text={
                  isCompleted ? 'This comment is marked as resolved' : 'Mark as resolved'
                }
              >
                <IconButton
                  disabled={!canEdit || isCompleted}
                  useIcon={Check}
                  color={isCompleted ? 'primary' : 'grey-20'}
                  css={resolveButtonCss}
                  onClick={() =>
                    onResolve(message.id).then(() => {
                      setIsCompleted(true)
                      successNotification('Comment marked as completed')
                    })
                  }
                />
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex pl="0.5em" pt="1em" css={EditorContainerCss}>
        {state === State.Default ? (
          <ChatTextEditor
            taggedEmployees={message.tagged_employees}
            defaultText={message.body}
            onSubmit={() => Promise.resolve()}
            readonly
            variant={ChatTextEditorVariant.InMessage}
          />
        ) : (
          <Box width="100%">
            <ChatTextEditor
              taggedEmployees={message.tagged_employees}
              defaultText={message.body}
              onSubmit={value => saveChanges(value)}
              variant={ChatTextEditorVariant.InMessage}
            >
              {(getValue, setValue) => (
                <Flex pt="s-6">
                  <Bar>
                    <Button
                      pending={editingPending}
                      variant="secondary"
                      size="sm"
                      onClick={() => saveChanges(getValue())}
                    >
                      Save changes
                    </Button>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => cancelChanges(setValue)}
                    >
                      Cancel
                    </Button>
                  </Bar>
                </Flex>
              )}
            </ChatTextEditor>
          </Box>
        )}
      </Flex>
    </Box>
  )
}

export default ChatMessage
