import React, { useState } from 'react'
import {
  ActionButton,
  Avatar,
  InputGroup,
  Item,
  Subheader,
  VStack,
} from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import JobPostingPopup from '@src/features/JobPostingFlow/components/JobPostingPopup'

const getPlaceholder = (title: string) => {
  switch (title) {
    case 'About the role':
      return 'What the function does'
    case 'What you’ll be doing':
      return 'The main goals and mission of this role (specialisation)'
    case "What you'll need":
      return 'The minimum requirements expected from the candidate'
    case 'Nice to have':
      return 'Any additional requirements (optional)'
    default:
      return ''
  }
}

export const Description = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [openJobPostings, setOpenJobPostings] = useState(false)
  return (
    <>
      {openJobPostings && (
        <JobPostingPopup
          confirmLabel="Reuse posting"
          specialisationId={values?.specialisation?.id!}
          onClose={() => {
            setOpenJobPostings(false)
          }}
          onConfirm={jobPosting => {
            values.name = jobPosting.name
            values.sections = jobPosting.sections
          }}
        />
      )}
      <Subheader>
        <Subheader.Title>Job description</Subheader.Title>
      </Subheader>
      <VStack gap="s-16">
        <Item
          use="label"
          onClick={() => {
            setOpenJobPostings(true)
          }}
        >
          <Item.Avatar>
            <Avatar useIcon="WizardHat" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Reuse existing jobs</Item.Title>
            <Item.Description>
              Reuse content from previously posted job postings
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <ActionButton useIcon="Search">Browse job postings</ActionButton>
          </Item.Side>
        </Item>
        <LapeNewInput
          name="name"
          label="Job posting title"
          message="This is job posting title candidates will see on the career website (Specialisation name by default)"
          required
        />
        {values.sections?.map((section, idx) => (
          <React.Fragment key={section.title}>
            <Subheader>
              <Subheader.Title>{section.title}</Subheader.Title>
            </Subheader>
            <InputGroup>
              <LapeHTMLEditor
                name={`sections.${idx}.content`}
                placeholder={getPlaceholder(section.title)}
                height={350}
                required
                addMarginToParagraphs
              />
            </InputGroup>
          </React.Fragment>
        ))}
      </VStack>
    </>
  )
}
