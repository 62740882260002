import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { MoreBar } from '@revolut/ui-kit'

import { EditableEmployeesTable } from '@src/features/EditableEmployeesTable'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import EmployeeTable from './EmployeeTable'

export const Employees = () => {
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const isOnboardingV2 = featureFlags.includes(FeatureFlags.OnboardingFlowV2)

  const [isEditing, setIsEditing] = useState(false)

  const canViewEmployeePreferences = permissions.includes(
    PermissionTypes.ViewEmployeePreferences,
  )

  return isOnboardingV2 ? (
    <EditableEmployeesTable
      sessionRoute={ROUTES.APPS.EMPLOYEES.BULK_UPDATE.SESSION}
      isPreview={!isEditing}
      isDetailed
      previewActions={
        <>
          <MoreBar.Action useIcon="Pencil" onClick={() => setIsEditing(true)}>
            Edit
          </MoreBar.Action>
          <MoreBar.Action
            useIcon="Upload"
            use={InternalLink}
            to={ROUTES.APPS.EMPLOYEES.BULK_UPDATE.IMPORT}
          >
            Import employees
          </MoreBar.Action>
          <MoreBar.Action
            to={pathToUrl(ROUTES.FORMS.EMPLOYEES_ORG_CHART)}
            use={InternalLink}
            useIcon="People"
          >
            View org chart
          </MoreBar.Action>
          {canViewEmployeePreferences && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.SETTINGS.EMPLOYEES.GENERAL)}
              use={InternalLink}
              useIcon="Gear"
            >
              Settings
            </MoreBar.Action>
          )}
        </>
      }
      beforeEditActions={
        <MoreBar.Action useIcon="Check" onClick={() => setIsEditing(false)}>
          Done
        </MoreBar.Action>
      }
    />
  ) : (
    <EmployeeTable />
  )
}
