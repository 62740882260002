import React, { useEffect, useState } from 'react'
import useResizeObserver from 'use-resize-observer'
import { useIntl } from 'react-intl'
import { ActionButton, HStack, Header } from '@revolut/ui-kit'

import { InternalLink } from '@src/components/InternalLink/InternalLink'
import mainHeaderState from '@src/features/MainHeader/MainHeaderState'
import { useCurrentTenantInfo } from '@src/api/tenants'

import { OnboardingChecklistRoute } from '../components/OnboardingChecklistContent'
import { SetupGuide } from '../SetupGuide/SetupGuide'
import { useRouteMatch } from 'react-router-dom'

interface OnboardingChecklistHeaderProps {
  title: React.ReactNode
  description?: React.ReactNode
  backUrl?: string
  routes?: OnboardingChecklistRoute[]
}

export const OnboardingChecklistHeader = ({
  title,
  description,
  backUrl,
  routes,
}: OnboardingChecklistHeaderProps) => {
  const [openSetupGuide, setOpenSetupGuide] = useState(false)
  const { formatMessage } = useIntl()
  const { isDemoMode } = useCurrentTenantInfo()

  const { ref, height: headerHeight } = useResizeObserver()

  useEffect(() => {
    if (headerHeight) {
      mainHeaderState.height = headerHeight
    }
  }, [headerHeight])

  const isFirstTab = !!useRouteMatch([routes?.[0].path || ''])

  return (
    <>
      {!isFirstTab && (
        <SetupGuide
          routes={routes}
          open={openSetupGuide}
          onClose={() => setOpenSetupGuide(false)}
        />
      )}
      <Header
        variant="item"
        ref={ref}
        top={isDemoMode ? 44 : 0}
        mb={isDemoMode ? 's-40' : 0}
      >
        <Header.BackButton use={InternalLink} to={backUrl} aria-label="Back" />
        <Header.Title use="h1">{title}</Header.Title>
        <Header.Description>{description}</Header.Description>
        <Header.Actions>
          <HStack space="s-16" align="center">
            {routes?.length && !isFirstTab && (
              <ActionButton onClick={() => setOpenSetupGuide(true)} useIcon="Cleaning">
                {formatMessage({
                  id: 'onboarding.general.setupGuideButton',
                  defaultMessage: 'Setup Guide',
                })}
              </ActionButton>
            )}
          </HStack>
        </Header.Actions>
      </Header>
    </>
  )
}
