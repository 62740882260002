import {
  BehaviourAssessmentItem,
  CriteriaAssessment,
  DeliverableJustificationValue,
  OverallFeedbackInterface,
  PerformanceRating,
  PerformanceSelector,
  ReviewCategory,
  ReviewDataValueBasedSectionInterface,
  ReviewScorecardInterface,
  SkillCardInterface,
} from '@src/interfaces/performance'
import { AssessButtonTypes } from '@components/AssessButtons/AssessButtons'
import { SkillLevels } from '@src/interfaces/roles'
import { ValidationError } from 'yup'
import { getCalculatedRating, putFirstOpenedScorecard } from '@src/api/performanceReview'
import { RevolutTheme } from '@src/styles/theme'
import produce from 'immer'
import { useEffect, useMemo, useState } from 'react'
import { getSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { Color, Token } from '@revolut/ui-kit'
import { getColor } from '@src/styles/colors'
import { IssueTypes } from '@src/interfaces/deliverables'
import { Statuses } from '@src/interfaces'
import { formatDate } from '@src/utils/format'
import { isAfter, subBusinessDays } from 'date-fns'
import { DAYS_BEFORE_OPENING_CHECKPOINT } from '@src/constants/performance'
import { CancelTokenSource } from 'axios'
import { useParams } from 'react-router-dom'
import { useGetAllFeedbackList } from '@src/api/anytimeFeedback'
import { SkillSettingsInterface } from '@src/interfaces/settings'
import { deliverablesJustificationPrefill } from '@components/ScorecardGeneral/DeliverablesJustifications'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { isNewPerformancePeriod } from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeInterface } from '@src/interfaces/employees'
import useIsCommercial from '@src/hooks/useIsCommercial'

export const getVisibleSections = (sections?: CriteriaAssessment[]) => {
  if (!sections?.length) {
    return []
  }

  const visibleSections = [sections[0]]

  for (let j = 0; j < sections.length; j += 1) {
    const level = sections[j]

    let isDontKnowCountLimit = false
    if (level.items?.length) {
      const donKnowCount = level.items.reduce(
        (acc, item) => (item.value === AssessButtonTypes.neutral ? acc + 1 : acc),
        0,
      )

      // if dont know count more than 50%
      isDontKnowCountLimit = donKnowCount > level.items.length * 0.5
    }

    if (
      level.items?.some(item => item.value === AssessButtonTypes.negative) ||
      isDontKnowCountLimit
    ) {
      break
    }

    if (level.items?.every(item => item.value !== null)) {
      if (sections[j + 1]) {
        visibleSections.push(sections[j + 1])
      }
    } else {
      break
    }
  }

  return visibleSections
}

export const getNormalizedCards = (cards: SkillCardInterface[]) => {
  const normalizedCards = []
  for (let i = 0; i < cards.length; i += 1) {
    const card = cards[i]
    const sections = getVisibleSections(card.sections as CriteriaAssessment[])

    normalizedCards.push({
      ...card,
      sections,
    })
  }

  return normalizedCards
}

export function assessButtonsValidation(
  this: {
    path: string
    parent: CriteriaAssessment[]
    createError(param: { path: any; message: string }): ValidationError
  },
  section?: CriteriaAssessment | null,
) {
  const order = [
    SkillLevels.Poor,
    SkillLevels.Basic,
    SkillLevels.Intermediate,
    SkillLevels.Advanced,
    SkillLevels.Expert,
  ]

  if (!section) {
    return true
  }

  if ((section as CriteriaAssessment)?.items?.every(elem => elem.value !== null)) {
    return true
  }

  const prevIndex = order.findIndex(level => level === section.level_key)

  for (let i = 0; i < prevIndex; i += 1) {
    const prevSection = this.parent.find(
      parentSection => parentSection.level_key === order[i],
    )

    // If one of previous levels has No mark, it's fine - validation passed
    if (prevSection?.items?.some(el => el.value === AssessButtonTypes.negative)) {
      return true
    }

    if (prevSection?.items?.length) {
      const dontKnowCount = prevSection.items.reduce(
        (acc, item) => (item.value === AssessButtonTypes.neutral ? acc + 1 : acc),
        0,
      )

      // if dont know count more than 50%, it's fine - validation passed
      if (dontKnowCount > prevSection.items.length * 0.5) {
        return true
      }
    }
  }

  return this.createError({
    path: this.path,
    message: 'Please complete this scorecard before submitting.',
  })
}

export function assessBehaviourButtonsValidation(
  this: {
    createError(param: { message: string; path: string }): ValidationError
    path: string
  },
  section?: BehaviourAssessmentItem | null,
) {
  if (!section) {
    return true
  }

  if ((section as BehaviourAssessmentItem).value !== null) {
    return true
  }

  return this.createError({
    message: 'Please complete this scorecard before submitting.',
    path: this.path,
  })
}

export function improvementFeedbackValidation(
  this: {
    createError(param: { message: string; path: string }): ValidationError
    path: string
    schema: {
      spec?: {
        meta?: {
          skillsPreferences: SkillSettingsInterface
        }
      }
    }
  },
  reviewData?: ReviewDataValueBasedSectionInterface | null,
) {
  if (
    !reviewData ||
    !reviewData.cards?.length ||
    !!reviewData.skipped_section_justification
  ) {
    return true
  }

  if (
    this.schema.spec?.meta?.skillsPreferences.company_values_validation_enabled === false
  ) {
    return true
  }

  const improvementNeededCount = () => {
    return reviewData.cards!.reduce((count, card) => {
      count += card.sections.filter(
        section => section.value === 'improvement_needed',
      )?.length
      return count
    }, 0)
  }

  if (improvementNeededCount() < 2) {
    return this.createError({
      message:
        'You should select at least 2 behaviours for the “Improvement focus” category.',
      path: `${this.path}.rating_expectation`,
    })
  }

  return true
}

export function overallFeedbackValidation(
  this: {
    path: string
    createError(param: { path: any; message: string }): ValidationError
  },
  feedback?: OverallFeedbackInterface | null,
) {
  if (!feedback?.pros?.join('\n')?.trim() && !feedback?.cons?.join('\n')?.trim()) {
    return this.createError({
      path: this.path,
      message: 'Overall feedback is obligatory. You cannot submit review without it.',
    })
  }

  return true
}

export const getUpdatedRatingCard = async (
  id: number,
  category: ReviewCategory,
  reviewedEmployeeId: number,
  card?: SkillCardInterface,
  tokenSource?: CancelTokenSource,
) => {
  const sections = (card?.sections || []) as CriteriaAssessment[]

  // If it's incomplete level we shouldn't send request to the backend, but if it's empty - we should
  if (
    !card ||
    sections.some(section => {
      const isIncompleteLevel = section.items?.some(item => item.value === null)
      const isEmptyLevel = section.items?.every(item => item.value === null)
      return isIncompleteLevel && !isEmptyLevel
    })
  ) {
    return null
  }

  const updatedCard = await getCalculatedRating(
    card,
    reviewedEmployeeId,
    id,
    category,
    tokenSource,
  )

  return updatedCard
}

export const getCardsForView = (cards: SkillCardInterface[]) => {
  return cards.map(card => ({
    ...card,
    sections: card.sections.filter(section =>
      (section as CriteriaAssessment)?.items?.some(item =>
        item?.values?.some(valueItem => valueItem.value),
      ),
    ),
  }))
}

export const getClearedIncompleteSections = (
  sections?: CriteriaAssessment[],
  visibleSections?: CriteriaAssessment[],
) => {
  if (!sections || !visibleSections) {
    return []
  }

  return sections.map((sectionLevel, idx) => {
    if (visibleSections[idx]) {
      return visibleSections[idx]
    }

    return {
      ...sectionLevel,
      items: sectionLevel.items?.map(item => ({
        ...item,
        value: null,
      })),
    }
  })
}

export const tooltipRatingToColor = (rating?: PerformanceRating | null) => {
  switch (rating) {
    case PerformanceRating.skipped:
    case PerformanceRating.dont_know:
      return Token.color.greyTone50
    case PerformanceRating.poor:
    case PerformanceRating.poor_plus:
    case PerformanceRating.improvement_needed:
      return Token.color.orange_70
    case PerformanceRating.basic:
    case PerformanceRating.basic_plus:
    case PerformanceRating.performing:
    case PerformanceRating.intermediate_minus:
    case PerformanceRating.intermediate:
    case PerformanceRating.intermediate_plus:
    case PerformanceRating.superpower:
    case PerformanceRating.advanced_minus:
    case PerformanceRating.advanced:
    case PerformanceRating.advanced_plus:
    case PerformanceRating.expert_minus:
    case PerformanceRating.expert:
    case PerformanceRating.expert_plus:
      return Token.color.teal_70
    default:
      return null
  }
}

const getColors = (theme: RevolutTheme) => ({
  None: getColor(theme, Color.GREY_TONE_2),
  Basic: getColor(theme, Color.TEAL_OPAQUE_5),
  Poor: getColor(theme, Color.RED_OPAQUE_10),
  Intermediate: getColor(theme, Color.TEAL_OPAQUE_30),
  Advanced: getColor(theme, Color.TEAL_OPAQUE_70),
  Expert: getColor(theme, Color.TEAL),
})

export const ratingToColor = (theme: RevolutTheme, rating?: PerformanceRating | null) => {
  const colors = getColors(theme)

  switch (rating) {
    case PerformanceRating.skipped:
    case PerformanceRating.dont_know:
      return colors.None
    case PerformanceRating.poor:
    case PerformanceRating.poor_plus:
      return colors.Poor
    case PerformanceRating.basic:
    case PerformanceRating.basic_plus:
    case PerformanceRating.performing:
      return colors.Basic
    case PerformanceRating.intermediate_minus:
    case PerformanceRating.intermediate:
    case PerformanceRating.intermediate_plus:
    case PerformanceRating.superpower:
      return colors.Intermediate
    case PerformanceRating.advanced_minus:
    case PerformanceRating.advanced:
    case PerformanceRating.advanced_plus:
      return colors.Advanced
    case PerformanceRating.expert_minus:
    case PerformanceRating.expert:
    case PerformanceRating.expert_plus:
      return colors.Expert
    default:
      return null
  }
}

export const getCleanValuesBeforeUpdate = (values: ReviewScorecardInterface) => {
  if (!values?.review_data) {
    return values
  }

  return produce(values, draft => {
    if (draft.review_data.deliverables?.skipped_section_justification) {
      draft.review_data.deliverables.cards = undefined
    }
    if (draft.review_data.culture_skills?.skipped_section_justification) {
      draft.review_data.culture_skills.cards = undefined
    }
    if (draft.review_data.culture_values?.skipped_section_justification) {
      draft.review_data.culture_values.cards = undefined
    }
    if (draft.review_data.functional_skills?.skipped_section_justification) {
      draft.review_data.functional_skills.cards = undefined
    }
    if (draft.review_data.manager_skills?.skipped_section_justification) {
      draft.review_data.manager_skills.cards = undefined
    }
    if (draft.review_data.manager_values?.skipped_section_justification) {
      draft.review_data.manager_values.cards = undefined
    }

    draft.review_data?.deliverables?.cards?.forEach(card => {
      card.justifications =
        (card.justifications as DeliverableJustificationValue[])
          ?.filter(justification => justification.comment || justification.reference_url)
          .map(
            justification =>
              ({
                comment: justification.comment || null,
                reference_url: justification.reference_url || null,
              } as DeliverableJustificationValue),
          ) || []
    })
    if (draft.review_data?.deliverables?.justifications) {
      draft.review_data.deliverables.justifications =
        (draft.review_data.deliverables.justifications as DeliverableJustificationValue[])
          ?.filter(justification => justification.comment || justification.reference_url)
          .map(
            justification =>
              ({
                comment:
                  !justification.comment ||
                  justification.comment === deliverablesJustificationPrefill
                    ? null
                    : justification.comment,
                reference_url: justification.reference_url || null,
              } as DeliverableJustificationValue),
          ) || []
    }
  }) as ReviewScorecardInterface
}

export const getCleanValuesBeforeSave = (values: ReviewScorecardInterface) => {
  const cleanValues = getCleanValuesBeforeUpdate(values)

  const visibleFuncSkillsSections = getNormalizedCards(
    values.review_data?.functional_skills?.cards || [],
  )

  cleanValues.review_data?.functional_skills?.cards?.forEach((card, cardIdx) => {
    const clearedIncompleteLevels = getClearedIncompleteSections(
      card.sections as CriteriaAssessment[],
      visibleFuncSkillsSections[cardIdx]?.sections,
    )

    cleanValues.review_data.functional_skills!.cards![cardIdx]!.sections =
      clearedIncompleteLevels
  })

  const visibleManagerSkillsSections = getNormalizedCards(
    cleanValues.review_data.manager_skills?.cards || [],
  )

  cleanValues.review_data?.manager_skills?.cards?.forEach((card, cardIdx) => {
    const clearedIncompleteLevels = getClearedIncompleteSections(
      card.sections as CriteriaAssessment[],
      visibleManagerSkillsSections[cardIdx]?.sections,
    )

    cleanValues.review_data.manager_skills!.cards![cardIdx]!.sections =
      clearedIncompleteLevels
  })

  const visibleCultureSkillsSections = getNormalizedCards(
    cleanValues.review_data.culture_skills?.cards || [],
  )

  cleanValues.review_data?.culture_skills?.cards?.forEach((card, cardIdx) => {
    const clearedIncompleteLevels = getClearedIncompleteSections(
      card.sections as CriteriaAssessment[],
      visibleCultureSkillsSections[cardIdx]?.sections,
    )

    cleanValues.review_data.culture_skills!.cards![cardIdx]!.sections =
      clearedIncompleteLevels
  })

  cleanValues.review_data?.culture_values?.cards?.forEach(card => {
    card.sections.forEach(section => {
      section.justification = section?.justification === '' ? null : section.justification
    })
  })

  return cleanValues
}

export const useSelectedPerformanceCycle = (
  key: selectorKeys = selectorKeys.cycle_offsets,
  useOffset = false,
) => {
  const [cycles, setCycles] = useState<OptionInterface[]>([])
  const [initialCycleOffset, setInitialCycleOffset] = useState<number | undefined>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getSelectors(key)
      .then(resp => {
        setCycles(resp.data.options.map(item => ({ ...item, sub_title: undefined })))
        const currentPerformanceCycle = resp.data.options.find(
          item => item.performance_reviews_selected_cycle,
        )

        if (currentPerformanceCycle) {
          if (useOffset) {
            setInitialCycleOffset(+currentPerformanceCycle.offset!)
          } else {
            setInitialCycleOffset(+currentPerformanceCycle.id)
          }
          return
        }

        setInitialCycleOffset(0)
      })
      .catch(() => {
        setInitialCycleOffset(0)
      })
      .finally(() => setLoading(false))
  }, [])

  return { initialCycleOffset, cycles, loading }
}

export const getIconKeyByIssue = (type: IssueTypes) => {
  switch (type) {
    case IssueTypes.Bug:
      return 'JiraBug'

    case IssueTypes.Epic:
      return 'JiraEpic'

    case IssueTypes.Story:
      return 'JiraStory'

    case IssueTypes.Subtask:
      return 'JiraSubtask'

    case IssueTypes.Task:
      return 'JiraTask'

    default:
      return 'JiraOther'
  }
}

export const getUpdatedTimeReview = (date?: string | null, status?: Statuses) => {
  if (
    (status === Statuses.completed ||
      status === Statuses.completed_late ||
      status === Statuses.rejected) &&
    date
  ) {
    return formatDate(date)
  }
  return ''
}

export const getCheckpointOpenDate = (date: string) =>
  subBusinessDays(new Date(date), DAYS_BEFORE_OPENING_CHECKPOINT)

export const isCheckpointOpened = (openDate: Date) => isAfter(new Date(), openDate)

export const useGetFeedbackSidebarData = () => {
  const params = useParams<{ employeeId: string }>()
  const { data: feedbackList, refetch: refetchFeedbackList } = useGetAllFeedbackList(
    Number(params.employeeId),
  )

  return {
    feedbackList: feedbackList?.results,
    refetchFeedbackList,
    employeeId: params.employeeId,
  }
}

export const getLastTabLocalStorageKey = (
  page: string,
  employeeId: string | number,
  id: string | number,
) => {
  return `${page}-${employeeId}-${id}--last-tab`
}

export const usePutFirstScorecardOpening = (
  values: ReviewScorecardInterface,
  category: ReviewCategory,
) => {
  const params = useParams<{ id: string; employeeId?: string }>()

  useEffect(() => {
    if (!values.opened_date_time && params.employeeId && params.id) {
      putFirstOpenedScorecard(params.employeeId, params.id, category).then(res => {
        values.opened_date_time = res.data.result?.opened_date_time
      })
    }
  }, [values.opened_date_time, params])
}

export const getProbationTemplateStatusColor = (
  status: 'enabled' | 'disabled',
): Color => {
  switch (status) {
    case 'enabled':
      return Color.GREEN
    case 'disabled':
      return Color.WARNING
    default:
      return Color.FOREGROUND
  }
}

export const useGetPeriodTypes = (selectedPeriod?: PerformanceSelector) => {
  const isNewPerformance = useMemo(
    () => !!selectedPeriod && isNewPerformancePeriod(selectedPeriod),
    [selectedPeriod, isNewPerformancePeriod],
  )

  const isNewProbation =
    selectedPeriod?.category === ReviewCycleCategory.Probation &&
    selectedPeriod.probation_version === 2

  const isOldProbation =
    selectedPeriod?.category === ReviewCycleCategory.Probation &&
    selectedPeriod.probation_version === 1

  const isPIP =
    selectedPeriod?.category === ReviewCycleCategory.PIP && selectedPeriod.version === 1

  const isPIPv2 =
    selectedPeriod?.category === ReviewCycleCategory.PIP && selectedPeriod.version === 2

  const isNewFlow = isNewPerformance || isNewProbation || isPIPv2

  return {
    isNewPerformance,
    isNewProbation,
    isOldProbation,
    isPIPv2,
    isPIP,
    isNewFlow,
  }
}

export const getCycleOffsetTitleAndColor = (offset?: number) => {
  if (offset === 0) {
    return { title: 'current', color: Token.color.green, bgColor: Token.color.green_20 }
  }
  if (offset && offset < 0) {
    return {
      title: 'future',
      color: Token.color.greyTone50,
      bgColor: Token.color.greyTone10,
    }
  }
  if (offset && offset > 0) {
    return {
      title: 'previous',
      color: Token.color.warning,
      bgColor: Token.color.orange_20,
    }
  }
  return { title: '', color: '', bgColor: '' }
}

export const useHasNewScorecards = () => {
  return !useIsCommercial()
}

export const useCanManageProbationPipGoals = (data?: EmployeeInterface) => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const isLineManager = user.id === data?.line_manager?.id
  const isFunctionalManager = user.id === data?.quality_control?.id
  const canSetJiraGoals = permissions.includes(PermissionTypes.ChangePerformanceGoal)
  const canSetNonJiraGoals = permissions.includes(PermissionTypes.SetGooalsEmployees)

  const canManageJiraGoals = isLineManager || isFunctionalManager || canSetJiraGoals
  const canManageNonJiraGoals = isLineManager || isFunctionalManager || canSetNonJiraGoals

  return { canManageJiraGoals, canManageNonJiraGoals }
}
