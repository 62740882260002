import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Avatar,
  IconButton,
  Item,
  Side,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import SideBar from '@components/SideBar/SideBar'
import { newDocumentRequests } from '@src/api/documents'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import FileUploader from '@components/Inputs/FileUploader/FileUploader'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentConfidentiality, DocumentInterface } from '@src/interfaces/documents'
import { selectUser } from '@src/store/auth/selectors'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

const AddDocumentFormContent = ({ onSubmit }: { onSubmit: () => void }) => {
  const user = useSelector(selectUser)
  const { values } = useLapeContext<DocumentInterface>()

  useEffect(() => {
    values.issue_date_time = new Date().toISOString()
    values.issuer = { id: user.id } as EmployeeOptionInterface
    values.confidentiality = DocumentConfidentiality.Employee
  }, [])

  return (
    <>
      <VStack space="s-16">
        <LapeNewInput name="name" label="Name" required />
        <LapeRadioSelectInput
          name="category"
          selector={selectorKeys.document_categories}
          label="Category"
        />
        <LapeRadioSelectInput
          name="employee"
          selector={selectorKeys.employee}
          label="Employee"
        />
        <Widget p="s-16">
          <Text variant="h6" color={Token.color.greyTone50} use="div" mb="s-8">
            File attachment
          </Text>
          {values.file ? (
            <Item pb={0} px={0}>
              <Item.Avatar>
                <Avatar useIcon="Document" color={Token.color.blue} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{values.file.name}</Item.Title>
              </Item.Content>
              <Item.Side>
                <IconButton
                  useIcon="Delete"
                  color={Token.color.greyTone20}
                  onClick={() => {
                    values.file = undefined
                  }}
                />
              </Item.Side>
            </Item>
          ) : (
            <FileUploader
              showDropAreaImage
              noDropAreaHighlight
              showClip={false}
              attachButtonText="click to upload files"
              name="file"
              onChange={async newFile => {
                if (newFile && !Array.isArray(newFile)) {
                  values.file = newFile
                }
              }}
              accept={
                'image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv, text/csv, application/csv, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              bottomInfo="Allowed document formats: .docx, .pdf, .jpeg, .jpg, .png, .xlsx, .csv"
            />
          )}
        </Widget>
      </VStack>
      <Side.Actions>
        <NewSaveButtonWithPopup
          onAfterSubmit={onSubmit}
          successText="Document was successfully added"
        >
          Add document
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </>
  )
}

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}
export const AddDocumentSidebar = ({ isOpen, onClose, onSubmit }: Props) => {
  return (
    <SideBar isOpen={isOpen} onClose={onClose} title="Add new document">
      <Form
        api={newDocumentRequests}
        forceParams={{ id: undefined }}
        disableLocalStorageCaching
      >
        <AddDocumentFormContent onSubmit={onSubmit} />
      </Form>
    </SideBar>
  )
}
