import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, Text, Token } from '@revolut/ui-kit'
import {
  getDirectHierarchyEmployee,
  useGetEmployeeDirectHierarchyUpward,
} from '@src/api/functions'
import { HierarchyNodeInterface } from '@src/interfaces/hierarchy'
import { ROUTES } from '@src/constants/routes'
import HierarchyTree from '@components/Hierarchy/HierarchyTree'
import { newTabTo } from '@src/utils/router'
import { selectUser } from '@src/store/auth/selectors'

export const Hiearchy = () => {
  const user = useSelector(selectUser)
  const { data: hierarchyData, isLoading } = useGetEmployeeDirectHierarchyUpward(user.id)

  const onChildrenRequest = async (node: HierarchyNodeInterface) => {
    const result = await getDirectHierarchyEmployee(node.id, false)
    return result.data.reports || []
  }

  const handleTitleClick = (node: HierarchyNodeInterface) =>
    newTabTo(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: node.id })

  const showTree = Boolean(isLoading || hierarchyData?.employees?.[0])

  return (
    <Flex flex={1}>
      {showTree ? (
        <HierarchyTree
          loading={isLoading}
          onChildrenRequest={onChildrenRequest}
          onNodeTitleClick={handleTitleClick}
          rootNodeData={hierarchyData?.employees?.[0]}
        />
      ) : (
        <Text color={Token.color.greyTone50}>There is no hierarchy information</Text>
      )}
    </Flex>
  )
}
