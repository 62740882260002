import React, { useState } from 'react'
import { Flex, IconButton, Image, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import { CrossSmall } from '@revolut/icons'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'

export const BudgetViewingRecommendationBanner = () => {
  const [isHidden, setIsHidden] = useState(
    !!workspaceLocalStorage.getItem(LOCAL_STORAGE.SHOW_PERF_REVIEW_PREFILLED),
  )

  if (isHidden) {
    return null
  }

  const onClose = () => {
    workspaceLocalStorage.setItem(LOCAL_STORAGE.SHOW_PERF_REVIEW_PREFILLED, 'true')
    setIsHidden(true)
  }

  return (
    <Widget p="s-16">
      <Flex gap="s-8" justifyContent="space-between">
        <Flex alignItems="center" maxHeight={48}>
          <Image
            size={84}
            src="https://assets.revolut.com/assets/3d-images/3D188.png"
            ml="-s-16"
            mt="-s-6"
          />
          <VStack>
            <Text variant="primary">All ratings are prefilled</Text>
            <Text variant="caption" color={Token.color.greyTone50}>
              We prefilled Performance Review with ratings from the previous Performance
              Cycle
            </Text>
          </VStack>
        </Flex>
        <IconButton
          useIcon={CrossSmall}
          color={Token.color.greyTone20}
          onClick={onClose}
          aria-label="Close message"
        />
      </Flex>
    </Widget>
  )
}
