import React from 'react'
import capitalize from 'lodash/capitalize'
import { useTableReturnType } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import { RowInterface, Stats } from '@src/interfaces/data'
import {
  SummarySubheader,
  SummaryTableContainer,
  summaryTableProps,
} from '@src/features/Summary/common'
import AdjustableTable from '@components/Table/AdjustableTable'
import { EntityTypes } from '@src/constants/api'
import { Statuses } from '@src/interfaces'
import {
  kpiCalibratedPerformanceColumn,
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { withoutFilterAndSort } from '@src/utils/table'
import { ColoredPercent } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import { useParams } from 'react-router'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import GraphIconChart, {
  fetchDataType,
} from '@components/Charts/GraphIconChart/GraphIconChart'
import { getKpiPerformanceGraph as getKpiGraphDepartment } from '@src/api/department'
import { getKpiPerformanceGraph as getKpiGraphTeam } from '@src/api/teams'
import { Flex } from '@revolut/ui-kit'
import { BarChart } from '@revolut/icons'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { rowHighlight } from '../KPI'

export interface KPIsSummaryProps {
  table: useTableReturnType<KpiInterface, Stats>
  entityType: EntityTypes
  kpiPercent: number
}

const row: RowInterface<KpiInterface> = {
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: withoutFilterAndSort([
    {
      ...kpiGenericNameColumn,
      title: 'Name',
    },
    {
      ...kpiWeightColumn,
      width: 80,
    },
    {
      ...kpiPerformanceColumn,
      width: 100,
    },
    {
      ...kpiCalibratedPerformanceColumn,
      width: 100,
    },
    {
      ...kpiInitialValueColumn,
      width: 70,
    },
    {
      ...kpiCurrentValueColumn,
      width: 70,
    },
    {
      ...kpiTargetColumn,
      width: 70,
    },
    {
      ...kpiUnitColumn,
      width: 70,
    },
  ]),
}

const getFormUrl = (entityType: EntityTypes, params: { id?: string }): string => {
  switch (entityType) {
    case EntityTypes.department:
      return pathToUrl(ROUTES.FORMS.DEPARTMENT.KPI, params)
    case EntityTypes.team:
      return pathToUrl(ROUTES.FORMS.TEAM.KPI, params)
    default:
      return ''
  }
}

const getKPIGraphRequest = (entityType: EntityTypes): fetchDataType => {
  switch (entityType) {
    case EntityTypes.department:
      return getKpiGraphDepartment
    case EntityTypes.team:
    default:
      return getKpiGraphTeam
  }
}

const KPIsSummary = ({ table, entityType, kpiPercent }: KPIsSummaryProps) => {
  const params = useParams<{ id?: string }>()

  if (!params.id) {
    return null
  }

  return (
    <>
      <SummarySubheader formUrl={getFormUrl(entityType, params)}>
        <Flex alignItems="center">
          KPIs · <ColoredPercent percent={formatPercentage(kpiPercent)} />
          <GraphIconChart
            id={+params.id}
            vertical="right"
            fetchData={getKPIGraphRequest(entityType)}
          >
            <Flex ml="s-8" mt="-s-2">
              <BarChart cursor="pointer" size={16} color="grey-tone-20" />
            </Flex>
          </GraphIconChart>
        </Flex>
      </SummarySubheader>
      <SummaryTableContainer>
        <AdjustableTable<KpiInterface>
          name={TableNames.KPIsSummary}
          dataType="KPI"
          row={row}
          {...table}
          noDataMessage={`No KPIs found for this ${capitalize(entityType)}`}
          onRowClick={onKPITableRowClick}
          {...summaryTableProps}
          hideCountAndButtonSection
        />
      </SummaryTableContainer>
    </>
  )
}

export default KPIsSummary
