import React from 'react'
import { Button, Grid } from '@revolut/ui-kit'

import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { PageActions } from '@src/components/Page/PageActions'
import { goBack } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { BulkDataImportSessionV2 } from '@src/features/BulkDataImportV2/BulkDataImportSessionV2'
import {
  CommonGenericEditableTableEntity,
  CommonGenericEditableTableRowOptions,
  TableActionsProps,
} from '@src/features/GenericEditableTable/GenericEditableTable'

export interface UploadSessionV2Props<T> {
  apiEndpoint: string
  previousRoute: string
  tableName: TableNames
  row: (options: CommonGenericEditableTableRowOptions) => RowInterface<ImportInterface<T>>
  entity: CommonGenericEditableTableEntity
  tableActions?: (props: TableActionsProps) => React.ReactNode
  hiddenColumns?: Record<string, boolean>
}

export const UploadSessionV2 = <T,>({
  row,
  apiEndpoint,
  tableName,
  previousRoute,
  entity,
  tableActions,
  hiddenColumns,
}: UploadSessionV2Props<T>) => {
  return (
    <BulkDataImportSessionV2
      row={row}
      apiEndpoint={apiEndpoint}
      entity={entity}
      variant="temporaryEntities"
      tableName={tableName}
      tableActions={tableActions}
      hiddenColumns={hiddenColumns}
      actions={({ onSubmit, submitPending, sessionData, disableActions }) => {
        return sessionData.state.id === 'ready_for_review' ||
          sessionData.state.id === 'failure' ||
          sessionData.state.id === 'applying' ? (
          <PageActions>
            <Grid columns={2} gap="s-8" maxWidth={375}>
              <Button
                variant="secondary"
                onClick={() => {
                  goBack(pathToUrl(previousRoute))
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                pending={submitPending}
                disabled={disableActions || sessionData.state.id === 'applying'}
                elevated
              >
                Apply changes
              </Button>
            </Grid>
          </PageActions>
        ) : (
          <PageActions>
            <Button
              onClick={() => {
                goBack(pathToUrl(previousRoute))
              }}
              elevated
            >
              Confirm
            </Button>
          </PageActions>
        )
      }}
    />
  )
}
