import React, { useEffect, useMemo, useState } from 'react'
import {
  ActionBar,
  ActionButton,
  Cell,
  Color,
  Flex,
  H5,
  VStack,
  Box,
} from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { dragIconColumn } from '@src/constants/columns/ordering'
import {
  KeyPersonSuccessorInterface,
  KeyRiskProfileSuccessionPlanConfigInterface,
  SuccessionStrategy,
} from '@src/interfaces/keyPerson'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useQuery } from '@src/utils/queryParamsHooks'
import { getOrderedSuccessors } from '@src/apps/People/KeyPersons/SuccessionPlan/helpers'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import {
  successorsOrderActionsColumn,
  successorsOrderEmployeeNameColumn,
  successorsOrderRankColumn,
  successorsOrderRoleColumn,
  successorsOrderStatusColumn,
} from '@src/constants/columns/successorsOrder'
import { useOrdering } from '@src/components/Table/hooks'
import { rowWrapperMinHeight } from '@components/Table/Row'
import { IdAndName } from '@src/interfaces'

const getRow = (
  onClickDelete: (employeeId: number) => void,
): RowInterface<KeyPersonSuccessorInterface> => ({
  cells: [
    dragIconColumn,
    {
      ...successorsOrderRankColumn,
      width: 60,
    },
    {
      ...successorsOrderEmployeeNameColumn,
      width: 160,
    },
    {
      ...successorsOrderRoleColumn,
      width: 140,
    },
    {
      ...successorsOrderStatusColumn,
      width: 70,
    },
    {
      ...successorsOrderActionsColumn,
      width: 70,
      insert: ({ data }) => {
        return (
          <ActionBar labelMoreButton="More">
            <ActionBar.Item
              aria-label="Remove successor"
              color={Color.RED}
              onClick={() => onClickDelete(data.id)}
            >
              Delete
            </ActionBar.Item>
          </ActionBar>
        )
      },
    },
  ],
})

export const SuccessorsTable = ({
  selectedResponsibility,
}: {
  selectedResponsibility: KeyRiskProfileSuccessionPlanConfigInterface
}) => {
  const { query, deleteQueryParam } = useQuery()

  useEffect(() => {
    if (query.addSuccessor) {
      deleteQueryParam('addSuccessor')
    }

    if (!selectedResponsibility.successors_order.length) {
      selectedResponsibility.successors_order = selectedResponsibility.successors.map(
        ({ id }) => id,
      )
    } else {
      selectedResponsibility.successors = getOrderedSuccessors(
        selectedResponsibility.successors,
        selectedResponsibility.successors_order,
      )
    }
  }, [])

  const [isAddConfirmationOpen, setIsAddConfirmationOpen] = useState(
    query.addSuccessor === 'true',
  )
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
  const [deletingSuccessorId, setDeletingSuccessorId] = useState<number>()
  const [orderedTableData, setOrderedTableData] = useState<KeyPersonSuccessorInterface[]>(
    selectedResponsibility.successors_order
      .map(orderedId =>
        selectedResponsibility.successors.find(({ id }) => id === orderedId),
      )
      .filter(Boolean),
  )
  const [newSuccessor, setNewSuccessor] = useState<EmployeeOptionInterface>()

  useEffect(() => {
    setOrderedTableData(
      selectedResponsibility.successors_order
        .map(orderedId =>
          selectedResponsibility.successors.find(({ id }) => id === orderedId),
        )
        .filter(Boolean),
    )
  }, [selectedResponsibility])

  const handleOrderChange = (order: KeyPersonSuccessorInterface[]) => {
    setOrderedTableData(order)
    selectedResponsibility.successors = order
    selectedResponsibility.successors_order = order.map(({ id }) => id)
  }

  const ordering = useOrdering(orderedTableData, handleOrderChange)

  /** `AdjustableTable` inside `TransitionCollapse` doesn't work well, so it's needed to specify the height */
  const tableWrapperHeight = useMemo(() => {
    if (
      !selectedResponsibility.successors ||
      selectedResponsibility.successors.length === 0
    ) {
      /** To account for header and 1 empty row */
      return rowWrapperMinHeight.medium * 2
    }
    return (selectedResponsibility.successors.length + 1) * rowWrapperMinHeight.medium
  }, [selectedResponsibility.successors])

  const onCloseDeleteConfirmation = () => {
    setDeletingSuccessorId(undefined)
    setIsDeleteConfirmationOpen(false)
  }

  const onCloseAddConfirmation = () => {
    setIsAddConfirmationOpen(false)
  }

  const handleDeleteSuccessor = (employeeId?: number) => {
    if (employeeId === undefined) {
      return
    }
    selectedResponsibility.successors = selectedResponsibility.successors.filter(
      ({ id: successorId }) => successorId !== employeeId,
    )
    selectedResponsibility.successors_order =
      selectedResponsibility.successors_order.filter(
        successorId => successorId !== employeeId,
      )
    handleOrderChange(selectedResponsibility.successors)
    onCloseDeleteConfirmation()
  }

  const handleAddSuccessor = (addedSuccessor?: EmployeeOptionInterface) => {
    if (addedSuccessor === undefined) {
      return
    }
    selectedResponsibility.successors = [
      ...selectedResponsibility.successors,
      addedSuccessor as KeyPersonSuccessorInterface,
    ]
    selectedResponsibility.successors_order = selectedResponsibility.successors.map(
      ({ id }) => id,
    )
    handleOrderChange(selectedResponsibility.successors)
    onCloseAddConfirmation()
    setNewSuccessor(undefined)
  }

  const currentSuccessionStrategy = selectedResponsibility?.succession_strategy

  return (
    <>
      <Cell>
        <Flex flexDirection="column" width="100%" gap="s-8">
          {orderedTableData.length === 0 && (
            <RadioSelectInput<IdAndName<SuccessionStrategy>>
              label="Succession strategy"
              selector={
                selectorKeys.key_risk_profile_succession_plan_succession_strategies
              }
              onChange={value => {
                selectedResponsibility.succession_strategy = value
              }}
              value={currentSuccessionStrategy}
            />
          )}

          {(orderedTableData.length !== 0 ||
            !['successors_not_required', 'successors_not_yet_identified'].includes(
              currentSuccessionStrategy?.id || '',
            )) && (
            <>
              <Box height={tableWrapperHeight} overflow="hidden">
                <AdjustableTable<KeyPersonSuccessorInterface>
                  name={TableNames.ApprovalFlow}
                  dataType="Successor"
                  row={getRow(handleDeleteSuccessor)}
                  data={orderedTableData}
                  count={orderedTableData.length}
                  orderingMode
                  disabledFiltering
                  hideCount
                  hideCountAndButtonSection
                  rowHeight="medium"
                  {...ordering}
                />
              </Box>
              <ActionButton
                mt="s-16"
                useIcon={Plus}
                onClick={() => setIsAddConfirmationOpen(true)}
              >
                Add successor
              </ActionButton>
            </>
          )}
        </Flex>
      </Cell>
      <ConfirmationDialog
        open={isDeleteConfirmationOpen}
        onClose={onCloseDeleteConfirmation}
        onReject={onCloseDeleteConfirmation}
        onConfirm={() => handleDeleteSuccessor(deletingSuccessorId)}
        showHeader={false}
        body={<H5>Are you sure you want to delete employee from successors list?</H5>}
        yesMessage="Confirm"
        noMessage="Go back"
      />
      <ConfirmationDialog
        open={isAddConfirmationOpen}
        onClose={onCloseAddConfirmation}
        onReject={onCloseAddConfirmation}
        onConfirm={() => handleAddSuccessor(newSuccessor)}
        showHeader={false}
        body={
          <VStack space="s-16">
            <H5>Select an employee to add</H5>
            <RadioSelectInput
              selector={selectorKeys.employee}
              label="New successor"
              value={newSuccessor}
              onChange={(value: EmployeeOptionInterface | null) => {
                if (value) {
                  setNewSuccessor(value)
                }
              }}
            />
          </VStack>
        }
        yesMessage="Confirm"
        noMessage="Go back"
      />
    </>
  )
}
