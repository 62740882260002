import React from 'react'
import {
  chain,
  Flex,
  Text,
  Token,
  useTooltip,
  VStack,
  Tooltip as UIKitTooltip,
} from '@revolut/ui-kit'
import {
  ReviewCalculatedDeliverablesRatingsInterface,
  PerformanceRating,
  ReviewDataInterface,
  ReviewDataSectionInterface,
  SkillCardInterface,
  ValueBasedCardInterface,
  ReviewSummaryDataInterface,
  Ratings,
  ReviewerRelationToShortTitle,
  SkillSummary,
  SummarySkillCardInterface,
  DeliverableOptions,
  performanceRatingToFinalGrade,
} from '@src/interfaces/performance'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  getKpiGradeColumn,
  kpiGenericNameColumn,
  kpiPerformanceColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import {
  performanceSummaryRatingColumn,
  performanceSummaryCalculatedRatingColumn,
  performanceSummaryDeliverablesNameColumn,
  performanceSummaryExpectedCalcRatingColumn,
  performanceSummaryExpectedRatingColumn,
  performanceSummarySkillsNameColumn,
} from '@src/constants/columns/performanceSummary'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { tooltipRatingToColor } from '@src/utils/performance'
import { GradesMapInterface, useGetReviewGradesMap } from '@src/utils/grades'

interface Props {
  reviewData: ReviewDataInterface | ReviewSummaryDataInterface
  reviewGradesMap?: GradesMapInterface
}

export interface KpiRowInterface extends KpiInterface {
  rating_expectation: PerformanceRating
  recommended_rating: DeliverableOptions
}

const RatingWithTooltip = ({
  getTitle,
  rating,
  ratings,
}: {
  getTitle: (rating: PerformanceRating) => string
  rating?: PerformanceRating
  ratings?: Ratings[]
}) => {
  const ratingTooltip = useTooltip()
  if (!rating) {
    return <Text variant="h6">-</Text>
  }
  return (
    <Flex
      gap="s-8"
      {...ratingTooltip.getAnchorProps()}
      width="fit-content"
      style={{ cursor: 'pointer' }}
    >
      <Text variant="caption" textDecoration="underline">
        {getTitle(rating)}
      </Text>
      <UIKitTooltip {...ratingTooltip.getTargetProps()} placement="top">
        <VStack>
          {ratings?.map(item => (
            <Flex key={item.review.id}>
              {chain(
                <Text variant="h6" color={tooltipRatingToColor(item.value)}>
                  {getTitle(item.value)}
                </Text>,
                <Text>{item.review.reviewer.full_name}</Text>,
                <Text color={Token.color.greyTone50}>
                  {ReviewerRelationToShortTitle[item.review.reviewer_relation]}
                </Text>,
              )}
            </Flex>
          ))}
        </VStack>
      </UIKitTooltip>
    </Flex>
  )
}

const getRatingWithTooltip = (
  getGrade: boolean,
  rating?: PerformanceRating,
  ratings?: Ratings[],
  gradesMap?: GradesMapInterface,
) => {
  return (
    <RatingWithTooltip
      getTitle={perfRating => {
        return getGrade
          ? gradesMap?.[performanceRatingToFinalGrade(perfRating)] || '-'
          : PerformanceRatingTitle[perfRating]
      }}
      rating={rating}
      ratings={ratings}
    />
  )
}

const getRatingColumnValue = (
  data:
    | ReviewDataSectionInterface
    | SkillCardInterface
    | ValueBasedCardInterface
    | SummarySkillCardInterface,
) => {
  return data.rating ? PerformanceRatingTitle[data.rating] : '-'
}

const getGradeColumnValue = (
  data:
    | ReviewDataSectionInterface
    | SkillCardInterface
    | ValueBasedCardInterface
    | SummarySkillCardInterface,
  gradesMap: GradesMapInterface,
) => {
  return data.rating ? gradesMap[performanceRatingToFinalGrade(data.rating)] : '-'
}

const getRecommendedRatingColumnValue = (
  data: KpiRowInterface | ReviewCalculatedDeliverablesRatingsInterface,
) => {
  return data.recommended_rating ? PerformanceRatingTitle[data.recommended_rating] : '-'
}

const getRatingSummaryColumnValue = (
  data: SkillSummary | SummarySkillCardInterface | KpiRowInterface,
) => {
  return getRatingWithTooltip(false, data.rating || undefined, data.ratings)
}

const getGradeSummaryColumnValue = (
  data: SkillSummary | SummarySkillCardInterface | KpiRowInterface,
  gradesMap: GradesMapInterface,
) => {
  return getRatingWithTooltip(true, data.rating || undefined, data.ratings, gradesMap)
}

const getDeliverablesRow: (
  gradesMap: GradesMapInterface,
) => RowInterface<ReviewDataSectionInterface | SkillSummary> = gradesMap => ({
  cells: [
    {
      ...performanceSummaryDeliverablesNameColumn,
      width: 100,
    },
    {
      ...performanceSummaryRatingColumn,
      insert: ({ data }) =>
        'ratings' in data
          ? getGradeSummaryColumnValue(data, gradesMap)
          : getGradeColumnValue(data, gradesMap),
      title: 'Latest grade',
      width: 60,
    },
  ],
})

const calcDeliverablesRow: RowInterface<ReviewCalculatedDeliverablesRatingsInterface> = {
  cells: [
    {
      ...performanceSummaryDeliverablesNameColumn,
      width: 100,
    },
    {
      ...performanceSummaryCalculatedRatingColumn,
      insert: ({ data }) => getRecommendedRatingColumnValue(data),
      width: 60,
    },
    {
      ...performanceSummaryExpectedCalcRatingColumn,
      width: 60,
    },
  ],
}

const getKpisRow: (
  gradesMap: GradesMapInterface,
) => RowInterface<KpiRowInterface> = gradesMap => ({
  cells: [
    {
      ...kpiGenericNameColumn,
      sortKey: null,
      filterKey: null,
      width: 150,
    },
    {
      ...kpiWeightColumn,
      sortKey: null,
      filterKey: null,
      width: 80,
    },
    {
      ...kpiPerformanceColumn,
      title: 'Calibrated performance',
      sortKey: null,
      filterKey: null,
      width: 80,
    },
    {
      ...getKpiGradeColumn(gradesMap),
      width: 110,
    },
  ],
})

const skillsRow: RowInterface<SkillCardInterface | SummarySkillCardInterface> = {
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      width: 100,
    },
    {
      ...performanceSummaryRatingColumn,
      insert: ({ data }) =>
        'ratings' in data
          ? getRatingSummaryColumnValue(data)
          : getRatingColumnValue(data),
      width: 60,
    },
    {
      ...performanceSummaryExpectedRatingColumn,
      width: 60,
    },
  ],
}

const valuesRow: RowInterface<
  SkillCardInterface | ValueBasedCardInterface | SummarySkillCardInterface
> = {
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      width: 100,
    },
    {
      ...performanceSummaryRatingColumn,
      insert: ({ data }) =>
        'ratings' in data
          ? getRatingSummaryColumnValue(data)
          : getRatingColumnValue(data),
      width: 60,
    },
  ],
}

const TableWrapper = ({
  children,
  headerTitle,
  headerStat,
  headerRating,
  completedReviews,
}: {
  children: React.ReactNode
  headerTitle: string
  headerStat?: number
  headerRating?: PerformanceRating
  completedReviews?: boolean
}) => {
  return (
    <VStack space="s-16">
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="h6" color={Token.color.greyTone50}>
          {chain(
            headerTitle,
            headerStat ? (
              <Text color={getPercentColor((headerStat || 0) * 100)}>
                {formatPercentage(headerStat || 0)}
              </Text>
            ) : undefined,
          )}
        </Text>
        {completedReviews && headerRating && (
          <Text variant="h6">{PerformanceRatingTitle[headerRating]}</Text>
        )}
      </Flex>
      <Flex style={{ position: 'relative', fontWeight: 400 }} flex="1 0">
        {children}
      </Flex>
    </VStack>
  )
}

export const SummaryReviewTables = ({ reviewGradesMap, reviewData }: Props) => {
  const newGradesMap = useGetReviewGradesMap()
  const gradesMap = reviewGradesMap || newGradesMap
  const isSummaryReview = 'ratings' in reviewData

  const calcDeliverablesData =
    !isSummaryReview && reviewData.calculated_deliverables_ratings
      ? [reviewData.calculated_deliverables_ratings]
      : undefined
  const deliverablesData = reviewData.deliverables ? [reviewData.deliverables] : undefined
  const kpisData = reviewData.kpis_section?.kpi_items
    ? [
        ...reviewData.kpis_section.kpi_items.map(
          item =>
            ({
              ...item,
              rating_expectation: reviewData.kpis_section?.rating_expectation,
              recommended_rating: reviewData.kpis_section?.recommended_rating,
            } as KpiRowInterface),
        ),
      ]
    : undefined

  const skillsData = reviewData.functional_skills?.cards
  const managerSkillsData = reviewData.manager_skills?.cards
  const cultureValuesData = reviewData.culture_values?.cards
  const cultureSkillsData = reviewData.culture_skills?.cards

  return (
    <VStack space="s-24">
      {!isSummaryReview && calcDeliverablesData && (
        <TableWrapper
          headerTitle="Goals and deliverables"
          headerRating={reviewData.calculated_deliverables_ratings?.recommended_rating}
        >
          <AdjustableTable
            name={TableNames.PerformanceSummaryDeliverables}
            row={calcDeliverablesRow}
            count={calcDeliverablesData.length}
            data={calcDeliverablesData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
      {deliverablesData && (
        <TableWrapper
          headerTitle="Goals"
          headerRating={reviewData.deliverables?.rating || undefined}
        >
          <AdjustableTable
            name={TableNames.PerformanceSummaryDeliverables}
            row={getDeliverablesRow(gradesMap)}
            count={deliverablesData.length}
            data={deliverablesData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
      {kpisData && (
        <TableWrapper
          headerTitle="Goals"
          headerStat={reviewData.kpis_section?.performance}
        >
          <AdjustableTable
            name={TableNames.PerformanceSummaryKPIs}
            row={getKpisRow(gradesMap)}
            count={kpisData.length}
            data={kpisData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
      {skillsData && (
        <TableWrapper headerTitle="Functional skills">
          <AdjustableTable<SkillCardInterface | SummarySkillCardInterface>
            name={TableNames.PerformanceSummarySkills}
            row={skillsRow}
            count={skillsData.length}
            data={skillsData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
      {managerSkillsData && (
        <TableWrapper headerTitle="Manager skills">
          <AdjustableTable<SkillCardInterface | SummarySkillCardInterface>
            name={TableNames.PerformanceSummaryManagerSkills}
            row={skillsRow}
            count={managerSkillsData.length}
            data={managerSkillsData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
      {cultureValuesData && (
        <TableWrapper headerTitle="Values">
          <AdjustableTable<
            SkillCardInterface | ValueBasedCardInterface | SummarySkillCardInterface
          >
            name={TableNames.PerformanceSummaryValues}
            row={valuesRow}
            count={cultureValuesData.length}
            data={cultureValuesData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
      {cultureSkillsData && (
        <TableWrapper headerTitle="Values">
          <AdjustableTable<
            SkillCardInterface | ValueBasedCardInterface | SummarySkillCardInterface
          >
            name={TableNames.PerformanceSummaryValues}
            row={valuesRow}
            count={cultureSkillsData.length}
            data={cultureSkillsData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
    </VStack>
  )
}
