import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import capitalize from 'lodash/capitalize'
import {
  Action,
  Button,
  Flex,
  Input,
  InputGroup,
  Subheader,
  Text,
  Token,
} from '@revolut/ui-kit'
import { ChevronDown, ChevronUp, Plus, Minus } from '@revolut/icons'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { QueryParameterTypes, ReportInterface } from '@src/interfaces/dataAnalytics'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { selectUser } from '@src/store/auth/selectors'
import { ScheduleWidget } from './components/ScheduleWidget'
import { useQueryOptions } from './hooks/useQueriesOptions'
import { getDefaultSchedule, getScheduleMessage } from './utils'

export const ContentForm = connect(() => {
  const user = useSelector(selectUser)
  const { values } = useLapeContext<ReportInterface>()
  const { queriesOptions } = useQueryOptions()
  const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState(false)

  const { options: schedulingTypes } = useFetchOptions<IdAndName<'advanced' | 'basic'>>(
    selectorKeys.report_scheduling_types,
  )

  const columnOptions = useMemo(
    () =>
      values.query?.columns.map(column => ({
        label: column.name,
        value: { id: column.name, ...column },
      })),
    [values.query?.columns],
  )

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id, full_name: user.full_name }
    }
  }, [])

  useEffect(() => {
    if (
      values.report_type === 'ui' &&
      !values.id &&
      !values.scheduling_type?.id &&
      !!schedulingTypes.length
    ) {
      values.scheduling_type =
        schedulingTypes.find(item => item.value.id === 'advanced')?.value || null
      values.schedule = getDefaultSchedule()
    }
  }, [schedulingTypes])

  return (
    <PageBody>
      <AutoStepper>
        <NewStepperTitle title="Add a report name and a description" />
        <InputGroup>
          <LapeNewInput label="Name" name="name" required />
          <LapeNewTextArea label="Description" name="description" rows={3} />
        </InputGroup>
        <NewStepperTitle
          subtitle="Choose an active query that will be used to collect data for your report"
          title="Select a data source for the report"
        />
        <LapeRadioSelectInput
          label="Select a Query"
          name="query"
          onChange={val => {
            if (val) {
              values.query = val

              // reset query parameters
              values.default_query_parameters =
                values.query.parameters.reduce(
                  (o, key) => Object.assign(o, { [key.name]: null }),
                  {},
                ) || {}
            }

            // reset output format
            values.output_format = {
              columns: values.query.columns.map(column => ({
                name: column.name,
                type: column.type,
                alias: null,
              })),
            }
          }}
          options={queriesOptions}
          value={
            queriesOptions.find(option => option.value.id === values.query?.id)?.value ||
            null
          }
        />
        {values.default_query_parameters && values.query.parameters.length > 0 && (
          <>
            <NewStepperTitle title="Provide default parameter values" />
            {values.query.parameters.map(parameter => {
              const messageInput =
                values.report_type === 'download'
                  ? "If you don't provide a default value, it will be requested at report run time"
                  : 'Parameters must be provided for UI reports'
              return (
                <Flex
                  backgroundColor={Token.color.background}
                  borderRadius={12}
                  flexDirection="column"
                  gap="s-8"
                  mb="s-16"
                  p="s-16"
                  key={parameter.name}
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text>{parameter.name}</Text>
                    <Text color={Token.color.greyTone50}>{`Type: ${capitalize(
                      parameter.type,
                    )}`}</Text>
                  </Flex>
                  {parameter.type === QueryParameterTypes.Date ? (
                    <DatePickerInput
                      data-name={parameter.name}
                      message={messageInput}
                      onChange={date => {
                        values.default_query_parameters[parameter.name] = date
                      }}
                      value={values.default_query_parameters[parameter.name] as Date}
                    />
                  ) : (
                    <Input
                      message={messageInput}
                      onChange={e => {
                        values.default_query_parameters[parameter.name] =
                          e.currentTarget.value || null
                      }}
                      name={parameter.name}
                      value={values.default_query_parameters[parameter.name] as string}
                    />
                  )}
                </Flex>
              )
            })}
          </>
        )}
        <Subheader>
          <Subheader.Title>Advanced Options</Subheader.Title>
          <Subheader.Side>
            <Action
              color="foreground"
              iconOnly
              onClick={() => setIsAdvancedOptionsOpen(!isAdvancedOptionsOpen)}
              useIcon={isAdvancedOptionsOpen ? ChevronUp : ChevronDown}
            />
          </Subheader.Side>
        </Subheader>
        {isAdvancedOptionsOpen && (
          <>
            <NewStepperTitle
              noAutoStep
              subtitle="Configure the details of when the report will run"
              title="Set the data refresh frequency"
            />
            <InputGroup>
              <LapeRadioSelectInput
                clearable
                label="How would you like to schedule the data refresh?"
                name="scheduling_type"
                options={schedulingTypes}
              />
              <>
                {values.scheduling_type?.id === 'advanced' && (
                  <LapeNewInput
                    label="Schedule"
                    message={getScheduleMessage(values)}
                    name="schedule"
                    required
                  />
                )}
                {values.scheduling_type?.id === 'basic' && <ScheduleWidget />}
              </>
            </InputGroup>
            {values.report_type === 'ui' && (
              <>
                <NewStepperTitle
                  noAutoStep
                  subtitle="The columns defined below do not need to be displayed in the interface but can be used to filter the report data to the context in which the report will be displayed"
                  title="(Optional) Choose context filter columns"
                />
                <InputGroup>
                  {values.output_format?.context_filters?.map((_, index) => (
                    <InputGroup variant="horizontal" key={index}>
                      <LapeRadioSelectInput
                        label="Context Type"
                        name={`output_format.context_filters.${index}.context_type`}
                        required
                        selector={selectorKeys.context_types}
                      />
                      <LapeRadioSelectInput
                        label="Column"
                        name={`output_format.context_filters.${index}.column_name`}
                        onChange={val => {
                          if (val && values.output_format.context_filters) {
                            values.output_format.context_filters[index].column_name =
                              val.name
                          }
                        }}
                        options={columnOptions}
                        required
                        value={
                          columnOptions.find(
                            option =>
                              option.value.name ===
                              (values.output_format.context_filters ?? [])[index]
                                .column_name,
                          )?.value || null
                        }
                      />
                    </InputGroup>
                  ))}
                  <Flex gap="s-16">
                    <Button
                      onClick={() => {
                        const newType = { column_name: null, context_type: null }

                        if (values.output_format.context_filters) {
                          values.output_format.context_filters.push(newType)
                        } else {
                          values.output_format.context_filters = [newType]
                        }
                      }}
                      size="sm"
                      variant="secondary"
                      useIcon={Plus}
                    >
                      Add Context Filter
                    </Button>
                    <Button
                      onClick={() => values.output_format.context_filters?.pop()}
                      size="sm"
                      variant="secondary"
                      useIcon={Minus}
                    >
                      Remove Context Filter
                    </Button>
                  </Flex>
                </InputGroup>
              </>
            )}
            <NewStepperTitle noAutoStep title="Set the report owner" />
            <LapeRadioSelectInput
              label="Owner"
              name="owner"
              required
              selector={selectorKeys.employee}
            />
          </>
        )}
      </AutoStepper>
    </PageBody>
  )
})
