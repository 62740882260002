import {
  FinalGrade,
  PerformanceCycleInterface,
  PerformanceRating,
  ReviewerInterface,
  ReviewerRelation,
  ReviewInterface,
  ReviewStatuses,
} from '@src/interfaces/performance'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { SkillLevels } from '@src/interfaces/roles'
import { DeliverableInterface, IssueTypes } from '@src/interfaces/deliverables'
import { FieldOptions, Statuses } from '@src/interfaces/index'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { OptionInterface } from '@src/interfaces/selectors'
import { KpiInterface } from '@src/interfaces/kpis'
import { PermissionTypes } from '@src/store/auth/types'

export interface ProbationCheckpoints {
  checkpoints: ProbationCheckpoint[]
  decision_checkpoints?: ProbationCheckpoint[]
}

export enum CheckpointType {
  Review = 'review',
  Recommendation = 'recommendation',
  Decision = 'decision',
  Goals = 'goals',
}

export interface ProbationCheckpoint {
  id: number
  can_be_submitted: boolean
  checkpoint_date_time: string
  checkpoint_start: string
  checkpoint_deadline: string
  checkpoint_number: number
  checkpoint_type: CheckpointType
  review?: ReviewInterface
  recommendation?: {
    id: number
    status: ReviewStatuses
    reviewed_employee?: ReviewerInterface
    cycle?: PerformanceCycleInterface
  }
  decision?: {
    id: number
    reviewer?: EmployeeOptionInterface
  }
  goals?: {
    status: Statuses.approved | Statuses.pending_approval | null
  }
  user_permissions?: PermissionTypes[]
}

export enum QuestionType {
  Seniority = 'seniority_select',
  YesNo = 'wide_yes_no',
}

export interface ProbationRecommendationQuestionInterface {
  text: string
  question_type: QuestionType
  value: string | null
  seniority?: SeniorityInterface
}

export interface ProbationRecommendationInterface {
  id: number
  questions: ProbationRecommendationQuestionInterface[]
  result: ProbationResults
  justification: string
  extension_date: string | null
  reviewer: ReviewerInterface
  reviewed_employee: ReviewerInterface
  reviewer_relation: ReviewerRelation
  field_options: FieldOptions
  status: Statuses
  created_date_time: string
  updated_date_time: string | null
}

export interface ExpectedSkillRatingInterface {
  seniority: SeniorityInterface
  expected_overall_rating: SkillLevels
  expected_deliverables_rating: SkillLevels
  expected_culture_skills_rating: SkillLevels
  expected_ratings_by_culture_skill: { [key: string]: SkillLevels }
  expected_functional_skills_rating: SkillLevels
  expected_ratings_by_functional_skill: { [key: string]: SkillLevels }
}

export interface ProbationDeliverablesInterface {
  goals: DeliverableInterface[]
  others: DeliverableInterface[]
}

export enum ProbationResults {
  Passed = 'passed',
  Extend = 'extend',
  ChangeSeniority = 'change_seniority',
  Failed = 'failed',
}

export interface CommitteeResultInterface {
  id: number
  cycle: PerformanceCycleInterface
  result: ProbationResults
  seniority: SeniorityInterface
  justification: string
  reviewer: ReviewerInterface
  reviewed_employee: ReviewerInterface
  extension_date: string
  field_options: FieldOptions
  final_grade?: OptionInterface
  end_of_probation_date_time?: string
}

export interface ProbationCheckpointManagersGrade {
  id: number
  checkpoint_date_time: string | null
  number: number
  line_manager_grade: FinalGrade | null
  functional_manager_grade: FinalGrade | null
}

export interface ProbationManagersGrade {
  final_managers_calculated_grade: FinalGrade | null
  managers_grades_by_checkpoint: ProbationCheckpointManagersGrade[]
}

export interface JiraIssueInterface {
  id: string
  self: string
  key: string
  fields: {
    summary: string
    issuetype: {
      self: string
      id: string
      description: string
      iconUrl: string
      name: IssueTypes
      subtask: boolean
      avatarId: number
      entityId: string
      hierarchyLevel: number
    }
    updated: string | null
    duedate: string | null
    status: {
      self: string
      description: string
      iconUrl: string
      name: string
      id: string
      statusCategory: {
        self: string
        id: number
        key: string
        colorName: string
        name: string
      }
    }
  }
}

export interface JiraIssuesInterface {
  total: number
  issues: JiraIssueInterface[]
}

export interface ProbationGoalInterface {
  employee?: number
  cycle: {
    id: string
  }
  id?: number
  jira_issue?: number
  jira_issue_key: string | null
  jira_issue_duedate: string | null
  jira_issue_status: string | null
  jira_issue_summary: string | null
  jira_issue_type: IssueTypes | null
  jira_issue_url?: string | null
  jira_issue_updated: string | null
}

export interface ProbationKPIRecommendationInterface {
  result: {
    comment: string | null
    kpi_items: Partial<KpiInterface>[]
    performance: number
    rating: PerformanceRating | null
    rating_score: number
  }
}
