import React, { useState } from 'react'
import { HiringProcessDetails } from './HiringProcessDetails'
import { HiringProcessInterface, StageType } from '@src/interfaces/hiringProccess'
import { AddStageButton } from './AddStageButton'
import { RoundDetailsSidebar } from './RoundDetailsSidebar'
import HiringStagePopup from './HiringStagePopup'
import { MoreBar, Text, Token } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { formHiringProcessStagesRequest } from '@src/api/hiringProcess'
import { Statuses } from '@src/interfaces'

type HiringStagesListProps = {
  isSpecialisation: boolean
  hiringProcessRounds: HiringProcessInterface[]
  onOrderChange: (hiringProcessRounds: HiringProcessInterface[]) => void
  onRefresh: () => void
}

export const HiringStagesList = ({
  isSpecialisation,
  hiringProcessRounds,
  onOrderChange,
  onRefresh,
}: HiringStagesListProps) => {
  const [stage, setStage] = useState<
    HiringProcessInterface | Pick<HiringProcessInterface, 'stage_type'>
  >()
  const [openStagePreview, setOpenStagePreview] = useState(false)
  const [openStageEditing, setOpenStageEditing] = useState(false)

  const onDelete = async (stageId: number) => {
    await formHiringProcessStagesRequest.update(
      {
        status: Statuses.archived,
      },
      {
        id: String(stageId),
      },
    )
    onRefresh()
  }
  return (
    <>
      <MoreBar>
        <AddStageButton
          onClick={stageType => {
            setOpenStagePreview(false)
            setStage({
              stage_type: {
                id: stageType.id as StageType,
                name: stageType.name,
              },
            })
            setOpenStageEditing(true)
          }}
        />
      </MoreBar>
      <Text variant="caption" color={Token.color.greyTone50}>
        <FormattedMessage
          id="onboarding.interviews.text"
          defaultMessage="Review and configure default interview process. This process then will be applied for every candidate in the pipeline. Pay attention to the skills Interview as this one is unique for each role and interview questions will be aligned to the skills that are required by every role."
        />
      </Text>
      <HiringProcessDetails
        hiringProcessRounds={hiringProcessRounds}
        onDeleteRound={({ id }) => {
          onDelete(id)
        }}
        onEditRound={hiringProcessRound => {
          setStage(hiringProcessRound)
          setOpenStageEditing(true)
        }}
        onOrderChange={onOrderChange}
        onShowRoundDetails={hiringProcessRound => {
          setStage(hiringProcessRound)
          setOpenStagePreview(true)
        }}
      />
      {openStagePreview && stage && 'id' in stage && (
        <RoundDetailsSidebar
          hiringProcessRound={stage}
          onEdit={() => {
            setOpenStageEditing(true)
          }}
          onClose={() => {
            setOpenStagePreview(false)
          }}
        />
      )}
      {openStageEditing && (
        <HiringStagePopup
          disableStageType
          isSpecialisation={isSpecialisation}
          stage={stage}
          onClose={updatedStage => {
            setStage(updatedStage)
            setOpenStageEditing(false)
          }}
          onRefresh={onRefresh}
        />
      )}
    </>
  )
}
