import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroList } from '@components/OnboardingIntro/OnboardingIntroList'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const InterviewsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Hiring%20Process.mp4"
      videoPosterUrl="/intros/Intro%20-%20Hiring%20Process.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.interviews.intro.title"
            defaultMessage="Configure your interview process"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.interviews.intro.text1"
            defaultMessage="Jobs and candidates are in the system. They can see your job opportunities and apply for the role. Now it is time to put interview process in place."
          />
        </p>
        <p>
          <FormattedMessage
            id="onboardingV2.interviews.intro.text2"
            defaultMessage="When set up properly, interview process in revolut people allow you to achieve high hiring precision by assessing candidates skills and performance. It also optimises time and effort with automation and scheduling features."
          />
        </p>
        <FormattedMessage
          id="onboardingV2.interviews.intro.text3"
          defaultMessage="Start with reviewing default interview process. "
        />
        <OnboardingIntroList
          items={[
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.cv"
                  defaultMessage="CV Screening"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.screenCall"
                  defaultMessage="screen Call"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.skills"
                  defaultMessage="skills Interview"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.interviews.intro.list.final"
                  defaultMessage="final Interview"
                />
              ),
            },
          ]}
        />
      </OnboardingIntroTextWidget>
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.interviews.intro.tips.title"
            defaultMessage="Setup interview tips"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.stages.title"
                defaultMessage="Interview stages"
              />
            ),
            icon: 'Microphone',
            text: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.stages.text"
                defaultMessage="We provide you with default list of interviews as a starting point. Feel free to explore settings and adjust interview questions. You can even add custom interview stages by clicking “Add interview stage” button."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.scorecards.title"
                defaultMessage="Scorecards"
              />
            ),
            icon: 'Questionnaire',
            text: (
              <FormattedMessage
                id="onboardingV2.interviews.intro.tips.scorecards.text"
                defaultMessage="Scorecard is the list of the questions recruiters will ask candidates during the interview round. For each interview stage there is unique scorecard that will help to get important information and complete interview. However you can adjust them, add your own unique questions to align with your company hiring goals."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
