import React from 'react'
import { Box, Token, VStack, Text, Icon, Badge, Flex } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const NoPersonalGoalsBanner = ({ employee }: { employee?: EmployeeInterface }) => {
  const team = employee?.team?.name ? (
    <Badge
      size={24}
      bg={Token.color.greyTone2}
      mt="s-8"
      maxWidth="fit-content"
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, {
        id: employee.team.id,
      })}
      target="_blank"
    >
      <Flex gap="s-4" alignItems="center" mx="s-4">
        <Icon name="People" size={12} color={Token.color.foreground} />
        <Text color={Token.color.foreground_80} variant="tiny" fontWeight={600}>
          {employee.team.name}
        </Text>
      </Flex>
    </Badge>
  ) : null

  return (
    <Box p="s-16" border={`1px solid ${Token.color.greyTone10}`} borderRadius={16}>
      <VStack>
        <Text variant="primary">This employee has not been assigned any goals</Text>
        <Text variant="secondary" color={Token.color.greyTone50}>
          Please assess what impact this person had on the team goals:
        </Text>
        {team}
      </VStack>
    </Box>
  )
}
