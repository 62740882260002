import React, { useEffect, useMemo, useState } from 'react'
import { Text } from '@revolut/ui-kit'
import {
  getPerformanceTimeline,
  getPerformanceTimelineChart,
  getPerformanceTimelineStats,
} from '@src/api/performance'
import { getInverseColorsAndGrade } from '@components/PerformanceGrade/PerformanceGrade'
import { useTable } from '@components/Table/hooks'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  FinalGradeToGraphNumber,
  PerformanceRatingToGraphNumber,
  PerformanceTimelineChartInterface,
  PerformanceTimelineInterface,
  PerformanceTimelineStats,
} from '@src/interfaces/performance'
import useChartTableSwitcher from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { pushError } from '@src/store/notifications/actions'
import { RevolutTheme, useTheme } from '@src/styles/theme'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'

export enum ToggleSecondaryTabs {
  Grades = 'Grades',
  Deliverables = 'Deliverables',
  Skills = 'Skills',
}

export const getLatestGrade = (theme: RevolutTheme, stats?: PerformanceTimelineStats) => {
  if (!stats?.latest_grade) {
    return 'N/A'
  }
  const grade = getInverseColorsAndGrade(theme, stats?.latest_grade.id)
  return grade ? <Text color={grade.color}>{grade.grade}</Text> : undefined
}

export const usePerformanceTimelineTable = (data: EmployeeInterface) => {
  const theme = useTheme()
  const tableApi = useMemo(() => {
    return {
      getItems: () => getPerformanceTimeline(data.id),
      getStats: () => getPerformanceTimelineStats(data.id),
    }
  }, [data.id])
  const table = useTable<PerformanceTimelineInterface, PerformanceTimelineStats>(
    tableApi,
    undefined,
    undefined,
    { refetchOnApiChange: true },
  )

  useEffect(() => {
    if (table.fetchError && table.fetchError.response?.status !== 403) {
      pushError({ error: table.fetchError })
    }
  }, [table.fetchError])

  if (table.fetchError?.response?.status === 403) {
    return { error: true as const }
  }

  return {
    table,
    tableStats: table.stats,
    latestGrade: getLatestGrade(theme, table.stats),
    error: false as const,
  }
}

export const useTableSwitchers = (performanceSettings?: PerformanceSettingsInterface) => {
  const { tabBar, currentTab } = useChartTableSwitcher({
    showTableFirst: true,
    showTableByDefault: true,
  })

  const subtabs = useMemo<ToggleSecondaryTabs[]>(() => {
    if (currentTab === 'Chart' && performanceSettings?.enable_skill_assessment) {
      return [
        ToggleSecondaryTabs.Grades,
        ToggleSecondaryTabs.Deliverables,
        ToggleSecondaryTabs.Skills,
      ]
    }
    if (currentTab === 'Chart' && !performanceSettings?.enable_skill_assessment) {
      return [ToggleSecondaryTabs.Grades, ToggleSecondaryTabs.Deliverables]
    }
    return []
  }, [performanceSettings?.enable_skill_assessment, currentTab])

  const { tabBar: secondaryTabBar, currentTab: currentSecondaryTab } = useTabBarSwitcher({
    tabs: subtabs,
    defaultTab: ToggleSecondaryTabs.Grades,
    highlightSelected: false,
  })

  return {
    tabBar,
    currentTab,
    secondaryTabBar,
    currentSecondaryTab,
  }
}

export const usePerformanceChartData = (
  data: EmployeeInterface,
  checkPermission: boolean = false,
) => {
  const [chartData, setChartData] = useState<PerformanceTimelineChartInterface>({
    grades: {
      progress_history: [],
      targets: [],
      values: [],
    },
    deliverables: {
      progress_history: [],
      targets: [],
      values: [],
    },
    skills: {
      progress_history: [],
      targets: [],
      values: [],
    },
  })
  const featureFlags = useSelector(selectFeatureFlags)
  const canViewPerformanceHistoryTab = !!data?.field_options?.permissions?.includes(
    PermissionTypes.CanViewPerformanceHistoryTab,
  )
  const canViewPerformanceHistory =
    featureFlags.includes(FeatureFlags.PerformanceHistory) && canViewPerformanceHistoryTab

  const mappedChartData = useMemo(
    () => ({
      grades: {
        ...chartData.grades,
        progress_history: chartData.grades?.values?.map(bar => ({
          ...bar,
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          expected: bar.expected && FinalGradeToGraphNumber[bar.expected],
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          progress: bar.progress && FinalGradeToGraphNumber[bar.progress.id],
        })),
      },
      deliverables: {
        ...chartData.deliverables,
        progress_history: chartData.deliverables?.values.map(bar => ({
          ...bar,
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          expected: bar.expected && PerformanceRatingToGraphNumber[bar.expected],
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          progress: bar.progress && PerformanceRatingToGraphNumber[bar.progress],
        })),
      },
      skills: {
        ...chartData.skills,
        progress_history: chartData.skills?.values.map(bar => ({
          ...bar,
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          expected: bar.expected && PerformanceRatingToGraphNumber[bar.expected],
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          progress: bar.progress && PerformanceRatingToGraphNumber[bar.progress],
        })),
      },
    }),
    [chartData],
  )

  const fetchChartData = async () => {
    const result = await getPerformanceTimelineChart(data.id)

    if (result?.data) {
      const { grades, deliverables, skills } = result.data

      setChartData(prevData => ({
        ...prevData,
        grades: grades ? { ...prevData.grades, ...grades } : prevData.grades,
        deliverables: deliverables
          ? { ...prevData.deliverables, ...deliverables }
          : prevData.deliverables,
        skills: skills ? { ...prevData.skills, ...skills } : prevData.skills,
      }))
    }
  }

  useEffect(() => {
    if (!checkPermission || canViewPerformanceHistory) {
      fetchChartData()
    }
  }, [])

  return { mappedChartData, setChartData }
}
