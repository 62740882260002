import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { BREAKPOINTS } from '@revolut/ui-kit'
import Navigation from './Navigation'
import { useParams } from 'react-router-dom'
import { getBulkEditItems, useBulkEdit } from '@src/api/contractsBulkEdit'
import PageLoading from '@src/components/PageLoading/PageLoading'
import TrackProgressWidget from '@src/components/BulkEdit/TrackProgressWidget'
import { TableNames } from '@src/constants/table'
import TrackProgressPageActions from '@src/components/BulkEdit/TrackProgressPageActions'
import { ROUTES } from '@src/constants/routes'

const TrackProgress = () => {
  const params = useParams<{ id: string }>()
  const { id } = params

  const { data: bulkEditData } = useBulkEdit(id)

  const isDone = bulkEditData?.status.name === 'completed'

  if (!bulkEditData) {
    return <PageLoading />
  }

  const selectedIds = bulkEditData?.object_ids || []

  return (
    <>
      <PageBody pb="s-32" maxWidth={BREAKPOINTS.xl}>
        <Navigation checkAll={isDone} selectedIds={selectedIds} />
        <TrackProgressWidget
          getItems={getBulkEditItems}
          useBulkEdit={useBulkEdit}
          tableName={TableNames.ContractsBulkEdit}
        />
      </PageBody>
      <TrackProgressPageActions closeRoute={ROUTES.APPS.CONTRACTS.ANY} />
    </>
  )
}

export default TrackProgress
