import {
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  Popup,
  Spacer,
  Text,
  TextArea,
  Token,
} from '@revolut/ui-kit'
import { getStatusColor } from '@src/components/CommonSC/General'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { Statuses } from '@src/interfaces'
import { GoalsInterface } from '@src/interfaces/goals'
import React, { useState } from 'react'

type GoalStatus = GoalsInterface['status']

export const SetFinalProgress = ({
  onSubmit,
  onCancel,
}: {
  onSubmit: (
    status: Statuses,
    calibratedProgress: number,
    comment: string,
  ) => Promise<unknown>
  onCancel: () => void
}) => {
  const [status, setStatus] = useState<GoalStatus>()
  const [progress, setProgress] = useState<number>()
  const [comment, setComment] = useState<string>()
  const statuses = [
    {
      id: Statuses.on_track,
      value: { id: Statuses.on_track, name: 'On track' },
      key: Statuses.on_track,
    },
    {
      id: Statuses.at_risk,
      value: { id: Statuses.at_risk, name: 'At risk' },
      key: Statuses.at_risk,
    },
    {
      id: Statuses.delayed,
      value: { id: Statuses.delayed, name: 'Delayed' },
      key: Statuses.delayed,
    },
    {
      id: Statuses.on_hold,
      value: { id: Statuses.on_hold, name: 'On hold' },
      key: Statuses.on_hold,
    },
    {
      id: Statuses.complete,
      value: { id: Statuses.complete, name: 'Complete' },
      key: Statuses.complete,
    },
  ]
  return (
    <>
      <Text variant="h5">Set final progress for the goal</Text>
      <Spacer height="s-8" />
      <Text variant="caption" color={Token.color.greyTone50}>
        Calibrate the status and progress
      </Text>
      <Spacer height="s-24" />
      <InputGroup>
        <RadioSelectInput<GoalStatus>
          inputProps={{
            renderPrefix: status
              ? () => <Icon name="Dot" color={getStatusColor(status.id)} />
              : undefined,
          }}
          value={status}
          options={statuses}
          onChange={option => setStatus(option || undefined)}
          label="Status"
          required
        >
          {option => (
            <Flex alignItems="center">
              <Icon name="Dot" color={getStatusColor(option.value.id)} />
              <Text>{option.value.name}</Text>
            </Flex>
          )}
        </RadioSelectInput>
        <Input
          label="Current progress (%)"
          value={progress}
          onChange={ev => setProgress(Number(ev.currentTarget.value))}
          required
        />
        <TextArea
          rows={2}
          label="Comment"
          value={comment}
          onChange={ev => setComment(ev.currentTarget.value)}
          required
        />
      </InputGroup>
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          elevated
          onClick={() => onSubmit(status!.id, (progress || 0) / 100, comment!)}
          disabled={!status || !comment || typeof progress === 'undefined'}
        >
          Submit
        </Button>
      </Popup.Actions>
    </>
  )
}
