import {
  Bullet,
  DetailsCell,
  HStack,
  Token,
  VStack,
  Text,
  IconButton,
  Box,
  Spinner,
  Icon,
} from '@revolut/ui-kit'
import { InfoIconWithTooltip } from '@src/components/Icon/InfoIconWithTooltip'
import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

export const BorderedCell = styled(DetailsCell)`
  border: 1px solid ${Token.color.greyTone10};
  padding-bottom: 0;
`

// UI-kit expandable cell mount hidden content only when expanded
// this approach doesn't work well when we need persistent state or mounting has async tasks and loading state
// perhaps ui-kit may add such persistent variant for the ExpandableCell component
// thread is started in slack: https://team-revolut.slack.com/archives/CDXMY0GF9/p1710435853768639
export const PersistentExpandableCell = ({
  title,
  description,
  index,
  onDelete,
  side,
  expanded,
  onToggle,
  children,
  hasFormErrors,
}: PropsWithChildren<{
  title: ReactNode
  description: ReactNode
  index: number
  side: ReactNode
  onDelete?: () => Promise<void>
  expanded: boolean
  onToggle: () => void
  hasFormErrors?: boolean
}>) => {
  const [mounted, setMounted] = useState(expanded)

  useEffect(() => {
    if (expanded && !mounted) {
      setMounted(true)
    }
  }, [expanded])

  return (
    <BorderedCell>
      <CellHeader
        index={index}
        title={title}
        description={description}
        onDelete={onDelete}
        side={side}
        expanded={expanded}
        onToggle={onToggle}
        hasFormErrors={hasFormErrors}
      />
      <DetailsCell.Note height={expanded ? 'auto' : 0} overflow="hidden">
        <Box mt="s-16" mb="s-16">
          {mounted && children}
        </Box>
      </DetailsCell.Note>
    </BorderedCell>
  )
}

export function CellHeader({
  index,
  title,
  description,
  side,
  expanded,
  onToggle,
  onDelete,
  hasFormErrors,
}: {
  index: number
  title: ReactNode
  description?: ReactNode
  side?: ReactNode
  expanded: boolean
  onToggle: () => void
  onDelete?: () => Promise<void>
  hasFormErrors?: boolean
}) {
  const [pending, setPending] = useState(false)

  const handleDelete = onDelete
    ? async () => {
        try {
          setPending(true)
          await onDelete()
        } finally {
          setPending(false)
        }
      }
    : undefined

  const deleteAction = pending ? (
    <Spinner size={16} />
  ) : (
    <IconButton
      size={18}
      useIcon="Delete"
      onClick={e => {
        e.stopPropagation()
        handleDelete?.()
      }}
      color={Token.color.greyTone20}
    />
  )

  return (
    <>
      <DetailsCell.Title onClick={onToggle} style={{ cursor: 'pointer' }}>
        <HStack space="s-12" align="center" minHeight={44}>
          <Bullet
            color={Token.color.white}
            variant="filled"
            backgroundColor={Token.color.greyTone20}
            size={18}
          >
            {index}
          </Bullet>
          <VStack>
            <Text variant="primary" color={Token.color.foreground}>
              {title}
            </Text>
            {!!description && (
              <Text variant="caption" color={Token.color.greyTone50}>
                {description}
              </Text>
            )}
          </VStack>
        </HStack>
      </DetailsCell.Title>

      <DetailsCell.Content onClick={onToggle} style={{ cursor: 'pointer' }}>
        <HStack space="s-24" align="center" height="100%" minHeight={44}>
          {side && <Box>{side}</Box>}
          <HStack space="s-8" align="center" height="100%">
            {hasFormErrors && (
              <InfoIconWithTooltip
                content="This target is invalid"
                color={Token.color.red}
              />
            )}
            <Icon
              name={expanded ? 'ChevronUp' : 'ChevronDown'}
              color={Token.color.greyTone20}
            />
            {!!handleDelete && deleteAction}
          </HStack>
        </HStack>
      </DetailsCell.Content>
    </>
  )
}
