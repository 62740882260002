import React, { useMemo } from 'react'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { Box, chain, Flex, Icon, Text, Token } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Id } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { HiringStageJobPostings } from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/HiringStageJobPostings'

type Props = {
  candidateId: number
  roundId: number
  mainRoundId?: number
  onChange: (roundId: number) => void
}

type OptionType = InterviewRoundInterface | Id<string>

export const HiringStageSwitcher = ({
  candidateId,
  roundId,
  mainRoundId,
  onChange,
}: Props) => {
  const { data, isLoading } = useGetFullInterviewRounds(candidateId || null)

  const value = data?.find(item => item.id === roundId)

  const options = useMemo<RadioSelectOption<OptionType>[]>(() => {
    const list = (data || []).reduce<{
      active: InterviewRoundInterface[]
      archived: InterviewRoundInterface[]
    }>(
      (acc, item) => {
        if (item.archived_reason) {
          acc.archived.push(item)
        } else {
          acc.active.push(item)
        }

        return acc
      },
      { active: [], archived: [] },
    )

    return [...list.active, { id: 'new', value: { id: 'new' } }, ...list.archived].map(
      item => ({
        id: item.id,
        value: item,
      }),
    )
  }, [data])

  const renderOption = (item: OptionType) =>
    'archived_reason' in item ? (
      <>
        <Text
          variant="h6"
          color={item.archived_reason ? Token.color.greyTone50 : undefined}
        >
          {chain(
            item.specialisation.name,
            mainRoundId === item.id && <Text color={Token.color.green}>Main</Text>,
            !!item.archived_reason && <Text>Archived</Text>,
          )}
        </Text>
        {item.archived_reason && (
          <Box>
            <Text variant="small" color={Token.color.greyTone50}>
              {chain(
                item.latest_interview_stage_updated_date_time &&
                  formatDate(item.latest_interview_stage_updated_date_time),
                item.archived_reason.name,
              )}
            </Text>
          </Box>
        )}
        {item.latest_interview_stage && (
          <Box>
            <Text variant="small" color={Token.color.greyTone50}>
              {item.latest_interview_stage.interview_type_display}&nbsp;
              {!!item.stage_count &&
                Number.isFinite(item.latest_interview_stage.interview_number) &&
                `(${item.latest_interview_stage.interview_number}/${item.stage_count})`}
            </Text>
          </Box>
        )}
        <HiringStageJobPostings
          round={item}
          textVariant="small"
          labelVariant="small"
          textColor={Token.color.greyTone50}
        />
      </>
    ) : (
      <>
        <Flex alignItems="center" color={Token.color.blue}>
          <Icon name="Plus" size={16} color={Token.color.blue} />
          &nbsp;Consider for another opportunity
        </Flex>
      </>
    )

  return (
    <RadioSelectInput<OptionType>
      label="Opportunity"
      value={value}
      labelPath="specialisation.name"
      searchable={false}
      onChange={item => {
        if (item && 'specialisation' in item) {
          onChange(item.id)
        } else {
          navigateTo(
            pathToUrl(ROUTES.FORMS.ADD_INTERVIEW_ROUND.GENERAL, {
              candidateId,
            }),
          )
        }
      }}
      loading={isLoading}
      disabled={isLoading}
      options={options}
      allowCreateNewOption
      referenceUrl={
        value?.specialisation
          ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
              id: value.specialisation.id,
            })
          : undefined
      }
    >
      {option => renderOption(option.value)}
    </RadioSelectInput>
  )
}
