import React from 'react'
import { FeatureFlags, GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import { Location } from 'history'
import { IconName } from '@revolut/ui-kit'

export interface TabBarWithPinningTabConfig {
  id?: HomeSectionId | 'dashboard'
  title: string
  path?: string | string[]
  icon?: IconName
  url: string
  urlDescriptor?: (locaiton: Location) => string | Location
  featureFlag?: FeatureFlags
  permissions?: PermissionTypes[]
  globalSetting?: GlobalSettings
  component?: React.ComponentType
  subtabs?: Subtab[]
  quickSummary?: React.ReactNode
  /** To group by category in PinTabsSidebar */
  category?: string
}

export interface Subtab {
  id: string
  path: string
  to: string
  title: string
  component: React.ComponentType
  globalSetting?: GlobalSettings
  permission?: PermissionTypes
  featureFlag?: FeatureFlags
  homeSectionId?: HomeSectionId
}

export interface TabBarWithPinningTabInterface {
  id: HomeSectionId
  title: string
  path?: string | string[]
  icon?: IconName
  url: string
  permission?: PermissionTypes
  featureFlag?: FeatureFlags
  globalSetting?: GlobalSettings
  component?: React.ComponentType
  subtabs?: Subtab[]
}

export enum HomeSectionId {
  employees = 'employees',
  probation = 'probation',
  pip = 'pip',
  contractTypes = 'contractTypes',
  contracts = 'contracts',
  offboarding = 'offboarding',
  onboarding = 'onboarding',
  resignations = 'resignations',
  documents = 'documents',
  notifications = 'notifications',
  timeManagement = 'time-management',
  requisitions = 'requisitions',
  candidates = 'candidates',
  crm = 'crm',
  interviews = 'interviews',
  bulkActions = 'bulk-actions',
  jobPostings = 'job-postings',
  hiringStages = 'hiring-stages',
  interviewScorecardTemplates = 'interview-scorecard-templates',
  hiringPipelines = 'hiring-pipelines',
  offerForms = 'offer-forms',
  teams = 'teams',
  departments = 'departments',
  specialisations = 'specialisations',
  roles = 'roles',
  functions = 'functions',
  company = 'company',
  payrollPayCycles = 'payroll-pay-cycles',
  payrollPayGroups = 'payroll-pay-groups',
  payrollPayments = 'payroll-payments',
  accessManagementUsers = 'access-management-users',
  accessManagementGroups = 'access-management-groups',
  accessManagementPermissions = 'access-management-permissions',
  communicationGroups = 'communication-groups',
  communicationEmailTemplates = 'communication-email-templates',
  keyPersonsResponsibilities = 'key-persons-responsibilities',
  keyPersonsRiskProfiles = 'key-persons-risk-profiles',
  keyPersonsSuccessionPlans = 'key-persons-succession-plans',
  kpis = 'kpis',
  kpisTemplates = 'kpis-templates',
  kpisRules = 'kpis-rules',
  skillsSkills = 'skills-skills',
  skillsValues = 'skills-values',
  performanceReviewCycles = 'performance-review-cycles',
  performanceReviewSupportTool = 'performance-review-support-tool',
  employeesCustomFields = 'employees-custom-fields',
  employeesSeniorities = 'employees-seniorities',
  attendanceSchedule = 'attendance-schedule',
  attendanceShifts = 'attendance-shifts',
  attendanceCompensation = 'attendance-compensation',
  attendancePolicies = 'attendance-policies',
  compensationBands = 'compensation-bands',
  compensationBudgetPools = 'compensation-budget-pools',
  compensationReviewCycles = 'compensation-review-cycles',
  compensationBonuses = 'compensation-bonuses',
  compensationSettings = 'compensation-settings',
  compensationOptionExercise = 'option-exercise',
  benefitEnrolment = 'benefit-enrolment',
  benefitTemplates = 'benefit-templates',
  engagementSurveys = 'engagement-surveys',
  engagementDrivers = 'engagement-drivers',
  engagementQuestions = 'engagement-questions',
  candidatesDataRetentionSettings = 'candidates-data-retention-settings',
  candidatesEmailTemplates = 'candidates-email-templates',
  candidatesReferralBonus = 'candidates-referral-bonus',
  hiringProcessRecruitmentGroups = 'hiring-process-recruitment-groups',
  offersAll = 'offers-all',
  offersPlaceholders = 'offers-placeholders',
  offersTemplates = 'offers-templates',
  dataRetentionEmploymentPolicies = 'data-retention-employment-policies',
  dataRetentionRecruitmentPolicies = 'data-retention-recruitment-policies',
  allScreeningChecks = 'all-screening-checks',
  reportingAppReports = 'reporting-app-reports',
  reportingAppQueries = 'reporting-app-queries',
  reportingAppConnections = 'reporting-app-connections',
  bookingLinks = 'booking-links',
  supportFaq = 'support-faq',
  supportFaqTopics = 'support-faq-topics',
}
