import { ROUTES } from '@src/constants/routes'
import { ReviewCategory } from '@src/interfaces/performance'
import { pathToUrl } from '@src/utils/router'
import { Location } from 'history'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

export const getReviewsUrl = (
  category?: ReviewCategory | ReviewCycleCategory,
  cycleId?: string,
  employeeId?: number,
  location?: Location<unknown>,
  showNewScorecards?: boolean,
) => {
  let route = ''
  switch (category) {
    case ReviewCategory.Upwards:
      route = ROUTES.FORMS.UPWARDS_REVIEW_VIEW.SKILLS
      break
    case ReviewCategory.Performance:
      route = showNewScorecards
        ? ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW_LAYOUT
        : ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.GENERAL
      break
    case ReviewCategory.Probation:
      route = showNewScorecards
        ? ROUTES.FORMS.PROBATION_REVIEW_VIEW_LAYOUT
        : ROUTES.FORMS.PROBATION_REVIEW_VIEW.DELIVERABLES
      break
    case ReviewCategory.PIP_V2:
      route = showNewScorecards
        ? ROUTES.FORMS.PIP_REVIEW_VIEW_LAYOUT
        : ROUTES.FORMS.PIP_REVIEW_VIEW.DELIVERABLES
      break
  }

  return (
    pathToUrl(route, {
      id: cycleId,
      employeeId,
    }) + (location?.search || '')
  )
}
