import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { teamsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { TeamsIntro } from './TeamsIntro'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { ReviewTeams } from './ReviewTeams'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.TEAMS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.TEAMS.INTRO,
    canView: [PermissionTypes.ViewTeams],
    component: TeamsIntro,
  },
  {
    title: 'Review teams',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.TEAMS.REVIEW,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.TEAMS.REVIEW,
    canView: [PermissionTypes.ViewTeams],
    component: ReviewTeams,
  },
]

export const Teams = () => {
  return <OnboardingChecklistContent config={teamsConfig} routes={routes} />
}
