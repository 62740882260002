import React, { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { navigateTo } from '@src/actions/RouterActions'
import { addRoadmaps, getUnassignedRoadmaps } from '@src/api/roadmaps'
import { EntityTypes } from '@src/constants/api'
import { PERFORMANCE_SERVICE_DESK_LINK } from '@src/constants/externalLinks'
import { ROUTES } from '@src/constants/routes'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { UnassignedRoadmapInterface } from '@src/interfaces/roadmaps'
import SidebarMultiselect, {
  SidebarOption,
} from '@components/Inputs/SidebarMultiselect/SidebarMultiselect'
import {
  Box,
  chain,
  Color,
  Flex,
  HStack,
  Icon,
  IconName,
  List,
  TagBar,
  Text,
  TextButton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { IssueTypes } from '@src/interfaces/deliverables'

interface StructureProps {
  entityType: EntityTypes
  id: number
  isOpen: boolean
  onAfterSubmit: () => void
  onClose: () => void
  reviewCycle: ReviewCyclesInterface | null
  tags?: string[]
}

export const issueIconsByType: Record<IssueTypes, IconName> = {
  [IssueTypes.Epic]: 'JiraEpic|image',
  [IssueTypes.Bug]: 'JiraBug|image',
  [IssueTypes.Story]: 'JiraStory|image',
  [IssueTypes.Task]: 'JiraTask|image',
  [IssueTypes.Subtask]: 'JiraSubtask|image',
  [IssueTypes.Other]: 'JiraOther|image',
  [IssueTypes.SCA]: 'JiraOther|image',
}

export const parseUnassignedRoadmaps = (
  roadmaps: UnassignedRoadmapInterface[],
): SidebarOption<UnassignedRoadmapInterface>[] => {
  return roadmaps.map(roadmap => {
    return {
      id: roadmap.id,
      link: {
        name: roadmap.key,
        href: roadmap.epic_url,
      },
      name: roadmap.epic_name,
      disabled: roadmap.issue_type !== IssueTypes.Epic,
      icon: roadmap.issue_type ? issueIconsByType[roadmap.issue_type] : undefined,
    }
  })
}

const parseKeys = (roadmaps: SidebarOption[]) => {
  return roadmaps.map(option => option?.link?.name || '')
}

const getUrl = (entityType: EntityTypes, params: { id: number }): string => {
  switch (entityType) {
    case EntityTypes.department:
      return pathToUrl(ROUTES.FORMS.DEPARTMENT.SETTINGS, params)
    case EntityTypes.team:
      return pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, params)
    default:
      return ''
  }
}

const emptySearchDescription = (
  <>
    <Box color={Color.GREY_TONE_50}>
      <Text>Can't find the epic you are looking for?</Text>
      <List color={Color.GREY_TONE_50} variant="compact">
        <List.Item>
          1. Make sure your <Text fontWeight="bold">Jira Project</Text> is defined above
        </List.Item>
        <List.Item>
          2. Use the <Text fontWeight="bold">Refresh Now</Text> button to find any
          recently created epics.
        </List.Item>
      </List>
    </Box>
    <HideIfCommercial>
      <Box color={Color.GREY_TONE_50}>
        <Text>Still having problems?</Text>
        <Text use="p">
          Reach out to us on the{' '}
          <Text
            color={Color.BLUE}
            href={PERFORMANCE_SERVICE_DESK_LINK}
            target="_blank"
            textDecoration="none"
            use="a"
          >
            Performance service desk
          </Text>
          .
        </Text>
      </Box>
    </HideIfCommercial>
  </>
)

export const AddRoadmapForm = ({
  entityType,
  id,
  isOpen,
  reviewCycle,
  onAfterSubmit,
  onClose,
  tags,
}: StructureProps) => {
  const [isSearchPending, setIsSearchPending] = useState<boolean>(false)
  const [isSubmitPending, setIsSubmitPending] = useState<boolean>(false)
  const [options, setOptions] = useState<SidebarOption[]>([])

  useEffect(() => {
    if (!isOpen) {
      setOptions([])
    }
  }, [isOpen])

  const fetchOptions = useMemo(
    () =>
      debounce(async (search: string) => {
        const request = getUnassignedRoadmaps(entityType, id, search).then(
          resp => resp.data.results,
        )

        try {
          const roadmaps = await request
          setOptions(parseUnassignedRoadmaps(roadmaps))
        } finally {
          setIsSearchPending(false)
        }
      }, 1000),
    [entityType, id],
  )

  const subtitle = (
    <VStack>
      <HStack align="center" my="s-8" space="s-8">
        <Box>
          <Text variant="primary">Projects:</Text>
        </Box>
        <Box minWidth={0}>
          {tags?.length ? (
            <TagBar>
              {tags.map(tag => (
                <TagBar.Item key={tag} color="grey-tone-50" variant="outlined">
                  {tag}
                </TagBar.Item>
              ))}
            </TagBar>
          ) : (
            <Text variant="primary">-</Text>
          )}
        </Box>
        <Box>
          <TextButton onClick={() => navigateTo(getUrl(entityType, { id }))}>
            Edit
          </TextButton>
        </Box>
      </HStack>
      {!tags?.length && (
        <Text variant="small" color={Token.color.error}>
          Please define the projects keys for search
        </Text>
      )}
    </VStack>
  )

  const descriptionContent = (
    <Text color="grey-tone-50" mb="s-8">{`Epics will be added to the ${
      reviewCycle?.name || ''
    } roadmap`}</Text>
  )

  return (
    <SidebarMultiselect<UnassignedRoadmapInterface>
      dataType="epics"
      description={descriptionContent}
      emptySearchMessage={chain('Showing epics', options.length)}
      emptySearchDescription={emptySearchDescription}
      initialSearchDescription={
        <Flex
          width="100%"
          height="120px"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="s-12"
        >
          <Icon name="Search" size={24} color={Token.color.greyTone50} />
          <Text color={Token.color.greyTone50}>
            Use the search bar to find your Jira epics
          </Text>
        </Flex>
      }
      isOpen={isOpen}
      onClose={onClose}
      onSearch={query => {
        if (query) {
          setIsSearchPending(true)
          fetchOptions(query)
        } else {
          setOptions([])
        }
      }}
      onSubmit={selectedOptions => {
        setIsSubmitPending(true)

        const keys = parseKeys(selectedOptions)
        addRoadmaps(entityType, id, keys, reviewCycle?.id)
          .then(() => {
            onAfterSubmit?.()
            onClose?.()
          })
          .finally(() => setIsSubmitPending(false))
      }}
      options={options}
      optionsPending={isSearchPending}
      submitPending={isSubmitPending}
      submitButtonTitle="Add roadmap"
      subtitle={entityType === EntityTypes.employee ? null : subtitle}
      title="Add roadmap"
    />
  )
}
