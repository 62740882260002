import { NotificationInterface, NotificationTypes } from './types'
import { AxiosError } from 'axios'
import get from 'lodash/get'
import {
  ERROR_DEFAULT_DURATION,
  ERRORS,
  SUCCESS_DEFAULT_DURATION,
} from '@src/constants/notifications'
import React from 'react'
import styled from 'styled-components'
import uniqueId from 'lodash/uniqueId'
import notificationState from './state'
import { Token } from '@revolut/ui-kit'
import { isProd } from '@src/utils'

const Title = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
`

const Error = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`

const A = styled.a`
  font-size: 14px;
  align-self: center;
  color: ${Token.color.foreground};
  text-decoration: ${Token.color.foreground} underline;
`

export const pushNotification = (data: Omit<NotificationInterface, 'id'>) => {
  const notification = {
    ...data,
    id: uniqueId('notification_'),
    open: true,
  }

  // do not push error notifications with the same message/value if one already exists
  if (
    notification.type === 'error' &&
    notificationState.notifications.some(
      ntf =>
        (ntf?.message && ntf.message === notification.message) ||
        ntf?.value === notification.value,
    )
  ) {
    return
  }

  // Little hack to not show all other errors when session expired
  if (
    !notificationState.notifications.some(ntf => ntf?.value === ERRORS.SESSION_EXPIRED)
  ) {
    notificationState.notifications.push(notification)
  }
}

export const closeNotification = (id: string) => {
  const notificationIndex = notificationState.notifications.findIndex(
    ntf => ntf?.id === id,
  )
  if (notificationIndex !== -1) {
    notificationState.notifications.splice(notificationIndex, 1)
  }
}

export const getMessageFromError = (
  error?: AxiosError,
  defaultMessage = ERRORS.UNKNOWN_REFRESH,
) => {
  if (!error) {
    return defaultMessage
  }

  const dataArrayMessage =
    typeof get(error, 'response.data.0') === 'string'
      ? get(error, 'response.data.0')
      : null

  return (
    get(error, 'response.data.message') ||
    get(error, 'response.data.detail') ||
    get(error, 'response.data.name') ||
    dataArrayMessage ||
    error.message ||
    defaultMessage
  )
}

export const getStringMessageFromError = (
  error: AxiosError,
  defaultMessage = ERRORS.UNKNOWN_REFRESH,
) => {
  const apiError = getMessageFromError(error, defaultMessage)

  return typeof apiError === 'string' ? apiError : defaultMessage
}

export const pushError = (
  data: { message?: string; error?: AxiosError<any> },
  disableNotification: boolean = false,
) => {
  const { message, error } = data

  /** To handle errors for requests with responseType === 'arraybuffer' */
  if (error?.response?.data instanceof ArrayBuffer) {
    try {
      error.response.data = JSON.parse(
        String.fromCharCode.apply(null, [...new Uint8Array(error.response.data)]),
      )
    } catch {
      /** Do nothing */
    }
  }

  const notification: Omit<NotificationInterface, 'id'> & { disabled: boolean } = {
    type: NotificationTypes.error,
    value: message || getMessageFromError(error),
    duration: ERROR_DEFAULT_DURATION,
    disabled: disableNotification,
  }

  switch (get(error, 'response.status')) {
    case 403:
      if (
        get(error, 'response.data.detail') === 'User not authenticated' ||
        get(error, 'response.data.detail') === 'Session expired' ||
        get(error, 'response.data.detail') === 'Invalid token'
      ) {
        notificationState.shouldLogOut = true
        notification.value = ERRORS.SESSION_EXPIRED
      } else {
        notification.value =
          error?.response?.data?.detail || ERRORS.NOT_ENOUGH_PERMISSIONS
      }
      break
    case 400:
      notification.duration = 999999999
      notification.message = JSON.stringify(error?.response?.data, undefined, 2).replace(
        /\[|\]|"|{|}|_|:/g,
        ' ',
      )
      notification.value = (
        <div data-testid="error-notification">
          <Title>{ERRORS.BAD_REQUEST}:</Title>
          <Error>{notification.message}</Error>
        </div>
      )

      if (error?.response?.data?.url && error.response.data?.url_title) {
        notification.message = error.response.data?.detail
        notification.value = (
          <div>
            <Title>{ERRORS.BAD_REQUEST}:</Title>
            <Error>
              {notification.message}
              {` `}
              {
                <A href={error.response.data.url} target="_blank">
                  {error.response.data.url_title}
                </A>
              }
            </Error>
          </div>
        )
      }
      break
    case 500:
      if (isProd()) {
        notification.disabled = true
      } else {
        notification.duration = 999999999
        notification.message =
          error?.message || JSON.stringify(error?.response, undefined, 2)
        notification.value = (
          <div>
            <Title>{ERRORS.UNKNOWN}:</Title>
            <Error>{notification.message}</Error>
          </div>
        )
      }
      break
    case 502:
      notification.value = ERRORS.UNKNOWN_REFRESH
      break
    case 503:
      notification.value = ERRORS.TOO_MANY_REQUESTS
      break
    case 401:
      notificationState.shouldLogOut = true
      notification.value = ERRORS.SESSION_EXPIRED
      break
    default:
      break
  }
  if (!notification.disabled) {
    pushNotification(notification)
  }
}

export const successNotification = (message: string, backUrl?: string) => {
  pushNotification({
    value: message,
    duration: SUCCESS_DEFAULT_DURATION,
    type: NotificationTypes.success,
    backUrl,
  })
}
