import { LinkedInPosting } from '@src/interfaces/jobPosting'
import { Statuses } from '@src/interfaces'

export const isRejected = ({ status }: LinkedInPosting) => status === Statuses.rejected

export const isPending = ({ status }: LinkedInPosting) => status === Statuses.pending

export const isPublished = ({ status }: LinkedInPosting) => status === 'published'

export const isPromoted = ({ is_promoted, status }: LinkedInPosting) =>
  is_promoted && status === 'published'

export const isBasic = ({ is_promoted, status }: LinkedInPosting) =>
  !is_promoted && status === 'published'

export const isClosed = ({ status }: LinkedInPosting) => status === Statuses.closed
