import React from 'react'

import {
  GenericEditableTableColumn,
  GenericEditableTableOnChange,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import { TeamInterface } from '@src/interfaces/teams'
import { selectorKeys } from '../api'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { IdAndName } from '@src/interfaces'
import { ImportInterface } from '@src/interfaces/bulkDataImport'

type OnboardingTeamsColumn = GenericEditableTableColumn<TeamInterface>

type OnboardingTeamsWithCreateNewColumn = (
  onChange: GenericEditableTableOnChange,
  onCreate: (id: number, onChangeAction: (newEntity: IdAndName) => void) => void,
) => ColumnInterface<ImportInterface<TeamInterface>>

export const onboardingTeamsNameColumn: OnboardingTeamsColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'team__name',
  filterKey: 'id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="name" />,
})

export const onboardingTeamsDepartmentColumn: OnboardingTeamsWithCreateNewColumn = (
  onChange,
  onCreate,
) => ({
  type: CellTypes.insert,
  idPoint: 'department.id',
  dataPoint: 'department.name',
  sortKey: 'department__name',
  filterKey: 'department__id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="department"
      selector={selectorKeys.department}
      onCreateNewClick={onCreate}
    />
  ),
})

export const onboardingTeamsHeadcountColumn: OnboardingTeamsColumn = () => ({
  type: CellTypes.text,
  idPoint: 'data.headcount',
  dataPoint: 'data.headcount',
  sortKey: 'headcount',
  filterKey: 'headcount',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
})

export const onboardingTeamsOwnerColumn: OnboardingTeamsColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'team_owner.id',
  dataPoint: 'team_owner.full_name',
  sortKey: 'team_owner__full_name',
  filterKey: 'team_owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="team_owner"
      selector={selectorKeys.employee}
      customLabel={
        data.data.team_owner ? (
          <UserWithAvatar {...data.data.team_owner} disableLink />
        ) : (
          '-'
        )
      }
    />
  ),
})
