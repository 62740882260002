import React from 'react'
import { VStack } from '@revolut/ui-kit'
import { Video } from '@components/Video/Video'

type Props = {
  videoUrl?: string
  videoPosterUrl?: string
  children: React.ReactNode
}

export const OnboardingIntro = ({ videoUrl, videoPosterUrl, children }: Props) => {
  return (
    <VStack gap="s-16">
      {videoUrl && <Video url={videoUrl} poster={videoPosterUrl} />}
      {children}
    </VStack>
  )
}
