import React, { useState } from 'react'
import {
  Box,
  Bullet,
  Button,
  Flex,
  HStack,
  List,
  Spinner,
  Subheader,
  Token,
  VStack,
  Widget,
  Text,
} from '@revolut/ui-kit'
import { Download, InfoOutline } from '@revolut/icons'
import { isArray } from 'lodash'

import FileUploader from '@components/Inputs/FileUploader/FileUploader'
import { getImportFileTemplate, importFile } from '@src/api/bulkDataImport'
import { BulkDataImportHistory } from '@src/features/BulkDataImport/BulkDataImportHistory'
import { saveFile } from '@src/utils'
import { handleError } from '@src/api'
import pluralize from 'pluralize'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ImportDataCategory } from '@src/interfaces/bulkDataImport'

const useImportFile = (
  endpoint: string,
  path: string,
  name: string,
  category: ImportDataCategory,
  params?: Record<string, string>,
) => {
  const [importPending, setImportPending] = useState(false)
  const [downloadTemplatePending, setDownloadTemplatePending] = useState(false)
  const [error, setError] = useState<string>()

  const onFileUpload = async (file: File | File[] | null) => {
    if (file) {
      setImportPending(true)
      setError(undefined)

      try {
        const response = await importFile(
          isArray(file) ? file[0] : file,
          endpoint,
          category,
        )
        navigateReplace(pathToUrl(path, { id: response.data.id }))
      } catch (e) {
        if (e?.response?.data?.file) {
          setError(e?.response?.data?.file as string)
        } else {
          handleError(e)
        }
        setImportPending(false)
      }
    }
  }

  const onDownloadTemplate = async () => {
    setDownloadTemplatePending(true)
    try {
      saveFile(await getImportFileTemplate(endpoint, params), name)
    } finally {
      setDownloadTemplatePending(false)
    }
  }

  return {
    importPending,
    downloadTemplatePending,
    onFileUpload,
    onDownloadTemplate,
    error,
  }
}

type HowToUploadGuideProps = {
  importName: string
}

export const HowToUploadGuide = ({ importName }: HowToUploadGuideProps) => {
  return (
    <Box>
      <Subheader>
        <Subheader.Title>How to guide</Subheader.Title>
      </Subheader>
      <List use="ol" variant="compact" color={Token.color.greyTone50}>
        <List.Item useIcon={<>1.</>}>
          Use our {importName} template by clicking “Download template”
        </List.Item>
        <List.Item useIcon={<>2.</>}>
          Fill in mandatory columns, provide optional information if necessary
        </List.Item>
        <List.Item useIcon={<>3.</>}>
          Upload your file by “Drag & drop or Click to attach document”
        </List.Item>
      </List>
    </Box>
  )
}

export interface BulkDataImportUploadFileProps {
  category: ImportDataCategory
  apiEndpoint: string
  name: string
  nextRoute: string
}

export const BulkDataImportUploadFile = ({
  category,
  apiEndpoint,
  name,
  nextRoute,
}: BulkDataImportUploadFileProps) => {
  const {
    importPending,
    downloadTemplatePending,
    onFileUpload,
    onDownloadTemplate,
    error,
  } = useImportFile(apiEndpoint, nextRoute, name, category)
  const importName = pluralize(name)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <>
      <VStack space="s-16">
        <HowToUploadGuide importName={importName} />
        <Widget p="s-24">
          <VStack>
            <HStack gap="s-16">
              {!!onDownloadTemplate && (
                <Button
                  onClick={onDownloadTemplate}
                  variant="secondary"
                  pending={downloadTemplatePending}
                  size="sm"
                  mb="s-16"
                  useIcon={Download}
                >
                  Download template
                </Button>
              )}
              <Button
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen)
                }}
                variant="secondary"
                size="sm"
                mb="s-16"
                useIcon={InfoOutline}
              >
                Show history
              </Button>
            </HStack>
            {importPending ? (
              <Flex justifyContent="center">
                <Spinner />
              </Flex>
            ) : (
              <FileUploader
                label={`CSV/XLSX attachment`}
                onChange={onFileUpload}
                hideOptional
                name="file"
                error={error}
              />
            )}
          </VStack>
        </Widget>
      </VStack>

      {isSidebarOpen && (
        <BulkDataImportHistory
          endpoint={apiEndpoint}
          name={importName}
          nextRoute={nextRoute}
          isOpen={isSidebarOpen}
          onClose={() => {
            setIsSidebarOpen(false)
          }}
        />
      )}
    </>
  )
}

export interface BulkDataImportUploadFileV2Props {
  category: ImportDataCategory
  apiEndpoint: string
  name: string
  nextRoute: string
  templateParams?: Record<string, string>
}

export const BulkDataImportUploadFileV2 = ({
  category,
  apiEndpoint,
  name,
  nextRoute,
  templateParams,
}: BulkDataImportUploadFileV2Props) => {
  const {
    importPending,
    downloadTemplatePending,
    onFileUpload,
    onDownloadTemplate,
    error,
  } = useImportFile(apiEndpoint, nextRoute, name, category, templateParams)
  const importName = pluralize(name)

  return (
    <Widget p="s-24">
      <VStack space="s-20">
        <HStack space="s-16">
          <Bullet variant="filled">1</Bullet>

          <VStack space="s-8">
            <Text>Use our {importName} data template</Text>
            <Button
              onClick={onDownloadTemplate}
              pending={downloadTemplatePending}
              size="sm"
              useIcon={Download}
            >
              Download template
            </Button>
          </VStack>
        </HStack>

        <HStack space="s-16">
          <Bullet variant="filled">2</Bullet>
          <Text>
            <b>Fill in the columns</b>, leverage the provided inputs when possible
          </Text>
        </HStack>

        <HStack space="s-16">
          <Bullet variant="filled">3</Bullet>
          <Text>
            <b>Upload your file</b> by “drag & drop or Click to attach document”
          </Text>
        </HStack>

        {importPending ? (
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <FileUploader
            label={`CSV/XLSX attachment`}
            onChange={onFileUpload}
            hideOptional
            name="file"
            error={error}
            showDropAreaImage
            noDropAreaHighlight
            attachButtonText="Drag & drop or click to upload file"
          />
        )}
      </VStack>
    </Widget>
  )
}
