import React from 'react'
import { Color, IconButton, Flex, Text } from '@revolut/ui-kit'
import { Message } from '@revolut/icons'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import {
  PerformanceRatingToGraphNumber,
  PerformanceTimelineInterface,
  Ratings,
  ReviewersScoresInterface,
  SkillCardInterface,
  SkillsReviewsInterface,
  SummarySkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { MatrixColumnInterface } from '@components/Table/MatrixTable'
import { ratingToColor } from '@src/utils/performance'
import { selectorKeys } from '../api'
import { PerformanceRatingTitle } from '../performance'
import styled from 'styled-components'
import { Grid } from '@src/components/CommonSC/Grid'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { Statuses } from '@src/interfaces'
import { getColor } from '@src/styles/colors'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import EmployeePerformanceChart from '@components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import Icon from '@components/Icon/Icon'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { RatingLabelTypes } from '@src/features/EmployeePerformance/RatingLabel'
import RatingTooltip from '@src/pages/EmployeeProfile/Preview/Performance/Common/RatingTooltip'
import { getInverseColorsAndGrade } from '@src/utils/grades'

export const performanceCriteriaColumn: MatrixColumnInterface<
  SkillCardInterface | SummarySkillCardInterface | ValueBasedCardInterface
> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  title: 'Criteria',
}

export const performanceSkillsColumn: MatrixColumnInterface<
  SkillCardInterface | SummarySkillCardInterface | ValueBasedCardInterface
> = {
  ...performanceCriteriaColumn,
  title: 'Skills',
}

export const performanceRatingColumn: MatrixColumnInterface<
  SkillCardInterface | SummarySkillCardInterface | ValueBasedCardInterface
> = {
  type: CellTypes.insert,
  idPoint: 'rating',
  dataPoint: 'rating',
  title: 'Rating',
  background: (data, theme) => {
    return ratingToColor(theme, data.rating)
  },
}

export const performanceNotesColumn: MatrixColumnInterface<
  SkillCardInterface | SummarySkillCardInterface | ValueBasedCardInterface
> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  title: 'Notes',
}

export const performanceTimelineNameColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.text,
    idPoint: 'stage',
    dataPoint: 'stage',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Cycle',
  }

export const performanceGradeColumn: ColumnInterface<PerformanceTimelineInterface> = {
  type: CellTypes.insert,
  idPoint: 'grade',
  dataPoint: 'grade',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Grade',
  insert: ({ data }) => {
    const grade = getInverseColorsAndGrade(data.grade)

    if (!grade) {
      return null
    }

    return <Text color={grade.color}>{data.grade.label}</Text>
  },
}

export const performanceDeliverablesRatingColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'deliverables_rating',
    dataPoint: 'deliverables_rating',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Deliverables',
    insert: ({ data }) => {
      return data.deliverables_rating
        ? PerformanceRatingTitle[data.deliverables_rating]
        : '-'
    },
  }

export const performanceSkillsRatingColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'skills_rating',
    dataPoint: 'skills_rating',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Skills',
    insert: ({ data }) => {
      return data.skills_rating ? PerformanceRatingTitle[data.skills_rating] : '-'
    },
  }

export const performanceValuesRatingColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'values_rating',
    dataPoint: 'values_rating',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Values',
    insert: ({ data }) => {
      return data.values_rating ? PerformanceRatingTitle[data.values_rating] : '-'
    },
  }

const TimelineStatus = styled.span<{ status: Statuses }>`
  text-transform: capitalize;
  color: ${({ theme, status }) => {
    switch (status) {
      case Statuses.ongoing:
        return getColor(theme, Color.GREEN)
      case Statuses.draft:
        return getColor(theme, Color.GREY_TONE_50)
      case Statuses.pending:
        return getColor(theme, Color.ORANGE)
      case Statuses.rejected:
        return getColor(theme, Color.RED)
      case Statuses.expired:
        return getColor(theme, Color.GREY_20_OPAQUE_90)
      default:
        return getColor(theme, Color.FOREGROUND)
    }
  }};
`

export const performanceTimelineStatusColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status',
    dataPoint: 'status',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    insert: ({ data }) => (
      <TimelineStatus status={data.status}>{data.status}</TimelineStatus>
    ),
  }

export const performanceTimelineReviewersColumn: ColumnInterface<
  PerformanceTimelineInterface | SkillsReviewsInterface
> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Reviewers',
  insert: ({ data }) => {
    return (
      <Grid flow="column" gap={10} justifyContent="start">
        {data?.reviewers?.map(reviewer => (
          <UserWithAvatar
            key={reviewer.id}
            id={reviewer.id}
            name={reviewer.full_name}
            avatar={reviewer.avatar}
            compact={data.reviewers.length > 1}
          />
        ))}
      </Grid>
    )
  },
}

export const performanceTimelineCompletionDateColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.date,
    idPoint: 'completion_date_time',
    dataPoint: 'completion_date_time',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Completion date',
  }

export const performanceTimelineSeniorityColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee_specialisation_seniority_sublevel.name',
    dataPoint: 'employee_specialisation_seniority_sublevel.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Seniority',
    insert: ({ data }) => {
      return data.employee_specialisation_seniority_sublevel?.name
        ? data.employee_specialisation_seniority_sublevel?.name
        : data.employee_seniority?.name || null
    },
  }

export const performanceTimelineSpecialisationColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employee_specialisation.id',
    dataPoint: 'employee_specialisation.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Role (Specialisation)',
  }

export const performanceTimelineRatingColumn: ColumnInterface<PerformanceTimelineInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'rating_label',
    dataPoint: 'rating_label',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Rating',
    insert: ({ data }) =>
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      data.rating_label ? PerformanceRatingTitle[data.rating_label] : '-',
  }

export const probationRecommendationQuestionColumn: MatrixColumnInterface<{}> = {
  type: CellTypes.insert,
  idPoint: 'text',
  dataPoint: 'text',
  title: 'Question',
}

export const probationRecommendationLMColumn: MatrixColumnInterface<{}> = {
  type: CellTypes.insert,
  idPoint: 'lm',
  dataPoint: 'lm',
  title: 'LM',
}

export const probationRecommendationFMColumn: MatrixColumnInterface<{}> = {
  type: CellTypes.insert,
  idPoint: 'fm',
  dataPoint: 'fm',
  title: 'FM',
}

export const probationRecommendationHOFColumn: MatrixColumnInterface<{}> = {
  type: CellTypes.insert,
  idPoint: 'hof',
  dataPoint: 'hof',
  title: 'HoF',
}

export const performanceSkillsNameColumn: ColumnInterface<SkillsReviewsInterface> = {
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Skills',
  insert: ({ data, parentIndexes }) => {
    if ('children' in data && parentIndexes.length === 1) {
      return (
        <TableCellLink
          to={getLocationDescriptor(
            pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, { id: data.skill.id }),
          )}
        >
          {data.skill.name}
        </TableCellLink>
      )
    }

    return (
      <>
        <Text>{`${data.cycle_name}`}</Text>
        {data.seniority?.name && (
          <Text color={Color.GREY_TONE_50}>{` (${data.seniority.name})`}</Text>
        )}
      </>
    )
  },
}

export const performanceSkillsAssessedtRatingColumn: ColumnInterface<SkillsReviewsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'rating',
    dataPoint: 'rating',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Assessed Rating',
    insert: ({ data, parentIndexes }) => {
      if ('children' in data && parentIndexes.length === 1) {
        const chartData: PerformanceChartCycles = {
          progress_history: [],
          targets: [],
        }

        for (let i = data.children.length - 1; i >= 0; i--) {
          const child = data.children[i]
          chartData.progress_history.push({
            ...child,
            expected:
              child.rating_expectation &&
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              PerformanceRatingToGraphNumber[child.rating_expectation],
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            progress: child.rating && PerformanceRatingToGraphNumber[child.rating],
            progress_datetime: child.cycle_name || '',
            progress_datetime_label: child.cycle_name || '',
          })
        }

        return (
          <EmployeePerformanceChart
            data={{ ...chartData, values: [] }}
            id={data.skill.id}
            isRating
            vertical="right"
          >
            <Flex justifyContent="space-between" width="100%">
              <PerformanceRatingLabelTag
                emptyMessage="-"
                fontWeight={400}
                rating={data.rating}
              />
              <Icon type="Graph" size="tiny" />
            </Flex>
          </EmployeePerformanceChart>
        )
      }

      const ratings = data?.reviewers_scores
        ? data.reviewers_scores.map(
            item =>
              ({
                value: item.rating,
                review: {
                  reviewer_relation: item.reviewer_relation,
                  reviewer: {
                    display_name: item.reviewer_name,
                  },
                },
              } as Ratings),
          )
        : []

      return data.rating ? (
        <RatingTooltip
          justifyContent="flex-start"
          placement="top"
          ratings={ratings}
          totalRating={data.rating}
          type={RatingLabelTypes.Inverse}
        />
      ) : (
        '-'
      )
    },
    background: (data, theme) => {
      return ratingToColor(theme, data.rating) || 'inherit'
    },
  }

export const performanceSkillsExpectedRatingColumn: ColumnInterface<SkillsReviewsInterface> =
  {
    type: CellTypes.text,
    idPoint: 'rating_expectation',
    dataPoint: 'rating_expectation',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Expected for seniority',
    insert: ({ data }) => (
      <PerformanceRatingLabelTag
        emptyMessage="-"
        fontWeight={400}
        rating={data.rating_expectation}
      />
    ),
    background: (data, theme) => {
      return ratingToColor(theme, data.rating_expectation) || 'inherit'
    },
  }

export const performanceSkillsNotesColumn = (
  onMessageClick: (scores: ReviewersScoresInterface[]) => void,
): ColumnInterface<SkillsReviewsInterface> => ({
  type: CellTypes.insert,
  idPoint: 'notes',
  dataPoint: 'notes',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Notes',
  insert: ({ data }) => {
    const notes = data.reviewers_scores?.filter(score => score.note?.length)
    if (notes?.length) {
      return (
        <IconButton
          aria-label="Message"
          color={Color.GREY_TONE_50}
          onClick={e => {
            e.stopPropagation()
            onMessageClick(notes)
          }}
          size={16}
          useIcon={Message}
        />
      )
    }

    return null
  },
})

export const performanceFeedbackColumn = (
  onMessageClick: (data: PerformanceTimelineInterface) => void,
): ColumnInterface<PerformanceTimelineInterface> => ({
  type: CellTypes.insert,
  idPoint: 'overall_feedback',
  dataPoint: 'overall_feedback',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Feedback',
  insert: ({ data }) => {
    if (data?.overall_feedback) {
      return (
        <IconButton
          aria-label="Message"
          color={Color.GREY_TONE_50}
          onClick={e => {
            e.stopPropagation()
            onMessageClick(data)
          }}
          size={16}
          useIcon={Message}
        />
      )
    }

    return null
  },
})
