import { AxiosPromise } from 'axios'

import { FetchDataQueryInterface } from '@src/interfaces/data'
import { GetRequestInterface } from '@src/interfaces'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { apiWithoutHandling } from '@src/api'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getEditableTable =
  <T>(apiEndpoint: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ImportInterface<T>>> => {
    return apiWithoutHandling
      .get<GetRequestInterface<{ id: number }>>(apiEndpoint, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      })
      .then(response => ({
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map(row => ({
            id: row.id,
            data: row as T,
            errors: {},
            state: { id: 'pending', name: 'Pending' },
            error_message: null,
            loading: {},
          })),
        },
      }))
  }

export const editEditableTableCell = <T>(
  apiEndpoint: string,
  entityId: number,
  data: T,
) => apiWithoutHandling.patch<T>(`${apiEndpoint}/${entityId}`, data)

export const deleteEditableTableRow = (apiEndpoint: string, rowId: number) =>
  apiWithoutHandling.delete(`${apiEndpoint}/${rowId}`)
