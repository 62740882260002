import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import Page404 from './Page404/Page404'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

export const DASHBOARD_ROUTE = `/dashboard`
export const LEGACY_TABLE_ROUTE = '/tables'

const DASHBOARD_REDIRECTS = [
  [`${DASHBOARD_ROUTE}/all`, ROUTES.APPS.TODO.ALL],
  [`${DASHBOARD_ROUTE}/reviews`, ROUTES.APPS.TODO.REVIEWS],
  [`${DASHBOARD_ROUTE}/upwards-reviews`, ROUTES.APPS.TODO.UPWARDS_REVIEW],
  [`${DASHBOARD_ROUTE}/probation`, ROUTES.APPS.TODO.PROBATION],
  [`${DASHBOARD_ROUTE}/kpis`, ROUTES.APPS.TODO.KPIS],
  [`${DASHBOARD_ROUTE}/kpis_bulk_upload`, ROUTES.APPS.TODO.KPIS_UPLOAD],
  [`${DASHBOARD_ROUTE}/kpis-to-assign`, ROUTES.APPS.TODO.KPIS_TO_ASSIGN],
  [`${DASHBOARD_ROUTE}/kpis-to-approve`, ROUTES.APPS.TODO.KPIS_TO_APPROVE],
  [`${DASHBOARD_ROUTE}/requisitions`, ROUTES.APPS.TODO.REQUISITIONS],
  [`${DASHBOARD_ROUTE}/pending-documents`, ROUTES.APPS.TODO.PENDING_DOCUMENTS],
  [`${DASHBOARD_ROUTE}/roles`, ROUTES.APPS.TODO.ROLES],
  [`${DASHBOARD_ROUTE}/trainings`, ROUTES.APPS.TODO.TRAININGS],
  [`${DASHBOARD_ROUTE}/review-calibration`, ROUTES.APPS.TODO.REVIEW_CALIBRATION],
  [`${DASHBOARD_ROUTE}/data-access`, ROUTES.APPS.TODO.DATA_ACCESS],
  [`${DASHBOARD_ROUTE}/service-desk`, ROUTES.APPS.TODO.SERVICE_DESK],
  [`${DASHBOARD_ROUTE}/findings`, ROUTES.APPS.TODO.FINDINGS],
  [`${DASHBOARD_ROUTE}/skills`, ROUTES.APPS.TODO.SKILLS],
  [`${DASHBOARD_ROUTE}/deliverables`, ROUTES.APPS.TODO.DELIVERABLES],
  [`${DASHBOARD_ROUTE}/interviews`, ROUTES.APPS.TODO.INTERVIEWS],
  [`${DASHBOARD_ROUTE}/catchup-interviews`, ROUTES.APPS.TODO.CATCHUP_INTERVIEWS],
  [`${DASHBOARD_ROUTE}/candidates`, ROUTES.APPS.TODO.CANDIDATES],
  [`${DASHBOARD_ROUTE}/ownership`, ROUTES.APPS.TODO.OWNERSHIP],
  [`${DASHBOARD_ROUTE}/job-postings`, ROUTES.APPS.TODO.JOB_POSTINGS],
  [`${DASHBOARD_ROUTE}/time-off`, ROUTES.APPS.TODO.TIME_OFF],
  [`${DASHBOARD_ROUTE}/comments`, ROUTES.APPS.TODO.COMMENTS],
  [`${DASHBOARD_ROUTE}/promotion`, ROUTES.APPS.TODO.PROMOTION],
  [`${DASHBOARD_ROUTE}/meetings`, ROUTES.APPS.TODO.MEETINGS],
]

const LEGACY_TABLE_REDIRECTS = [
  [`${LEGACY_TABLE_ROUTE}/revoluters`, ROUTES.PEOPLE.EMPLOYEES],
  [`${LEGACY_TABLE_ROUTE}/employees`, ROUTES.PEOPLE.EMPLOYEES],
  [
    `${LEGACY_TABLE_ROUTE}/review-cycles`,
    ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES,
  ],
  [`${LEGACY_TABLE_ROUTE}/requisitions`, ROUTES.RECRUITMENT.REQUISITIONS],
  [`${LEGACY_TABLE_ROUTE}/teams`, ROUTES.ORGANISATION.TEAMS],
  [`${LEGACY_TABLE_ROUTE}/departments`, ROUTES.ORGANISATION.DEPARTMENTS],
  [`${LEGACY_TABLE_ROUTE}/roles`, ROUTES.FUNCTION.ROLES],
  [`${LEGACY_TABLE_ROUTE}/feedback`, ROUTES.ADMIN.FEEDBACK],
  [`${LEGACY_TABLE_ROUTE}/functions`, ROUTES.FUNCTION.FUNCTIONS],
  [`${LEGACY_TABLE_ROUTE}/kpis`, ROUTES.PERFORMANCE.KPIS],
  [`${LEGACY_TABLE_ROUTE}/skills`, ROUTES.FUNCTION.SKILLS],
  [`${LEGACY_TABLE_ROUTE}/job-postings`, ROUTES.RECRUITMENT.JOB_POSTINGS],
  [
    `${LEGACY_TABLE_ROUTE}/hiring-scorecards`,
    ROUTES.RECRUITMENT.INTERVIEW_SCORECARD_TEMPLATES,
  ],
]

export const AppRedirectRouter = () => {
  return (
    <Switch>
      <InternalRedirect
        from={ROUTES.FORMS.TEAM.GENERAL}
        to={ROUTES.FORMS.TEAM.SETTINGS}
      />
      <InternalRedirect
        from={ROUTES.FORMS.DEPARTMENT.GENERAL}
        to={ROUTES.FORMS.DEPARTMENT.SETTINGS}
      />
      <InternalRedirect from={ROUTES.FUNCTION.SKILLS} to={ROUTES.PERFORMANCE.SKILLS} />
      <InternalRedirect
        from={ROUTES.FORMS.INTERVIEW_DASHBOARD.PENDING}
        to={ROUTES.RECRUITMENT.INTERVIEWS.PENDING}
      />
      <InternalRedirect
        from={ROUTES.FORMS.INTERVIEW_DASHBOARD.COMPLETED}
        to={ROUTES.RECRUITMENT.INTERVIEWS.COMPLETED}
      />
      {DASHBOARD_REDIRECTS.map(([dashboardRoute, todoRoute]) => (
        <InternalRedirect from={dashboardRoute} to={todoRoute} key={dashboardRoute} />
      ))}
      {LEGACY_TABLE_REDIRECTS.map(([legacyRoute, newRoute]) => (
        <InternalRedirect from={legacyRoute} to={newRoute} key={legacyRoute} />
      ))}
      <InternalRedirect
        from={ROUTES.FORMS.INTERVIEW_TOOL_SCORECARD.GENERAL}
        to={ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL}
      />

      <InternalRedirect
        from={ROUTES.FORMS.INTERVIEW_TOOL_CANDIDATE.SUMMARY}
        to={ROUTES.FORMS.CANDIDATE.SUMMARY}
      />
      <InternalRedirect
        from={ROUTES.FORMS.INTERVIEW_TOOL_CANDIDATE.FORMS}
        to={ROUTES.FORMS.CANDIDATE.FORMS}
      />
      <InternalRedirect
        from={ROUTES.FORMS.INTERVIEW_TOOL_CANDIDATE.EMAILS}
        to={ROUTES.FORMS.CANDIDATE.EMAILS}
      />
      <InternalRedirect
        from={ROUTES.FORMS.INTERVIEW_TOOL_CANDIDATE.COMMENTS}
        to={ROUTES.FORMS.CANDIDATE.COMMENTS}
      />
      <InternalRedirect
        from={ROUTES.FORMS.INTERVIEW_TOOL_CANDIDATE.TIMELINE}
        to={ROUTES.FORMS.CANDIDATE.TIMELINE}
      />
      <InternalRedirect
        from={ROUTES.FORMS.INTERVIEW_TOOL_CANDIDATE.COMPETENCIES}
        to={ROUTES.FORMS.CANDIDATE.COMPETENCIES}
      />
      <InternalRedirect
        from={ROUTES.FORMS.INTERVIEW_TOOL_CANDIDATE.DOCUMENTS}
        to={ROUTES.FORMS.CANDIDATE.DOCUMENTS}
      />
      <InternalRedirect
        from={ROUTES.PERFORMANCE.SUPPORT_TOOL.WHITELIST}
        to={ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.WHITELIST}
      />
      <InternalRedirect
        from={ROUTES.PERFORMANCE.SUPPORT_TOOL.ELIGIBLE_EMPLOYEES}
        to={ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.ELIGIBLE_EMPLOYEES}
      />
      <InternalRedirect
        from={ROUTES.PERFORMANCE.SUPPORT_TOOL.GRADE_CALIBRATION}
        to={ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.GRADE_CALIBRATION}
      />
      <InternalRedirect
        from={ROUTES.PERFORMANCE.REVIEW_CYCLES}
        to={ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.REVIEW_CYCLES}
      />
      <InternalRedirect
        from={ROUTES.SETTINGS.FUNCTION}
        to={ROUTES.SETTINGS.ROLES.GENERAL}
      />
      <InternalRedirect
        from={ROUTES.PLATFORM_ONBOARDING.SETUP_STEPS.ANY}
        to={ROUTES.ONBOARDING_CHECKLIST.ALL}
      />
      <InternalRedirect
        from={ROUTES.FORMS.EMPLOYEE.KPI.PERSONAL}
        to={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.PERSONAL}
      />
      <InternalRedirect
        from={ROUTES.FORMS.EMPLOYEE.KPI.RELEVANT}
        to={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.RELEVANT}
      />
      <InternalRedirect
        from={ROUTES.FORMS.EMPLOYEE.ENGAGEMENT.DRIVERS}
        to={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.DRIVERS}
      />
      <InternalRedirect
        from={ROUTES.FORMS.EMPLOYEE.ENGAGEMENT.QUESTIONS}
        to={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.QUESTIONS}
      />
      <InternalRedirect
        from={ROUTES.FORMS.EMPLOYEE.ENGAGEMENT.FEEDBACK}
        to={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.ENGAGEMENT.FEEDBACK}
      />
      <InternalRedirect
        from={ROUTES.FORMS.EMPLOYEE.TALENT.DIRECT_REPORTS}
        to={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.DIRECT_REPORTS}
      />
      <InternalRedirect
        from={ROUTES.FORMS.EMPLOYEE.TALENT.FUNCTIONAL_REPORTS}
        to={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.FUNCTIONAL_REPORTS}
      />
      <InternalRedirect
        from={ROUTES.FORMS.EMPLOYEE.TALENT.DIRECT_REPORTS_PERFORMANCE}
        to={
          ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.DIRECT_REPORTS_PERFORMANCE
        }
      />
      <InternalRedirect
        from={ROUTES.FORMS.EMPLOYEE.TALENT.FUNCTIONAL_REPORTS_PERFORMANCE}
        to={
          ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT
            .FUNCTIONAL_REPORTS_PERFORMANCE
        }
      />
      <InternalRedirect
        from={ROUTES.FORMS.EMPLOYEE.TALENT.HIERARCHY}
        to={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIERARCHY}
      />
      <Route>
        <Page404 />
      </Route>
    </Switch>
  )
}
