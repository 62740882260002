import { ActionWidget, ActionButton, useStatusPopup, StatusPopup } from '@revolut/ui-kit'
import React, { useState } from 'react'
import { TicketFullInterface } from '@src/interfaces/tickets'
import { closeTicket, reopenTicket } from '@src/api/tickets'
import { getStringMessageFromError } from '@src/store/notifications/actions'

interface TicketFeedbackProps {
  ticket: TicketFullInterface
  refetch: () => void
  openRatePopup: () => void
}

export const TicketFeedback = ({
  ticket,
  refetch,
  openRatePopup,
}: TicketFeedbackProps) => {
  const [reopenTicketPending, setReopenTicketPending] = useState(false)
  const [closeTicketPending, setCloseTicketPending] = useState(false)

  const statusPopup = useStatusPopup()

  if (ticket.current_status.status !== 'fixed') {
    return null
  }

  const handleNoAction = () => {
    setReopenTicketPending(true)

    reopenTicket(ticket.id)
      .then(() => {
        statusPopup.show(
          <StatusPopup onClose={statusPopup.hide} variant="success">
            <StatusPopup.Title>Thank you for feedback</StatusPopup.Title>
            <StatusPopup.Description>
              We have reopened your issue.
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup onClose={statusPopup.hide} variant="error">
            <StatusPopup.Title>Failed to reopen the issue</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
      .finally(() => {
        setReopenTicketPending(false)
        refetch()
      })
  }

  const handleYesAction = () => {
    setCloseTicketPending(true)

    closeTicket(ticket.id)
      .then(() => {
        openRatePopup()
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup onClose={statusPopup.hide} variant="error">
            <StatusPopup.Title>Failed to close the issue</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
      .finally(() => {
        setCloseTicketPending(false)
        refetch()
      })
  }

  return (
    <>
      <ActionWidget>
        <ActionWidget.Title>Did we fix your issue?</ActionWidget.Title>
        <ActionWidget.Content>
          Help us to improve by leaving your feedback
        </ActionWidget.Content>
        <ActionWidget.Actions>
          <ActionButton
            useIcon="SocialLike"
            onClick={handleYesAction}
            pending={closeTicketPending}
          >
            Yes
          </ActionButton>
          <ActionButton
            useIcon="SocialDislike"
            onClick={handleNoAction}
            pending={reopenTicketPending}
          >
            No
          </ActionButton>
        </ActionWidget.Actions>
      </ActionWidget>
    </>
  )
}
