import React, { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { IntlProvider } from '@revolut-internal/event-tracker/react'

import './styles/index.css'
import './styles/normalize.css'
import App from './pages/App'
import { store } from './utils/store'
import { env, Environments } from './constants/api'
import { history } from './utils/router'
import { Flex } from '@revolut/ui-kit'
import { setAutoFreeze } from 'immer'
import { lapeTrackUseState } from 'lape'
import { QueryClient, QueryClientProvider } from 'react-query'
import ErrorBoundary, { DefaultFallBack } from '@src/features/ErrorBoundary/ErrorBoundary'
import { AnalyticsContextProvider, GoogleAnalyticsWrapper } from '@src/utils/analytics'
import { UIKitWithThemeProvider } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { CookieConsentWrapper } from '@src/pages/Landing/components/CookieConsentWrapper'
import { WorkspaceContextProvider } from '@src/features/Workspaces/WorkspaceContext'

const EventTracker = React.lazy(() =>
  import(
    /* webpackChunkName: 'event-tracker' */ '@src/features/EventTracker/EventTracker'
  )
    .then(module => ({
      default: module.EventTracker,
    }))
    .catch(error => {
      Sentry.captureException(error)
      console.error('event-tracker failed:', error)
      return { default: () => <></> }
    }),
)

if (
  env === Environments.production ||
  env === Environments.productionCommercial ||
  env === Environments.productionCommercialRoot
) {
  Sentry.init({
    dsn:
      env === Environments.production
        ? 'https://8a8797e8f3f34eaf8a1ef1e9dcef2748@sentry.io/1542966'
        : 'https://b1026b46e947a4142288b6aded8809b8@o104379.ingest.sentry.io/4505793610776576',
    environment: 'production', // environment is used by Tower and it expect either 'production' or 'prod' values
    ignoreErrors: [
      // TODO: this is a final form error, try to remove this ignore after legacy forms are migrated to lape
      "Cannot read property 'active' of undefined",
      // Ignore all 400s
      /^Request failed with status code 4\d{2}$/,
      'Request failed with status code 502',
      'Request failed with status code 503',
      'Request failed with status code 504',
      // NB: can be ignored safely: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver loop limit exceeded',
      // NB: comes from our dependencies, not sure we have control on it
      // About this issue: https://github.com/juggle/resize-observer/issues/103
      'ResizeObserver loop completed with undelivered notifications.',
      'Error: Network Error',
      'TypeError: Failed to fetch',
      'timeout exceeded',
      'Request aborted',
      `Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
    ],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        _metricOptions: {
          _reportAllChanges: true,
        },
      }),
    ],
    tracesSampleRate: 0.2,
  })
}

setAutoFreeze(false)
lapeTrackUseState()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: env !== Environments.local,
    },
  },
})

const renderApp = () => {
  ReactDOM.render(
    <IntlProvider locale="en">
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <WorkspaceContextProvider>
            <UIKitWithThemeProvider>
              <CookieConsentWrapper>
                <GoogleAnalyticsWrapper>
                  <AnalyticsContextProvider>
                    <ErrorBoundary
                      fallback={
                        <Flex
                          width="100vw"
                          height="100vh"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <DefaultFallBack />
                        </Flex>
                      }
                    >
                      <App />
                      {env === Environments.local ||
                      env === Environments.production ||
                      env === Environments.developmentCommercial ||
                      env === Environments.developmentCommercialRoot ||
                      env === Environments.productionCommercial ||
                      env === Environments.productionCommercialRoot ? (
                        <Suspense fallback={null}>
                          <EventTracker />
                        </Suspense>
                      ) : null}
                    </ErrorBoundary>
                  </AnalyticsContextProvider>
                </GoogleAnalyticsWrapper>
              </CookieConsentWrapper>
            </UIKitWithThemeProvider>
          </WorkspaceContextProvider>
        </QueryClientProvider>
      </Provider>
    </IntlProvider>,
    document.getElementById('root'),
  )
}

if (
  env !== Environments.production &&
  env !== Environments.productionCommercial &&
  env !== Environments.productionCommercialRoot &&
  localStorage.getItem('enable_msw') === 'true'
) {
  const startWorker = async () => {
    const { worker } = await import('@src/api/mocks/browser')
    worker.start({ onUnhandledRequest: 'bypass' })
  }
  startWorker().then(() => renderApp())
} else {
  renderApp()
}
