import React, { useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { EntityPermissions } from '@src/store/auth/types'
import { goBack } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { MoreBar } from '@revolut/ui-kit'
import { difference } from '@src/utils/form'
import { publishRequisition } from '@src/api/requisitions'

const PublishButton = () => {
  const [submitting, setSubmitting] = useState(false)
  const { values, initialValues } = useLapeContext<RequisitionInterface>()

  const canPublish = values.field_options?.actions?.includes(EntityPermissions.Publish)
  const publish = () => {
    setSubmitting(true)

    const unsavedData = difference(values, initialValues)
    publishRequisition(values.id, unsavedData)

    goBack(ROUTES.RECRUITMENT.REQUISITIONS)
  }

  if (!canPublish || initialValues.status !== Statuses.approved) {
    return null
  }

  return (
    <MoreBar.Action useIcon="Megaphone" pending={submitting} onClick={publish}>
      Publish
    </MoreBar.Action>
  )
}

export default PublishButton
