import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route, Switch } from 'react-router-dom'
import NewFormSwitch from '@src/pages/Forms/NewFormSwitch'
import Employees from '@src/apps/People/Employees/Employees'
import TimeOff from '@src/apps/People/TimeOff/TimeOff'
import Compensation from '@src/apps/People/Compensation/Compensation'
import Documents from '@src/apps/People/Documents/Documents'
import Requisitions from '@src/apps/Recruitment/Requisitions/Requisitions'
import JobPostings from '@src/apps/Recruitment/JobPostings/JobPostings'
import Teams from '@src/apps/Organisation/Teams/Teams'
import Positions from '@src/apps/Organisation/Positions/Positions'
import Locations from '@src/apps/Organisation/Locations/Locations'
import Entities from '@src/apps/Organisation/Entities/Entities'
import HiringProcess from '@src/apps/Recruitment/HiringProcess/HiringProcess'
import Todo from '@src/apps/General/Todo/Todo'
import Communication from '@src/apps/General/Communication/Communication'
import Benefits from '@src/apps/People/Benefits/Benefits'
import Engagement from '@src/apps/People/Engagement/Engagement'
import Candidates from '@src/apps/Recruitment/Candidates/Candidates'
import Offers from '@src/apps/Recruitment/Offers/Offers'
import PerformanceReviews from '@src/apps/Performance/PerformanceReviews/PerformanceReviews'
import KPIs from '@src/apps/Performance/KPIs/KPIs'
import Skills from '@src/apps/Performance/Skills/Skills'
import Promotions from '@src/apps/Performance/Promotions/Promotions'
import AccessManagement from '@src/apps/General/AccessManagement/AccessManagement'
import Feedback from '@src/apps/General/Feedback/Feedback'
import Settings from '@src/pages/Settings'
import Attendance from '@src/apps/People/Attendance/Attendance'
import Lifecycle from '@src/apps/People/Lifecycle/Lifecycle'
import PlatformStatus from '@src/pages/PlatformStatus/PlatformStatus'
import Screening from '@src/apps/People/Screening/Screening'
import Contracts from '@src/apps/People/Contracts/Contracts'
import DataRetention from '@src/apps/General/DataRetention'
import HiringPipelines from '@src/apps/Recruitment/HiringPipelines/HiringPipelines'
import Payroll from '@src/apps/People/Payroll/Payroll'
import Roadmaps from '@src/apps/Performance/Roadmaps/Roadmaps'
import { PlatformOnboarding } from './PlatformOnboarding'
import Groups from '@src/apps/General/Groups/Groups'
import KeyPersons from '@src/apps/People/KeyPersons'
import DataAnalytics from '@src/apps/General/DataAnalytics'
import HelpCenter from '@src/apps/General/HelpCenter/HelpCenter'
import { Tickets } from '@src/pages/Tickets'

const AppRouter = () => {
  return (
    <Switch>
      <Route
        path={[
          ROUTES.PERFORMANCE.PERFORMANCE_REVIEWS.ANY,
          ROUTES.PERFORMANCE.PROBATION.ANY,
          ROUTES.PERFORMANCE.PIP,
        ]}
      >
        <PerformanceReviews />
      </Route>
      <Route path={ROUTES.ADMIN.ACCESS_MANAGEMENT.ANY}>
        <AccessManagement />
      </Route>
      <Route path={ROUTES.ADMIN.FEEDBACK}>
        <Feedback />
      </Route>
      <Route path={[ROUTES.PERFORMANCE.SKILLS, ROUTES.PERFORMANCE.VALUES]}>
        <Skills />
      </Route>
      <Route path={ROUTES.PERFORMANCE.PROMOTION_NOMINEES}>
        <Promotions />
      </Route>
      <Route path={[ROUTES.PERFORMANCE.KPIS, ROUTES.FORMS.KPI_TEMPLATES.ANY]}>
        <KPIs />
      </Route>
      <Route path={ROUTES.PERFORMANCE.ROADMAPS}>
        <Roadmaps />
      </Route>
      <Route path={ROUTES.APPS.EMPLOYEES.ANY}>
        <Employees />
      </Route>
      <Route path={ROUTES.APPS.LIFECYCLE.ANY}>
        <Lifecycle />
      </Route>
      <Route path={ROUTES.APPS.TIME_MANAGEMENT.ANY}>
        <TimeOff />
      </Route>
      <Route path={ROUTES.APPS.CONTRACTS.ANY}>
        <Contracts />
      </Route>
      <Route path={ROUTES.APPS.PAYROLL.ANY}>
        <Payroll />
      </Route>
      <Route path={ROUTES.APPS.ATTENDANCE.ANY}>
        <Attendance />
      </Route>
      <Route path={ROUTES.APPS.COMPENSATION.ANY}>
        <Compensation />
      </Route>
      <Route path={ROUTES.APPS.DOCUMENTS.ANY}>
        <Documents />
      </Route>
      <Route path={ROUTES.APPS.ENGAGEMENT.ANY}>
        <Engagement />
      </Route>
      <Route path={ROUTES.APPS.KEY_PERSONS.ANY}>
        <KeyPersons />
      </Route>
      <Route path={ROUTES.APPS.CANDIDATES.ANY}>
        <Candidates />
      </Route>
      <Route path={ROUTES.APPS.OFFERS.ANY}>
        <Offers />
      </Route>
      <Route path={ROUTES.APPS.REQUISITIONS}>
        <Requisitions />
      </Route>
      <Route path={ROUTES.APPS.JOB_POSTINGS}>
        <JobPostings />
      </Route>
      <Route path={ROUTES.APPS.HIRING_PIPELINES.ANY}>
        <HiringPipelines />
      </Route>
      <Route path={ROUTES.APPS.TEAMS.ANY}>
        <Teams />
      </Route>
      <Route path={ROUTES.APPS.POSITIONS.ANY}>
        <Positions />
      </Route>
      <Route path={ROUTES.APPS.LOCATIONS}>
        <Locations />
      </Route>
      <Route path={ROUTES.APPS.ENTITIES}>
        <Entities />
      </Route>
      <Route path={ROUTES.APPS.TODO.ANY}>
        <Todo />
      </Route>
      <Route path={ROUTES.APPS.DATA_RETENTION.ANY}>
        <DataRetention />
      </Route>
      <Route path={ROUTES.APPS.GROUPS}>
        <Groups />
      </Route>
      <Route path={ROUTES.APPS.COMMUNICATION.ANY}>
        <Communication />
      </Route>
      <Route path={ROUTES.APPS.BENEFITS.ANY}>
        <Benefits />
      </Route>
      <Route path={ROUTES.APPS.HIRING_PROCESS.ANY}>
        <HiringProcess />
      </Route>
      <Route path={ROUTES.APPS.SCREENING.ANY}>
        <Screening />
      </Route>
      <Route path={ROUTES.APPS.DATA_ANALYTICS.ANY}>
        <DataAnalytics />
      </Route>
      <Route path={ROUTES.APPS.HELP_CENTER.ANY}>
        <HelpCenter />
      </Route>
      <Route path={ROUTES.SETTINGS.ANY}>
        <Settings />
      </Route>
      <Route path={ROUTES.TICKETS.ANY}>
        <Tickets />
      </Route>
      <Route path={ROUTES.PLATFORM_ONBOARDING.ANY}>
        <PlatformOnboarding />
      </Route>
      <Route path={ROUTES.PLATFORM_STATUS}>
        <PlatformStatus />
      </Route>
      <Route path={ROUTES.ANY}>
        <NewFormSwitch />
      </Route>
    </Switch>
  )
}

export default AppRouter
