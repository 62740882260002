import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const PayrollIntro = () => {
  return (
    <OnboardingIntro
      // @TODO: change
      videoUrl="/intros/Intro%20-%20Documents.mp4"
      videoPosterUrl="/intros/Intro%20-%20Documents.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.payroll.intro.tips.title"
            defaultMessage="Handle all payroll information in one place"
          />
        }
        text={
          <FormattedMessage
            id="onboardingV2.payroll.intro.tips.text"
            defaultMessage="From salaries to bonuses, handle everything that impacts payroll in one central place"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.payroll.intro.tips.define.title"
                defaultMessage="Define your payroll cycles"
              />
            ),
            icon: 'Document',
            text: (
              <FormattedMessage
                id="onboardingV2.payroll.intro.tips.define.text"
                defaultMessage="Define how your payroll works, when employees are paid, cutoff date."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.payroll.intro.tips.keepTrack.title"
                defaultMessage="Keep track of all changes in one place"
              />
            ),
            icon: 'People',
            text: (
              <FormattedMessage
                id="onboardingV2.payroll.intro.tips.keepTrack.text"
                defaultMessage="Every time there is a change that is relevant to payroll, you can track this in the payroll cycle screen, making sure that there are no surprises when running payroll."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.payroll.intro.tips.export.title"
                defaultMessage="Export your change report"
              />
            ),
            icon: 'BalanceSheet',
            text: (
              <FormattedMessage
                id="onboardingV2.payroll.intro.tips.export.text"
                defaultMessage="When the cycle is nearing it’s end, download the change report and send it to whoever will take care of payroll."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
