import React, { useEffect } from 'react'
import { BaseWidget } from '../BaseWidget'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { goalsListTableRequests } from '@src/api/goals'
import { TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  goalsNameColumn,
  goalsProgressColumn,
  goalsStatusColumn,
  goalsApprovalStatusColumn,
  goalsOwnerColumn,
} from '@src/constants/columns/goals'
import { useTable } from '@src/components/Table/hooks'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

export const AggregatedGoalsWidget = ({ cycle }: { cycle: ReviewCyclesInterface }) => {
  const { values } = useLapeContext<GoalsInterface>()
  const table = useTable(
    goalsListTableRequests,
    [
      {
        columnName: 'aggregated_parent_id',
        filters: [{ id: values.id, name: String(values.id) }],
        nonResettable: true,
      },
      {
        columnName: 'cycle__id',
        filters: [{ id: cycle.id, name: cycle.name }],
        nonResettable: true,
      },
    ],
    undefined,
    { disableQuery: true },
  )

  useEffect(() => {
    const cycleFilter = table.filterBy.find(f => f.columnName === 'cycle__id')
    if (cycleFilter?.filters[0].id !== cycle.id) {
      table.onFilterChange({
        columnName: 'cycle__id',
        filters: [{ id: cycle.id, name: cycle.name }],
      })
    }
  }, [cycle])

  return (
    <BaseWidget
      title="Aggregated goals"
      icon="TurboTransfer"
      emptyState={{
        description: 'Progress will be measured based on children goal(s) performance',
        icon: 'TurboTransfer',
      }}
    >
      <TableWidget style={{ padding: 0 }}>
        <TableWidget.Table>
          <AdjustableTable
            noDataMessage="No goals assigned yet"
            rowHeight="medium"
            hideCountAndButtonSection
            name={TableNames.Goals}
            dataType="Target"
            row={{
              cells: [
                {
                  ...goalsNameColumn(),
                  width: 300,
                },
                { ...goalsProgressColumn, width: 100 },
                { ...goalsStatusColumn, width: 100 },
                { ...goalsOwnerColumn, width: 150 },
                { ...goalsApprovalStatusColumn, width: 50 },
              ],
              noChildrenRequest: true,
            }}
            {...table}
          />
        </TableWidget.Table>
      </TableWidget>
    </BaseWidget>
  )
}
