import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { jobsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { JobsIntro } from './JobsIntro'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.INTRO,
    canView: [PermissionTypes.ViewJobPostingPreferences],
    component: JobsIntro,
  },
]

export const Jobs = () => {
  return <OnboardingChecklistContent config={jobsConfig} routes={routes} />
}
