import React from 'react'
import { Avatar, Box, Flex, HStack, Item, TextButton, Text } from '@revolut/ui-kit'
import { ExclamationTriangle, InfoOutline } from '@revolut/icons'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import MainHeader from '@src/features/MainHeader/MainHeader'
import {
  useGetCandidateSettings,
  useGlobalSettings,
  useGetTimeOffSettings,
} from '@src/api/settings'
import { TableWithSubtabs } from '@src/features/TabPinning/TableWithSubtabs'
import { TabBarWithPinningTabConfig, Subtab } from '@src/interfaces/tabPinning'
import { TabBarWithPinning } from '@src/features/TabPinning/TabBarWithPinning'
import { GlobalSettings } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { useSubsciptionInfo } from '@src/api/plans'

interface MainTableContainerInterface {
  sectionName: 'Home' | 'People' | 'Recruitment' | 'Organisation'
  tabs: TabBarWithPinningTabConfig[]
}

export const MainTableContainer = ({
  sectionName,
  tabs,
}: MainTableContainerInterface) => {
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)

  const globalSettings = useGlobalSettings()
  const subscriptionInfo = useSubsciptionInfo()

  const { data: timeOffSettings } = useGetTimeOffSettings(sectionName !== 'People')
  const { data: candidateSettings } = useGetCandidateSettings(
    sectionName !== 'Recruitment',
  )

  const filteredTabs = tabs.filter(tab => {
    const isAllowed = tab.permissions
      ? tab.permissions.some(permission => permissions.includes(permission))
      : true

    const settingEnabled = (() => {
      // TODO: once BE has all the settings in one global settings response, update this
      if (tab.globalSetting === GlobalSettings.TimeOffEnabled) {
        return !!timeOffSettings?.enabled
      }
      // TODO: once BE has all the settings in one global settings response, update this
      if (tab.globalSetting === GlobalSettings.CandidateSchedulingEnabled) {
        return !!candidateSettings?.enable_scheduling
      }
      if (tab.globalSetting) {
        return !!globalSettings.settings[tab.globalSetting]
      }

      return true
    })()

    const featureEnabled = tab.featureFlag ? featureFlags.includes(tab.featureFlag) : true

    const checkSubtabsFeatureFlags = (subtabs: Subtab[]) =>
      subtabs.some(subtab =>
        subtab.featureFlag ? featureFlags.includes(subtab.featureFlag) : true,
      )

    const subtabsFeatureEnabled = tab.subtabs
      ? checkSubtabsFeatureFlags(tab.subtabs)
      : true

    return isAllowed && featureEnabled && subtabsFeatureEnabled && settingEnabled
  })

  const isAccountClosing = subscriptionInfo.info?.state.id === 'closing'

  return (
    <Flex pt="s-8" pb="s-24" flexDirection="column">
      <MainHeader title={sectionName} withGlobalSearch>
        {isAccountClosing && sectionName === 'People' ? (
          <Item mb="s-16">
            <Item.Avatar>
              <Avatar useIcon={ExclamationTriangle} color="terracotta" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Adding new employees has been restricted</Item.Title>
              <Item.Description>
                Since your account is scheduled to be closed, we will no longer allow
                adding new employees in Revolut People.
              </Item.Description>
              <TextButton
                to={ROUTES.SETTINGS.CLOSE_ACCOUNT.CONFIRMATION}
                use={InternalLink}
              >
                <HStack align="center" space="s-4">
                  <InfoOutline size={16} />
                  <Text variant="h6">Learn more</Text>
                </HStack>
              </TextButton>
            </Item.Content>
          </Item>
        ) : null}

        <Box mb="s-16">
          <TabBarWithPinning tabs={filteredTabs} />
        </Box>
      </MainHeader>
      <Switch>
        {filteredTabs.map(tab => {
          if (tab.component || tab.subtabs) {
            return (
              <Route
                exact
                key={tab.id}
                path={tab.path}
                render={() => {
                  document.title = `${sectionName} - ${tab.title}`
                  if (tab.component) {
                    return <tab.component />
                  }
                  if (tab.subtabs) {
                    return <TableWithSubtabs subtabs={tab.subtabs} />
                  }
                  return null
                }}
              />
            )
          }
          return null
        })}
      </Switch>
    </Flex>
  )
}
