import React from 'react'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getJustificationViewList } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { FeedbackCommon } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/common/FeedbackCommon'
import { FeedbackProps } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Feedback'
import { EmployeePerformanceViewSummaryContent } from '@src/pages/Forms/EmployeePerformanceView/Summary'
import ExpandableText from '@components/ExpandableText/ExpandableText'
import { Box } from '@revolut/ui-kit'

export const FeedbackView = connect(({ recommendationData, reviews }: FeedbackProps) => {
  const { values } = useLapeContext<ReviewSummaryInterface>()

  const user = useSelector(selectUser)
  const reviewedEmployeeId = values.reviews?.[0]?.reviewed_employee?.id
  const isSelfReview = reviewedEmployeeId === user.id

  const renderJustification = () => {
    if (reviews) {
      return <ExpandableText list={getJustificationViewList(reviews)} />
    }
    return null
  }

  const feedbackContent = (
    <Box mt={isSelfReview ? '-s-32' : 's-4'}>
      <EmployeePerformanceViewSummaryContent
        values={values}
        width="100%"
        showStepperTitle={false}
        withBorder
        hideAdditional
      />
    </Box>
  )

  return (
    <FeedbackCommon
      isViewMode
      recommendationData={recommendationData}
      renderJustification={renderJustification}
      reviews={reviews}
      isSelfReview={isSelfReview}
      feedbackContent={feedbackContent}
    />
  )
})
