import React, { useRef, useState } from 'react'
import {
  ActionMenu,
  Button,
  Group,
  Icon,
  Separator,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { HeaderItem } from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget/HeaderItem'
import ChangeJobPosting from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget/ChangeJobPosting'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { useNavigateToJobPostingPath } from '@src/features/JobPostingFlow/utils'

type StartHiringProps = {
  onRefresh: () => Promise<void>
}

const StartHiring = ({ onRefresh }: StartHiringProps) => {
  const { values } = useLapeContext<RequisitionInterface>()
  const anchorRef = useRef(null)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [openJobPostingSidebar, setOpenJobPostingSidebar] = useState(false)
  const navigateToJobPosting = useNavigateToJobPostingPath()
  return (
    <>
      <Group>
        <HeaderItem />
        <Separator />
        <VStack gap="s-8" py="s-32" align="center">
          <Icon name="Info" color={Token.color.greyTone50} />
          <Text color={Token.color.greyTone50}>No job posting</Text>
          <>
            <Button
              size="xs"
              ref={anchorRef}
              onClick={() => {
                setOpenDropdown(!openDropdown)
              }}
              data-testid="job_posting_actions"
            >
              Start hiring
            </Button>
            <ActionMenu
              open={openDropdown}
              anchorRef={anchorRef}
              onClose={() => {
                setOpenDropdown(false)
              }}
            >
              <ActionMenu.Group>
                <ActionMenu.Item
                  onClick={() => {
                    navigateToJobPosting({
                      specialisationId: values.specialisation.id,
                      requisitionId: values.id,
                    })
                  }}
                  useIcon="Plus"
                >
                  Create new job posting
                </ActionMenu.Item>
                <ActionMenu.Item
                  use="button"
                  useIcon="Plug"
                  onClick={() => {
                    setOpenDropdown(false)
                    setOpenJobPostingSidebar(true)
                  }}
                >
                  Connect to existing job posting
                </ActionMenu.Item>
              </ActionMenu.Group>
            </ActionMenu>
          </>
        </VStack>
      </Group>
      {openJobPostingSidebar && (
        <ChangeJobPosting
          onClose={() => {
            setOpenJobPostingSidebar(false)
          }}
          onRefresh={onRefresh}
        />
      )}
    </>
  )
}

export default StartHiring
