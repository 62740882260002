import React from 'react'
import HelpSections from '@src/pages/Forms/EmployeePerformance/components/HelpSections/HelpSections'
import { Text, VStack } from '@revolut/ui-kit'

const DeliverablesHelp = () => {
  const sections = [
    'Performance for goals is based on the overall calibrated progress percentage of goals the employee owned for this cycle.',
    <VStack key={1}>
      <Text>
        Goals are assigned a recommended grade based on the calibrated progress.
      </Text>
      <ul style={{ paddingInlineStart: 16, margin: 0 }}>
        <li>Below 40% - Unsatisfactory</li>
        <li>40-60% - Developing</li>
        <li>60-80% - Performing</li>
        <li>80-120% - Exceeding</li>
        <li>Above 120% - Exceptional</li>
      </ul>
    </VStack>,
    'If an employee has not been assigned any goals, they should be reviewed based on the contribution and impact they have made to their team achieving its goals.',
    <VStack key={3}>
      <Text>When assessing an employee, a reviewer should consider:</Text>
      <ul style={{ paddingInlineStart: 16, margin: 0 }}>
        <li>How much was contributed this cycle given the size of the goals?</li>
        <li>Was the employee a critical driver for success?</li>
        <li>
          What has been the impact on business goals as a result of their contribution?
        </li>
        <li>Have they met the expectations for their seniority and role?</li>
        <li>Are they helping to raise the talent bar of those around them?</li>
      </ul>
    </VStack>,
    'If a selected rating is different from the expectations or recommendation for an employee, you will need to add a comment to justify your review.',
  ]
  return <HelpSections sections={sections} />
}

export default DeliverablesHelp
