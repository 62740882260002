import React from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { EntityTypes } from '@src/constants/api'
import {
  analyticsDashboardNameColumn,
  analyticsDashboardRelatedKpisColumn,
  analyticsDashboardOwnerColumn,
  analyticsDashboardRatingColumn,
  analyticsDashboardSourceColumn,
} from '@src/constants/columns/analyticsDashboard'
import { ROUTES } from '@src/constants/routes'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { EmployeeInterface } from '@src/interfaces/employees'
import { OrderingDataType } from '@src/interfaces/ordering'

interface Props {
  data: EmployeeInterface
}

const row: RowInterface<GenericAnalyticsDashboardInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD, {
        id,
      }),
    ),
  cells: [
    {
      ...analyticsDashboardNameColumn,
      width: 200,
    },
    {
      ...analyticsDashboardRelatedKpisColumn,
      width: 300,
    },
    {
      ...analyticsDashboardRatingColumn,
      width: 40,
    },
    {
      ...analyticsDashboardSourceColumn,
      width: 40,
    },
    {
      ...analyticsDashboardOwnerColumn,
      width: 80,
    },
  ],
}

export const AnalyticsDashboards = ({ data }: Props) => {
  const initialFilter = [
    {
      filters: [
        {
          name: OrderingDataType.Employees,
          id: OrderingDataType.Employees,
        },
      ],
      columnName: 'parent_type',
      nonResettable: true,
    },
    {
      filters: [{ name: data.full_name, id: data.id }],
      columnName: 'parent_id',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'position_number',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  return (
    <AnalyticsDashboardsTab
      canAdd={data.field_options.permissions?.includes(PermissionTypes.ManageDashboards)}
      data={{ id: data.id, name: data.full_name }}
      entity={EntityTypes.employee}
      row={row}
      initialFilter={initialFilter}
      initialSort={initialSortBy}
    />
  )
}
