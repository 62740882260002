import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { performanceReviewLayoutRequests } from '@src/api/performanceReview'
import { connect } from 'lape'
import { ReviewCategory, ReviewScorecardInterface } from '@src/interfaces/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import PerformanceCycleClosed from '@components/ScorecardGeneral/PerformanceCycleClosed'
import Form from '@src/features/EmployeePerformance/Form'
import PerformancePage from '@src/pages/Forms/EmployeePerformance/components/PerformancePage'
import PerformanceErrorWidget from '@src/pages/Forms/EmployeePerformance/components/PerformanceErrorWidget'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { usePutFirstScorecardOpening } from '@src/utils/performance'
import { ScorecardContent } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { Box } from '@revolut/ui-kit'
import { PerformancePageHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformancePageHeader'
import { PerformanceHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformanceHeader'

export const PerformanceContentWrapper: React.FC = ({ children }) => {
  return <Box maxWidth="1100px">{children}</Box>
}

export interface EmployeePerformanceInterface {
  latestValues?: ReviewScorecardInterface
}

const EmployeePerformance = connect(({ latestValues }: EmployeePerformanceInterface) => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  usePutFirstScorecardOpening(values, ReviewCategory.Performance)

  if (!performanceSettings) {
    return <PageLoading />
  }

  if (!values.review_data) {
    return <PerformanceErrorWidget />
  }

  return (
    <PerformancePage showHeader={false}>
      <PerformancePageHeader />
      <PerformanceContentWrapper>
        {!values.current_user_can_edit_review ? (
          <>
            <PerformanceHeader />
            <PerformanceCycleClosed employeeId={values.reviewed_employee.id!} />
          </>
        ) : (
          <ScorecardContent latestValues={latestValues} />
        )}
      </PerformanceContentWrapper>
    </PerformancePage>
  )
})

export const EmployeePerformanceLayout = connect(() => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()
  // needed to track every form change, apparently values from useLapeContext don't allow
  // tracking nested fields when [values] defined in dependency's array in useEffect
  // this is a hack to get latest values on every form change as onChange in LapeForm passes them as props
  const [latestValues, setLatestValues] = useState<ReviewScorecardInterface>()

  return (
    <Form
      api={performanceReviewLayoutRequests}
      storageKey={`${ReviewCategory.Performance}-${id}-${employeeId}`}
      onChange={values => setLatestValues(values)}
    >
      <EmployeePerformance latestValues={latestValues} />
    </Form>
  )
})
