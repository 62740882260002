import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  ButtonVariant as ButtonVariantType,
  Color,
  Flex,
  Text,
  Widget,
} from '@revolut/ui-kit'
import { Pencil, Plus } from '@revolut/icons'
import { committeeResultRequests as probationCommitteeResultRequests } from '@src/api/probationReview'
import { committeeResultRequests as pipCommitteeResultRequests } from '@src/api/pip'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  FinalGrade,
  PerformanceSidebarManager,
  ReviewCategory,
} from '@src/interfaces/performance'
import {
  CommitteeResultInterface,
  ProbationCheckpoints,
} from '@src/interfaces/probationReview'
import { Grid } from '@components/CommonSC/Grid'
import { PageBody } from '@src/components/Page/PageBody'
import PerformanceGrade from '@components/PerformanceGrade/PerformanceGrade'
import ProbationResult from '@components/ProbationResult/ProbationResult'
import Tooltip from '@components/Tooltip/Tooltip'
import SummarySidebar from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummarySidebar'
import CommitteeReviewers from '@src/pages/Forms/Overview/Committee/CommitteeReviewers'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { getStartOfCheckpoint } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary/ProbationPipTimeline/utils'

const Actions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
  margin: 16px 0;
  justify-content: flex-start;
`

const PerformanceGradeHead = styled(PerformanceGrade)`
  font-weight: 600;
  font-size: 20px;
`

const ResultHead = styled(ProbationResult)`
  font-weight: 600;
  font-size: 20px;
`

type Props = {
  category: ReviewCategory
  checkpoints?: ProbationCheckpoints
  cycleId: string
  data: EmployeeInterface
}

enum ButtonStates {
  Add = 'Add',
  View = 'View',
  Edit = 'Edit',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Committee Decision',
  [ButtonStates.Edit]: 'Edit Committee Decision',
  [ButtonStates.View]: 'View Committee Decision',
}

const ButtonIcon = {
  [ButtonStates.Add]: Plus,
  [ButtonStates.Edit]: Pencil,
}

const ButtonVariant = {
  [ButtonStates.Add]: 'primary',
  [ButtonStates.Edit]: 'secondary',
  [ButtonStates.View]: 'secondary',
}

const ReviewResultPath = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_COMMITTEE_RESULT.GENERAL,
  [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP_COMMITTEE_RESULT.GENERAL,
}

export const Committee = ({ category, checkpoints, cycleId, data }: Props) => {
  const [decision, setDecision] = useState<CommitteeResultInterface | undefined>()
  const [sidebarOpen, setSidebarOpen] = useState<PerformanceSidebarManager>(
    PerformanceSidebarManager.SummaryOpened,
  )

  const fetchDecision = async (id: number) => {
    const requests =
      category === ReviewCategory.Probation
        ? probationCommitteeResultRequests
        : pipCommitteeResultRequests
    const result = await requests.get({
      employeeId: String(data.id),
      id: String(id),
      cycleId,
    })
    setDecision(result.data)
  }

  const checkpoint = useMemo(() => checkpoints?.decision_checkpoints?.[0], [checkpoints])

  useEffect(() => {
    if (checkpoint?.decision?.id) {
      fetchDecision(checkpoint.decision.id)
    }
  }, [checkpoint?.decision?.id])

  const onClickAddReview = () => {
    navigateTo(
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      pathToUrl(ReviewResultPath[category], {
        employeeId: data.id,
        cycleId,
        id: checkpoint?.decision?.id,
      }),
    )
  }

  const onClickViewReview = () => {
    setSidebarOpen(
      sidebarOpen === PerformanceSidebarManager.SummaryOpened
        ? PerformanceSidebarManager.Closed
        : PerformanceSidebarManager.SummaryOpened,
    )
  }

  let state = ButtonStates.View
  if (checkpoint?.can_be_submitted) {
    state = !checkpoint.decision ? ButtonStates.Add : ButtonStates.Edit
  }

  const startOfCheckpoint = checkpoint && getStartOfCheckpoint(checkpoint)

  return (
    <>
      <PageBody maxWidth={676}>
        <Widget p="s-16">
          <Flex alignItems="center">
            <ResultHead
              result={decision?.result}
              status={decision?.result ? Statuses.completed : Statuses.pending}
            />
            <Text color={Color.GREY_TONE_20} pl="s-6" pr="s-6" fontSize="h5" use="div">
              ·
            </Text>
            <PerformanceGradeHead
              inverse
              grade={decision?.final_grade?.id as FinalGrade}
            />
          </Flex>
          {startOfCheckpoint && (
            <Text color={Color.GREY_TONE_50}>
              Committee checkpoint: {formatDate(startOfCheckpoint)}
            </Text>
          )}
          <Actions>
            <Tooltip
              hide={checkpoint?.can_be_submitted}
              placement="top"
              text="You are not allowed to submit/view result at this time"
            >
              <Button
                disabled={!checkpoint?.can_be_submitted && !checkpoint?.decision}
                onClick={onClickAddReview}
                size="sm"
                /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                useIcon={ButtonIcon[state]}
                variant={ButtonVariant[state] as ButtonVariantType}
              >
                {ButtonTitles[state]}
              </Button>
            </Tooltip>

            <Button size="sm" variant="secondary" onClick={onClickViewReview}>
              View Reviews
            </Button>
          </Actions>
          <Box mt="s-32">
            <Text color={Color.GREY_TONE_50} fontWeight={500}>
              Committee result
            </Text>
            <Box variant="plain" mt="s-16">
              <Grid gap={34}>
                {checkpoints?.decision_checkpoints?.map((cp, i) => (
                  <CommitteeReviewers checkpoint={cp} decision={decision} key={i} />
                ))}
              </Grid>
            </Box>
          </Box>
        </Widget>
      </PageBody>
      <SummarySidebar
        category={category}
        cycleId={cycleId}
        cycleName={''}
        employeeId={data.id}
        employeeSeniorityId={data.seniority?.id}
        isOpen={sidebarOpen === PerformanceSidebarManager.SummaryOpened}
        onClose={() => setSidebarOpen(PerformanceSidebarManager.Closed)}
      />
    </>
  )
}
