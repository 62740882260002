import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Flex } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Subtab } from '@src/interfaces/tabPinning'
import { useGlobalSettings } from '@src/api/settings'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

interface TableWithSubtabsProps {
  subtabs: Subtab[]
}

export const TableWithSubtabs = ({ subtabs }: TableWithSubtabsProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)
  const globalSettings = useGlobalSettings()

  if (!subtabs) {
    return null
  }

  const filteredTabs = subtabs.filter(tab => {
    const checkPermissions = tab.permission ? permissions.includes(tab.permission) : true
    const checkFeatureFlags = tab.featureFlag
      ? featureFlags.includes(tab.featureFlag)
      : true

    const isAllowed = checkPermissions && checkFeatureFlags

    const settingEnabled = tab.globalSetting
      ? /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        !!globalSettings.settings[tab.globalSetting]
      : true

    return isAllowed && settingEnabled
  })

  return (
    <>
      <Flex alignItems="flex-start" mb="s-24">
        <TabBarNavigation isSubtab tabs={filteredTabs} />
      </Flex>
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
        <InternalRedirect to={filteredTabs[0].path} />
      </Switch>
    </>
  )
}
