import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { FieldOptions, IdAndName, Statuses } from '@src/interfaces/index'
import { OptionInterface } from '@src/interfaces/selectors'
import { ApprovalStatuses, ApproverInterface } from './approvalFlow'
import { TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import { AttachedTemplateInterface } from '@src/interfaces/documentsTemplates'

export enum DocumentSources {
  Embedded = 'embedded',
  Upload = 'upload',
  Docusign = 'docusign',
  Request = 'request',
}

export enum DocumentStatuses {
  pending = 'pending',
  completed = 'completed',
  pending_upload = 'pending_upload',
  pending_approval = 'pending_approval',
}

export enum DocumentConfidentiality {
  Employee = 'employee',
  HRManager = 'hr_manager',
}

export interface EmployeeDocumentInterface {
  document_id: string
  download_id: string
  document_name: string
  employee: EmployeeOptionInterface
  id: number
  category: IdAndName<string>
  deadline_date_time: string
  issued_date_time: string
  issuer: EmployeeOptionInterface
  recipient_id: string
  signed_date_time: string
  source: DocumentSources
  status: DocumentStatuses
}

export interface AllDocumentsStats {
  total: number
  pending_upload: number
  pending_signature: number
  pending_approval: number
}

export interface DocumentInterface {
  category: OptionInterface
  confidentiality: DocumentConfidentiality
  creation_date_time: string
  description: string
  employee: EmployeeOptionInterface
  field_options: FieldOptions
  file_media_type: string
  file_name: string
  file?: File
  id: number
  issue_date_time: string
  issuer: EmployeeOptionInterface
  name: string
  signing_date_time: string
  updated_date_time: string
  uploaded_by: EmployeeOptionInterface
}

export interface DocumentGenerateInterface {
  id: number
  name: string
  document_rule: IdAndName
  generated_by: IdAndName
  url?: string
}

export interface DocumentRequestInterface {
  id: number
  name: string
  document_rule: IdAndName
  requested_by: IdAndName
}

export interface DocumentUploadRequestInterface extends DocumentInterface {
  approval_date_time: string | null
  approval_status: IdAndName<ApprovalStatuses>
  approval_step: IdAndName<string>
  approver: ApproverInterface | null
  instructions: string
  description: string
  status: IdAndName<DocumentStatuses>
  document_template: IdAndName | null
  template_file_media_type: string | null
  template_file_name: string | null
}

export interface DocusignDocumentInterface {
  employee: EmployeeOptionInterface
  envelope: { id: string; email_subject: string }
  field_options: FieldOptions
  id: string
  sender: OptionInterface
  sent_date_time: string
  signed_date_time: string
  status: Statuses.pending | Statuses.completed
}

export interface EmbeddedDocumentInterface {
  creation_date_time: string
  document_name: string
  employee: EmployeeOptionInterface
  envelope_uuid: string
  environment: string
  is_envelope_completed: boolean
  is_envelope_recipient: boolean
  is_next_in_signing: boolean
  is_recipient?: boolean
  signers: {
    employee: EmployeeOptionInterface
    is_next_in_signing: boolean
    routing_order: number
    status: Statuses.pending | Statuses.completed
  }[]
  field_options: FieldOptions
  id: number
  rule: OptionInterface
  sent_by: OptionInterface
  status: Statuses.pending | Statuses.completed
  instructions?: string
  signed_on: string | null
  category?: IdAndName
}

export interface DocumentsCategoryInterface {
  category_id: number
  category_name: string
  documents_count: number
  documents_pending: number
}

export type DocumentsBulkRequestStatus = IdAndName<
  | 'validation_pending'
  | 'validation_in_progress'
  | 'validation_completed'
  | 'ready_to_be_submitted'
  | 'submit_in_progress'
  | 'submit_completed'
>

export interface DocumentsBulkRequestInterface {
  id: number
  created_by: EmployeeOptionInterface
  creation_date_time: string
  document_drafts: IdAndName[] | null
  document_templates: AttachedTemplateInterface[] | null
  dynamic_group: IdAndName | null
  employees_count: number
  employees_filters: TableFilter | null
  is_valid?: boolean
  issuer: EmployeeOptionInterface
  name: string
  status: DocumentsBulkRequestStatus
  update_date_time: string
}

export type DocumentsBulkRequestEligibilityGroupInterface = Pick<
  DocumentsBulkRequestInterface,
  'dynamic_group' | 'employees_filters'
>

export type DocumentsBulkRequestEmployeeValidationStatus = IdAndName<
  'validation_pending' | 'validation_completed' | 'validation_failed' | 'submit_completed'
>

export interface DocumentsBulkRequestEligibleEmployeeInterface {
  id: number
  employee: EmployeeOptionInterface
  errors: string[]
  status: DocumentsBulkRequestEmployeeValidationStatus
}

export interface DocumentsBulkRequestEligibleEmployeesStatsInterface {
  total: number
  submits_completed: number
  submits_failed: number
  validations_completed: number
  validations_failed: number
  validations_pending: number
}

export interface DocumentDraftInterface {
  id: number
  name: string
  issuer: EmployeeOptionInterface
  issue_date_time: string
  description: string
  category: IdAndName<string>
}

export type TemplateSqlFieldDataType = 'text' | 'number' | 'date' | 'money'
