import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { documentsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'

import { DocumentsIntro } from './DocumentsIntro'
import { DocumentsList } from './DocumentsList'
import { DocumentsUploadFiles } from './DocumentsUploadFiles'
import { DocumentsUploadSession } from './DocumentsUploadSession'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageWrapper } from '@components/Page/Page'
import { BREAKPOINTS } from '@revolut/ui-kit'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.INTRO,
    canView: [PermissionTypes.ViewDocumentsPreferences],
    component: DocumentsIntro,
  },
  {
    title: 'Import',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.LIST,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.LIST,
    canView: [PermissionTypes.ViewDocumentsPreferences],
    component: DocumentsList,
    isWide: true,
  },
]

const ImportWrapper: React.FC<{ maxWidthMd?: string }> = ({
  children,
  maxWidthMd = BREAKPOINTS.lg,
}) => (
  <PageWrapper>
    <PageHeader
      title="Import documents"
      backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.LIST}
    />
    <PageBody maxWidthMd={maxWidthMd}>{children}</PageBody>
  </PageWrapper>
)

export const Documents = () => (
  <Switch>
    <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.FILES}>
      <ImportWrapper maxWidthMd={BREAKPOINTS.md}>
        <DocumentsUploadFiles />
      </ImportWrapper>
    </Route>
    <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.UPLOAD.SESSION}>
      <ImportWrapper>
        <DocumentsUploadSession />
      </ImportWrapper>
    </Route>
    <Route>
      <OnboardingChecklistContent config={documentsConfig} routes={routes} />
    </Route>
  </Switch>
)
