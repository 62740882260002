import { RoleInterface } from './roles'
import { FieldOptions, Statuses } from './index'
import { EntityInterface } from './enitities'
import { TeamInterface } from './teams'
import { EmployeeOptionInterface, EmployeeType, IdStatuses } from './employees'
import { OptionInterface } from '@src/interfaces/selectors'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { LocationInterface as GlobalLocationInterface } from '@src/interfaces/locations'
import { ApprovalFlowStep, ApprovalStatuses } from '@src/interfaces/approvalFlow'

export enum Priority {
  Low = 'low',
  High = 'high',
  Company = 'company',
}

export interface NameIdInterface {
  id: number
  name: string
}

export interface LineManagerInterface {
  id: number
  full_name: string
  status: IdStatuses
}

export type CountryEmploymentLegalRiskType = 'high' | 'medium' | 'low'

export interface LocationInterface
  extends Pick<
    GlobalLocationInterface,
    | 'id'
    | 'location_name'
    | 'posting_compensation_enabled'
    | 'posting_compensation_period'
  > {
  name: string
  is_preferred?: boolean
  country_employment_legal_risk?: CountryEmploymentLegalRiskType | null
}

export interface GeneralJobDescriptionInterface {
  id: number
  name: string
  content: string
  format: string
}

export interface RequisitionDepartmentBudgetImpactInterface {
  current_approved_budget: {
    headcount: number
    staff_cost: number
  }
  current_run_rate: {
    headcount: number
    staff_cost: number
  }
  available_budget: {
    headcount: number
    staff_cost: number
  }
  requisition_impact: {
    headcount: number
    staff_cost: number | null
  }
  budget_after_requisition: {
    headcount: number
    staff_cost: number
  }
  requisition_impact_by_location: RequisitionBudgetImpactByLocationInterface
}

export interface RequisitionBudgetImpactByLocationInterface {
  [key: string]: {
    max_value: number
    min_value: number
  }
}

export interface RequisitionPosting {
  metabase_url: string
  posting_id: string
  posting_title: string
}

interface SpecialisationInterface extends OptionInterface {
  role_id?: number
  role: { id: number; name: string; status: string }
}

type RequisitionApprovalStep =
  | 'no_step'
  | 'department_owner'
  | 'function_owner'
  | 'cost_control'
  | 'uk_bank_cos'

export interface RequisitionInterface {
  approval_status?: ApprovalStatuses
  approval_step: RequisitionApprovalStep
  status_display?: string
  approval_flow_status?: {
    approval_steps: Partial<ApprovalFlowStep>[]
  }
  id: number
  full_name: string
  position: Partial<RoleInterface>
  specialisation: SpecialisationInterface
  seniority?: NameIdInterface
  seniority_min?: SeniorityInterface
  seniority_max?: SeniorityInterface
  team: Partial<TeamInterface>
  headcount_before?: number
  potential_start_date?: string
  estimated_fill_date?: string
  recruiter?: LineManagerInterface
  line_manager: LineManagerInterface
  main_location?: LocationInterface
  locations: LocationInterface[]
  quality_control?: LineManagerInterface
  entity?: EntityInterface
  status: Statuses
  backfill: boolean
  backfill_employees?: EmployeeOptionInterface[]
  is_published: boolean
  description: string
  priority: Priority
  owner?: LineManagerInterface
  postings?: RequisitionPosting[]
  headcount: number
  hired_headcount: number
  remaining_vs_total_headcount?: string
  creation_date_time: string
  requisition_title: string
  cv_screening_interviewers?: LineManagerInterface[]
  screencall_interviewers?: LineManagerInterface[]
  home_task_interviewers?: [
    {
      skill_id: number
      interviewers: LineManagerInterface[]
    },
  ]
  first_round_interviewers?: LineManagerInterface[]
  second_round_interviewers?: LineManagerInterface[]
  final_round_interviewers?: LineManagerInterface[]
  job_skills_interviewers?: LineManagerInterface[]
  general_job_description?: GeneralJobDescriptionInterface
  role_job_description?: string
  benefits_job_description?: GeneralJobDescriptionInterface
  general_notes?: string
  targeted_entities?: string
  red_flags?: string
  calibration?: string[]
  field_options: FieldOptions
  rejection_comment: string
  pipeline_queue_position: number
  pipeline_queue_total: number
  is_mrt_jira_ticket_url_required?: boolean
  is_mrt_jira_ticket_url_confirmed?: boolean
  is_regulated?: boolean
  is_confidential?: boolean
}

export interface StartDateOffsetInterface {
  minimum_start_date_offset_days: number
}

export const initialRequisitionStatusFilter = [
  { name: Statuses.pending, id: Statuses.pending },
  { name: Statuses.approved, id: Statuses.approved },
  { name: Statuses.opened, id: Statuses.opened },
]

export interface HireInterface {
  id: number
  full_name: string
  specialisation: NameIdInterface
  seniority: SeniorityInterface
  joining_date_time?: string
  status: IdStatuses
  candidate: {
    id: number
    recruiter: {
      id: number
      name: string
      full_name: string
      status: IdStatuses
      avatar: string | null
      employee_type: EmployeeType
    }
  }
}

export interface RequisitionTitlePostingInterface {
  options: JobPostingInterface[]
  publish_postings: boolean
}

export interface RequisitionBySpecialisationInterface
  extends Pick<
    RequisitionInterface,
    'id' | 'requisition_title' | 'headcount' | 'line_manager' | 'team'
  > {
  active_offer_count: number
  available_headcount: number
  hired_headcount: number
}

export interface RequisitionSelectorInterface
  extends Partial<Pick<RequisitionInterface, 'id' | 'requisition_title'>> {
  specialisation?: SpecialisationInterface
}

export interface RequisitionOptionInterface {
  id?: number
  name?: string
  value?: RequisitionSelectorInterface
}

export type RequisitionsJobPostingStats = {
  requisition_remaining_headcount: number
  requisition_total_headcount: number
}
