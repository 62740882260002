import React from 'react'
import { SendOnlineTestSidebar } from '@src/pages/Forms/SendOnlineTest/SendOnlineTestSidebar'

type Props = {
  onClose: () => void
  nextStageId: number
  candidateId: number
  roundId?: number
  onSuccess: () => void
}

export const CVScreeningSendOnlineTest = ({
  onClose,
  nextStageId,
  candidateId,
  roundId,
  onSuccess,
}: Props) => {
  return (
    <SendOnlineTestSidebar
      candidateId={candidateId}
      roundId={roundId}
      stageId={nextStageId}
      onSuccess={onSuccess}
      onClose={onClose}
    />
  )
}
