import React from 'react'
import {
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CommonCardProps } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { KpiInterface } from '@src/interfaces/kpis'
import { TableNames } from '@src/constants/table'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { Statuses } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  getKpiGradeColumn,
  kpiGenericNameColumn,
  kpiOwnerColumn,
  kpiPerformanceColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { useGetEmployeeWithManagerFilter } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import { usePersonalKPITable } from '@src/pages/EmployeeProfile/Preview/KPI/PersonalKPI'
import { GradesMapInterface } from '@src/utils/grades'

interface KPIsCardInterface extends CommonCardProps {
  employee: EmployeeInterface
  gradesMap: GradesMapInterface
}

export const getKpisRow: (
  gradesMap: GradesMapInterface,
) => RowInterface<KpiInterface> = gradesMap => ({
  disabled: data => data.status === Statuses.archived,
  linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id })),
  cells: [
    {
      ...kpiGenericNameColumn,
      width: 300,
    },
    {
      ...kpiWeightColumn,
      width: 60,
    },
    {
      ...kpiOwnerColumn,
      width: 80,
    },
    {
      ...kpiPerformanceColumn,
      title: 'Calibrated performance',
      width: 100,
    },
    {
      ...getKpiGradeColumn(gradesMap),
      width: 110,
    },
  ],
})

const KPIsCardTableContent = ({ employee, gradesMap }: KPIsCardInterface) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const cycleId = 'reviews' in values ? values.reviews[0].cycle?.id : values.cycle?.id

  const table = usePersonalKPITable(employee, undefined, [
    {
      columnName: 'cycle__id',
      filters: cycleId
        ? [{ name: String(cycleId), id: cycleId }]
        : [{ name: '0', id: 0 }],
      nonResettable: true,
    },
  ])

  return (
    <TableWidget style={{ padding: 0 }}>
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          name={TableNames.EmployeePerformanceKPI}
          dataType="KPI"
          row={getKpisRow(gradesMap)}
          {...table}
          noDataMessage="No KPI defined"
          onRowClick={onKPITableRowClick}
          noReset
          hideCountAndButtonSection
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export const KPIsCardTable = connect(
  ({ gradesMap }: { gradesMap: GradesMapInterface }) => {
    const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
    const { data: employee } = useGetEmployeeWithManagerFilter(
      'reviewed_employee' in values
        ? values.reviewed_employee.id
        : values.reviews[0].reviewed_employee.id,
      'line_manager__id',
    )

    if (!employee) {
      return null
    }

    return <KPIsCardTableContent employee={employee} gradesMap={gradesMap} />
  },
)
