import React, { useMemo, useState, useRef } from 'react'
import {
  getTargetSeniorityAndSpecialisation,
  PromotionCommonProps,
  PromotionParams,
} from '@src/pages/Forms/PromotionNominationForm/common/common'
import { useLocation, useParams } from 'react-router-dom'
import {
  Box,
  Cell,
  chain,
  FAB,
  Flex,
  Link,
  MoreBar,
  Radio,
  RadioGroup,
  Sticky,
  Text,
  useIntersectViewport,
  useTooltip,
  Tooltip as UiKitTooltip,
  VStack,
} from '@revolut/ui-kit'
import { BarChart, Chat, Document, ArrowThinDown } from '@revolut/icons'
import {
  PromotionLocationState,
  PromotionNominationInterface,
  PromotionStageInterface,
  PromotionStageReviewData,
} from '@src/interfaces/promotions'
import {
  getPromotionComments,
  updatePromotionStage,
  useGetPromotionNomination,
  useGetPromotionStage,
} from '@src/api/promotions'
import { KeeperGrade, RankGrade } from '@src/interfaces/performance'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import PageLoading from '@components/PageLoading/PageLoading'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import NomineeSummary from '@src/pages/Forms/PromotionNominationForm/common/NomineeSummary'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { IdAndName, Statuses } from '@src/interfaces'
import NewCheckboxGroup, {
  IdInterface,
} from '@components/Inputs/NewCheckboxGroup/NewCheckboxGroup'
import { PageBody } from '@components/Page/PageBody'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { connect } from 'lape'
import FormLocalstorageLape from '@src/features/Form/FormLocalstorageLape'
import { getStatusColor } from '@components/CommonSC/General'
import SummarySidebar from '@src/pages/Forms/PromotionNominationForm/common/SummarySidebar'
import SettingsButtons from '@src/features/SettingsButtons'
import DelegateButton from '@src/pages/Forms/PromotionNominationForm/Buttons/DelegateButton'
import { navigateReplace } from '@src/actions/RouterActions'
import startCase from 'lodash/startCase'
import { FormValidatorProvider } from '@src/features/Form/FormValidator'
import {
  answerOptions,
  answerRankOptions,
} from '@src/pages/Forms/PromotionNominationForm/common/common'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import PerformanceDataSidebar from '@src/pages/Forms/PromotionNominationForm/PerformanceDataSidebar'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import { EligibilityCriteria } from '@src/pages/Forms/PromotionNominationForm/common/EligibilityCriteria'
import { PermissionTypes } from '@src/store/auth/types'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { AnytimeFeedbackSidebarStandalone } from '@src/components/AnytimeFeedback/AnytimeFeedbackSidebarStandalone'
import { css } from 'styled-components'
import Tooltip from '@components/Tooltip/Tooltip'
import { EvidenceDescription } from './common/EvidenceDescription'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import ResetStageButton from '@src/pages/Forms/PromotionNominationForm/Buttons/ResetStageButton'

const buttonStyle = css`
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.tooltip};
`

interface StageReviewProps extends PromotionCommonProps {
  nomination: PromotionNominationInterface
  isReviewed: boolean
  onStageReset: () => void
}

enum SidebarType {
  DATA = 'data',
  SUMMARY = 'summary',
  FEEDBACK = 'feedback',
}

const rejectionReasons = [
  'Does not meet eligibility criteria',
  'Does not sufficiently deliver tasks at the proposed seniority and role',
  'Does not sufficiently demonstrate the skills required for the proposed seniority and role',
  'Insufficient evidence of consistent hiring of A-players into the team/department',
  'Insufficient evidence of building a self-sustaining machine where A-players are positioned for success',
]

const rejectionOptions: RadioSelectOption<IdInterface>[] = rejectionReasons.map(
  reason => ({
    label: reason,
    value: { id: reason },
  }),
)

const getRejectionValue = (
  options: IdInterface[],
): PromotionStageReviewData['rejection_reasons'] => {
  const selected = options.map(opt => opt.id)

  return rejectionReasons.map(reason => ({
    name: reason,
    value: selected.includes(reason),
  }))
}

const parseRejectionValue = (
  value: PromotionStageInterface['rejection_reasons'] | null,
): IdInterface[] | undefined => {
  if (!value) {
    return undefined
  }
  return value.reduce((result, reason) => {
    if (reason.value) {
      result.push({ id: reason.name })
    }
    return result
  }, [] as IdInterface[])
}

const StageReview = connect(
  ({ employee, nomination, isReviewed, onStageReset }: StageReviewProps) => {
    const { id } = useParams<PromotionParams>()
    const { state } = useLocation<PromotionLocationState>()
    const form = useLapeContext<PromotionStageInterface>()
    const { initialValues, values } = form
    const status: Statuses | undefined = isReviewed
      ? initialValues.outcome?.id
      : Statuses.pending
    const [hideButton, setHideButton] = useState(true)
    const scrollRef = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLDivElement>(null)
    const tooltip = useTooltip()

    const permissions = values.field_options?.permissions
    const showReset =
      permissions?.includes(PermissionTypes.ResetPromotionStage) &&
      (values.outcome.id === Statuses.rejected || values.outcome.id === Statuses.accepted)

    useIntersectViewport(buttonRef, () => {
      setHideButton(!hideButton)
    })

    const backUrl =
      state?.backUrl ||
      pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.PREVIEW, {
        id,
        employeeId: employee.id,
      })

    const employeeUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_SUMMARY, {
      id: employee.id,
    })

    const { targetSeniority, targetSpecialisation } =
      getTargetSeniorityAndSpecialisation(nomination)

    const rejectionReasonsValue = useMemo(() => {
      if (!values.rejection_reasons) {
        return undefined
      }
      return parseRejectionValue(values.rejection_reasons)
    }, [values.rejection_reasons])

    const commentsApi = useMemo(() => {
      return getPromotionComments(nomination.id)
    }, [nomination.id])

    const [openedSidebar, setOpenedSidebar] = useState<SidebarType | null>(null)
    const toggleSidebar = (sidebar: SidebarType) => {
      setOpenedSidebar(sidebar === openedSidebar ? null : sidebar)
    }

    const handleScroll = () => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    return (
      <>
        {nomination.id && (
          <PerformanceDataSidebar
            nominationId={String(nomination.id)}
            employee={employee}
            onClose={() => toggleSidebar(SidebarType.DATA)}
            isOpen={openedSidebar === SidebarType.DATA}
          />
        )}
        <SummarySidebar
          isOpen={openedSidebar === SidebarType.SUMMARY}
          onClose={() => toggleSidebar(SidebarType.SUMMARY)}
          nomination={nomination}
          employee={employee}
        />
        <AnytimeFeedbackSidebarStandalone
          userId={employee.id}
          isOpen={openedSidebar === SidebarType.FEEDBACK}
          onClose={() => toggleSidebar(SidebarType.FEEDBACK)}
          nominationId={nomination.id}
        />
        <PageWrapper>
          <PageHeader
            title={chain(
              values.stage_type.name,
              status ? (
                <Text color={getStatusColor(status)}>{startCase(status)}</Text>
              ) : undefined,
            )}
            backUrl={backUrl}
            routerAction={PageHeaderRouterAction.navigate}
            subtitle={
              <>
                <Link color="inherit" target="_blank" to={employeeUrl} use={InternalLink}>
                  {employee.full_name}
                </Link>
                ,{' '}
                <Link
                  color="inherit"
                  target="_blank"
                  to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                    id: employee.specialisation?.id,
                  })}
                  use={InternalLink}
                >
                  {employee.specialisation?.name}
                </Link>
                ,{' '}
                <Link
                  color="inherit"
                  target="_blank"
                  to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, {
                    id: employee.team?.id,
                  })}
                  use={InternalLink}
                >
                  {employee.team?.name}
                </Link>
              </>
            }
          />
          <PageBody>
            <SettingsButtons pb="s-24">
              <MoreBar.Action
                useIcon={Chat}
                onClick={() => toggleSidebar(SidebarType.FEEDBACK)}
              >
                Show feedbacks
              </MoreBar.Action>
              <MoreBar.Action
                useIcon={BarChart}
                onClick={() => toggleSidebar(SidebarType.DATA)}
              >
                View performance data
              </MoreBar.Action>
              <MoreBar.Action
                onClick={() => toggleSidebar(SidebarType.SUMMARY)}
                useIcon={Document}
              >
                View nomination summary
              </MoreBar.Action>
              {values.outcome.id === Statuses.pending && (
                <DelegateButton
                  nominationId={nomination.id}
                  stage={values}
                  nominatedEmployee={employee}
                  variant="button"
                  onStatusPopupClose={() => navigateReplace(backUrl)}
                />
              )}
              {showReset && (
                <ResetStageButton
                  nominationId={String(nomination.id)}
                  employeeId={String(employee.id)}
                  stageId={String(values.id)}
                  onSuccess={onStageReset}
                  actionButton
                />
              )}
            </SettingsButtons>
            <NomineeSummary
              nomination={nomination}
              employee={employee}
              employeeUrl={employeeUrl}
              targetSeniority={targetSeniority}
              targetSpecialisation={targetSpecialisation}
              hideBanner
            />

            <AutoStepper>
              <AutoStepperTitle
                title="Promotion criteria"
                subtitle="Please review the eligibility and evidence for this promotion nomination"
              />

              <VStack space="s-16">
                <Text variant="h6" color="grey-tone-50">
                  Eligibility criteria
                </Text>

                {nomination.progression_track && (
                  <Cell my="s-16">
                    <EligibilityCriteria
                      employeeId={employee.id}
                      targetSeniority={targetSeniority}
                      targetSpecialisation={targetSpecialisation}
                      nominationId={String(nomination.id)}
                      showSubtitle
                      employee={employee}
                      nomination={nomination}
                      progressionTrack={nomination.progression_track}
                    />
                  </Cell>
                )}

                <Text variant="h6" color="grey-tone-50">
                  Justification and evidence cases
                </Text>

                <Cell my="s-16">
                  <VStack space="s-32">
                    <VStack space="s-8">
                      <Text variant="primary">
                        Why should this employee be reviewed now?
                      </Text>
                      <Text variant="caption">
                        {nomination.business_requirements_justification}
                      </Text>
                    </VStack>
                    {nomination.justification.map((item, i) => (
                      <VStack space="s-8" key={i}>
                        <Text variant="primary">Evidence case {i + 1}</Text>
                        <Text variant="secondary" whiteSpace="break-spaces">
                          <EvidenceDescription text={item} />
                        </Text>
                      </VStack>
                    ))}
                  </VStack>
                </Cell>

                <Text variant="h6" color="grey-tone-50">
                  Manager recommendations
                </Text>

                <Cell>
                  <VStack space="s-24">
                    <Flex justifyContent="space-between">
                      <Text variant="caption" color="grey-tone-50">
                        A. This employee exemplifies our core values at all times and acts
                        as a culture champion within their current peer group (the same
                        seniority and function)?
                      </Text>
                      <Text variant="caption" width="20%" textAlign="end">
                        {nomination.recommendation_data.is_culture_champion.name}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text variant="caption" color="grey-tone-50">
                        B. This employee has demonstrated skills and capabilities
                        exceeding their current peer group (the same seniority and
                        function)?
                      </Text>
                      <Text variant="caption" width="20%" textAlign="end">
                        {
                          nomination.recommendation_data
                            .are_skills_exceeding_current_position.name
                        }
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text variant="caption" color="grey-tone-50">
                        C. This employee has demonstrated skills and capabilities
                        exceeding their future peer group (proposed new seniority and
                        function)?
                      </Text>
                      <Text variant="caption" width="20%" textAlign="end">
                        {
                          nomination.recommendation_data
                            .are_skills_exceeding_future_position.name
                        }
                      </Text>
                      <Box ref={scrollRef} />
                    </Flex>
                  </VStack>
                </Cell>
              </VStack>

              {!hideButton && (
                <Sticky display="flex" bottom={34} css={buttonStyle}>
                  <Tooltip placement="top" text="Scroll to approval form">
                    <FAB
                      elevated
                      useIcon={ArrowThinDown}
                      aria-label="Scroll down"
                      variant="white"
                      onClick={handleScroll}
                    />
                  </Tooltip>
                </Sticky>
              )}
              <AutoStepperTitle
                title="Fill this form"
                subtitle="Make your decision and justify it"
              />
              <Cell ref={buttonRef}>
                <Box>
                  <>
                    <Text use="p" variant="primary" pb="s-16">
                      1. How should we proceed?
                    </Text>
                    <VStack space="s-16" {...tooltip.getAnchorProps()}>
                      {isReviewed && (
                        <UiKitTooltip {...tooltip.getTargetProps()} placement="top">
                          Please reset the status to change the result
                        </UiKitTooltip>
                      )}
                      <RadioGroup<Statuses>
                        disabled={form.disabled || isReviewed}
                        value={values.outcome.id}
                        onChange={value => {
                          if (value) {
                            values.outcome = { id: value, name: value }
                          }
                        }}
                      >
                        {group => (
                          <>
                            <Radio {...group.getInputProps({ value: Statuses.accepted })}>
                              <Radio.Label>Proceed with promotion</Radio.Label>
                              <Radio.Description>
                                By clicking this you confirm; this individual delivers on
                                complexity, speed, and quality of tasks at the proposed
                                seniority and role, as well as showing a mastery of skills
                                required.
                              </Radio.Description>
                            </Radio>
                            <Radio {...group.getInputProps({ value: Statuses.rejected })}>
                              <Radio.Label>
                                <Text color="red">Reject Promotion</Text>
                              </Radio.Label>
                            </Radio>
                          </>
                        )}
                      </RadioGroup>
                    </VStack>
                  </>
                  <Box mt="s-32">
                    {values.outcome.id === Statuses.rejected && (
                      <>
                        <Text use="p" variant="primary" pb="s-16">
                          2. Reasons for rejection{' '}
                          <Text color="grey-tone-50">(Select all that apply)</Text>
                        </Text>
                        <VStack space="s-16">
                          <NewCheckboxGroup<IdInterface>
                            disabled={form.disabled}
                            options={rejectionOptions}
                            value={rejectionReasonsValue}
                            onChange={selected => {
                              values.rejection_reasons = getRejectionValue(selected)
                            }}
                          />
                        </VStack>
                      </>
                    )}
                  </Box>
                  <Box mt="s-32">
                    <Text use="p" variant="primary" pb="s-16">
                      {values.outcome.id === Statuses.rejected ? '3' : '2'}. Please
                      justify your decision
                    </Text>
                    {form.disabled ? (
                      <Text whiteSpace="pre-wrap">{values.decision_justification}</Text>
                    ) : (
                      <LapeNewTextArea
                        name="decision_justification"
                        label="Enter here"
                        rows={2}
                        required
                      />
                    )}
                  </Box>
                </Box>
              </Cell>
              <AutoStepperTitle
                title="Recommendations"
                subtitle="Select any recomendations that can help to proceed with this promotion"
              />
              <Cell display="block">
                <VStack space="s-32">
                  <Box>
                    <Text variant="primary" use="p" pb="s-16">
                      A. This employee exemplifies our core values at all times and acts
                      as a culture champion within their <u>current</u> peer group (the
                      seniority and function)?
                    </Text>
                    <LapeRadioSelectInput<IdAndName<KeeperGrade>>
                      label="Answer"
                      name="recommendation_data.is_culture_champion"
                      options={answerOptions}
                      required={values.outcome.id === Statuses.accepted}
                    />
                  </Box>
                  <Box>
                    <Text variant="primary" use="p" pb="s-16">
                      B. This employee has demonstrated skills and capabilities exceeding
                      their <u>current</u> peer group (the same seniority and function)?
                    </Text>
                    <LapeRadioSelectInput<IdAndName<KeeperGrade>>
                      label="Answer"
                      name="recommendation_data.are_skills_exceeding_current_position"
                      options={answerOptions}
                      required={values.outcome.id === Statuses.accepted}
                    />
                  </Box>
                  <Box>
                    <Text variant="primary" use="p" pb="s-16">
                      C. This employee has demonstrated skills and capabilities exceeding
                      their <u>future</u> peer group (proposed new seniority and
                      function)?
                    </Text>
                    <LapeRadioSelectInput<IdAndName<KeeperGrade>>
                      label="Answer"
                      name="recommendation_data.are_skills_exceeding_future_position"
                      options={answerOptions}
                      required={values.outcome.id === Statuses.accepted}
                    />
                  </Box>
                  <Box>
                    <Text variant="primary" use="p" pb="s-16">
                      D. How would you rank this employee against other promotion
                      nominations within your department?
                    </Text>
                    <LapeRadioSelectInput<IdAndName<RankGrade>>
                      label="Answer"
                      name="recommendation_data.employee_department_rank"
                      options={answerRankOptions}
                      required={values.outcome.id === Statuses.accepted}
                    />
                  </Box>
                </VStack>
              </Cell>
            </AutoStepper>
            {commentsApi && (
              <Box>
                <CommentsSection api={commentsApi} />
              </Box>
            )}
          </PageBody>
          {!form.disabled && values.outcome.id !== Statuses.pending && (
            <PageActions>
              <NewSaveButtonWithPopup
                afterSubmitUrl={backUrl}
                useValidator
                onAfterSubmit={() => {
                  localStorage.removeItem(
                    window.location.pathname + window.location.search,
                  )
                }}
              >
                Save changes
              </NewSaveButtonWithPopup>
            </PageActions>
          )}
        </PageWrapper>
      </>
    )
  },
)

export default (props: PromotionCommonProps) => {
  const url = window.location.pathname + window.location.search
  const savedData = localStorage.getItem(url)
  const parsedSavedData = savedData ? JSON.parse(savedData) : {}

  const { id, stageId } = useParams<PromotionParams>()
  const { data: nomination, refetch: refetchNomination } = useGetPromotionNomination(
    props.employee.id,
    id,
  )
  const { data: stageData, refetch: refetchStage } = useGetPromotionStage(id, stageId)

  if (!nomination || !stageData) {
    return <PageLoading />
  }

  const isPendingStage = stageData.outcome?.id === Statuses.pending
  const allowEdit =
    isPendingStage ||
    stageData.field_options.permissions?.includes(PermissionTypes.ResetPromotionStage)

  const onStageReset = () => {
    refetchNomination()
    refetchStage()
  }

  return (
    <LapeForm<PromotionStageInterface>
      disabled={!allowEdit}
      initialValues={{ ...stageData, ...(isPendingStage ? parsedSavedData : {}) }}
      onSubmit={form =>
        updatePromotionStage({
          nominationId: id,
          stageId,
          stageData: {
            ...pick(form.values, ['outcome', 'new_salary', 'decision_justification']),
            recommendation_data: isEmpty(form.values.recommendation_data)
              ? undefined
              : form.values.recommendation_data,
            rejection_reasons:
              form.values.outcome.id === Statuses.rejected
                ? form.values.rejection_reasons
                : [],
          },
        }).then(res => res.data)
      }
    >
      <FormValidatorProvider>
        <StageReview
          {...props}
          nomination={nomination}
          onStageReset={onStageReset}
          isReviewed={!isPendingStage}
        />
      </FormValidatorProvider>
      <FormLocalstorageLape
        isExistingData={!isPendingStage}
        disabled={!allowEdit}
        url={url}
      />
    </LapeForm>
  )
}
