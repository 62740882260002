import React, { ReactNode, useEffect, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { Id, IdAndName } from '@src/interfaces'
import { CellWithItem } from '../../../common/CellWithItem'
import AddGoalTargetForm from '../../SidebarForms/AddGoalTargetForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import {
  Avatar,
  Caption,
  ErrorWidget,
  Flex,
  HStack,
  Icon,
  Text,
  TextButton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { useGetGoal } from '@src/api/goals'
import { TargetCell } from './TargetCell'
import { BorderedCell } from './PersistentExpandableCell'

interface TargetsWidgetProps {
  reviewCycle: ReviewCyclesInterface
  onTypeChanged: (type?: UpdateTypes) => void
}

export const TargetsWidget = ({ reviewCycle, onTypeChanged }: TargetsWidgetProps) => {
  const { values } = useLapeContext<GoalsInterface>()

  const isCascaded = values.update_type?.id === 'cascaded'

  const editTitle = isCascaded ? 'Parent targets' : 'Add targets'
  const [expandedIndex, setExpandedIndex] = useState<number>()
  const [parentExpandedIndex, setParentExpandedIndex] = useState<number>()

  const isCascadedGoal = values.update_type?.id === UpdateTypes.cascaded
  const isAggregatedGoal = values.update_type?.id === UpdateTypes.aggregated

  const { getEntityProps } = useOrgEntity()
  const emptyTarget = {
    ...getEntityProps(),
    tempId: Math.ceil(Math.random() * 10e6),
    owner: values.owner as EmployeeOptionInterface & { team: IdAndName },
    goal: { id: values.id },
    targets: [{ review_cycle: reviewCycle }],
    update_type: isCascadedGoal
      ? UpdateTypes.cascaded
      : isAggregatedGoal
      ? UpdateTypes.aggregated
      : undefined,
  } as unknown as KpiInterface & { tempId?: number }

  useEffect(() => {
    if (values.kpis.length === 0) {
      values.kpis.push(emptyTarget)
      setExpandedIndex(0)
    }
  }, [values.kpis.length])

  const onAddClicked = async () => {
    values.kpis.push(emptyTarget)
    setExpandedIndex(values.kpis.length - 1)
  }

  const hasSavedTargets = !!(values.kpis?.length && values.kpis[0].id)

  const renderCards = (cards: KpiInterface[]) => {
    return cards.map((target, index) => {
      return (
        <TargetCell
          target={target}
          index={index + 1}
          key={target.id}
          expanded={expandedIndex === index}
          onToggle={() => {
            return expandedIndex === index
              ? setExpandedIndex(undefined)
              : setExpandedIndex(index)
          }}
        >
          <AddGoalTargetForm
            onTypeChanged={onTypeChanged}
            hideAggregated={hasSavedTargets || values.content_type?.model === 'employees'}
            hideCascaded={hasSavedTargets || values.is_company}
            initialValues={target}
          />
        </TargetCell>
      )
    })
  }

  // const hasFormErrors = checkFormErrors(form)

  return (
    <CellWithItem
      icon="TurboTransfer"
      title={editTitle}
      description="What are the success criteria and how is progress measured?"
    >
      {renderCards(values.kpis)}
      {values.update_type?.id === UpdateTypes.cascaded && (
        <BorderedCell>
          <VStack width="100%" space="s-12" mb="s-16">
            <ParentTargetsWidget
              parent={values.parent}
              renderTarget={(target, index) => (
                <TargetCell
                  allowDelete={false}
                  key={target.id}
                  target={target}
                  index={index + 1}
                  expanded={parentExpandedIndex === index}
                  onToggle={() =>
                    parentExpandedIndex === index
                      ? setParentExpandedIndex(undefined)
                      : setParentExpandedIndex(index)
                  }
                >
                  <AddGoalTargetForm
                    onTypeChanged={onTypeChanged}
                    hideAggregated={
                      !!values.kpis.length || values.content_type?.model === 'employees'
                    }
                    hideCascaded={!!values.kpis?.length || values.is_company}
                    initialValues={target}
                  />
                </TargetCell>
              )}
            />
          </VStack>
        </BorderedCell>
      )}
      {values.update_type?.id === 'aggregated' && (
        <BorderedCell>
          <VStack width="100%" space="s-12" mb="s-16">
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              p="s-24"
            >
              <Icon name="TurboTransfer" color={Token.color.greyTone20} />
              <ErrorWidget.Description
                color={Token.color.greyTone20}
                maxWidth={300}
                textAlign="center"
              >
                Progress will track the total from all child goals linked to this one
              </ErrorWidget.Description>
            </Flex>
          </VStack>
        </BorderedCell>
      )}

      {values.update_type?.id === 'target_based' ? (
        <TextButton onClick={onAddClicked}>
          <HStack align="center" space="s-12">
            <Avatar useIcon="16/Plus" size={32} />
            <Text>Add another target</Text>
          </HStack>
        </TextButton>
      ) : null}
    </CellWithItem>
  )
}

function ParentTargetsWidget({
  parent,
  renderTarget,
}: {
  parent?: (Id & Partial<GoalsInterface>) | null
  renderTarget: (target: KpiInterface, index: number) => ReactNode
}) {
  const { data, isLoading } = useGetGoal(parent?.id)

  if (isLoading) {
    // todo: add a proper loading state
    return null
  }

  return data?.kpis.length ? (
    <>
      <Caption color={Token.color.greyTone50}>Filled from parent goal</Caption>
      {data?.kpis.map(renderTarget)}
    </>
  ) : (
    <Flex justifyContent="center" alignItems="center" flexDirection="column" p="s-24">
      <Icon name="TurboTransfer" color={Token.color.greyTone20} />
      <ErrorWidget.Description color={Token.color.greyTone20}>
        {parent ? 'Set parent goal targets' : 'Select a parent goal to preview targets'}
      </ErrorWidget.Description>
    </Flex>
  )
}
