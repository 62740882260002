import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ImportTimeOffData } from './ImportTimeOffData'
import { ROUTES } from '@src/constants/routes'
import { TimeOffRequestsSession } from './TimeOffRequests/TimeOffRequestsSession'
import { TimeOffBalancesSession } from './TimeOffBalances/TimeOffBalancesSession'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

export const ImportTimeOff = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Time off"
        subtitle="Update your historical time-off records to ensure accurate leave balances for all employees"
        backUrl={ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.REQUESTS}
      />

      <Switch>
        <Route path={ROUTES.FORMS.IMPORT_DATA.TIME_OFF.UPLOAD_FILE} exact>
          <ImportTimeOffData />
        </Route>
        <Route path={ROUTES.FORMS.IMPORT_DATA.TIME_OFF.REQUESTS_SESSION} exact>
          <TimeOffRequestsSession />
        </Route>
        <Route path={ROUTES.FORMS.IMPORT_DATA.TIME_OFF.BALANCES_SESSION} exact>
          <TimeOffBalancesSession />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
