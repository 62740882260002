import pluralize from 'pluralize'
import { assign, capitalize, pick } from 'lodash'
import {
  Box,
  Button,
  ExpandableCell,
  Flex,
  HStack,
  InputGroup,
  Item,
  Subheader,
  VStack,
  Widget,
} from '@revolut/ui-kit'

import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { AccrualPeriodType, TimeOffPolicyDetailsStep } from '@src/interfaces/timeOff'
import { LapeRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import React, { useEffect } from 'react'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { TitleAndSubtitle } from '@src/components/TitleAndSubtitle'
import PageLoading from '@components/PageLoading/PageLoading'

import { ActivePolicyDraftWarning } from '../components/ActivePolicyDraftWarning'
import { NextTabButton } from './NextTabButton'
import { isReadonly, PolicyEditTabCommonProps } from './common'

const TimeOffCycleResetWidget = ({ isUnlimited }: { isUnlimited?: boolean }) => {
  const { values } = useLapeContext<TimeOffPolicyDetailsStep>()

  const isAccrualYearly = values.accrual_period?.id === 'yearly'
  const isBalanceClosingPeriodReadOnly = values.field_options?.read_only.includes(
    'balance_closing_period',
  )

  const isClosingAnniversary = values.balance_closing_period?.id === 'anniversary'
  const isClosingYearly = values.balance_closing_period?.id === 'yearly'

  const adjustAccrual = () => {
    if (!isAccrualYearly || isUnlimited) {
      return
    }
    values.accrual_period_day_of_month = values.balance_closing_period_day_of_month
    values.accrual_period_month = values.balance_closing_period_month
  }

  useEffect(() => {
    if (isAccrualYearly) {
      values.balance_closing_period = { id: 'yearly', name: 'Yearly' }
      adjustAccrual()
    }
  }, [isAccrualYearly])

  return (
    <Widget data-testid="cycle-reset-widget">
      <HStack gap="s-8" p="s-16" align="center">
        <Subheader>The time off cycle resets</Subheader>
        <Box width={isClosingAnniversary ? '250px' : '150px'}>
          <LapeRadioSelectInput
            name="balance_closing_period"
            label="Period"
            selector={selectorKeys.time_off_balance_closing_periods}
            disabled={
              isAccrualYearly ||
              isBalanceClosingPeriodReadOnly ||
              isReadonly(values, 'balance_closing_period')
            }
            onAfterChange={() => {
              values.balance_closing_period_day_of_month = null
              values.balance_closing_period_month = null
            }}
          />
        </Box>
        {!isClosingAnniversary && (
          <>
            <Subheader>on:</Subheader>
            {isClosingYearly && (
              <Flex flex="1">
                <LapeRadioSelectInput
                  name="balance_closing_period_month"
                  label="Month"
                  selector={selectorKeys.months}
                  clearable
                  onAfterChange={adjustAccrual}
                />
              </Flex>
            )}
            <Flex flex="1">
              <LapeNewInput
                name="balance_closing_period_day_of_month"
                label="Day"
                required
                type="number"
                onAfterChange={adjustAccrual}
              />
            </Flex>
          </>
        )}
      </HStack>
    </Widget>
  )
}

export const PolicyEditDetailsTab = ({
  copyFrom,
  basicStepInfo,
  setIsCopied,
  nextTabPath,
  onNavigateToNextTab,
}: PolicyEditTabCommonProps) => {
  const { values } = useLapeContext<TimeOffPolicyDetailsStep>()

  useEffect(() => {
    if (!copyFrom) {
      return
    }
    assign(
      values,
      pick(copyFrom, [
        'accrual_amount',
        'accrual_period',
        'accrual_period_day_of_month',
        'accrual_period_day_of_week',
        'accrual_period_month',
        'balance_closing_period',
        'balance_closing_period_day_of_month',
        'balance_closing_period_month',
        'balance_rounding_logic',
        'balance_rounding_precision',
        'can_use_leave_not_accrued',
        'carryover_amount',
        'carryover_expiry_day_of_month',
        'carryover_expiry_month',
        'carryover_limit',
        'first_accrual',
        'half_days',
        'is_negative_balance_allowed',
        'max_request_duration',
        'maximum_balance',
        'prorate_accruals_with_fte',
      ]),
    )
    setIsCopied()
  }, [copyFrom])

  const unit = (amount?: number | null) =>
    pluralize(basicStepInfo?.unit?.name || 'day', amount || 0).toLowerCase()
  const isUnlimited = basicStepInfo?.balance_type.id === 'unlimited'

  useEffect(() => {
    if (isUnlimited) {
      values.balance_rounding_logic = null
      values.balance_rounding_precision = null
      return
    }
    if (!values.balance_rounding_precision) {
      values.balance_rounding_precision = { id: 'two_decimal', name: 'Two Decimal' }
    }
    if (!values.balance_rounding_logic) {
      values.balance_rounding_logic = {
        id: 'round',
        name: 'Round',
      }
    }
  }, [values.balance_rounding_precision, values.balance_rounding_logic, isUnlimited])

  useEffect(() => {
    if (
      values.carryover_limit?.id === 'no_carryover' ||
      values.carryover_limit?.id === 'unlimited_carryover'
    ) {
      values.carryover_amount = null
    }
  }, [values.carryover_limit])

  if (!basicStepInfo) {
    return <PageLoading />
  }
  return (
    <>
      {values.draft_has_active_policy && <ActivePolicyDraftWarning />}
      {isUnlimited ? (
        <>
          <SectionTitle title="When should the time off cycle reset?" />
          <TimeOffCycleResetWidget />
        </>
      ) : (
        <VStack gap="s-16">
          <Box>
            <SectionTitle
              title={`When and how many ${unit(
                values.accrual_amount,
              )} should your employees earn?`}
              subtitle={`Set how many ${unit(
                values.accrual_amount,
              )} they accrue, at which rate and also when the balance closes.`}
            />
            <VStack gap="s-8">
              <Widget data-testid="accrual-widget">
                <HStack gap="s-8" padding="s-16" align="center">
                  <Subheader>Employees will gain</Subheader>
                  <LapeNewInput
                    name="accrual_amount"
                    label="Accrual amount"
                    required
                    width="170px"
                  />
                  <Subheader>{unit(values.accrual_amount)} every</Subheader>
                  <Flex flex="1">
                    <LapeRadioSelectInput<AccrualPeriodType>
                      name="accrual_period"
                      label="Cycle"
                      clearable
                      selector={selectorKeys.time_off_accrual_periods_noun}
                      required
                      searchable={false}
                      onAfterChange={() => {
                        values.accrual_period_day_of_month = null
                        values.accrual_period_day_of_week = null
                        values.accrual_period_month = null
                      }}
                    />
                  </Flex>
                </HStack>
              </Widget>
              {values.accrual_period?.id &&
                !['daily', 'anniversary', 'yearly'].includes(
                  values.accrual_period?.id,
                ) && (
                  <Widget>
                    <HStack gap="s-8" padding="s-16" align="start">
                      <Subheader>{capitalize(unit())} will be earned on</Subheader>
                      {values.accrual_period?.id === 'monthly' ? (
                        <Flex flex="1" maxWidth={300}>
                          <LapeRadioSelectInput
                            searchable={false}
                            name="accrual_period_monthly"
                            selector={
                              selectorKeys.time_off_accrual_periods_monthy_choices
                            }
                          />
                        </Flex>
                      ) : null}
                      {values.accrual_period?.id === 'weekly' && (
                        <Flex flex="1" maxWidth={300}>
                          <LapeRadioSelectInput
                            name="accrual_period_day_of_week"
                            label="Day of week"
                            selector={selectorKeys.days_of_the_week}
                          />
                        </Flex>
                      )}
                      {values.accrual_period?.id === 'monthly' &&
                        values.accrual_period_monthly?.id === 'specific_date' && (
                          <Flex flex="1" maxWidth={200}>
                            <LapeNewInput
                              name="accrual_period_day_of_month"
                              label="Day of month"
                              required
                              type="number"
                            />
                          </Flex>
                        )}
                    </HStack>
                  </Widget>
                )}
              <TimeOffCycleResetWidget />
            </VStack>
          </Box>

          <>
            <Box>
              <SectionTitle title="Should employees be able to carry over their leave after the cycle resets?" />
              <VStack gap="s-8">
                <LapeRadioSwitch<IdAndName<string>>
                  name="carryover_limit"
                  variant="horizontal"
                  options={[
                    {
                      id: 'unlimited_carryover',
                      label: 'Unlimited',
                      description: `Allows employees to carry over all unused leave ${unit()}.`,
                      value: {
                        id: 'unlimited_carryover',
                        name: 'Unlimited carryover',
                      },
                    },
                    {
                      id: 'yes',
                      label: 'Limited',
                      description: `Limits the carry-over at a specific number of ${unit()}.`,
                      value:
                        basicStepInfo.unit.id === 'day'
                          ? {
                              id: 'limited_number_of_days',
                              name: 'Limited number of days',
                            }
                          : {
                              id: 'limited_number_of_hours',
                              name: 'Limited number of hours',
                            },
                    },
                    {
                      id: 'no_carryover',
                      label: 'None',
                      description:
                        'Unused leave expires each cycle, with no option to carry over.',
                      value: {
                        id: 'no_carryover',
                        name: 'No carryover',
                      },
                    },
                  ]}
                />
                {values.carryover_limit?.id.includes('limited') && (
                  <>
                    {values.carryover_limit?.id.includes('limited_number') && (
                      <Widget>
                        <HStack gap="s-8" padding="s-16" align="center">
                          <Subheader>Employees should be able to carry over</Subheader>
                          <LapeNewInput
                            name="carryover_amount"
                            label="Carry over amount"
                            required
                            width="170px"
                          />
                          <Subheader>{unit(values.carryover_amount)}.</Subheader>
                        </HStack>
                      </Widget>
                    )}
                    <Widget data-testid="carryover-expiry-widget">
                      <HStack gap="s-8" padding="s-16" align="center">
                        <Subheader>The carry over should expire on:</Subheader>
                        <Flex flex="1">
                          <LapeRadioSelectInput
                            name="carryover_expiry_month"
                            label="Month"
                            selector={selectorKeys.months}
                            disabled={
                              !!values.carryover_no_expiry ||
                              isReadonly(values, 'carryover_expiry_month')
                            }
                            clearable
                          />
                        </Flex>
                        <Flex flex="1">
                          <LapeNewInput
                            name="carryover_expiry_day_of_month"
                            label="Day"
                            required
                            type="number"
                            disabled={
                              !!values.carryover_no_expiry ||
                              isReadonly(values, 'carryover_expiry_day_of_month')
                            }
                          />
                        </Flex>
                      </HStack>
                    </Widget>
                  </>
                )}
              </VStack>
            </Box>
            <Box>
              <SectionTitle
                title="Should the first leave earning be proportionate or given in full?"
                subtitle="For example, an employee that joins in the middle of the year will receive all of the time off for the year or just half?"
              />
              <LapeRadioSwitch<IdAndName<string>>
                name="first_accrual"
                variant="horizontal"
                options={[
                  {
                    id: 'full_amount',
                    label: 'In full',
                    description:
                      'Even when employees join in the middle of the period, they will receive the full amount.',
                    value: { id: 'full_amount', name: 'Full amount' },
                  },
                  {
                    id: 'prorate_amount',
                    label: 'Proportional',
                    description:
                      'If an employee joins in the middle of the period, they will receive proportional to their start date.',
                    value: { id: 'prorate_amount', name: 'Prorate amount' },
                  },
                ]}
              />
            </Box>
            <Box>
              <VStack gap="s-16">
                <SectionTitle
                  title="Should employees be able to use leave they haven't earned yet?"
                  subtitle="Even if you currently have 5 days off in balance, you can take the full 10 days off that you're set to earn"
                />
                <LapeRadioSwitch<boolean>
                  name="can_use_leave_not_accrued"
                  variant="horizontal"
                  options={[
                    {
                      id: 'allow',
                      label: 'Allow using unearned leave',
                      description:
                        "This will allow employees to use their time off before it's accrued",
                      value: true,
                    },
                    {
                      id: 'disallow',
                      label: 'Disallow using unearned leave',
                      description:
                        "This will won't allow employees to use their time off before it's accrued",
                      value: false,
                    },
                  ]}
                />
                <ExpandableCell
                  aria-label="Advanced pay authorities frequency settings"
                  padding="s-2"
                >
                  <ExpandableCell.Title mx="s-16" my="s-8">
                    Advanced schedule settings
                  </ExpandableCell.Title>
                  <ExpandableCell.Note>
                    {basicStepInfo?.unit?.id !== 'hour' && (
                      <LapeNewSwitch
                        itemTypeProps={{
                          title: 'Employees should be able to take half a day time off',
                          description:
                            'For example, should someone be able to take an afternoon or morning off.',
                        }}
                        checked={values.half_days?.id === 'yes'}
                        name="half_days"
                        onAfterChange={checked => {
                          if (checked) {
                            values.half_days = {
                              id: 'yes',
                              name: 'Can request half-days',
                            }
                          } else {
                            values.half_days = {
                              id: 'no',
                              name: 'Can only request full days',
                            }
                          }
                        }}
                      />
                    )}
                    <LapeNewSwitch
                      itemTypeProps={{
                        title: 'The carry-over for this time off policy does not expire',
                        description: `This indicates that carry-over will accumulate until utilised or the balance cap is met.`,
                      }}
                      name="carryover_no_expiry"
                      onAfterChange={checked => {
                        if (checked) {
                          values.carryover_expiry_month = null
                          values.carryover_expiry_day_of_month = null
                        }
                      }}
                    />
                    <LapeNewSwitch
                      itemTypeProps={{
                        title:
                          'Time off should be proportional to their contract working hours?',
                        description: `For example, if I work 30 hours instead of 40, I would receive less ${unit()} off.`,
                      }}
                      name="prorate_accruals_with_fte"
                    />
                    <Item>
                      <Item.Content>
                        <Item.Title>There should be a maximum balance allowed</Item.Title>
                        <Item.Description>
                          If an employee’s balance for this policy goes above the maximum
                          value, it will be capped
                        </Item.Description>
                      </Item.Content>
                      <Item.Side>
                        <Box width={150}>
                          <LapeNewInput
                            name="maximum_balance"
                            label={`Maximum (${unit()})`}
                            hideOptional
                          />
                        </Box>
                      </Item.Side>
                    </Item>
                    <Item>
                      <Item.Content>
                        <Item.Title>How should the balance be rounded?</Item.Title>
                        <Item.Description>
                          For example, if my balance is 2.3, what should it round to?
                        </Item.Description>
                      </Item.Content>
                      <Item.Side>
                        <Box width={190}>
                          <LapeRadioSelectInput
                            name="balance_rounding_logic"
                            label="Balance rounding logic"
                            selector={
                              selectorKeys.time_off_balance_rounding_logics_human_readable
                            }
                          />
                        </Box>
                      </Item.Side>
                    </Item>
                    <Box mx="s-16">
                      <Box mb="s-16">
                        <TitleAndSubtitle
                          title="Add additional accrual for specific groups within the policy"
                          subtitle="For example if a specific seniority will gain extra days per period. We recommend having separate policies for easier maintenance."
                        />
                      </Box>
                      <InputGroup>
                        {values.accrual_levels?.map((_accrual, index) => (
                          <InputGroup variant="horizontal" key={index}>
                            <Flex flex={1}>
                              <LapeNewInput
                                label="Additional accrual amount"
                                name={`accrual_levels.${index}.amount`}
                                required
                              />
                            </Flex>
                            <Flex flex={1}>
                              <LapeRadioSelectInput
                                label="Eligibility group"
                                name={`accrual_levels.${index}.group`}
                                selector={selectorKeys.dynamic_groups}
                              />
                            </Flex>
                          </InputGroup>
                        ))}

                        <Flex gap="s-16">
                          <Button
                            onClick={() => {
                              const newLevel = { amount: '', group: null }

                              if (values.accrual_levels) {
                                values.accrual_levels.push(newLevel)
                              } else {
                                values.accrual_levels = [newLevel]
                              }
                            }}
                            variant="secondary"
                            size="sm"
                            useIcon="Plus"
                          >
                            Add level
                          </Button>
                          {!!values.accrual_levels?.length && (
                            <Button
                              onClick={() => values.accrual_levels?.pop()}
                              variant="secondary"
                              size="sm"
                              useIcon="Minus"
                            >
                              Remove level
                            </Button>
                          )}
                        </Flex>
                      </InputGroup>
                    </Box>
                  </ExpandableCell.Note>
                </ExpandableCell>
              </VStack>
            </Box>
          </>
        </VStack>
      )}
      <NextTabButton
        nextTabPath={nextTabPath}
        onNavigateToNextTab={onNavigateToNextTab}
      />
    </>
  )
}
