import React, { useState } from 'react'
import { TableWidget, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalPreviewInterface } from '@src/interfaces/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { fetchGoalGraph, getGoalCommentsAPI, useGetGoal } from '@src/api/goals'
import {
  CycleFilter,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { TargetsWidget } from '../Widgets/Targets/TargetsWidget'
import { ParentTargetsWidget } from '../Widgets/Targets/ParentTargetsWidget'
import { RoadmapsWidget } from '../Widgets/Roadmaps/RoadmapsWidget'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { DashboardsWidget } from '../Widgets/Dashboards/DashboardsWidget'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { KpiInterface } from '@src/interfaces/kpis'
import { GoalTargetSidebarForm } from '@src/features/Goals/GoalTargetSidebarForm'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { PerformanceLayoutCycleFilter } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'
import { AggregatedGoalsWidget } from '../Widgets/Targets/AggregatedGoalsWidget'
import { useGoalRoadmapsTable } from '@src/features/Goals/common/useGoalRoadmapsTable'

export const GoalsPreviewLeftSide = ({
  cycle,
  availableCycles,
  onCycleChanged,
}: {
  cycle: ReviewCyclesInterface
  availableCycles: ReviewCyclesInterface[]
  onCycleChanged: (cycleId: number | string) => void
}) => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const { data: parent } = useGetGoal(values.parent?.id)
  const [selectedTarget, setSelectedTarget] = useState<KpiInterface>()
  const roadmapsTable = useGoalRoadmapsTable()

  const onCycleChange = (filter: FilterByInterface) => {
    const cycleId = filter.filters[0].id
    onCycleChanged(cycleId)
  }
  const filter = [
    {
      columnName: 'review_cycle',
      filters: [{ id: cycle?.id, name: cycle?.name }],
    },
  ]

  const currentRoadmaps = values.roadmaps?.filter(
    roadmap => roadmap.review_cycle.id === cycle.id,
  )

  const goalCycles =
    (values.update_type?.id === 'cascaded' ? parent?.goal_cycles : values.goal_cycles) ||
    []

  const goalPregressDetails = goalCycles.find(
    cycleDetails => cycleDetails.review_cycle.id === cycle.id,
  )

  const progress =
    goalPregressDetails?.calibrated_progress ?? goalPregressDetails?.progress

  return (
    <VStack space="s-24">
      <TableWidget>
        {/* marginBottom is needed because there is no table below for now */}
        <TableWidget.Info style={{ marginBlockEnd: '-16px', marginBottom: '-16px' }}>
          <OverallProgress value={progress} id={values.id} fetchData={fetchGoalGraph} />

          {values.content_type?.model === 'employees' ? (
            <PerformanceLayoutCycleFilter
              onFilterChange={onCycleChange}
              columnName="review_cycle"
              filter={filter}
              selector={() =>
                Promise.resolve({
                  options: availableCycles as unknown as OptionInterface[],
                })
              }
            />
          ) : (
            <CycleFilter
              type={CycleFilterType.NewUI}
              onFilterChange={onCycleChange}
              columnName="review_cycle"
              filter={filter}
              selector={() =>
                Promise.resolve({
                  options: availableCycles as unknown as OptionInterface[],
                })
              }
              filterInputType={FilterSelectType.SingleSelect}
            />
          )}
        </TableWidget.Info>
        <TableWidget.Table />
      </TableWidget>
      {values.update_type?.id === 'aggregated' && <AggregatedGoalsWidget cycle={cycle} />}
      {values.update_type?.id === 'cascaded' && <ParentTargetsWidget />}
      {values.update_type?.id === 'target_based' && (
        <TargetsWidget viewMode onSelected={kpi => setSelectedTarget(kpi)} />
      )}
      {currentRoadmaps && currentRoadmaps.length ? <RoadmapsWidget viewMode /> : null}
      {values.dashboards?.length ? (
        <DashboardsWidget
          viewMode
          onSelected={dashboard =>
            navigateTo(
              pathToUrl(ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD, { id: dashboard.id }),
            )
          }
        />
      ) : null}
      <GoalsCommentsSection goalId={values.id} />
      <GoalTargetSidebarForm
        target={selectedTarget}
        onClose={() => setSelectedTarget(undefined)}
        onAfterSubmit={data => {
          if (selectedTarget) {
            Object.assign(selectedTarget, data)
          }

          setSelectedTarget(undefined)
          roadmapsTable.refresh()
        }}
      />
    </VStack>
  )
}

function GoalsCommentsSection({ goalId }: { goalId: number }) {
  const api = getGoalCommentsAPI(goalId)

  return <CommentsSection api={api} />
}
