import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiWithoutHandling } from './index'
import { API, PerformanceTimeRange } from '../constants/api'
import {
  DefaultCompetencyMatrixInterface,
  RoleInterface,
  SpecialisationInterface,
} from '../interfaces/roles'
import { IdAndName, RequestInterface, RequestInterfaceNew } from '../interfaces'
import { AxiosPromise } from 'axios'
import { getTalentStats } from '@src/api/talent'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { getMonthsByRange } from '@src/utils/kpi'
import { useFetch } from '@src/utils/reactQuery'
import {
  getNormalizedDeliverablesCompetencyMatrix,
  getNormalizedFuncCompetencyMatrix,
} from '@src/features/CompetencyMatrixTable/utils'

export const rolesRequests: RequestInterface<RoleInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      API.ROLES,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
  getItem: async id => api.get(`${API.ROLES}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.ROLES}/${id}`, data),
  deleteItem: async id => api.delete(`${API.ROLES}/${id}`),
  postItem: async data => api.post(API.ROLES, data),
}

export const useGetExampleRoles = () => {
  const context = useFetch<{
    results: RoleInterface[]
  }>(`${API.ROLES}`, 'v2')

  return { ...context, data: context.data?.results }
}

export const specialisationsRequests: RequestInterface<SpecialisationInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.SPECIALISATIONS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.SPECIALISATIONS}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.SPECIALISATIONS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.SPECIALISATIONS}/${id}`),
  postItem: async data => api.post(API.SPECIALISATIONS, data),
}

export const rolesRequestsNew: RequestInterfaceNew<RoleInterface> = {
  get: async ({ id }) => api.get<RoleInterface>(`${API.ROLES}/${id}`),
  update: async (diff, { id }, data) => {
    const values = {
      ...diff,
    }

    if (diff.seniority_min || diff.seniority_max) {
      values.functional_competency_matrix = getNormalizedFuncCompetencyMatrix(data)
    }
    return api.patch(`${API.ROLES}/${id}`, values)
  },
  submit: async data => {
    const values = {
      ...data,
      functional_competency_matrix: getNormalizedFuncCompetencyMatrix(data),
    }

    return data?.id
      ? api.patch(`${API.ROLES}/${data.id}`, values)
      : api.post(API.ROLES, values)
  },
  delete: async ({ id }) => api.delete(`${API.ROLES}/${id}`),
}

export const silentDeleteRole = async ({ id }: { id: number }) =>
  apiWithoutHandling.delete(`${API.ROLES}/${id}`)

export const specialisationsRequestsNew: RequestInterfaceNew<SpecialisationInterface> = {
  get: async ({ id }) => api.get<SpecialisationInterface>(`${API.SPECIALISATIONS}/${id}`),
  update: async (diff, { id }, data) => {
    const values = {
      ...diff,
    }

    if (diff.seniority_min || diff.seniority_max) {
      values.functional_competency_matrix = getNormalizedFuncCompetencyMatrix(data)
      values.deliverables_competencies = getNormalizedDeliverablesCompetencyMatrix(data)
    }

    return apiWithoutHandling.patch(`${API.SPECIALISATIONS}/${id}`, values)
  },
  submit: async data => {
    const values = {
      ...data,
      functional_competency_matrix: getNormalizedFuncCompetencyMatrix(data),
      deliverables_competencies: getNormalizedDeliverablesCompetencyMatrix(data),
    }

    return data?.id
      ? apiWithoutHandling.patch(`${API.SPECIALISATIONS}/${data.id}`, values)
      : apiWithoutHandling.post(API.SPECIALISATIONS, values)
  },
  delete: async ({ id }) => api.delete(`${API.SPECIALISATIONS}/${id}`),
}

export const silentDeleteSpecialisation = async ({ id }: { id: number }) =>
  apiWithoutHandling.delete(`${API.SPECIALISATIONS}/${id}`)

export const roleTalentTabStats = (id: string) => getTalentStats('position__id', id)

export const specialisationTalentTabStats = (id: string) =>
  getTalentStats('specialisation__id', id)

export const getDefaultCompetencyMatrix =
  (): AxiosPromise<DefaultCompetencyMatrixInterface> =>
    api.get(`${API.ROLES}/defaultCompetencyMatrices`)

export const getKpiPerformanceGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.ROLES}/${id}/kpiPerformance`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
    },
  })

export type SimpleSpecialisationCreateInterface = {
  name: { name: string } | string
  owner: IdAndName
  seniority_max: IdAndName
  seniority_min: IdAndName
}

export const createSpecialisationSimple = (data: SimpleSpecialisationCreateInterface) =>
  apiWithoutHandling.post(API.SPECIALISATIONS, {
    ...data,
    name: typeof data.name === 'string' ? data.name : data.name?.name,
  })

export const updateSpecialisation = (
  id: number,
  data: Partial<SpecialisationInterface>,
) => apiWithoutHandling.patch(`${API.SPECIALISATIONS}/${id}`, data)
