import React, { useEffect, useState } from 'react'
import { isBefore, isPast } from 'date-fns'
import { Button, ProgressStep, ProgressSteps } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { CheckpointType, ProbationCheckpoints } from '@src/interfaces/probationReview'
import {
  PerfReviewRequestFeedbackInterface,
  ReviewCategory,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { getPerformanceReviewSummary } from '@src/api/performanceReview'
import {
  getStartOfCheckpoint,
  getStepDescription,
  getStepState,
  getStepTitle,
} from '@src/pages/EmployeeProfile/Preview/PerformanceSummary/ProbationPipTimeline/utils'
import { formatDate } from '@src/utils/format'
import { CheckpointStepReviewers } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/CheckpointStepReviewers'
import { CommitteeStepReviewers } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/CommitteeStepReviewers'
import { RecommendationStepReviewers } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/RecommendationStepReviewers'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'

type Props = {
  data: EmployeeInterface
  checkpoints: ProbationCheckpoints
  endDate: string
  requests?: PerfReviewRequestFeedbackInterface[]
  employeeId: number
  cycleId: string
  reviewCategory: ReviewCategory
  performanceLink?: string
}

const progressStepTitle: { [key: string]: string } = {
  [ReviewCategory.Probation]: 'End of probation',
  [ReviewCategory.PIP_V2]: 'End of PIP',
}

export const ProbationLayoutTimeline = ({
  data,
  checkpoints,
  endDate,
  requests,
  employeeId,
  cycleId,
  reviewCategory,
  performanceLink,
}: Props) => {
  const [summaryCheckpoint, setSummaryCheckpoint] = useState<
    ReviewSummaryInterface | undefined
  >()
  const user = useSelector(selectUser)

  const fetchSummary = async () => {
    const result = await getPerformanceReviewSummary(
      cycleId,
      employeeId,
      reviewCategory,
      [
        {
          columnName: 'reviewer_relation',
          filters: [
            {
              id: 'line_manager',
              name: 'line_manager',
            },
            {
              id: 'functional_manager',
              name: 'functional_manager',
            },
          ],
        },
      ],
    )

    setSummaryCheckpoint(result.data)
  }

  useEffect(() => {
    fetchSummary()
  }, [])
  const timelineSteps = [
    ...checkpoints.checkpoints,
    ...(checkpoints.decision_checkpoints ? checkpoints.decision_checkpoints : []),
  ]
  let currentTimelineSteps = timelineSteps.filter(
    event =>
      getStepState(getStartOfCheckpoint(event), event.checkpoint_deadline) === 'pending',
  )
  if (!currentTimelineSteps.length) {
    currentTimelineSteps = timelineSteps
      .filter(
        event => getStepState(getStartOfCheckpoint(event), event.checkpoint_deadline),
        'default',
      )
      .slice(0, 1)
  }

  const formattedEndDate = formatDate(endDate, 'd MMMM yyyy')
  const endTitle = isPast(new Date(endDate))
    ? `Closed ${formattedEndDate}`
    : `Closing ${formattedEndDate}`

  const onClickAddGoals = () => {
    navigateTo(
      pathToUrl(
        reviewCategory === ReviewCategory.Probation
          ? ROUTES.FORMS.PROBATION_GOALS
          : ROUTES.FORMS.PIP.GOAL_SETTINGS,
        {
          employeeId,
          cycleId,
          id: cycleId,
        },
      ),
    )
  }

  return (
    <>
      <ProgressSteps variant="vertical-compact">
        {timelineSteps.map(checkpoint => {
          const title = getStepTitle(checkpoint, reviewCategory)
          const startOfCheckpoint = getStartOfCheckpoint(checkpoint)
          const state = getStepState(startOfCheckpoint, checkpoint.checkpoint_deadline)
          const description = getStepDescription(checkpoint)
          const isLineManager = user.id === data.line_manager.id
          const canAddGoals =
            (reviewCategory === ReviewCategory.PIP_V2 || isLineManager) &&
            state === 'pending'

          return (
            <ProgressStep key={checkpoint.id} state={state}>
              <ProgressStep.Title>{title}</ProgressStep.Title>
              <ProgressStep.Description>{description}</ProgressStep.Description>
              {checkpoint.checkpoint_type === CheckpointType.Goals && canAddGoals && (
                <ProgressStep.Side>
                  <Button
                    useIcon={Plus}
                    variant="secondary"
                    size="sm"
                    onClick={onClickAddGoals}
                  >
                    Add goals
                  </Button>
                </ProgressStep.Side>
              )}
              {checkpoint.checkpoint_type === CheckpointType.Review && (
                <ProgressStep.Description>
                  <CheckpointStepReviewers
                    checkpoint={checkpoint}
                    requests={requests}
                    grades={summaryCheckpoint?.summary?.final_grades}
                    reviewCategory={reviewCategory}
                    performanceLink={performanceLink}
                  />
                </ProgressStep.Description>
              )}
              {checkpoint.checkpoint_type === CheckpointType.Recommendation && (
                <ProgressStep.Description>
                  <RecommendationStepReviewers
                    checkpoint={checkpoint}
                    reviewCategory={reviewCategory}
                    cycleId={cycleId}
                    employeeId={employeeId}
                  />
                </ProgressStep.Description>
              )}
              {checkpoint.checkpoint_type === CheckpointType.Decision && (
                <ProgressStep.Description>
                  <CommitteeStepReviewers
                    checkpoint={checkpoint}
                    reviewCategory={reviewCategory}
                    cycleId={cycleId}
                    employeeId={employeeId}
                  />
                </ProgressStep.Description>
              )}
            </ProgressStep>
          )
        })}

        <ProgressStep
          state={isBefore(new Date(endDate), new Date()) ? 'done' : 'default'}
        >
          <ProgressStep.Title>{progressStepTitle[reviewCategory]}</ProgressStep.Title>
          <ProgressStep.Description>{endTitle}</ProgressStep.Description>
        </ProgressStep>
      </ProgressSteps>
    </>
  )
}
