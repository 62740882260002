import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroList } from '@components/OnboardingIntro/OnboardingIntroList'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'

export const CandidatesIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Candidates.mp4"
      videoPosterUrl="/intros/Intro%20-%20Candidates.png"
    >
      <OnboardingIntroTextWidget
        title={
          <FormattedMessage
            id="onboardingV2.candidates.intro.title"
            defaultMessage="Add candidates"
          />
        }
      >
        <p>
          <FormattedMessage
            id="onboardingV2.candidates.intro.text1"
            defaultMessage="When there are hundreds of candidates applied for a role it’s important to have all information about them to keep high level of hiring accuracy. {br}Add your candidates to the system. You can upload candidates during onboarding in bulk or add them one by one."
            values={{
              br: <br />,
            }}
          />
        </p>
        <FormattedMessage
          id="onboardingV2.candidates.intro.text2"
          defaultMessage="Candidate profile information basic fields:"
        />
        <OnboardingIntroList
          items={[
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.name"
                  defaultMessage="candidate name"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.email"
                  defaultMessage="email"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.linkedin"
                  defaultMessage="LinkedIn profile"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.role"
                  defaultMessage="role"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.country"
                  defaultMessage="country"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.seniority"
                  defaultMessage="seniority"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.recruiter"
                  defaultMessage="dedicated recruiter"
                />
              ),
            },
            {
              msg: (
                <FormattedMessage
                  id="onboarding.candidates.intro.list.posting"
                  defaultMessage="job posting"
                />
              ),
            },
          ]}
        />
        <FormattedMessage
          id="onboardingV2.candidates.intro.text3"
          defaultMessage="All of that essential data is consolidated on the candidate profile, enabling you to track candidate’s progress and jump into action at every stage of the recruitment process."
        />
      </OnboardingIntroTextWidget>
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.candidates.intro.tips.title"
            defaultMessage="Candidates upload tips"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.info.title"
                defaultMessage="Candidates information"
              />
            ),
            icon: 'Profile',
            text: (
              <FormattedMessage
                id="onboardingV2.candidates.intro.tips.info.text"
                defaultMessage="We have two ways of uploading your candidates. Bulk upload works better for companies that already have a pool of applicants. When on candidate upload step, click “Upload candidates” to add them in bulk. If you just start hiring and have only handful of applicants, concider adding them manually one by one bu selecting “Add candidate” button"
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
