import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const RolesIntro = () => {
  return (
    <OnboardingIntro
      // @TODO: change
      videoUrl="/intros/Intro%20-%20Documents.mov"
      videoPosterUrl="/intros/Intro%20-%20Documents.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.roles.intro.tips.title"
            defaultMessage="Evaluate and Adjust Your Roles"
          />
        }
        text={
          <FormattedMessage
            id="onboardingV2.roles.intro.tips.text"
            defaultMessage="Prepare to assess the roles you've crafted—an essential step as it forms the foundation for unlocking value through recruitment and performance features. Add some examples"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.roles.intro.tips.assign.title"
                defaultMessage="Assign owners"
              />
            ),
            icon: 'Services',
            text: (
              <FormattedMessage
                id="onboardingV2.roles.intro.tips.assign.text"
                defaultMessage="Every role must be assigned an owner. Owners of roles are accountable for ensuring quality, and if you utilize performance features, they will review and assess the skills associated with their assigned roles. What additional responsibilities do they hold?"
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
