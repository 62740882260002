import {
  CycleFilter,
  CycleFilterProps,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React from 'react'
import { useGetPerformanceSelector } from '@src/api/performance'
import { useGetCycleSelector } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { useMemo } from 'react'
import { PerformanceLayoutCycleFilter } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'

export const useGetEmployeeCyclesSelector = (employeeId: number) => {
  const { data: cycles } = useGetPerformanceSelector(employeeId)
  const cycleSelector = useGetCycleSelector(cycles)
  const initialCycle = cycles?.find(item => item.offset === 0)

  return useMemo(
    () => ({
      initialCycle,
      cycleSelector,
      getCycleById: (id: string) =>
        cycles?.find(cycle => String(cycle.id) === String(id)),
    }),
    [initialCycle, cycleSelector, cycles],
  )
}

export const EmployeeCycleFilter = ({
  employeeId,
  columnName = 'review_cycle__offset',
  ...rest
}: CycleFilterProps & { employeeId: number }) => {
  const { cycleSelector } = useGetEmployeeCyclesSelector(employeeId)
  return (
    <PerformanceLayoutCycleFilter
      columnName={columnName}
      selector={cycleSelector}
      {...rest}
    />
  )
}

export const GeneralCycleFilter = ({
  type = CycleFilterType.NewUI,
  selector = selectorKeys.cycle_offsets,
  columnName = 'review_cycle__offset',
  filterInputType = FilterSelectType.SingleSelect,
  ...rest
}: CycleFilterProps) => {
  return (
    <CycleFilter
      type={type}
      selector={selector}
      columnName={columnName}
      filterInputType={filterInputType}
      {...rest}
    />
  )
}

export const GoalCycleFilter = ({ ...cycleFilterProps }: CycleFilterProps) => {
  const { entity } = useOrgEntity()

  if (!entity) {
    // nice-to-have: add a proper loading state. not needed for now as whole tab mount waits for entity
    return null
  }

  switch (entity.type) {
    case EntityTypes.employee:
    case EntityTypes.employees:
      return <EmployeeCycleFilter {...cycleFilterProps} employeeId={entity.data.id} />

    default:
      return <GeneralCycleFilter {...cycleFilterProps} />
  }
}
