import { kpisRequestsNew } from '@src/api/kpis'
import SideBar from '@src/components/SideBar/SideBar'
import { KpiInterface } from '@src/interfaces/kpis'
import React from 'react'
import Form from '@src/features/Form/Form'
import { Flex, InputGroup } from '@revolut/ui-kit'
import { TargetFieldsByType } from '@src/pages/Forms/GoalForm/Form/SidebarForms/AddGoalTargetForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import InputsSkeleton from '@src/components/Skeletons/InputsSkeleton'

export const GoalTargetSidebarForm = ({
  target,
  onClose,
  onAfterSubmit,
}: {
  target?: KpiInterface
  onClose: () => void
  onAfterSubmit: (kpi: KpiInterface) => void
}) => {
  const loadingState = (
    <InputGroup>
      <InputsSkeleton />
      <InputsSkeleton />
    </InputGroup>
  )
  return (
    <SideBar
      useLayout
      variant="wide"
      isOpen={!!target}
      onClose={onClose}
      title={target?.name}
      subtitle="Update target"
    >
      {!!target && (
        <Form
          api={kpisRequestsNew}
          forceParams={{ id: String(target.id) }}
          loadingState={loadingState}
        >
          <Flex
            aria-label="Add a target"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            gap="s-24"
          >
            <TargetFieldsByType type={target.update_type} />
            <Flex justifyContent="center" alignItems="center">
              <NewSaveButtonWithPopup<KpiInterface>
                width="350px"
                useValidator
                onAfterSubmit={onAfterSubmit}
              >
                Save target
              </NewSaveButtonWithPopup>
            </Flex>
          </Flex>
        </Form>
      )}
    </SideBar>
  )
}
