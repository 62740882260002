import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import SidebarFeedbacks from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SidebarFeedbacks'
import { useGetAllFeedbackList } from '@src/api/anytimeFeedback'
import { ReviewSummaryDataInterface } from '@src/interfaces/performance'
import { FeedbackSection } from '@src/pages/EmployeeProfile/Layout/Performance/FeedbackSection'
import { Text, Token, VStack } from '@revolut/ui-kit'

export const SummaryFeedbackSection = ({
  employee,
  summary,
}: {
  employee: EmployeeInterface
  summary?: ReviewSummaryDataInterface
}) => {
  const { data: feedback, refetch: refetchFeedbackList } = useGetAllFeedbackList(
    employee.id,
  )

  return (
    <VStack space="s-16">
      <Text variant="h6" color={Token.color.greyTone50}>
        Feedback
      </Text>
      <FeedbackSection
        data={employee}
        feedback={feedback}
        refetchFeedbackList={refetchFeedbackList}
        performanceContent={
          summary?.overall_feedbacks?.length ? (
            <SidebarFeedbacks
              feedbacks={summary.overall_feedbacks}
              ratings={summary.ratings}
              renderTitle={false}
            />
          ) : undefined
        }
      />
    </VStack>
  )
}
