import { DataPoint, Flex, Icon, TextSkeleton, Token } from '@revolut/ui-kit'
import GraphIconChart, {
  GraphIconChartInterface,
} from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { ColoredPercent } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import Tooltip from '@src/components/Tooltip/Tooltip'
import React from 'react'

type OverallProgressProps = {
  value?: number
  id?: GraphIconChartInterface['id']
  fetchData?: GraphIconChartInterface['fetchData']
  tooltip?: string
}

export const OverallProgress = ({
  value,
  id,
  fetchData,
  tooltip,
}: OverallProgressProps) => {
  return (
    <DataPoint>
      <DataPoint.Value color={Token.color.greyTone50} aria-labelledby="overallProgress">
        {value !== undefined ? (
          <Flex gap="s-8" width="fit-content">
            <Flex flex={2}>
              <Tooltip placement="right" text={tooltip}>
                <ColoredPercent percent={value * 100} />
              </Tooltip>
            </Flex>

            {id && fetchData ? (
              <Flex flex={1}>
                <GraphIconChart vertical="right" id={id} fetchData={fetchData}>
                  <Flex width="100%" justifyContent="center">
                    <Icon
                      name="BarChart"
                      size={16}
                      style={{ cursor: 'pointer' }}
                      color={Token.color.greyTone20}
                    />
                  </Flex>
                </GraphIconChart>
              </Flex>
            ) : null}
          </Flex>
        ) : (
          <TextSkeleton />
        )}
      </DataPoint.Value>
      <DataPoint.Label id="overallProgress">Overall Progress</DataPoint.Label>
    </DataPoint>
  )
}
