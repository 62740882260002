import React from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { Avatar, Group } from '@revolut/ui-kit'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingSettingsInterface } from '@src/interfaces/settings'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { API, selectorKeys } from '@src/constants/api'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { LocationInterface } from '@src/interfaces/locations'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { jobPostingSettings } from '@src/api/settings'
import Form from '@src/features/Form/Form'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { LinkedinIntegrationButton } from '@src/pages/Settings/JobPosting/LinkedinIntegrationButton'

const JobPostingsGeneral = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const { values } = useLapeContext<JobPostingSettingsInterface>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeJobPostingPreferences)
  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle title="General" />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable automation workflow settings',
                description:
                  'This setting allows recruiters and other users with access to job postings to create automation rules based on questions from the application form. For example, "Automatically archive candidates with less than 4 years of experience" or "Assign the following recruiter to all candidates located in London."',
              }}
              name="enable_automation_rules"
              disabled={disableEdit}
            />
            <ApprovalSteps
              title="Enable job postings approval"
              description="Enable and customise an approval flow for job postings to efficiently achieve stakeholder alignment. Define custom approval steps by selecting an employee, a dynamic group, or a specific position within an organisation with a defined relationship to an item pending approval."
              switchField="enable_approvals"
              approvalProcess="job_posting_approvals"
              entity="job postings"
              disabled={disableEdit}
              formRef={values}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Require requisitions to publish a Job Posting',
                description:
                  'If enabled, you must attach a requisition to a job posting before it can be published. This ensures that each published job posting is connected to an open position in the company. Disabling this control will provide more flexibility and streamline the job posting publishing process but will reduce the level of control and planning.',
              }}
              name="enable_requisition_publishing_checks"
              disabled={disableEdit}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow internal job postings',
                description:
                  'If a Job Posting is published Internally, it will be visible in the Job Postings list in Revolut People. You will be able to add candidates for this Job Posting manually. Job Postings in this status won’t be visible on the Careers Website',
              }}
              name="enable_all_publishing_statuses"
              disabled={disableEdit}
            />
          </Group>
          <NewStepperTitle title="Compensation bands" />
          <Group>
            <LapeNewMultiSelect<LocationInterface>
              name="mandatory_compensation_locations"
              placeholder="Locations"
              itemTypeProps={{
                title: 'Locations requiring compensation bands',
                description: (
                  <>
                    In some countries and regions it is required by law for job postings
                    to include a compensation band (salary range). Failing to comply with
                    these rules may pose regulatory and financial risks. With this option,
                    you can define countries and locations for which the "compensation
                    bands" field will be mandatory during the job posting creation. If you
                    can’t find a location or need to access location-specific details, use
                    the{' '}
                    <InternalLink to={pathToUrl(ROUTES.APPS.LOCATIONS)}>
                      Locations app.
                    </InternalLink>
                  </>
                ),
              }}
              required
              selector={selectorKeys.location}
              disabled={disableEdit}
            />
            <HideIfCommercial>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable automated Compensation Bands filling',
                  description:
                    'If you have added compensation bands in the "Compensation Bands" app, you can enable the automatic filling of compensation bands based on a combination of specialisation and seniority. If this setting is disabled, job posting creators will need to add the compensation bands manually during job posting creation for each location that requires compensation information.',
                }}
                name="enable_automatic_compensation_ranges"
                disabled={disableEdit}
              />
            </HideIfCommercial>
          </Group>
          <NewStepperTitle
            title="Job board integrations"
            subtitle={
              <>
                Decide where you want you job postings to be published. <br /> You can
                enable automatic posting of your jobs to external job boards. Once
                enabled, all currently published job postings and all the job posting you
                will publish in the future will be sent to the job board you enabled.
                Please note that it might take up to 24 hours for the job posting to
                appear on the external website.
              </>
            }
          />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                avatar: <Avatar size={40} label="M" />,
                title: 'Monster',
                description:
                  'Enable this integration to let all your published job postings appear on the Monster.com job board.',
              }}
              name="enable_monster_integration"
              disabled={disableEdit}
            />
            {featureFlags.includes(FeatureFlags.LinkedinIntegration) && (
              <LinkedinIntegrationButton />
            )}
          </Group>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.JOB_POSTING_SETTINGS)
          }}
          useValidator
          isExistingData
        />
      </PageActions>
    </>
  )
}

export default () => (
  <Form api={jobPostingSettings} forceParams={{ id: '1' }}>
    <JobPostingsGeneral />
  </Form>
)
