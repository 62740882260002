import React, { useState } from 'react'
import { Action, MoreBar, TableWidget, HStack } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import {
  updateDashboardsOrder,
  getAnalyticsDashboards,
  getAnalyticsDashboardsOrdered,
} from '@src/api/analyticsDashboards'
import { navigateTo } from '@src/actions/RouterActions'
import { EntityTypes } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { analyticsDashboardDetailsColumn } from '@src/constants/columns/analyticsDashboard'
import { TableNames } from '@src/constants/table'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { FilterByInterface, RowInterface, SortByInterface } from '@src/interfaces/data'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable, useOrdering } from '@components/Table/hooks'
import { pathToUrl } from '@src/utils/router'
import { DetailsSideBar } from './DetailsSideBar'
import OrderingTableControls from '@src/features/OrderingTableControls/OrderingTableControls'
import { UpdateOrderingInterface } from '@src/interfaces/ordering'
import { dragIconColumn } from '@src/constants/columns/ordering'
import { IdAndName } from '@src/interfaces'

interface FindingsTabProps {
  canAdd?: boolean
  data?: IdAndName
  entity: EntityTypes
  initialFilter?: FilterByInterface[]
  initialSort?: SortByInterface[]
  row: RowInterface<GenericAnalyticsDashboardInterface>
}

export const AnalyticsDashboardsTab = ({
  canAdd,
  data,
  entity,
  initialFilter,
  initialSort,
  row,
}: FindingsTabProps) => {
  const [sideBarId, setSideBarId] = useState<number>()
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [showAll, setShowAll] = useState<boolean>()
  const [orderingMode, setOrderingMode] = useState(false)

  const table = useTable<GenericAnalyticsDashboardInterface>(
    {
      getItems:
        entity === EntityTypes.company
          ? getAnalyticsDashboards(entity, data?.id)
          : getAnalyticsDashboardsOrdered(entity, data?.id),
    },
    initialFilter,
    initialSort,
  )

  const onAfterChange = async (requestData: UpdateOrderingInterface) => {
    try {
      await updateDashboardsOrder(requestData, initialFilter || [])
    } catch (e) {
      // if something went wrong - cancel optimistic changes
      table.refresh()
    }
  }

  const {
    onChangeOrder,
    moveToTop,
    moveToBottom,
    selectedOrderingIds,
    setSelectedOrderingIds,
  } = useOrdering(table.data, table.setData, table.count, table.refresh, onAfterChange)

  const tableRow: RowInterface<GenericAnalyticsDashboardInterface> = {
    ...row,
    cells: [
      { ...dragIconColumn, width: 25 },
      ...row.cells,
      {
        ...analyticsDashboardDetailsColumn,
        width: 40,
        insert: ({ data: dashboardData }) => (
          <Action
            onClick={e => {
              e.stopPropagation()

              setSideBarOpen(!sideBarOpen)
              setSideBarId(dashboardData.id)
            }}
          >
            Details
          </Action>
        ),
      },
    ],
  }

  const onChangeOrderingMode = (isOrdering: boolean) => {
    if (isOrdering) {
      table.resetFiltersAndSorting([
        {
          filters: [{ name: 'False', id: 'False' }],
          columnName: 'include_non_prioritised_items',
          nonResettable: true,
        },
      ])
    } else {
      table.resetFiltersAndSorting(initialFilter)
    }
    setOrderingMode(isOrdering)
  }

  const onSwitch = () => {
    table.onFilterChange([
      {
        columnName: 'company_related',
        filters: showAll
          ? [
              {
                id: 'true',
                name: 'true',
              },
            ]
          : [],
      },
    ])

    setShowAll(!showAll)
  }

  const handleRowClick = (r: GenericAnalyticsDashboardInterface) => {
    navigateTo(pathToUrl(ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD, { id: r.id }))
  }

  return (
    <>
      <TableWidget>
        <TableWidget.Actions>
          <HStack space="s-12" align="center">
            <MoreBar>
              {canAdd && (
                <MoreBar.Action
                  onClick={() => {
                    const locationState = {
                      entityType: entity,
                      ...(data && { entityId: data.id, entityName: data.name }),
                    }

                    navigateTo(
                      pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS, {}),
                      locationState,
                    )
                  }}
                  useIcon={Plus}
                >
                  Add dashboard
                </MoreBar.Action>
              )}
              {canAdd && entity !== EntityTypes.company ? (
                <OrderingTableControls
                  disabled={false}
                  orderingMode={orderingMode}
                  onChangeMode={onChangeOrderingMode}
                  moveToTop={moveToTop}
                  moveToBottom={moveToBottom}
                  disabledMoveButtons
                  showMoveButtons={false}
                  buttonLabel="Reorder"
                />
              ) : null}
            </MoreBar>
          </HStack>
        </TableWidget.Actions>
        {entity === EntityTypes.company && (
          <TableWidget.Filters>
            <SwitchButton checked={showAll} onClick={onSwitch}>
              Show all Dashboards
            </SwitchButton>
          </TableWidget.Filters>
        )}
        <TableWidget.Table>
          <AdjustableTable<GenericAnalyticsDashboardInterface>
            dataType="Dashboards"
            name={TableNames.AnalyticsDashboards}
            row={tableRow}
            {...table}
            onRowClick={orderingMode ? undefined : handleRowClick}
            useWindowScroll
            orderingMode={orderingMode}
            disabledFiltering={orderingMode}
            setSelectedOrderingIds={setSelectedOrderingIds}
            selectedOrderingIds={selectedOrderingIds}
            onChangeOrder={onChangeOrder}
            hiddenCells={{ [dragIconColumn.idPoint]: !orderingMode }}
          />
        </TableWidget.Table>
      </TableWidget>
      <DetailsSideBar
        dashboardId={sideBarId}
        isOpen={sideBarOpen}
        setIsOpen={setSideBarOpen}
      />
    </>
  )
}
