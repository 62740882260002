import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import {
  useFetchDiversityEthnicities,
  useFetchGenderChoices,
} from '@src/api/recruitment/careers'
import SectionLayout from './components/Section'

type Props = {
  locked?: boolean
}

const CareersApplicationDiversity = ({ locked }: Props) => {
  const { data: genders } = useFetchGenderChoices()
  const { data: ethnicities } = useFetchDiversityEthnicities()

  return (
    <SectionLayout
      title="Equal employment opportunity information (Completion is voluntary and will not subject you to adverse treatment)"
      text="Our company values diversity. To ensure that we comply with reporting requirements and to learn more about how we can increase diversity in our candidate pool, we invite you to voluntarily provide demographic information in a confidential survey at the end of this application. Providing this information is optional. It will not be accessible or used in the hiring process, and has no effect on your opportunity for employment."
      body={
        <InputGroup>
          <LapeRadioSelectInput
            label="Select gender you identify with"
            name="gender"
            options={
              genders?.map(gender => ({
                key: gender.id,
                label: gender.name,
                value: gender,
              })) || []
            }
            disabled={locked}
          />
          <LapeRadioSelectInput
            label="Select ethnicity"
            name="ethnicity"
            options={
              ethnicities?.map(ethnicity => ({
                key: ethnicity.id,
                label: ethnicity.name,
                value: ethnicity,
              })) || []
            }
            disabled={locked}
          />
        </InputGroup>
      }
      locked={locked}
    />
  )
}

export default CareersApplicationDiversity
