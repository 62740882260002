import React from 'react'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { PageBody } from '@components/Page/PageBody'
import { Group } from '@revolut/ui-kit'
import NewSelectionCard from '@components/NewSelectionCard/NewSelectionCard'
import { pathToUrl } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'
import { InternalLink } from '@components/InternalLink/InternalLink'

const ListPage = () => {
  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const findingsHidden = !values.findings?.visible
  const riskHidden = !values.risk?.visible
  const bugsTrackingHidden = !values.bugs_tracking?.visible
  const cxHidden = !values.customer_experience?.visible
  const dataAccessRequestHidden = !values.data_access_requests?.visible

  const generalSettingsHidden =
    cxHidden &&
    bugsTrackingHidden &&
    findingsHidden &&
    riskHidden &&
    dataAccessRequestHidden

  return (
    <PageBody mb="s-64">
      <Group mb="s-24">
        {!generalSettingsHidden && (
          <NewSelectionCard
            icon="Gear"
            title="General"
            to={pathToUrl(ROUTES.SETTINGS.ORGANISATION.GENERAL)}
            subtitle={
              <FormattedMessage
                id="settings.organisation.general"
                defaultMessage="Settings that apply to teams, departments and company"
              />
            }
            use={InternalLink}
          />
        )}
        <NewSelectionCard
          icon="Bank"
          title="Teams"
          to={pathToUrl(ROUTES.SETTINGS.ORGANISATION.TEAMS)}
          subtitle={
            <FormattedMessage
              id="settings.organisation.teams"
              defaultMessage="Settings that apply to all teams"
            />
          }
          use={InternalLink}
        />
        <NewSelectionCard
          icon="Services"
          title="Departments"
          to={pathToUrl(ROUTES.SETTINGS.ORGANISATION.DEPARTMENTS)}
          subtitle={
            <FormattedMessage
              id="settings.organisation.departments"
              defaultMessage="Settings that apply to all departments"
            />
          }
          use={InternalLink}
        />
      </Group>
    </PageBody>
  )
}

const routes = [
  {
    title: 'Teams settings',
    path: ROUTES.SETTINGS.ORGANISATION.LIST,
    url: ROUTES.SETTINGS.ORGANISATION.LIST,
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
    component: ListPage,
  },
]

export const List = () => {
  return (
    <SettingsForm
      routes={routes}
      api={organisationSettings}
      title="Teams settings"
      subtitle="Settings that apply to all teams"
    />
  )
}
