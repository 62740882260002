import React from 'react'
import OrderingModeCheckbox from '@src/features/OrderingTableControls/OrderingModeCheckbox'
import { Button, HStack } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'

type Props = {
  disabled: boolean
  orderingMode: boolean
  onChangeMode: (isOrdering: boolean) => void
  moveToTop: () => void
  moveToBottom: () => void
  disabledMoveButtons: boolean
  showMoveButtons?: boolean
  buttonLabel?: string
}

const OrderingTableControls = ({
  disabled,
  orderingMode,
  onChangeMode,
  moveToTop,
  moveToBottom,
  disabledMoveButtons,
  showMoveButtons = true,
  buttonLabel,
}: Props) => {
  return (
    <HStack space="s-12" align="center">
      <OrderingModeCheckbox
        disabled={disabled}
        checked={orderingMode}
        onChange={onChangeMode}
        buttonLabel={buttonLabel}
      />
      {showMoveButtons && orderingMode && (
        <>
          <Tooltip
            placement="top"
            text="You should select at least one row to move"
            hide={!disabledMoveButtons}
          >
            <Button
              variant="secondary"
              size="sm"
              disabled={disabledMoveButtons}
              onClick={e => {
                // to avoid clearing selectedIds on click outside
                e.stopPropagation()
                moveToTop()
              }}
            >
              Move to top
            </Button>
          </Tooltip>
          <Tooltip
            placement="top"
            text="You should select at least one row to move"
            hide={!disabledMoveButtons}
          >
            <Button
              variant="secondary"
              size="sm"
              disabled={disabledMoveButtons}
              onClick={e => {
                e.stopPropagation()
                moveToBottom()
              }}
            >
              Move to bottom
            </Button>
          </Tooltip>
        </>
      )}
    </HStack>
  )
}

export default OrderingTableControls
