import React from 'react'
import { useTable } from '@components/Table/hooks'
import {
  FilterByInterface,
  SORT_DIRECTION,
  Stats,
  RowInterface,
} from '@src/interfaces/data'
import { kpisRequests } from '@src/api/kpis'
import { useTableReturnType } from '@components/Table/hooks'
import { TeamInterface } from '@src/interfaces/teams'
import { KpiInterface } from '@src/interfaces/kpis'
import { getKpiPerformanceGraph } from '@src/api/teams'
import Tooltip from '@components/Tooltip/Tooltip'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
} from '@src/constants/columns/kpi'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { PermissionTypes } from '@src/store/auth/types'
import { KPISettingDates } from '@src/features/Popups/ImportantKpiMessage'
import useKPIWarning from '@src/hooks/UseKPIWarning'
import { Box, IconButton as NewIconButton, MoreBar, TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useQuery } from '@src/utils/queryParamsHooks'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { TableNames } from '@src/constants/table'
import { rowHighlight } from '@src/features/KPI'
import { useRenderRefreshKPIsTableNote } from '@src/utils/kpi'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { KpiHelpButton } from '@src/features/KPI/KpiHelpButton'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { foreignGoalColumn } from '@src/constants/columns/goals'
import { selectorKeys } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

interface StructureProps {
  data: TeamInterface
  table: useTableReturnType<KpiInterface, Stats>
  warnAboutMandatoryKPIs: boolean
}

const ROW: RowInterface<KpiInterface> = {
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...kpiGenericNameColumn,
      width: 400,
    },
    {
      ...foreignGoalColumn,
      width: 100,
    },
    {
      ...kpiPerformanceColumn,
      width: 130,
    },
    {
      ...kpiInitialValueColumn,
      width: 70,
    },
    {
      ...kpiCurrentValueColumn,
      width: 70,
    },
    {
      ...kpiTargetColumn,
      width: 70,
    },
    {
      ...kpiUnitColumn,
      width: 70,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 100,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 70,
    },
  ],
}

const KPI = ({ data, table, warnAboutMandatoryKPIs }: StructureProps) => {
  const { query } = useQuery<{ review_cycle__offset: string }>()
  const { showTeam, reviewCycleData } = useKPIWarning()

  const canAdd = data.field_options.permissions?.includes(PermissionTypes.CanAddKPIsTeam)
  const user = useSelector(selectUser)

  const canChangeKpiWeight = data.field_options.permissions?.includes(
    PermissionTypes.ChangeKpiWeight,
  )

  const canRefreshKpis = data.field_options.permissions?.includes(
    PermissionTypes.CanRefreshKpis,
  )

  const { refreshNote, refreshButton } = useRenderRefreshKPIsTableNote(
    table,
    canRefreshKpis,
  )

  const handleEditKPIs = () => {
    const baseUrl = pathToUrl(ROUTES.FORMS.ASSIGN_KPIS.TEAM, { id: data.id })
    const offset = query.review_cycle__offset?.split(',')?.[0] || 0

    navigateTo(`${baseUrl}?review_cycle__offset=${offset}`)
  }

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
      initialValues: getKPIFormInitialValues(user, {
        team: { name: data.name, id: data.id },
      }),
    })
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={table.onFilterChange}
          columnName="cycle__id"
          filter={table.filterBy}
          filterInputType={FilterSelectType.SingleSelect}
          selector={selectorKeys.review_cycles}
        />
        <OverallProgress
          value={table?.stats?.avg_performance}
          id={data.id}
          fetchData={getKpiPerformanceGraph}
          tooltip="Overall progress is calculated as the weighted average of all approved KPIs."
        />
        {warnAboutMandatoryKPIs && (
          <Box pt="s-8">
            <Tooltip
              placement="right"
              text="This profile has mandatory KPIs which must have 100% progress by cycle end, otherwise Overall Progress will be set to zero."
            >
              <NewIconButton useIcon="16/Warning" color="red" />
            </Tooltip>
          </Box>
        )}
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          {canAdd && (
            <MoreBar.Action
              onClick={handleNewRow}
              useIcon="Plus"
              data-testid="add-button"
            >
              Add New KPI
            </MoreBar.Action>
          )}
          {canChangeKpiWeight && (
            <MoreBar.Action
              onClick={handleEditKPIs}
              useIcon="Pencil"
              data-testid="weights-button"
            >
              Edit KPIs
            </MoreBar.Action>
          )}
          {refreshButton}
          <KpiHelpButton kpiChampions={data.kpi_champions} />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Filters>
        {showTeam && (
          <Tooltip
            placement="right"
            body={
              <Box minWidth={700} color="background" p="s-16">
                {reviewCycleData && <KPISettingDates teamOnly cycle={reviewCycleData} />}
              </Box>
            }
          >
            <NewIconButton useIcon="Info" aria-label="Info" color="red" size={32} />
          </Tooltip>
        )}
      </TableWidget.Filters>
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          expandableType="chevron"
          name={TableNames.TeamKPI}
          dataType="KPI"
          row={ROW}
          {...table}
          useWindowScroll
          onRowClick={onKPITableRowClick}
          noDataMessage="Please add your team KPIs."
          useFetchedChildren
        />
        {refreshNote}
      </TableWidget.Table>
    </TableWidget>
  )
}

const getKpiFilters = (
  data: TeamInterface,
  cycle: ReviewCyclesInterface,
): FilterByInterface[] => [
  {
    filters: [
      { name: Statuses.pending, id: Statuses.pending },
      { name: Statuses.approved, id: Statuses.approved },
      { name: Statuses.future, id: Statuses.future },
      { name: Statuses.completed, id: Statuses.completed },
      { name: Statuses.requires_changes, id: Statuses.requires_changes },
    ],
    columnName: 'target_status',
  },
  {
    filters: [{ name: cycle.name, id: cycle.id }],
    columnName: 'cycle__id',
    nonResettable: true,
  },
  {
    filters: [{ name: data.id.toString(), id: data.id }],
    columnName: 'team__id',
    nonResettable: true,
    nonInheritable: true,
  },
]

export const kpiSorting = [
  {
    sortBy: 'weight',
    direction: SORT_DIRECTION.ASC,
    nonResettable: true,
  },
]

export const TeamTargets = ({
  data,
  cycle,
}: {
  data: TeamInterface
  cycle: ReviewCyclesInterface
}) => {
  const table = useTable<KpiInterface, Stats>(
    kpisRequests,
    getKpiFilters(data, cycle),
    kpiSorting,
  )

  return (
    <KPI
      data={data}
      table={table}
      warnAboutMandatoryKPIs={!!table?.stats?.mandatory_kpi_incomplete}
    />
  )
}
