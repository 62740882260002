import React from 'react'
import { ROUTES } from '@src/constants/routes'
import RequisitionsTable from '@src/pages/Recruitment/RecruitmentSubTabs/Requisitions/RequisitionsTable'
import HiringTable from '@src/pages/Recruitment/RecruitmentSubTabs/Hiring/HiringTable'
import InterviewScorecardTemplatesTable from '@src/pages/Recruitment/RecruitmentSubTabs/InterviewScorecardTemplates/InterviewScorecardTemplatesTable'
import { GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import JobPostingsTable from '@src/pages/Recruitment/RecruitmentSubTabs/Job Postings/JobPostingsTable'
import CommonCandidatesTable from '@src/features/CommonCandidatesTable/CommonCandidatesTable'
import OffersTable from '@src/pages/Recruitment/RecruitmentSubTabs/OfferForms/OffersTable/OffersTable'
import OfferPlaceholders from '@src/pages/Recruitment/RecruitmentSubTabs/OfferForms/OfferPlaceholders/OfferPlaceholders'
import OfferTemplates from '@src/pages/Recruitment/RecruitmentSubTabs/OfferForms/OfferTemplates/OfferTemplates'
import PendingInterviews from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/PendingInterviews'
import CompletedInterviews from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/CompletedInterviews'
import { HomeSectionId, TabBarWithPinningTabInterface } from '@src/interfaces/tabPinning'
import HiringPipelines from '@src/pages/Recruitment/RecruitmentSubTabs/HiringPipelines/HiringPipelines'
import BookingLinksTable from '@src/features/BookingLinks/Recruiter/BookingLinksTable'
import CRMTable from '@src/pages/Recruitment/RecruitmentSubTabs/CRM/CRMTable'
import CandidateBulkEmails from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkEmails'
import CandidateBulkArchive from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkArchive'
import CandidateBulkEdit from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkEdit'
import CandidateBulkSendOnlineTest from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkSendOnlineTest'
import CandidateBulkMoveStage from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkMoveStage'
import CandidateBulkSnooze from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkSnooze'
import CandidateBulkUnsnooze from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkUnsnooze'
import RejectedInterviews from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/RejectedInterviews'

export const recruitmentSubtabs: TabBarWithPinningTabInterface[] = [
  {
    id: HomeSectionId.requisitions,
    title: 'Requisitions',
    path: ROUTES.RECRUITMENT.REQUISITIONS,
    url: ROUTES.RECRUITMENT.REQUISITIONS,
    permission: PermissionTypes.ViewRequisitions,
    component: RequisitionsTable,
    globalSetting: GlobalSettings.RequisitionsEnabled,
  },
  {
    id: HomeSectionId.hiringPipelines,
    title: 'Pipelines',
    path: ROUTES.RECRUITMENT.HIRING_PIPELINES.ANY,
    url: ROUTES.RECRUITMENT.HIRING_PIPELINES.MY,
    permission: PermissionTypes.ViewHiringPipeline,
    component: HiringPipelines,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
  },
  {
    id: HomeSectionId.candidates,
    title: 'Candidates',
    path: ROUTES.RECRUITMENT.CANDIDATES,
    url: ROUTES.RECRUITMENT.CANDIDATES,
    permission: PermissionTypes.ViewCandidate,
    component: () => <CommonCandidatesTable type="common" />,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
  },
  {
    id: HomeSectionId.crm,
    title: 'CRM',
    path: ROUTES.RECRUITMENT.CRM,
    url: ROUTES.RECRUITMENT.CRM,
    permission: PermissionTypes.ViewInterviewRoundCRM,
    component: CRMTable,
    globalSetting: GlobalSettings.CommercialProductDisabled,
  },
  {
    id: HomeSectionId.interviews,
    title: 'Interviews',
    path: ROUTES.RECRUITMENT.INTERVIEWS.ANY,
    url: ROUTES.RECRUITMENT.INTERVIEWS.PENDING,
    permission: PermissionTypes.ViewInterviewFeedback,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
    subtabs: [
      {
        id: 'pending',
        path: ROUTES.RECRUITMENT.INTERVIEWS.PENDING,
        to: ROUTES.RECRUITMENT.INTERVIEWS.PENDING,
        title: 'Pending',
        component: PendingInterviews,
      },
      {
        id: 'completed',
        path: ROUTES.RECRUITMENT.INTERVIEWS.COMPLETED,
        to: ROUTES.RECRUITMENT.INTERVIEWS.COMPLETED,
        title: 'Completed',
        component: CompletedInterviews,
      },
      {
        id: 'rejected',
        path: ROUTES.RECRUITMENT.INTERVIEWS.REJECTED,
        to: ROUTES.RECRUITMENT.INTERVIEWS.REJECTED,
        title: 'Rejected',
        component: RejectedInterviews,
      },
    ],
  },
  {
    id: HomeSectionId.jobPostings,
    title: 'Job postings',
    path: ROUTES.RECRUITMENT.JOB_POSTINGS,
    url: ROUTES.RECRUITMENT.JOB_POSTINGS,
    permission: PermissionTypes.ViewJobdescriptions,
    component: JobPostingsTable,
    globalSetting: GlobalSettings.JobPostingsEnabled,
  },
  {
    id: HomeSectionId.hiringStages,
    title: 'Hiring stages',
    path: ROUTES.RECRUITMENT.HIRING_STAGES,
    url: ROUTES.RECRUITMENT.HIRING_STAGES,
    permission: PermissionTypes.ViewHiringstage,
    component: HiringTable,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
  },
  {
    id: HomeSectionId.interviewScorecardTemplates,
    title: 'Interview scorecard templates',
    path: ROUTES.RECRUITMENT.INTERVIEW_SCORECARD_TEMPLATES,
    url: ROUTES.RECRUITMENT.INTERVIEW_SCORECARD_TEMPLATES,
    permission: PermissionTypes.ViewInterviewScorecardTemplate,
    component: InterviewScorecardTemplatesTable,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
  },
  {
    id: HomeSectionId.offerForms,
    title: 'Offer forms',
    path: ROUTES.RECRUITMENT.OFFER_FORMS.ANY,
    url: ROUTES.RECRUITMENT.OFFER_FORMS.ANY,
    globalSetting: GlobalSettings.OffersTemplatesEnabled,
    subtabs: [
      {
        id: 'offers',
        path: ROUTES.RECRUITMENT.OFFER_FORMS.OFFERS,
        to: ROUTES.RECRUITMENT.OFFER_FORMS.OFFERS,
        title: 'Offers',
        component: OffersTable,
        permission: PermissionTypes.ViewOfferForm,
      },
      {
        id: 'offer-placeholders',
        path: ROUTES.RECRUITMENT.OFFER_FORMS.OFFER_PLACEHOLDERS,
        to: ROUTES.RECRUITMENT.OFFER_FORMS.OFFER_PLACEHOLDERS,
        title: 'Offer placeholders',
        component: OfferPlaceholders,
        permission: PermissionTypes.ViewOfferFormTemplate,
      },
      {
        id: 'offer-templates',
        path: ROUTES.RECRUITMENT.OFFER_FORMS.OFFER_TEMPLATES,
        to: ROUTES.RECRUITMENT.OFFER_FORMS.OFFER_TEMPLATES,
        title: 'Offer templates',
        component: OfferTemplates,
        permission: PermissionTypes.ViewOfferFormTemplate,
      },
    ],
  },
  {
    id: HomeSectionId.bookingLinks,
    title: 'Booking Links',
    path: ROUTES.RECRUITMENT.BOOKING_LINKS,
    url: ROUTES.RECRUITMENT.BOOKING_LINKS,
    permission: PermissionTypes.ViewBookingLinkDefinition,
    component: () => <BookingLinksTable />,
    globalSetting: GlobalSettings.CandidateSchedulingEnabled,
  },
  {
    id: HomeSectionId.bulkActions,
    title: 'Bulk actions',
    path: ROUTES.RECRUITMENT.BULK_ACTIONS.ANY,
    url: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_EMAILS,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
    permission: PermissionTypes.ViewCandidate,
    subtabs: [
      {
        id: 'candidate_emails',
        path: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_EMAILS,
        to: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_EMAILS,
        title: 'Candidate emails',
        component: CandidateBulkEmails,
      },
      {
        id: 'candidate_archive',
        path: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_ARCHIVE,
        to: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_ARCHIVE,
        title: 'Candidate archive',
        component: CandidateBulkArchive,
      },
      {
        id: 'candidate_edit',
        path: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_EDIT,
        to: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_EDIT,
        title: 'Candidate edit',
        component: CandidateBulkEdit,
      },
      {
        id: 'candidate_send_online_test',
        path: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_SEND_ONLINE_TEST,
        to: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_SEND_ONLINE_TEST,
        title: 'Candidate send online test',
        component: CandidateBulkSendOnlineTest,
      },
      {
        id: 'candidate_move_stage',
        path: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_MOVE_STAGE,
        to: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_MOVE_STAGE,
        title: 'Candidate move to stage',
        component: CandidateBulkMoveStage,
      },
      {
        id: 'candidate_snooze',
        path: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_SNOOZE,
        to: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_SNOOZE,
        title: 'Candidate snooze',
        component: CandidateBulkSnooze,
      },
      {
        id: 'candidate_unsnooze',
        path: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_UNSNOOZE,
        to: ROUTES.RECRUITMENT.BULK_ACTIONS.CANDIDATE_UNSNOOZE,
        title: 'Candidate unsnooze',
        component: CandidateBulkUnsnooze,
      },
    ],
  },
]
