import { useEffect, useState } from 'react'
import {
  CellTypes,
  ColumnCellInterface,
  RowInterface,
  SortByInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import {
  ReportColumnsTableInterface,
  ReportInterface,
  QueryColumnInterface,
  ReportHighlightInterface,
  ReportFilterInterface,
} from '@src/interfaces/dataAnalytics'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'

export interface ReorderSettingsInterface {
  hidden: ReportColumnsTableInterface[]
  visible: ReportColumnsTableInterface[]
}

export interface FilterSettingsInterface {
  hidden: ReportFilterInterface[]
  visible: ReportFilterInterface[]
}

export interface SortSettingsInterface {
  hidden: SortByInterface[]
  visible: SortByInterface[]
}

export const orderingToSort = (ordering: string[]): SortByInterface[] => {
  return ordering.map(val => {
    const desc = val[0] === '-'

    if (desc) {
      return {
        sortBy: val.slice(1),
        direction: SORT_DIRECTION.DESC,
      }
    }

    return {
      sortBy: val,
      direction: SORT_DIRECTION.ASC,
    }
  })
}

const sortToOrdering = (sort: SortByInterface[]): string[] => {
  return sort.map(val =>
    val.direction === SORT_DIRECTION.DESC ? `-${val.sortBy}` : val.sortBy,
  )
}

export const useTableSettings = () => {
  const { values } = useLapeContext<ReportInterface>()

  const allVisibleColumnsReorder: ReorderSettingsInterface = {
    visible: values.query.columns.map(column => ({
      name: column.name,
      type: column.type,
      alias: null,
    })),
    hidden: [],
  }

  const initialReorderSettings = values.output_format.columns
    ? {
        visible: values.output_format.columns,
        hidden: allVisibleColumnsReorder.visible.filter(
          column => !values.output_format.columns.find(vCol => vCol.name === column.name),
        ),
      }
    : allVisibleColumnsReorder

  let initialSortSettings: SortSettingsInterface = {
    visible: [],
    hidden: initialReorderSettings.visible.map(column => ({
      sortBy: column.name,
      direction: SORT_DIRECTION.DESC,
    })),
  }

  let initialFilterSettings: FilterSettingsInterface = {
    visible: values.output_format.filters || [],
    hidden: initialReorderSettings.visible
      .filter(
        vCol =>
          !values.output_format.filters?.find(column => column.column_name === vCol.name),
      )
      .map(col => ({
        column_name: col.name,
        default_value: null,
      })),
  }

  if (values.output_format.ordering) {
    const visibleColumnsSort = orderingToSort(values.output_format.ordering).filter(
      vCol => initialReorderSettings.visible.find(column => column.name === vCol.sortBy),
    )

    initialSortSettings = {
      visible: visibleColumnsSort,
      hidden: initialReorderSettings.visible
        .filter(column => !visibleColumnsSort.find(vCol => vCol.sortBy === column.name))
        .map(col => ({
          sortBy: col.name,
          direction: SORT_DIRECTION.DESC,
        })),
    }
  }

  const [reorderSettings, setReorderSettings] =
    useState<ReorderSettingsInterface>(initialReorderSettings)
  const [filterSettings, setFilterSettings] =
    useState<FilterSettingsInterface>(initialFilterSettings)
  const [sortSettings, setSortSettings] =
    useState<SortSettingsInterface>(initialSortSettings)
  const [highlightSettings, setHighlightSettings] = useState<
    ReportHighlightInterface[] | null
  >(values.output_format.highlighting || null)
  const [tableRows, setTableRows] = useState<RowInterface<QueryColumnInterface>>({
    cells: [],
  })

  useEffect(() => {
    values.output_format.columns = [...reorderSettings.visible]

    setSortSettings(current => {
      const visibleColumns = current.visible.filter(vCol =>
        reorderSettings.visible.find(column => column.name === vCol.sortBy),
      )

      return {
        visible: visibleColumns,
        hidden: reorderSettings.visible
          .filter(column => !visibleColumns.find(vCol => vCol.sortBy === column.name))
          .map(col => ({
            sortBy: col.name,
            direction: SORT_DIRECTION.DESC,
          })),
      }
    })

    setFilterSettings(current => {
      const visibleColumns = current.visible.filter(vCol =>
        reorderSettings.visible.find(column => column.name === vCol.column_name),
      )

      return {
        visible: visibleColumns,
        hidden: reorderSettings.visible
          .filter(
            column => !visibleColumns.find(vCol => vCol.column_name === column.name),
          )
          .map(col => ({
            column_name: col.name,
            default_value: null,
          })),
      }
    })

    setTableRows({
      cells: reorderSettings.visible.map(
        key =>
          ({
            type: CellTypes.text,
            idPoint: key.name,
            dataPoint: key.name,
            sortKey: key.name,
            filterKey: null,
            selectorsKey: selectorKeys.none,
            title: key.alias || key.name,
            width: 300,
          } as ColumnCellInterface<QueryColumnInterface>),
      ),
    })
  }, [reorderSettings])

  useEffect(() => {
    values.output_format.ordering = sortToOrdering(sortSettings.visible)
  }, [sortSettings])

  useEffect(() => {
    values.output_format.filters = filterSettings.visible
  }, [filterSettings])

  useEffect(() => {
    values.output_format.highlighting = highlightSettings
  }, [highlightSettings])

  return {
    reorderSettings,
    setReorderSettings,
    sortSettings,
    setSortSettings,
    filterSettings,
    setFilterSettings,
    highlightSettings,
    setHighlightSettings,
    tableRows,
    setTableRows,
  }
}
