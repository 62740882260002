import React from 'react'
import styled from 'styled-components'
import { CDN_BUCKET } from '@src/constants/externalLinks'

type Props = {
  url: string
  poster?: string
}

const VideoPlayer = styled.video`
  border-radius: 20px;
  outline: none;
  width: 100%;
  height: auto;
`

export const Video = ({ url, poster }: Props) => {
  const fullUrl = url.startsWith('http') ? url : `${CDN_BUCKET}/videos${url}`

  let fullUrlPoster: string | undefined

  if (poster) {
    fullUrlPoster = poster.startsWith('http') ? poster : `${CDN_BUCKET}/videos${poster}`
  }

  return (
    <VideoPlayer controls poster={fullUrlPoster}>
      <source src={fullUrl} type="video/mp4" />
    </VideoPlayer>
  )
}
