import { GoalsInterface } from '@src/interfaces/goals'
import { KpiInterface } from '@src/interfaces/kpis'
import { TargetFormInterface } from '../../SidebarForms/AddGoalTargetForm'
import get from 'lodash/get'

export const getTargetBoundaries = (
  kpi: Partial<KpiInterface>,
): Partial<Pick<KpiInterface, 'initial_value' | 'target' | 'unit'>> => {
  const defaultBoundaries = {
    initial_value: 0,
    target: 100,
    unit: '%',
  }

  const target = kpi.targets && kpi.targets[0]

  if (target) {
    return {
      initial_value: target.initial_value === null ? undefined : target.initial_value,
      target: target.target === null ? undefined : target.target,
      unit: kpi.unit,
    }
  }

  return defaultBoundaries
}

const getNameIfEpic = (kpi: Partial<KpiInterface>): string | undefined => {
  return (
    kpi.target_epics &&
    kpi.target_epics[0] &&
    kpi.target_epics[0].epics &&
    kpi.target_epics[0].epics[0]?.name
  )
}

export const mapKpi = (kpi: KpiInterface, goal: GoalsInterface) => {
  return {
    ...kpi,
    name: kpi.name || getNameIfEpic(kpi) || goal.name,
    ...getTargetBoundaries(kpi),
  }
}

// lape field cleans up validation errors by set(field, undefined) which keeps the key
// means Object.keys(form.apiErrors).length would be still > 0
export const checkFormErrors = (form?: TargetFormInterface): boolean => {
  return (
    Object.keys(form?.apiErrors || {}).filter(fieldName => {
      if (fieldName === 'target_epics') {
        return get(form?.apiErrors, 'target_epics.0.epics')
      }
      if (fieldName === 'targets') {
        // targets is an artificial error thrown by FE to prevent goal submitting when one of targets submit failed
        // we want to keep it till any of fields in the form has error
        // we may need to add more fields here
        return (
          get(form?.apiErrors, 'targets.0.initial_value') ||
          get(form?.apiErrors, 'targets.0.target')
        )
      }

      return get(form?.apiErrors, fieldName)
    }).length > 0
  )
}
