import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { goalsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { GoalsIntro } from './GoalsIntro'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.INTRO,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: GoalsIntro,
  },
]

export const Goals = () => {
  return <OnboardingChecklistContent config={goalsConfig} routes={routes} />
}
