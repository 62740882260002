import React, { useEffect } from 'react'
import { Flex, MoreBar, TableWidget } from '@revolut/ui-kit'

import { useTable, UseTableOptions } from '@components/Table/hooks'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { KpiInterface } from '@src/interfaces/kpis'
import { kpisRequests } from '@src/api/kpis'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiStrategyColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
} from '@src/constants/columns/kpi'
import { useSelector } from 'react-redux'
import { canAddTeamKpi } from '@src/store/auth/selectors'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeInterface } from '@src/interfaces/employees'
import AdjustableTable from '@components/Table/AdjustableTable'
import { getKpiPerformanceGraph } from '@src/api/employees'
import { useQuery } from '@src/utils/queryParamsHooks'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { TableNames } from '@src/constants/table'
import { rowHighlight } from '@src/features/KPI'
import { useRenderRefreshKPIsTableNote } from '@src/utils/kpi'
import { KpiHelpButton } from '@src/features/KPI/KpiHelpButton'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { PerformanceLayoutCycleFilter } from '@components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { OverallProgress } from '@src/features/Goals/components/OverallProgress'
import { foreignGoalColumn } from '@src/constants/columns/goals'
import { useGetCyclesSelector } from '@src/features/Goals/useCyclesSelector'
import { EntityTypes } from '@src/constants/api'

interface StructureProps {
  data: EmployeeInterface
  navigation?: React.ReactElement
}

export const ROW: RowInterface<KpiInterface> = {
  highlight: data => rowHighlight(data.target_status),
  disabled: data => data.target_status === Statuses.archived,
  cells: [
    {
      ...kpiGenericNameColumn,
      width: 400,
    },
    {
      ...foreignGoalColumn,
      width: 100,
    },
    {
      ...kpiPerformanceColumn,
      width: 120,
    },
    {
      ...kpiInitialValueColumn,
      width: 90,
    },
    {
      ...kpiCurrentValueColumn,
      width: 90,
    },
    {
      ...kpiTargetColumn,
      width: 90,
    },
    {
      ...kpiUnitColumn,
      width: 90,
    },
    {
      ...kpiStrategyColumn,
      width: 90,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 120,
    },
  ],
}

export const usePersonalKPITable = (
  data: EmployeeInterface,
  options?: UseTableOptions,
  filters: FilterByInterface[] = [],
) => {
  const initialFilter = [
    {
      filters: [
        { name: Statuses.active, id: Statuses.active },
        { name: Statuses.draft, id: Statuses.draft },
        { name: 'new', id: 'new' },
      ],
      columnName: 'status',
    },
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_employee',
      nonResettable: true,
    },
    {
      filters: [{ name: data.id.toString(), id: data.id }],
      columnName: 'owner__id',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      filters: [
        { name: Statuses.pending, id: Statuses.pending },
        { name: Statuses.approved, id: Statuses.approved },
        { name: Statuses.future, id: Statuses.future },
        { name: Statuses.completed, id: Statuses.completed },
      ],
      columnName: 'target_status',
      nonResettable: true,
    },
    ...filters,
  ]

  return useTable<KpiInterface>(kpisRequests, initialFilter, [], options)
}

export const EmployeeTargets = ({ data, navigation }: StructureProps) => {
  const { query, deleteQueryParam } =
    useQuery<{ cycle__id: string; review_cycle__offset?: string }>()
  const isNewLayout = useIsNewLayout()
  const table = usePersonalKPITable(data, { disable: !query.cycle__id })
  const canAdd = useSelector(canAddTeamKpi)
  const { initialCycle, cycleSelector, getCycleById } = useGetCyclesSelector({
    data,
    type: EntityTypes.employees,
  })

  useEffect(() => {
    if (!initialCycle || query.cycle__id) {
      return
    }

    table.onFilterChange({
      filters: [{ name: String(initialCycle.name), id: String(initialCycle.id) }],
      columnName: 'cycle__id',
    })
  }, [initialCycle])

  useEffect(() => {
    if (query.review_cycle__offset !== undefined) {
      const cycleId = query.review_cycle__offset?.split(',')?.[0]

      deleteQueryParam('review_cycle__offset')

      if (cycleId !== undefined) {
        table.onFilterChange({
          filters: [{ name: String(cycleId), id: String(cycleId) }],
          columnName: 'cycle__id',
        })
      }
    }
  }, [])

  const canChangeKpiWeight = data.field_options.permissions?.includes(
    PermissionTypes.ChangeKpiWeight,
  )

  const canRefreshKpis = data.field_options.permissions?.includes(
    PermissionTypes.CanRefreshKpis,
  )

  const { refreshNote, refreshButton } = useRenderRefreshKPIsTableNote(
    table,
    canRefreshKpis,
  )

  const handleEditKPIs = () => {
    const baseUrl = pathToUrl(ROUTES.FORMS.ASSIGN_KPIS.PERSONAL, { id: data.id })
    const cycle = getCycleById(query.cycle__id?.split(',')?.[0])

    if (cycle?.category === 'probation') {
      navigateTo(
        pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS, {
          employeeId: data.id,
          cycleId: cycle.id,
        }),
      )
      return
    }

    const offset = cycle?.offset || 0
    navigateTo(`${baseUrl}?review_cycle__offset=${offset}`)
  }

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
      initialValues: getKPIFormInitialValues(
        {
          id: data.id,
          name: data.full_name,
          team: {
            id: data.team.id,
            name: data.team.name,
          },
        },
        { is_employee: true },
      ),
    })
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        {isNewLayout ? (
          <PerformanceLayoutCycleFilter
            onFilterChange={table.onFilterChange}
            columnName="cycle__id"
            filter={table.filterBy}
            selector={cycleSelector}
          />
        ) : (
          <CycleFilter
            type={CycleFilterType.NewUI}
            onFilterChange={table.onFilterChange}
            columnName="cycle__id"
            filter={table.filterBy}
            selector={cycleSelector}
            filterInputType={FilterSelectType.SingleSelect}
          />
        )}
        <OverallProgress
          value={table?.stats?.avg_performance}
          fetchData={getKpiPerformanceGraph}
          id={data.id}
          tooltip="Overall progress is calculated as the weighted average of all approved KPIs."
        />
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          {canAdd && (
            <MoreBar.Action onClick={handleNewRow} useIcon="Plus">
              Add new KPI
            </MoreBar.Action>
          )}
          {canChangeKpiWeight && (
            <MoreBar.Action onClick={handleEditKPIs} useIcon="Pencil">
              Edit KPIs
            </MoreBar.Action>
          )}
          {refreshButton}
          <KpiHelpButton />
        </MoreBar>
      </TableWidget.Actions>
      {navigation && (
        <TableWidget.Search>
          <Flex justifyContent="flex-end">{navigation}</Flex>
        </TableWidget.Search>
      )}
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          expandableType="chevron"
          name={TableNames.EmployeePersonalKPIs}
          useWindowScroll
          dataType="KPI"
          row={ROW}
          {...table}
          noDataMessage="Please add your personal KPIs."
          onRowClick={row =>
            navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: row.id }))
          }
        />
        {refreshNote}
      </TableWidget.Table>
    </TableWidget>
  )
}
