import React, { useState } from 'react'
import { GoalsPreviewHeader } from './Header'
import { GoalsPreviewLeftSide } from './LeftSide'
import { PageWrapper } from '@src/components/Page/Page'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalPreviewInterface } from '@src/interfaces/goals'
import { captureException } from '@sentry/react'
import { BREAKPOINTS } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'

export const GoalPreviewPage = () => {
  const { values } = useLapeContext<GoalPreviewInterface>()

  const availableCycles =
    values.goal_cycles
      ?.map(details => details.review_cycle)
      .sort((a, b) => b.offset - a.offset) || []

  const initialCycle =
    availableCycles.find(({ offset }) => offset === 0) || availableCycles[0]

  const [cycle, setCycle] = useState<ReviewCyclesInterface>(initialCycle)

  return (
    <PageWrapper>
      <PageBody maxWidth={{ all: BREAKPOINTS.lg, xxl: BREAKPOINTS.xl }}>
        <GoalsPreviewHeader cycle={cycle} />
        {cycle ? (
          <GoalsPreviewLeftSide
            cycle={cycle}
            availableCycles={availableCycles}
            onCycleChanged={id => {
              const newCycle = availableCycles.find(c => c.id === id)!
              setCycle(newCycle)
              if (!newCycle) {
                // should not ever happen
                const meta = {
                  cycleId: id,
                  availableCyclesId: availableCycles.map(c => c.id),
                }
                captureException(`Failed to update cycle in the goal preview page`, {
                  extra: meta,
                })
              }
            }}
          />
        ) : null}
      </PageBody>
    </PageWrapper>
  )
}
